import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";
import "./InfiniteScrollSpinner.scss";

const InfiniteScrollSpinner = () => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <div className="InfiniteScrollSpinner_container">
      <div className={`loader loader_${teamsTheme}`}></div>
    </div>
  );
};

export default InfiniteScrollSpinner;
