import moment from "moment";
import AUTH from "../constants/auth";
import localStorageManager from "../utils/localStorageManager";

const { refreshToken, userId, expireTime, accessToken, dataAnalysis } = AUTH;
const { getStorage, setStorage, clearStorage } = localStorageManager;

//토큰 갱신 요청 중복 제어를 위한 Lock
export const tokenRefreshLock = {
  isRefreshing: false,
  queue: [] as (() => void)[], // 콜백 함수의 배열로 타입 지정
  acquireLock: function () {
    if (this.isRefreshing) {
      return new Promise<void>((resolve) => {
        this.queue.push(resolve);
      });
    } else {
      this.isRefreshing = true;
      return Promise.resolve();
    }
  },
  releaseLock: function () {
    this.isRefreshing = false;
    const nextResolve = this.queue.shift();
    if (nextResolve) {
      nextResolve();
    }
  },
};

export const getNewAccessToken = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVICE_URL}/auth/refresh_access_token`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token: getStorage(refreshToken),
          user_id: getStorage(userId),
        }),
      }
    );

    if (response.ok) {
      const { access_token, refresh_token, expires_delta, canAnalyzeFile } =
        await response.json();

      if (access_token) {
        setStorage(accessToken, access_token);
        setStorage(refreshToken, refresh_token);
        setStorage(
          expireTime,
          moment(moment.utc(expires_delta).toDate()).format("YYYYMMDDHHmm")
        );
        setStorage(dataAnalysis, canAnalyzeFile);
        window.dispatchEvent(new Event("storage"));
        return access_token;
      }
    }
  } catch (error) {
    redirectLogin();
  }
};

export const getAuthTokenHandler = async () => {
  await tokenRefreshLock.acquireLock();

  const originExpireTime = getStorage(expireTime);
  const originToken = getStorage(accessToken);

  if (!originExpireTime || !originToken) {
    redirectLogin();
  }

  try {
    if (originExpireTime) {
      const isExpired =
        Number(originExpireTime) - Number(moment().format("YYYYMMDDHHmm")) <=
        10;
      if (!isExpired) return originToken;

      const newToken = await getNewAccessToken();
      if (!newToken) redirectLogin();

      return newToken;
    }
  } finally {
    tokenRefreshLock.releaseLock();
  }
};

const redirectLogin = () => {
  clearStorage();
  window.location.href = "/login";
};
