import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import { memo } from "react";

type Props = {
  checked: boolean;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  radioId: string;
  labelName: string;
};

const InputRadioLabel: React.FC<Props> = ({
  checked,
  onChangeHandler,
  radioId,
  labelName,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <div className="input_label_wrap">
      <input
        className={`input_checkbox_radio`}
        checked={checked}
        onChange={onChangeHandler}
        type="radio"
        id={`${radioId}`}
      />
      <label className={`checkbox_label_${teamsTheme}`} htmlFor={`${radioId}`}>
        {labelName}
      </label>
    </div>
  );
};

export default memo(InputRadioLabel);
