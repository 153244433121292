import { useMediaQuery } from "react-responsive";
import { mediaQuery } from "../../constants/responsiveView";

const useResponsiveView = () => {
  const isBasicMobile = useMediaQuery({
    query: mediaQuery.MAX_WIDTH_MOBILE,
  });
  const isBasicTablet = useMediaQuery({
    query: `${mediaQuery.MIN_WIDTH_TABLET} and ${mediaQuery.MAX_WIDTH_TABLET}`,
  });
  const isTabletPro = useMediaQuery({
    query: mediaQuery.MAX_WIDTH_TABLET_PRO,
  });
  const isBasicDesktop = useMediaQuery({
    query: mediaQuery.MIN_WIDTH_DESKTOP,
  });

  const isMobile = isBasicMobile;
  const isTablet = isBasicTablet || isTabletPro;
  const isDesktop = isBasicDesktop && !isTabletPro;
  return { isTablet, isMobile, isDesktop };
};

export default useResponsiveView;

//   type Breakpoint = "xs" | "sm" | "tablet" | "xlTablet" | "desktop" | "xlDesktop";
//     const [breakpoint, setBreakpoint] = useState<Breakpoint>('xs');

//     useEffect(() => {
//         const handleResize = () => {
//             const width = window.innerWidth;
//             const height = window.innerHeight;

//             let newBreakpoint: Breakpoint;

//             if (width < 576) {
//                 newBreakpoint = 'xs';
//             } else if (width < 768) {
//                 newBreakpoint = 'sm';
//             } else if (width < 992) {
//               newBreakpoint = "tablet";
//             } else if (width < 1025 && height < 1367) {
//               newBreakpoint = "xlTablet";
//             } else if (width < 1025) {
//               newBreakpoint = "desktop";
//             } else {
//               newBreakpoint = "xlDesktop";
//             }

//             setBreakpoint(newBreakpoint);
//         };

//         window.addEventListener('resize', handleResize);
//         handleResize(); // Initial check

//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     return breakpoint;
