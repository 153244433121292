import "../admin/admin.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Pagenator from "../../components/common/Pagenator";
import { useAppDispatch } from "../../../features";
import { selectTeamsTheme } from "../../../features/auth";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import FilterInputTextType from "../../components/userInterface/filters/FilterInputTextType";
import CreatedMyAIChatTable from "../../components/myAIChats/CreatedMyAIChatTable";
import {
  deleteMyGPTSAsync,
  getMyGPTsAsync,
  selectMyGptsSideBarList,
  selectMyGptsTotalCnt,
} from "../../../features/myGpts";
import DeleteModal from "../../components/common/DeleteModal";
import MobileCreatedMyAIChatTable from "../../components/myAIChats/MobileCreatedMyAIChatTable";

const MyAIChatMgmtPage = () => {
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const totalCount = useSelector(selectMyGptsTotalCnt);
  // const windowSize = useWindowResize();
  const mySideBarGPTsList = useSelector(selectMyGptsSideBarList);

  const [pageIndex, setPageIndex] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<string>("");

  const [searchText, setSearchText] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [pageSize, setPageSize] = useState(window.innerWidth <= 1024 ? 6 : 10);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    getMyGptsList();

    if (window.innerWidth <= 1024) {
      setPageSize(6);
    } else {
      setPageSize(10);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 1024) {
      setPageSize(6);
    } else {
      setPageSize(10);
    }
  }, [windowWidth]);

  useEffect(() => {
    getMyGptsList();
  }, [pageSize]);

  const getMyGptsList = (idx?: number, deleteSearch?: boolean) => {
    dispatch(
      getMyGPTsAsync.request({
        pageIndex: idx ? idx : pageIndex,
        pageSize: pageSize,
        search: deleteSearch ? "" : searchText,
      })
    );

    if (deleteSearch) {
      setSearchText("");
    }
  };

  const setCurrentPage = (currentPage: number) => {
    setPageIndex(currentPage);
    getMyGptsList(currentPage);
  };

  const deleteHandler = () => {
    //
    const isDeletedGPTsObj = mySideBarGPTsList.find(
      (elem) => elem.id === selectedDeleteId
    );

    dispatch(
      deleteMyGPTSAsync.request({
        myGPTsId: selectedDeleteId,
        search: searchText,
        pageIndex: pageIndex,
        pageSize: pageSize,
        isNewSidebarMyGPTs: isDeletedGPTsObj ? true : false,
      })
    );
    setDeleteModal(false);
    setSelectedDeleteId("");
  };

  return (
    <>
      {deleteModal && selectedDeleteId.length > 0 && (
        <DeleteModal
          deleteHandler={deleteHandler}
          onClose={() => setDeleteModal(false)}
        />
      )}
      <div className={`mgmt_page_filter mgmt_page_filter_${teamsTheme}`}>
        <FilterInputTextType
          title="Search"
          value={searchText}
          onChangeHandler={(e) => setSearchText(e.target.value)}
          deleteInputHandler={() => setSearchText("")}
        />

        <div className="filter_tool_btns">
          <MainColorBtn
            onClickHandler={() => getMyGptsList(1)}
            btnName="Search"
          />
        </div>
      </div>
      <CreatedMyAIChatTable
        setSelectedDeleteId={setSelectedDeleteId}
        setDeleteModal={setDeleteModal}
      />

      <MobileCreatedMyAIChatTable
        setDeleteModal={setDeleteModal}
        setSelectedDeleteId={setSelectedDeleteId}
      />
      <Pagenator
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={pageIndex}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default MyAIChatMgmtPage;
