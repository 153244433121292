import "./Modal.scss";
import { selectTeamsTheme } from "../../../features/auth";
import { useSelector } from "react-redux";
import ModalCancelBtn from "../userInterface/buttons/ModalCancelBtn";
import ModalRegisterBtn from "../userInterface/buttons/ModalRegisterBtn";

type Props = {
  deleteHandler: () => void;
  onClose: () => void;
};

const DeleteModal: React.FC<Props> = ({ onClose, deleteHandler }) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <div className={`modal_black_bg modal_black_bg_${teamsTheme}`}>
      <div className={`modal_white_bg modal_white_bg_${teamsTheme}`}>
        <p className="delete_txt">Are you sure you want to delete it?</p>

        <div
          className={`delete_modal_btn_wrap modal_btn_wrap_${teamsTheme} modal_delete_btn_wrap`}
        >
          <ModalCancelBtn onClickHandler={onClose} btnName="Cancel" />
          <ModalRegisterBtn onClickHandler={deleteHandler} btnName="Delete" />
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
