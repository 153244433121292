import axios from "axios";
import { AssistantType, IFileInfoItem } from "../chat";
import { NavigateFunction } from "react-router-dom";

export interface GetMyGPTsParam {
  pageIndex: number;
  pageSize: number;
  search: string;
}

export interface GetMyGPTsResult {
  rows: MyGPTsData[];
  totalCount: number;
}

export interface MyGPTsData {
  // RESPONSE
  id: string;
  profileImage: string;
  name: string;
  description: string;
  instructions: string;
  conversationStarters: string[];
  knowledge: IFileInfoItem[];
  capabilities: AssistantType[];
  createdDate: string;
  updatedDate: string;
}

export interface DeleteMyGPTsParam extends GetMyGPTsParam {
  myGPTsId: string;
  isNewSidebarMyGPTs: boolean;
}

export interface SetMyGptsParam {
  id?: string | null;
  profileImage: string | ArrayBuffer | null;
  name: string;
  description: string;
  instructions: string;
  conversationStarters: string[];
  modifyKnowledge?: IFileInfoItem[];
  knowledge: File[];
  capabilities: AssistantType[];
  deleteAttachId: string[];
  updatedDate?: string;
}

export interface GetMyGptsDetailParam {
  myGPTsId: string;
}

export interface AddMyGptsParam {
  param: SetMyGptsParam;
  navigate: any;
}

export interface IUpdateMyGPTsPayload {
  param: SetMyGptsParam;
  navigate: NavigateFunction | null;
}

export const getMyGpts = async (param: GetMyGPTsParam) => {
  const res = await axios.post(`/assistant/getMyGPTs`, param);

  return res.data;
};

export const getMyGptsDetail = async (param: GetMyGptsDetailParam) => {
  const res = await axios.post(`/assistant/getMyGPTsDetail`, param);

  return res.data;
};

export const updateMyGpts = async (param: SetMyGptsParam) => {
  const formData = new FormData();

  if (param.id) {
    formData.append("myGPTsId", param.id);
  }

  if (param.profileImage) {
    let profileImageBlob: Blob | string;
    if (param.profileImage instanceof ArrayBuffer) {
      profileImageBlob = new Blob([param.profileImage]);
    } else {
      profileImageBlob = param.profileImage;
    }
    formData.append("profileImage", profileImageBlob);
  }
  if (param.deleteAttachId.length) {
    param.deleteAttachId.forEach((_, index) => {
      formData.append(`deleteAttachId`, param.deleteAttachId[index]);
    });
  }
  formData.append("name", param.name);
  formData.append("description", param.description);
  formData.append("instructions", param.instructions);

  param.conversationStarters.forEach((starter, index) => {
    formData.append(`conversationStarters`, param.conversationStarters[index]);
  });

  param.knowledge.forEach((file, index) => {
    formData.append(`knowledge`, param.knowledge[index]);
  });

  param.capabilities.forEach((capability, index) => {
    formData.append(`capabilities`, param.capabilities[index]);
  });

  const res = await axios.post(`/assistant/setMyGPTsConfig`, formData);

  return res.data;
};

export const addMyGpts = async (param: AddMyGptsParam) => {
  const data = param.param;
  const formData = new FormData();

  if (data.profileImage) {
    let profileImageBlob: Blob | string;
    if (data.profileImage instanceof ArrayBuffer) {
      profileImageBlob = new Blob([data.profileImage]);
    } else {
      profileImageBlob = data.profileImage;
    }
    formData.append("profileImage", profileImageBlob);
  }
  formData.append("name", data.name);
  formData.append("description", data.description);
  formData.append("instructions", data.instructions);

  data.conversationStarters.forEach((starter, index) => {
    formData.append(`conversationStarters`, data.conversationStarters[index]);
  });

  data.knowledge.forEach((file, index) => {
    formData.append(`knowledge`, data.knowledge[index]);
  });

  data.capabilities.forEach((capability, index) => {
    formData.append(`capabilities`, data.capabilities[index]);
  });

  const res = await axios.post(`/assistant/setMyGPTsConfig`, formData);

  return res.data;
};

export const editMyGpts = async (param: AddMyGptsParam) => {
  const data = param.param;
  const formData = new FormData();

  if (data.id) {
    formData.append("myGPTsId", data.id);
  }
  if (data.profileImage) {
    let profileImageBlob: Blob | string;
    if (data.profileImage instanceof ArrayBuffer) {
      profileImageBlob = new Blob([data.profileImage]);
    } else {
      profileImageBlob = data.profileImage;
    }
    formData.append("profileImage", profileImageBlob);
  }
  formData.append("name", data.name);
  formData.append("description", data.description);
  formData.append("instructions", data.instructions);

  data.conversationStarters.forEach((starter, index) => {
    formData.append(`conversationStarters`, data.conversationStarters[index]);
  });

  data.deleteAttachId.forEach((starter, index) => {
    formData.append(`deleteAttachId`, data.deleteAttachId[index]);
  });

  data.knowledge.forEach((file, index) => {
    formData.append(`knowledge`, data.knowledge[index]);
  });

  data.capabilities.forEach((capability, index) => {
    formData.append(`capabilities`, data.capabilities[index]);
  });

  const res = await axios.post(`/assistant/setMyGPTsConfig`, formData);

  return res.data;
};

export const deleteMyGpts = async (myGPTsId: string) => {
  await axios.post(`/assistant/deleteMyGPTs`, {
    myGPTsId,
  });

  return { myGPTsId };
};

export const saveMyGpts = async (param: SetMyGptsParam) => {
  const formData = new FormData();

  if (param.id) {
    formData.append("myGPTsId", param.id);
  }
  if (param.profileImage) {
    let profileImageBlob: Blob | string;
    if (param.profileImage instanceof ArrayBuffer) {
      profileImageBlob = new Blob([param.profileImage]);
    } else {
      profileImageBlob = param.profileImage;
    }
    formData.append("profileImage", profileImageBlob);
  }
  formData.append("name", param.name);
  formData.append("description", param.description);
  formData.append("instructions", param.instructions);

  param.conversationStarters.forEach((starter, index) => {
    formData.append(`conversationStarters`, param.conversationStarters[index]);
  });

  param.deleteAttachId.forEach((starter, index) => {
    formData.append(`deleteAttachId`, param.deleteAttachId[index]);
  });

  param.knowledge.forEach((file, index) => {
    formData.append(`knowledge`, param.knowledge[index]);
  });

  param.capabilities.forEach((capability, index) => {
    formData.append(`capabilities`, param.capabilities[index]);
  });

  const res = await axios.post(`/assistant/setMyGPTsConfig`, formData);

  return res.data;
};
