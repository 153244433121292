import { useState } from "react";
import ModalCancelBtn from "../../../userInterface/buttons/ModalCancelBtn";
import ModalRegisterBtn from "../../../userInterface/buttons/ModalRegisterBtn";
import {
  AddModelInfo,
  ModelInfo,
  RegisteredModelInfo,
  addModelAsync,
  updateModelAsync,
} from "../../../../../features/model";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../../features/auth";
import "../../MgmtModal.scss";
import { useAppDispatch } from "../../../../../features";

import { openAlert } from "../../../../../features/alert";
import { ModalTitleTypes } from "../../../../../features/admin";

type Props = {
  pageCount: number;
  setPageCount: React.Dispatch<React.SetStateAction<number>>;
  title: ModalTitleTypes;
  onClose: () => void;
  selectDevelopmentIds: {
    development: RegisteredModelInfo;
    tags: string[];
  }[];
  modifyData: ModelInfo | undefined;
};

const ModalControlButtons: React.FC<Props> = ({
  pageCount,
  setPageCount,
  onClose,
  selectDevelopmentIds,
  title,
  modifyData,
}) => {
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);

  const registerHandler = () => {
    if (modifyData) {
      const updateData = {
        gmodel_id: modifyData._id,
        tags: selectDevelopmentIds[0].tags,
      };

      dispatch(updateModelAsync.request(updateData));
    } else {
      const transformData = selectDevelopmentIds.map((data) => {
        return {
          deployment_id: data.development.deploymentId,
          tags: data.tags,
        };
      });

      dispatch(
        addModelAsync.request({
          addData: { createModelInfos: transformData as AddModelInfo[] },
          getData: { searchText: "", pageIndex: 1, pageSize: 10 },
        })
      );
    }

    onClose();
  };

  return (
    <div className={`modal_btn_wrap modal_btn_wrap_${teamsTheme}`}>
      <ModalCancelBtn onClickHandler={onClose} btnName="Cancel" />
      {pageCount === 2 && title !== "Modify" && (
        <ModalCancelBtn
          onClickHandler={() => setPageCount(1)}
          btnName={"Prev"}
        />
      )}
      {pageCount === 1 && title !== "Modify" && (
        <ModalRegisterBtn
          onClickHandler={() => {
            if (selectDevelopmentIds.length === 0) {
              dispatch(openAlert({ alertMsg: "Please select model" }));
              return;
            }
            setPageCount(2);
          }}
          btnName={"Next"}
        />
      )}
      {pageCount === 2 && (
        <ModalRegisterBtn onClickHandler={registerHandler} btnName={title} />
      )}
    </div>
  );
};

export default ModalControlButtons;
