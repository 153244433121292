import "./SelectGPTModel.scss";
import Select from "react-select";
import { useAppDispatch } from "../../../../features";
import { useSelector } from "react-redux";
import {
  SelectGPTOption,
  selectChatLoading,
  selectModelEngine,
  selectSelectedGptOption,
  setModelEngine,
  setSelectedGptOption,
} from "../../../../features/chat";
import parse from "html-react-parser";
import clsx from "clsx";
import {
  selectChatLoader,
  selectUploadFileLoading,
} from "../../../../features/ui_loading";
import { selectTeamsTheme } from "../../../../features/auth";

export const GPT_SELECT_OPTIONS: SelectGPTOption[] = [
  {
    value: "3.5",
    label: parse(
      `<div class='gpt-select__option_title'>GPT 3.5</div> <div class='gpt-select__option_description'>Great for everyday tasks</div>`
    ),
    description: "Great for everyday tasks",
    chatModel: "3.5v",
  },
  {
    value: "4.0",
    label: parse(
      `<div class='gpt-select__option_title'>GPT 4.0</div> <div class='gpt-select__option_description'>Advanced model for complex tasks</div>`
    ),
    description: "Advanced model for complex tasks",
    chatModel: "4.0v",
  },
];

export const SelectGPTModel = () => {
  const dispatch = useAppDispatch();
  const modelEngine = useSelector(selectModelEngine);
  const selectedGptOption = useSelector(selectSelectedGptOption);
  const answerLoading = useSelector(selectChatLoader);
  const uploadFileLoading = useSelector(selectUploadFileLoading);
  const chatLoading = useSelector(selectChatLoading);
  const teamsTheme = useSelector(selectTeamsTheme);

  const changeHandler = (newValue: any) => {
    const { value, chatModel } = newValue;
    dispatch(
      setSelectedGptOption({
        ...newValue,
        label: value,
      })
    );
    dispatch(setModelEngine(chatModel));
  };

  return (
    <Select
      isDisabled={chatLoading || uploadFileLoading || answerLoading}
      className={clsx(`gpt-select_container ${teamsTheme}`, {
        "hidden-select": modelEngine === "dalle",
      })}
      classNamePrefix="gpt-select"
      options={GPT_SELECT_OPTIONS}
      value={selectedGptOption}
      isSearchable={false}
      onChange={changeHandler}
      menuPlacement={"top"}
      // menuPosition={"fixed"}
      // menuPortalTarget={document.body}
    />
  );
};
