import { createReducer } from "typesafe-actions";
import produce from "immer";
import { MyGptsState, MyGptsAction } from "./types";
import {
  deleteMyGPTSAsync,
  getMyGPTSDetailAsync,
  getMyGPTsAsync,
  getMyGPTsSideBarAsync,
  pageChangeDeleteMyChatId,
  setMyAIChatForm,
  updateMyGPTsAsync,
} from "./actions";

const initialState = {
  myGptsList: [],
  myGptsSideBarList: [],
  myGptsTotalCount: 0,
  myAIChatForm: {
    id: "",
    profileImage: null,
    name: "",
    description: "",
    instructions: "",
    conversationStarters: [""],
    knowledge: [],
    modifyKnowledge: [],
    capabilities: [],
    deleteAttachId: [],
  },
  selectedDeleteId: [],
  selectedPreviewMyGptId: "",
};

const myGpts = createReducer<MyGptsState, MyGptsAction>(initialState)
  .handleAction(getMyGPTsAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.myGptsList = payload.rows;
      draft.myGptsTotalCount = payload.totalCount;
      draft.selectedDeleteId = [];
      draft.selectedPreviewMyGptId = "";
    });
  })

  .handleAction(getMyGPTsSideBarAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.myGptsSideBarList = payload.rows;
    });
  })

  .handleAction(setMyAIChatForm, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.myAIChatForm = payload;
    });
  })
  .handleAction(deleteMyGPTSAsync.request, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.selectedDeleteId = [...state.selectedDeleteId, payload.myGPTsId];
    });
  })
  .handleAction(deleteMyGPTSAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.selectedDeleteId = state.selectedDeleteId.filter(
        (id) => id !== payload.myGPTsId
      );
    });
  })
  .handleAction(updateMyGPTsAsync.success, (state, { payload }) => {
    // assistant/setMyGPTsConfig
    return produce(state, (draft) => {
      draft.myAIChatForm.updatedDate = payload.updatedDate;
      draft.selectedPreviewMyGptId = payload.id;
      draft.myAIChatForm.id = payload.id;
      draft.myAIChatForm.deleteAttachId = [];
      draft.myAIChatForm.modifyKnowledge = payload.knowledge;
      draft.myAIChatForm.knowledge = [];

      if (state.myGptsSideBarList.find((data) => data.id === payload.id)) {
        draft.myGptsSideBarList = state.myGptsSideBarList.map((myGpts) =>
          myGpts.id === payload.id ? payload : myGpts
        );
      } else {
        draft.myGptsSideBarList = [payload, ...state.myGptsSideBarList];
      }
    });
  })
  .handleAction(getMyGPTSDetailAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.myAIChatForm = {
        ...payload,
        conversationStarters:
          payload.conversationStarters.length === 0
            ? [""]
            : [...payload.conversationStarters, ""],
        modifyKnowledge: payload.knowledge,
        knowledge: [],
        deleteAttachId: [],
      };
      draft.selectedPreviewMyGptId = payload.id;
    });
  })

  .handleAction(pageChangeDeleteMyChatId, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.selectedPreviewMyGptId = "";
    });
  });

export default myGpts;
