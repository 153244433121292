import { RootState } from "../index";
import { OuterServiceType } from "./types";

export const makeSelectBatchMetadataSearchInputs =
  (service: OuterServiceType) => (state: RootState) => {
    switch (service) {
      case "employee":
        return selectEmployeeBatchMetadataSearchInputs(state);
      case "eapproval":
        return selectEapprovalBatchMetadataSearchInputs(state);
      default:
        return selectEmployeeBatchMetadataSearchInputs(state);
    }
  };

export const selectEmployeeBatchMetadataSearchInputs = (state: RootState) =>
  state.batchMetadata.employeeBatchSearchInputs;
export const selectEapprovalBatchMetadataSearchInputs = (state: RootState) =>
  state.batchMetadata.eapprovalBatchSearchInputs;

export const makeSelectBatchMetadataRegisterInputs =
  (service: OuterServiceType) => (state: RootState) => {
    switch (service) {
      case "employee":
        return selectEmployeeBatchMetadataRegisterInputs(state);
      case "eapproval":
        return selectEapprovalBatchMetadataRegisterInputs(state);
      default:
        return selectEmployeeBatchMetadataRegisterInputs(state);
    }
  };

export const selectEmployeeBatchMetadataRegisterInputs = (state: RootState) =>
  state.batchMetadata.employeeBatchRegisterInputs;
export const selectEapprovalBatchMetadataRegisterInputs = (state: RootState) =>
  state.batchMetadata.eapprovalBatchRegisterInputs;

export const makeSelectBatchMetadataList =
  (service: OuterServiceType) => (state: RootState) => {
    switch (service) {
      case "employee":
        return selectEmployeeBatchMetadataList(state);
      case "eapproval":
        return selectEapprovalBatchMetadataList(state);
      default:
        return selectEmployeeBatchMetadataList(state);
    }
  };

export const selectEmployeeBatchMetadataList = (state: RootState) =>
  state.batchMetadata.employeeBatchMetadataList;
export const selectEapprovalBatchMetadataList = (state: RootState) =>
  state.batchMetadata.eapprovalBatchMetadataList;

export const selectBatchMetadataTotalCount = (state: RootState) =>
  state.batchMetadata.batchMetadataTotalCount;
