import { useEffect, useState } from "react";
import localStorageManager from "../../utils/localStorageManager";
import AUTH from "../../constants/auth";
const { getStorage } = localStorageManager;
const { dataAnalysis } = AUTH;

const getUpdatedDataAnalysis = () => {
  const savedDataAnalysis = getStorage(dataAnalysis);
  if (savedDataAnalysis !== null) {
    return JSON.parse(savedDataAnalysis);
  }
  window.dispatchEvent(new Event("storage"));
  return "null";
};

export const useIsCanAnalyzeFile = () => {
  const [isCanAnalyzeFile, setIsCanAnalyzeFile] = useState<boolean>();
  const [storageCanAnalyzeFile, setStorageCanAnalyzeFile] = useState<boolean>(
    getUpdatedDataAnalysis
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setStorageCanAnalyzeFile(getUpdatedDataAnalysis);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (storageCanAnalyzeFile === true) {
      setIsCanAnalyzeFile(true);
    }
    if (storageCanAnalyzeFile === false) {
      setIsCanAnalyzeFile(false);
    }
  }, [storageCanAnalyzeFile]);

  return isCanAnalyzeFile;
};
