const CloseSVG = () => {
  return (
    <svg
      width={11}
      height={11}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#3C85CC"
        strokeLinecap="round"
        d="M.95 9.435 9.435.95m.024 8.461L.974.926"
      />
    </svg>
  );
};
export default CloseSVG;
