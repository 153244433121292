import { ActionType } from "typesafe-actions";
import * as actions from "./actions";
import {
  AccessCompanyData,
  AccessDeptData,
  AccessUserData,
  AdminUserData,
} from "./api";

export type AdminAction = ActionType<typeof actions>;

export type AdminState = {
  accessUserData: AccessUserData[];
  accessCompanyData: AccessCompanyData[];
  accessDeptData: AccessDeptData[];
  accessTotalCount: number;
  searchAccessData: string;
  accessPageIndex: number;
  adminUserData: AdminUserData[];
  adminTotalCount: number;
  searchAdminUserData: string;
  adminPageIndex: number;
};

export const batchMetadataServiceParams = ["eapproval", "employee"] as const;
export type BatchMetadataServiceParam =
  (typeof batchMetadataServiceParams)[number];

export type BatchMetadataServiceName = "e-Approval" | "Employee";
export type ModalTitleTypes = "Register" | "Modify";
