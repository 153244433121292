import axios from "axios";

export interface DeleteAndGetModel {
  dataListLength?: number;
}
export interface getModelParam extends DeleteAndGetModel {
  searchText: string;
  pageIndex: number;
  pageSize: number;
}

export interface getModelResult {
  totalCount: number;
  result: ModelInfo[];
  searchText: string;
  pageIndex: number;
}

export interface AddModelInfo {
  deployment_id: string;
  tags: string[];
}

export interface AddModelParam {
  addData: { createModelInfos: AddModelInfo[] };
  getData: getModelParam;
}

export interface UpdateModelParam {
  gmodel_id: string;
  tags: string[];
}

export interface ModelInfo {
  _id: string;
  endpoint: string;
  deploymentId: string;
  deploymentName: string;
  resourceName: string;
  modelName: string;
  modelVersion: string;
  tags: string[];
  createBy: string;
  createDate: string;
}

export interface GetModelsInfo {
  result: RegisteredModelInfo[];
  totalCount: number;
}

export interface RegisteredModelInfo {
  aoaiResourceName: string;
  deploymentId: string;
  deploymentName: string;
  modelName: string;
  modelVersion: string;
  isInDB: boolean;
}

export interface DeleteModelData {
  _id: string;
}
export interface DeleteModelParam {
  deleteData: DeleteModelData;
  getData: getModelParam;
}

//! model
export const getModel = async (param: getModelParam) => {
  const res = await axios.get(
    `/gmodel/getModels/?skip=${param.pageIndex}&limit=${param.pageSize}&searchText=${param.searchText}`
  );

  return {
    ...res.data,
    searchText: param.searchText,
    pageIndex: param.pageIndex,
  };
};

export const addModel = async (param: { createModelInfos: AddModelInfo[] }) => {
  const res = await axios.post(`/gmodel/addModel`, param);

  return res.data;
};

export const updateModel = async (param: UpdateModelParam) => {
  const res = await axios.post(`/gmodel/updateModel`, param);

  return res.data;
};

export const deleteModel = async (param: DeleteModelData) => {
  const res = await axios.delete(`/gmodel/deleteModel/${param._id}`);

  return res;
};

export const getModelsInfo = async () => {
  const res = await axios.get(`/gmodel/getAoaiInfo`);

  return res.data;
};
export const getModelsTags = async () => {
  const res = await axios.get("/gmodel/getModelTags");

  return res.data.tags;
};
