import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../features";
import {
  agreeTermsAndConditionsAsync,
  openPopup,
  selectUserId,
} from "../../../features/auth";
import "./InitialConsentPopup.scss";

const InitialConsentPopup = () => {
  const dispatch = useAppDispatch();
  const userId = useSelector(selectUserId);

  const agreeHandler = (isAgreed: boolean) => {
    dispatch(agreeTermsAndConditionsAsync.request({ isAgreed, userId }));
  };

  return (
    <div className="InitialConsentPopup_bg">
      <div className="InitialConsentPopup_modal">
        <div className="InitialConsentPopup_info_wrap">
          <div className="InitialConsentPopup_info">
            <h4>[ AI Chat Service Usage Guidelines ]</h4>

            <ul className="InitialConsentPopup_info_list">
              <li className="list">
                Please refrain from using the AI Chat service for personal
                purposes and use it for work-related purposes.
              </li>
              <li className="list">
                Please be cautious not to enter personal information or
                sensitive data
              </li>
              <li className="list">
                The Information entered in the AI Chat service may be collected
                and utilized.
              </li>
              <li className="list">
                If there are elements of copyright infringement, usage may be
                suspended.
              </li>
              <li className="list">
                Depending on the sensitivity of the question being input,
                answers may be declined.
              </li>
            </ul>

            <p>
              Employees are kindly requested to pay attention to avoid any
              issues arising from non-compliance with the instructions or
              violation of the guidelines.
            </p>
            <p>
              If you agree with the above content and want to use the AI Chat
              service, please click the
              <strong> "Agree"</strong> button below.
            </p>
          </div>

          <hr></hr>

          <div className="InitialConsentPopup_info">
            <h4>[ AI Chat 서비스 사용안내 ]</h4>

            <ul className="InitialConsentPopup_info_list">
              <li className="list">
                AI Chat 서비스를 업무상 목적이 아닌, 개인 목적으로 사용하는 것을
                지양해주시기 바랍니다.
              </li>
              <li className="list">
                개인정보등 민감한 정보가 등록되지 않도록 주의해주십시오.
              </li>
              <li className="list">
                본 AI Chat 서비스에 등록되는 정보는 수집되어 이후 활용될 수
                있습니다.
              </li>
              <li className="list">
                AI Chat 서비스 사용시, 저작권 위반의 요소가 있는 경우 사용이
                중지될 수 있습니다.
              </li>
              <li className="list">
                등록된 정보 혹은 등록하려는 질문의 민감도에 따라 답변이 거부될
                수 있습니다.
              </li>
            </ul>

            <p>
              임직원께서는 위 안내에 따르지 않거나, 이용 지침을 위반함으로 인한
              문제가 생기지 않도록 주의를 기울여 주시기 바랍니다.
            </p>
            <p>
              내용에 동의하시고 AI Chat 서비스를 사용하시려면 아래{" "}
              <strong>"동의"</strong>버튼을 누르세요.
            </p>
          </div>
        </div>

        <div className="btn_wrap">
          <button onClick={() => agreeHandler(true)}>동의 ( Agree )</button>
          <button
            onClick={() => {
              agreeHandler(false);
              dispatch(openPopup({ open: false }));
            }}
          >
            취소 ( Cancel )
          </button>
        </div>
      </div>
    </div>
  );
};

export default InitialConsentPopup;
