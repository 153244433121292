import "./MyAIChatIntro.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { selectTeamsTheme, selectTemperature } from "../../../features/auth";
import React, { useEffect } from "react";
import {
  getAnswerAsync,
  selectAssistantTypeInfo,
} from "../../../features/chat";
import { ReactComponent as GPTsDefault } from "../../../assets/svg/logo/GPTsDefault.svg";
import { selectMyAIChatForm } from "../../../features/myGpts";
import { ClipLoader } from "react-spinners";
import { useAppDispatch } from "../../../features";
import { selectGetMyGptsDetailLoading } from "../../../features/ui_loading";
import useResponsiveView from "../../../libs/hooks/useResonsiveView";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

export const COLOR = {
  primary: "#005eb8",
  white: "#fefefe",
};

const MyAIChatIntro = React.forwardRef<HTMLDivElement>((_, ref) => {
  const location = useLocation();
  const { isTablet } = useResponsiveView();
  const teamsTheme = useSelector(selectTeamsTheme);
  const dispatch = useAppDispatch();
  const temperature = useSelector(selectTemperature);
  // const modelEngine = useSelector(selectModelEngine);
  // const selectedMyGptsId = useSelector(selectSelectedSideBarMyGptId);
  const assistantTypeInfo = useSelector(selectAssistantTypeInfo);
  const mGPTsDetailLoading = useSelector(selectGetMyGptsDetailLoading);
  const myAIChatForm = useSelector(selectMyAIChatForm);

  useEffect(() => {
    const scrollBody = document.getElementById("chat_body");

    if (scrollBody) {
      scrollBody.style.scrollBehavior = "auto";
      scrollBody.scrollTo(0, 0);
    }
  }, []);

  const getAnswer = (prompt: string) => {
    const myGPTsId = location.pathname.includes("my_chat_mgmt")
      ? myAIChatForm.id
      : assistantTypeInfo.myGPTsId;

    dispatch(
      getAnswerAsync.request({
        myGPTsId,
        listId: null,
        prompt,
        temperature,
        fileInfo: [],
        assistantType: assistantTypeInfo.assistantType,
        isPreviewChat: location.pathname.includes("my_chat_mgmt")
          ? true
          : false,
      })
    );
  };

  return mGPTsDetailLoading ? (
    <div className={clsx("my_ai_chat_intro_container")}>
      <ClipLoader loading={mGPTsDetailLoading} size={20} color="#80b1e2" />
    </div>
  ) : (
    <div
      ref={ref}
      className={clsx(`my_ai_chat_intro_container ${teamsTheme}`, {
        "preview_my_ai_chat_intro_container":
          location.pathname.includes("my_chat_mgmt"),
      })}
    >
      {typeof myAIChatForm.profileImage === "string" ? (
        <img
          src={myAIChatForm.profileImage}
          alt="My AI Chat"
          className="my_gpt_profile"
        />
      ) : (
        <GPTsDefault />
      )}

      {myAIChatForm.name && <h2>{myAIChatForm.name}</h2>}
      {myAIChatForm.description && (
        <p className="description">{myAIChatForm.description}</p>
      )}
      {myAIChatForm.conversationStarters.length > 1 && (
        <div
          className={clsx(
            `conversation_starters_data ${teamsTheme} scroll scroll_${teamsTheme}`,
            {
              "preview_conversation_starters_data":
                location.pathname.includes("my_chat_mgmt"),
            }
          )}
        >
          {myAIChatForm.conversationStarters
            .filter((conversation) => conversation !== "")
            .map((data) => (
              <div
                className="conversation_starter_item"
                onClick={() => getAnswer(data)}
              >
                <p className={`scroll_x scroll_x_${teamsTheme}`}>{data}</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
});

export default MyAIChatIntro;
