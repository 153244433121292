import "./ErrorPage.scss";
import { BiSolidError } from "react-icons/bi";

const ErrorPage = () => {
  return (
    <div className="ErrorPage_bg">
      <div className="ErrorPage">
        <hgroup>
          <BiSolidError className="error_icon" />
          <h3>
            <p>
              Sorry, we're unable to perform the requested function right now.
            </p>
            <p>Please click the refresh button or restart the app.</p>
          </h3>
        </hgroup>

        <div className="ErrorPage_txt">
          <p>
            If the problem persists, feel free to contact our support team for
            assistance.
          </p>
          <p>- IT Service Center (1588-1896)</p>
          <br />
          <p>
            If Doosan AI Chat is not accessible or errors occur, <br /> it may
            be due to a Teams cache issue. <br />
            Please follow the guide in the link below to clear the cache and
            restart the service.{" "}
          </p>
          Clear the MS Teams client cache (
          <a
            href="https://learn.microsoft.com/ko-kr/microsoftteams/troubleshoot/teams-administration/clear-teams-cache"
            target="_blank"
            rel="noreferrer"
          >
            Korean
          </a>
          /
          <a
            href="https://learn.microsoft.com/en-us/microsoftteams/troubleshoot/teams-administration/clear-teams-cache"
            target="_blank"
            rel="noreferrer"
          >
            English
          </a>
          )<p style={{ marginTop: "15px" }}>Thank you.</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
