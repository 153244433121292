import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { selectErrorMsg } from "../../features/ui_loading";
import { useEffect } from "react";

export const useErrorSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const errorMsg = useSelector(selectErrorMsg);

  useEffect(() => {
    if (errorMsg.msg.length > 0) {
      if (errorMsg.msg === "이미 Wishlist에 존재합니다")
        enqueueSnackbar(errorMsg.msg, {
          variant: "warning",
          autoHideDuration: 1500,
        });
      else
        enqueueSnackbar(errorMsg.msg, {
          variant: "error",
          autoHideDuration: 1500,
        });
    }
  }, [errorMsg]);
};
