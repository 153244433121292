import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  prism,
  nightOwl,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import tsx from "react-syntax-highlighter/dist/cjs/languages/prism/tsx";
import typescript from "react-syntax-highlighter/dist/cjs/languages/prism/typescript";
import scss from "react-syntax-highlighter/dist/cjs/languages/prism/scss";
import bash from "react-syntax-highlighter/dist/cjs/languages/prism/bash";
import markdown from "react-syntax-highlighter/dist/cjs/languages/prism/markdown";
import json from "react-syntax-highlighter/dist/cjs/languages/prism/json";
import python from "react-syntax-highlighter/dist/cjs/languages/prism/python";
import javascript from "react-syntax-highlighter/dist/cjs/languages/prism/javascript";
import java from "react-syntax-highlighter/dist/cjs/languages/prism/java";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import { AiOutlineCopy } from "react-icons/ai";
import CHAT from "../../../../constants/chat";

SyntaxHighlighter.registerLanguage("tsx", tsx);
SyntaxHighlighter.registerLanguage("typescript", typescript);
SyntaxHighlighter.registerLanguage("scss", scss);
SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("markdown", markdown);
SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("javascript", javascript);
SyntaxHighlighter.registerLanguage("java", java);

const CodeBlock = (props: any) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  const { node, className, inline, children } = props;

  const hasLang = /language-(\w+)/.exec(className || "");
  // const hasMeta = node?.data?.meta;
  // console.log("hasLang", hasLang);
  // console.log("className", className);
  // console.log("children", children);
  // console.log("inline", inline);

  const copyTextHandler = () => {
    if (!hasLang) {
      return;
    }

    const textCopy = children + `${CHAT.SUPPORTED_BY_DOOSAN}`;
    const textArea = document.createElement("textarea");
    textArea.value = textCopy;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (error) {
      console.log("텍스트 복사에 실패하였습니다. 다른 방법을 시도해보세요.");
    }

    document.body.removeChild(textArea);
  };

  return !inline && hasLang ? (
    <div className="code_block_wrap">
      <button className="copy_button" onClick={copyTextHandler}>
        <AiOutlineCopy />
        Copy
      </button>
      <SyntaxHighlighter
        style={teamsTheme === "default" ? prism : nightOwl}
        language={hasLang[1]}
        PreTag="div"
        className="code_block"
        showLineNumbers={false}
        useInlineStyles={true}
        {...props}
      >
        {String(children).replace(/\n$/, "")}
      </SyntaxHighlighter>
    </div>
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );
};

export default CodeBlock;
