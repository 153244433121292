import { useSelector } from "react-redux";
import "./Alert.scss";
import {
  closeAlert,
  selectAlertMessage,
  selectOpenAlert,
} from "../../../features/alert";
import { useAppDispatch } from "../../../features";
import { useEffect, useState } from "react";
import { TbAlertCircleFilled } from "react-icons/tb";

const Alert = () => {
  const dispatch = useAppDispatch();
  const alertMsg = useSelector(selectAlertMessage);
  const openAlert = useSelector(selectOpenAlert);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (openAlert) {
      setIsVisible(true);

      setTimeout(() => {
        setIsVisible(false);
        setTimeout(() => {
          dispatch(closeAlert());
        }, 500); // 사라지는 애니메이션 시간과 일치하게 설정
      }, 3000); // 알림이 표시되는 시간
    }
  }, [openAlert]);

  if (!openAlert) return null;

  return (
    <div className={`alert_modal ${isVisible ? "open" : "close"}`}>
      <p className="alert_msg">
        <TbAlertCircleFilled className="alert_icon" />
        <span>{alertMsg}</span>
      </p>
    </div>
  );
};

export default Alert;
