import createAsyncSaga from "../../libs/createAsyncSaga";
import { all, call, put, takeLeading } from "typed-redux-saga";
import { AxiosError } from "axios";
import {
  ADD_ACCESS_COMPANY_DATA,
  ADD_ACCESS_DEPT_DATA,
  ADD_ACCESS_USER_DATA,
  ADD_ADMIN_USER_DATA,
  DELETE_ACCESS_COMPANY_DATA,
  DELETE_ACCESS_DEPT_DATA,
  DELETE_ACCESS_USER_DATA,
  DELETE_ADMIN_USER_DATA,
  GET_ACCESS_COMPANY_DATA,
  GET_ACCESS_DEPT_DATA,
  GET_ACCESS_USER_DATA,
  GET_ADMIN_USER_DATA,
  UPDATE_ACCESS_COMPANY_DATA,
  UPDATE_ACCESS_DEPT_DATA,
  UPDATE_ACCESS_USER_DATA,
  UPDATE_ADMIN_USER_DATA,
  addAccessCompanyDataAsync,
  addAccessDeptDataAsync,
  addAccessUserDataAsync,
  addAdminUserDataAsync,
  deleteAccessCompanyDataAsync,
  deleteAccessDeptDataAsync,
  deleteAccessUserDataAsync,
  deleteAdminUserDataAsync,
  getAccessCompanyDataAsync,
  getAccessDeptDataAsync,
  getAccessUserDataAsync,
  getAdminUserDataAsync,
  updateAccessCompanyDataAsync,
  updateAccessDeptDataAsync,
  updateAccessUserDataAsync,
  updateAdminUserDataAsync,
} from "./actions";
import {
  addAccessCompanyData,
  addAccessDeptData,
  addAccessUserData,
  addAdminUserData,
  deleteAccessCompanyData,
  deleteAccessDeptData,
  deleteAccessUserData,
  deleteAdminUserData,
  getAccessCompanyData,
  getAccessDeptData,
  getAccessUserData,
  getAdminUserData,
  updateAccessCompanyData,
  updateAccessDeptData,
  updateAccessUserData,
  updateAdminUserData,
} from "./api";

//! company access
const getAccessCompanyDataSaga = createAsyncSaga(
  getAccessCompanyDataAsync,
  getAccessCompanyData
);

function* addAccessCompanyDataSaga(action: any) {
  const payload = action.payload;

  try {
    const res = yield* call(addAccessCompanyData, payload.data);

    if (res) {
      const getData = yield* call(getAccessCompanyData, {
        pageIndex: 1,
        pageSize: payload.pageSize,
        searchText: payload.searchText,
      });

      if (getData) {
        yield put(
          getAccessCompanyDataAsync.success({ ...getData, pageIndex: 1 })
        );
      }
    }
  } catch (err) {
    yield put(addAccessCompanyDataAsync.failure(err as AxiosError));
  }
}

const updateAccessCompanyDataSaga = createAsyncSaga(
  updateAccessCompanyDataAsync,
  updateAccessCompanyData
);

function* deleteAccessCompanyDataSaga(action: any) {
  const payload = action.payload;

  try {
    const res = yield* call(deleteAccessCompanyData, payload);

    if (res.data) {
      const getData = yield* call(getAccessCompanyData, {
        pageIndex:
          payload.length - 1 === 0 ? payload.pageIndex - 1 : payload.pageIndex,
        pageSize: payload.pageSize,
        searchText: payload.searchText,
      });

      if (getData) {
        yield put(getAccessCompanyDataAsync.success(getData));
      }
    }
  } catch (err) {
    yield put(deleteAccessCompanyDataAsync.failure(err as AxiosError));
  }
}

//! dept access
const getAccessDeptDataSaga = createAsyncSaga(
  getAccessDeptDataAsync,
  getAccessDeptData
);

function* addAccessDeptDataSaga(action: any) {
  const payload = action.payload;

  try {
    const res = yield* call(addAccessDeptData, payload.data);

    if (res) {
      const getData = yield* call(getAccessDeptData, {
        pageIndex: 1,
        pageSize: payload.pageSize,
        searchText: payload.searchText,
      });

      if (getData) {
        yield put(getAccessDeptDataAsync.success({ ...getData, pageIndex: 1 }));
      }
    }
  } catch (err) {
    yield put(addAccessDeptDataAsync.failure(err as AxiosError));
  }
}

const updateAccessDeptDataSaga = createAsyncSaga(
  updateAccessDeptDataAsync,
  updateAccessDeptData
);

function* deleteAccessDeptDataSaga(action: any) {
  const payload = action.payload;

  try {
    const res = yield* call(deleteAccessDeptData, payload);

    if (res.data) {
      const getData = yield* call(getAccessDeptData, {
        pageIndex:
          payload.length - 1 === 0 ? payload.pageIndex - 1 : payload.pageIndex,
        pageSize: payload.pageSize,
        searchText: payload.searchText,
      });

      if (getData) {
        yield put(getAccessDeptDataAsync.success(getData));
      }
    }
  } catch (err) {
    yield put(deleteAccessDeptDataAsync.failure(err as AxiosError));
  }
}

//! user access
const getAccessUserDataSaga = createAsyncSaga(
  getAccessUserDataAsync,
  getAccessUserData
);

function* addAccessUserDataSaga(action: any) {
  const payload = action.payload;

  try {
    const res = yield* call(addAccessUserData, payload.data);

    if (res) {
      const getData = yield* call(getAccessUserData, {
        pageIndex: 1,
        pageSize: payload.pageSize,
        searchText: payload.searchText,
      });

      if (getData) {
        yield put(getAccessUserDataAsync.success({ ...getData, pageIndex: 1 }));
      }
    }
  } catch (err) {
    yield put(addAccessUserDataAsync.failure(err as AxiosError));
  }
}

const updateAccessUserDataSaga = createAsyncSaga(
  updateAccessUserDataAsync,
  updateAccessUserData
);

function* deleteAccessUserDataSaga(action: any) {
  const payload = action.payload;

  try {
    const res = yield* call(deleteAccessUserData, payload);

    if (res.data) {
      const getData = yield* call(getAccessUserData, {
        pageIndex:
          payload.length - 1 === 0 ? payload.pageIndex - 1 : payload.pageIndex,
        pageSize: payload.pageSize,
        searchText: payload.searchText,
      });

      if (getData) {
        yield put(getAccessUserDataAsync.success(getData));
      }
    }
  } catch (err) {
    yield put(deleteAccessUserDataAsync.failure(err as AxiosError));
  }
}

//! admin mgmt
const getAdminUserDataSaga = createAsyncSaga(
  getAdminUserDataAsync,
  getAdminUserData
);

function* addAdminUserDataSaga(action: any) {
  const payload = action.payload;

  try {
    const res = yield* call(addAdminUserData, payload.data);

    if (res) {
      const getData = yield* call(getAdminUserData, {
        pageIndex: 1,
        pageSize: payload.pageSize,
        searchText: payload.searchText,
      });

      if (getData) {
        yield put(getAdminUserDataAsync.success({ ...getData, pageIndex: 1 }));
      }
    }
  } catch (err) {
    yield put(addAdminUserDataAsync.failure(err as AxiosError));
  }
}

const updateAdminUserDataSaga = createAsyncSaga(
  updateAdminUserDataAsync,
  updateAdminUserData
);

function* deleteAdminUserDataSaga(action: any) {
  const payload = action.payload;

  try {
    const res = yield* call(deleteAdminUserData, payload);

    if (res.data) {
      const getData = yield* call(getAdminUserData, {
        pageIndex:
          payload.length - 1 === 0 ? payload.pageIndex - 1 : payload.pageIndex,
        pageSize: payload.pageSize,
        searchText: payload.searchText,
      });

      if (getData) {
        yield put(getAdminUserDataAsync.success(getData));
      }
    }
  } catch (err) {
    yield put(deleteAdminUserDataAsync.failure(err as AxiosError));
  }
}

export function* adminSaga() {
  //! company access
  yield* all([
    yield* takeLeading(GET_ACCESS_COMPANY_DATA, getAccessCompanyDataSaga),
  ]);
  yield* all([
    yield* takeLeading(ADD_ACCESS_COMPANY_DATA, addAccessCompanyDataSaga),
  ]);
  yield* all([
    yield* takeLeading(UPDATE_ACCESS_COMPANY_DATA, updateAccessCompanyDataSaga),
  ]);
  yield* all([
    yield* takeLeading(DELETE_ACCESS_COMPANY_DATA, deleteAccessCompanyDataSaga),
  ]);

  //! dept access
  yield* all([yield* takeLeading(GET_ACCESS_DEPT_DATA, getAccessDeptDataSaga)]);
  yield* all([yield* takeLeading(ADD_ACCESS_DEPT_DATA, addAccessDeptDataSaga)]);
  yield* all([
    yield* takeLeading(UPDATE_ACCESS_DEPT_DATA, updateAccessDeptDataSaga),
  ]);
  yield* all([
    yield* takeLeading(DELETE_ACCESS_DEPT_DATA, deleteAccessDeptDataSaga),
  ]);

  //! user access
  yield* all([yield* takeLeading(GET_ACCESS_USER_DATA, getAccessUserDataSaga)]);
  yield* all([yield* takeLeading(ADD_ACCESS_USER_DATA, addAccessUserDataSaga)]);
  yield* all([
    yield* takeLeading(UPDATE_ACCESS_USER_DATA, updateAccessUserDataSaga),
  ]);
  yield* all([
    yield* takeLeading(DELETE_ACCESS_USER_DATA, deleteAccessUserDataSaga),
  ]);

  //! admin mgmt
  yield* all([yield* takeLeading(GET_ADMIN_USER_DATA, getAdminUserDataSaga)]);
  yield* all([yield* takeLeading(ADD_ADMIN_USER_DATA, addAdminUserDataSaga)]);
  yield* all([
    yield* takeLeading(UPDATE_ADMIN_USER_DATA, updateAdminUserDataSaga),
  ]);
  yield* all([
    yield* takeLeading(DELETE_ADMIN_USER_DATA, deleteAdminUserDataSaga),
  ]);
}
