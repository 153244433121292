import clsx from "clsx";
import moment from "moment";
import "./InfiniteScrollBar.scss";
import { BiSearch } from "react-icons/bi";
import { TiDelete } from "react-icons/ti";
import { AiFillPushpin } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import BeforeChat from "../chat/BeforeChat";
import {
  selectQaList,
  selectHasMore,
  setModelEngine,
  BeforeChatData,
  selectChatLoading,
  selectPinnedQaList,
  selectQaListPageSize,
  selectSelectedChatId,
  getBeforeChatListAsync,
  getSelectChatInfoAsync,
  selectFirstSelectedChatId,
} from "../../../features/chat";
import { useAppDispatch } from "../../../features";
import {
  openPopup,
  selectUserId,
  selectTeamsTheme,
  selectIsContractAgreed,
} from "../../../features/auth";
import InfiniteScrollSpinner from "../spinner/InfiniteScrollSpinner";
import SearchModelAndGPTsUL from "./SearchModelAndGPTsUL";
import { openAlert } from "../../../features/alert";

type Props = {
  onClose?: () => void;
};

const InfiniteScrollBar: React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [days, setDays] = useState([
    "Today",
    "Yesterday",
    "Previous 7 Days",
    "Previous 14 Days",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const firstSelectedId = useSelector(selectFirstSelectedChatId);
  const qaList = useSelector(selectQaList);
  const pinnedChatList = useSelector(selectPinnedQaList);
  const teamsTheme = useSelector(selectTeamsTheme);
  const userId = useSelector(selectUserId);
  const hasMore = useSelector(selectHasMore);
  const selectedId = useSelector(selectSelectedChatId);
  const isContractAgreed = useSelector(selectIsContractAgreed);
  const chatLoading = useSelector(selectChatLoading);
  const pageSize = useSelector(selectQaListPageSize);

  const [deleteId, setDeleteId] = useState<string>("");
  const [modifyId, setModifyId] = useState<string>("");
  const [searchText, setSearchText] = useState("");
  const [qaDateList, setQaDateList] = useState<{
    [key in string]: BeforeChatData[];
  }>({});

  const [modifyTxt, setModifyTxt] = useState("");

  useEffect(() => {
    if (!isContractAgreed) {
      dispatch(openPopup({ open: true }));
      setSearchText("");
      return;
    }

    getBeforeChatListHandler();
  }, [isContractAgreed]);

  useEffect(() => {
    const dates: { [key in string]: string } = {
      Today: "Today",
      Yesterday: "Yesterday",
      "Previous 7 Days": "Previous 7 Days",
      "Previous 14 Days": "Previous 14 Days",
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      "10": "October",
      "11": "November",
      "12": "December",
    };
    const todayDate = Number(moment().format("YYYYMMDD"));

    const yesterdayDate = Number(
      moment().subtract(1, "days").format("YYYYMMDD")
    );
    const previous7Days = Number(
      moment().subtract(7, "days").format("YYYYMMDD")
    );
    const previous14Days = Number(
      moment().subtract(14, "days").format("YYYYMMDD")
    );

    if (qaList.length > 0) {
      const changeDateArr = qaList
        .map((chat) => {
          return {
            ...chat,
            custumDate: Number(moment(chat.updatedDate).format("YYYYMMDD")),
          };
        })
        .sort((a, b) => {
          if (a.updatedDate > b.updatedDate) return -1;
          if (a.updatedDate < b.updatedDate) return 1;
          return 0;
        });
      let copyQaList = {};

      for (const date of changeDateArr) {
        if (date.custumDate === todayDate) {
          copyQaList = newQaList(copyQaList, dates["Today"], date);
        } else if (date.custumDate === yesterdayDate) {
          copyQaList = newQaList(copyQaList, dates["Yesterday"], date);
        } else if (
          date.custumDate - previous7Days >= 0 &&
          date.custumDate < yesterdayDate
        ) {
          copyQaList = newQaList(copyQaList, dates["Previous 7 Days"], date);
        } else if (
          date.custumDate - previous14Days >= 0 &&
          date.custumDate < previous7Days
        ) {
          copyQaList = newQaList(copyQaList, dates["Previous 14 Days"], date);
        } else {
          let dateTime = "";
          if (date.updatedDate !== undefined) {
            dateTime = date.updatedDate;
          } else {
            dateTime = date.createdDate;
          }
          const dateyear = moment(dateTime).format("YYYY");
          const dateMonth = moment(dateTime).format("MM");

          copyQaList = newQaList(
            copyQaList,
            "date" + dateyear + dateMonth + dates[dateMonth],
            date
          );
        }
      }

      setQaDateList(copyQaList);
    } else {
      setQaDateList({});
    }
  }, [qaList]);

  const newQaList = (
    copyQaList: { [key in string]: BeforeChatData[] },
    title: string,
    qa: BeforeChatData
  ) => {
    if (copyQaList[title]) {
      copyQaList[title] = [...copyQaList[title], qa];
    } else {
      copyQaList[title] = [qa];
    }
    //중복제거
    setDays((days) => {
      const all_days = [...days, title];
      const temp_abstract_days = all_days.filter(
        (date) => !date.startsWith("date")
      );
      const temp_number_days = all_days.filter((date) =>
        date.startsWith("date")
      );
      const sortedDateStrings = temp_number_days.sort().reverse();
      return [...new Set([...temp_abstract_days, ...sortedDateStrings])];
    });

    return copyQaList;
  };

  // useEffect(() => {
  //   if (!isContractAgreed) {
  //     dispatch(openPopup({ open: true }));
  //     setSearchText("");
  //     return;
  //   }
  // }, [searchText, isContractAgreed]);

  const getBeforeChatListHandler = (text?: string) => {
    dispatch(
      getBeforeChatListAsync.request({
        userId,
        pageIndex: 0,
        pageSize,
        searchText: text ? "" : searchText,
      })
    );

    if (text) {
      setSearchText("");
    }
  };

  const selectDeleteIdHandler = (id: string) => {
    setDeleteId(id);
  };

  const selectModifyIdHandler = (id: string) => {
    setModifyId(id);
  };

  useEffect(() => {
    const scrollBody = document.getElementById("chat_body");

    if (scrollBody && selectedId !== "-1") {
      scrollBody.style.scrollBehavior = "auto";
      scrollBody.scrollTo(0, scrollBody.scrollHeight);
    }

    const inputChat = document.getElementById("chat_input");

    if (inputChat) inputChat.focus();
  }, [selectedId]);

  const clickQaItem = (qaItemInfo: BeforeChatData) => {
    if (!isContractAgreed) {
      dispatch(openPopup({ open: true }));
      return;
    }
    if (chatLoading) {
      dispatch(
        openAlert({
          alertMsg: "You cannot select this menu while it is loading.",
        })
      );
      return;
    }

    if (modifyId === qaItemInfo._id) return;

    dispatch(setModelEngine(qaItemInfo.qaModelEngine));

    if (selectedId !== qaItemInfo._id && firstSelectedId !== qaItemInfo._id) {
      dispatch(
        getSelectChatInfoAsync.request({ listId: qaItemInfo._id, userId })
      );
    }

    selectModifyIdHandler("");
    selectDeleteIdHandler("");

    navigate("/private/chat");

    if (onClose) onClose();
  };

  return (
    <>
      <div
        className={`InfiniteScrollBar scroll scroll_${teamsTheme}`}
        id="scrollableDiv"
      >
        <InfiniteScroll
          dataLength={qaList.length}
          next={getBeforeChatListHandler}
          hasMore={hasMore}
          style={{
            height: "100%",
          }}
          loader={<InfiniteScrollSpinner />}
          scrollableTarget="scrollableDiv"
          className="infinit_scroll"
        >
          <SearchModelAndGPTsUL />
          <div className="side_bar_divider" />
          <div className="qa_list_search">
            <input
              className={`qa_list_search_input qa_list_search_input_${teamsTheme}`}
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyUp={(e) => e.key === "Enter" && getBeforeChatListHandler()}
              placeholder="Search"
              disabled={!isContractAgreed}
            />
            <BiSearch className="search_icon" />
            {searchText.length > 0 && (
              <button
                onClick={() => getBeforeChatListHandler("delete_chat")}
                className="qa_search_delete"
              >
                <TiDelete />
              </button>
            )}
          </div>

          <h5 className={`qa_list_title qa_list_title_${teamsTheme}`}>
            <AiFillPushpin className="pin_icon" />
            Pinned
          </h5>

          {pinnedChatList.map((chat: BeforeChatData) => (
            <div
              key={`pinned_${chat._id}`}
              onClick={() => clickQaItem(chat)}
              title={chat.title}
              className={clsx(
                `Chat_before_info Chat_before_info_${teamsTheme}`,
                {
                  [`selected_Chat_before_info  ${teamsTheme} ${teamsTheme}_${chat.qaModelEngine}`]:
                    selectedId === chat._id || firstSelectedId === chat._id,
                  [`dalle_qa_item_${teamsTheme}`]:
                    chat.qaModelEngine === "dalle",
                  [`myGPTs_qa_item_${teamsTheme}`]:
                    chat.qaModelEngine === "myGPTs",
                }
              )}
            >
              <BeforeChat
                selectDeleteIdHandler={selectDeleteIdHandler}
                selectModifyIdHandler={selectModifyIdHandler}
                deleteId={deleteId}
                modifyId={modifyId}
                chat={chat}
                onClose={onClose}
                setModifyTxt={setModifyTxt}
                modifyTxt={modifyTxt}
              />
            </div>
          ))}

          {days.map(
            (day, idx) =>
              qaDateList[day] &&
              qaDateList[day].length > 0 && (
                <Fragment
                  key={`${day.startsWith("date") ? day.slice(10) : day}_${idx}`}
                >
                  <h5 className={`qa_list_title qa_list_title_${teamsTheme}`}>
                    {day.startsWith("date") ? day.slice(10) : day}
                  </h5>

                  {qaDateList[day]
                    .sort((a, b) => {
                      if (a.updatedDate > b.updatedDate) return -1;
                      if (a.updatedDate < b.updatedDate) return 1;
                      return 0;
                    })
                    .map((chat) => (
                      <div
                        key={`default_${chat._id}`}
                        onClick={() => clickQaItem(chat)}
                        title={chat.title}
                        className={clsx(
                          `Chat_before_info Chat_before_info_${teamsTheme}`,
                          {
                            [`selected_Chat_before_info  ${teamsTheme} ${teamsTheme}_${chat.qaModelEngine}`]:
                              selectedId === chat._id ||
                              firstSelectedId === chat._id,
                            [`dalle_qa_item_${teamsTheme}`]:
                              chat.qaModelEngine === "dalle",
                            [`myGPTs_qa_item_${teamsTheme}`]:
                              chat.qaModelEngine === "myGPTs",
                          }
                        )}
                      >
                        <BeforeChat
                          selectDeleteIdHandler={selectDeleteIdHandler}
                          selectModifyIdHandler={selectModifyIdHandler}
                          deleteId={deleteId}
                          modifyId={modifyId}
                          chat={chat}
                          onClose={onClose}
                          setModifyTxt={setModifyTxt}
                          modifyTxt={modifyTxt}
                        />
                      </div>
                    ))}
                </Fragment>
              )
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default InfiniteScrollBar;
