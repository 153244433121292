import { useSelector } from "react-redux";
import {
  MessageInfo,
  MessageSourceInfo,
  likeAnswerAsync,
  selectSelectedData,
} from "../../../features/chat";
import "./Answer.scss";
import { useState } from "react";

import {
  BsHandThumbsUp,
  BsHandThumbsDown,
  BsHandThumbsUpFill,
  BsHandThumbsDownFill,
} from "react-icons/bs";
import { AiOutlineCopy } from "react-icons/ai";
import Markdown from "./Markdown";
import { useAppDispatch } from "../../../features";
import { selectTeamsTheme } from "../../../features/auth";
import TypingAnimation from "./TypingAnimation";
import { selectChatLoading } from "../../../features/chat";
import { memo } from "react";
import { DetailInfoList } from "./detailInfo/DetailInfoList";
import { MdDownload } from "react-icons/md";
import { AnswerProfileImage } from "./AnswerProfileImage";
import CHAT from "../../../constants/chat";

type Props = {
  answer: MessageInfo;
  lastChat: boolean;
  source: MessageSourceInfo[];
};

const Answer: React.FC<Props> = ({ lastChat, answer, source }) => {
  const dispatch = useAppDispatch();
  const selectData = useSelector(selectSelectedData);
  const teamsTheme = useSelector(selectTeamsTheme);
  const chatLoading = useSelector(selectChatLoading);

  const [selectThumbs, setSelectThumbs] = useState<-1 | 1 | 0>(answer.favor);

  const thumbsUpAndDownHandler = (value: -1 | 1) => {
    if (value === selectThumbs) {
      setSelectThumbs(0);
      dispatch(likeAnswerAsync.request({ itemId: answer._id, favor: 0 }));
    } else {
      setSelectThumbs(value);
      dispatch(likeAnswerAsync.request({ itemId: answer._id, favor: value }));
    }
  };

  const copyTextHandler = () => {
    const textCopy = answer.answer + `${CHAT.SUPPORTED_BY_DOOSAN}`;

    const textArea = document.createElement("textarea");
    textArea.value = textCopy;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (error) {
      console.log("텍스트 복사에 실패하였습니다. 다른 방법을 시도해보세요.");
    }

    document.body.removeChild(textArea);
  };

  const copyDragHandler = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const copyText =
      (window.getSelection() as any).toString() + `${CHAT.SUPPORTED_BY_DOOSAN}`;
    e.clipboardData.setData("text", copyText);
    e.preventDefault();
  };

  const dalleImgDownloadHandler = async (imgUrl: string) => {
    const response = await fetch(imgUrl);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = "downloaded_image.jpg";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="answer_wrap_container">
      <div
        title={`${answer.latency}ms`}
        className={`search_type_wrap search_type_wrap_${teamsTheme} ${
          selectData &&
          lastChat &&
          !answer.failure &&
          !answer.answerDone &&
          "answer_wrap_loading"
        }`}
      >
        <AnswerProfileImage />
      </div>
      <div
        className={`answer_wrap answer_wrap_${teamsTheme} ${
          selectData &&
          lastChat &&
          !answer.failure &&
          !answer.answerDone &&
          "answer_wrap_loading"
        }`}
      >
        <div
          id="answer"
          className={`answer answer_${teamsTheme} scroll_x scroll_x_${teamsTheme} ${
            answer.failure && "answer_cancel"
          }`}
          onCopy={copyDragHandler}
        >
          {lastChat && chatLoading ? (
            <>
              <Markdown answer={answer.answer} />
            </>
          ) : (
            <>
              <Markdown answer={answer.answer} />

              {answer.dalleUrl && (
                <Markdown
                  answer={"![Image](" + answer.dalleUrl + ")"}
                  isDalleUrl={true}
                />
              )}

              {source.length ? <DetailInfoList source={source} /> : null}
            </>
          )}
        </div>

        {!answer.failure && (
          <div className="answer_bottom_btn_wrap">
            <div className="sources">Supported by Doosan AI Chat</div>
            <div className="thumps_up_down">
              {answer.dalleUrl && (
                <button
                  className={`thumps_up_down_btn img_download thumps_up_down_btn_${teamsTheme}`}
                  title="Img Download"
                  onClick={() => dalleImgDownloadHandler(answer.dalleUrl)}
                >
                  <MdDownload className="img_download_icon" />
                </button>
              )}
              <button
                className={`thumps_up_down_btn thumps_up_down_btn_${teamsTheme}`}
                title="Copy"
                onClick={copyTextHandler}
              >
                <AiOutlineCopy />
              </button>
              <button
                className={`thumps_up_down_btn thumps_up_down_btn_${teamsTheme}`}
                title="Like"
                onClick={() => thumbsUpAndDownHandler(1)}
              >
                {selectThumbs === 1 ? (
                  <BsHandThumbsUpFill />
                ) : (
                  <BsHandThumbsUp />
                )}
              </button>
              <button
                className={`thumps_up_down_btn thumps_up_down_btn_${teamsTheme}`}
                title="DisLike"
                onClick={() => thumbsUpAndDownHandler(-1)}
              >
                {selectThumbs === -1 ? (
                  <BsHandThumbsDownFill />
                ) : (
                  <BsHandThumbsDown />
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Answer);
