import moment from "moment";
import { createReducer } from "typesafe-actions";
import {
  BillingAction,
  getBillingNameListAsync,
  getTokenCompanyAsync,
  getTokenLogAsync,
  resetBillingSearchInputs,
  setBillingSearchInputs,
} from "./actions";
import produce from "immer";
import { BillingState, DomainType } from "./types";

const initialSearchInputs = {
  startDate: new Date(moment().subtract(1, "months").format("YYYY-MM-DD")),
  endDate: new Date(moment().format("YYYY-MM-DD")),
  model: "0",
  filterList: [],
  filterType: undefined,
  skip: 1,
  limit: 10,
  searchText: "",
  domainType: "AICHAT" as DomainType,
};
const initialTotalTokenData = {
  totalAnswerTokens: 0,
  totalQuestionTokens: 0,
  totalTokens: 0,
};

const initialState = {
  totalTokenData: initialTotalTokenData,
  tokenLogDataList: [],
  tokenList: [],
  billingNameList: [],
  billingSearchInputs: initialSearchInputs,
  tokenTotalCount: 0,
};

const billing = createReducer<BillingState, BillingAction>(initialState)
  .handleAction(getTokenLogAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.totalTokenData = {
        totalAnswerTokens: payload.totalAnswerTokens,
        totalQuestionTokens: payload.totalQuestionTokens,
        totalTokens: payload.totalTokens,
      };

      draft.tokenLogDataList = payload.result;
      draft.tokenTotalCount = payload.totalCount;
    });
  })
  .handleAction(getTokenCompanyAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.tokenCompanyData = payload;
    });
  })
  .handleAction(getBillingNameListAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.billingNameList = payload;
      draft.billingSearchInputs.filterList = payload
        .filter((item) => item.hasLog)
        .map((item) => item.code);
    });
  })
  .handleAction(setBillingSearchInputs, (state, { payload }) => {
    if (payload.key === "domainType") {
      console.log("❗");
      console.log("payload", payload);
    }
    return produce(state, (draft) => {
      draft.billingSearchInputs = {
        ...state.billingSearchInputs,
        [payload.key]: payload.value,
      };
    });
  })
  .handleAction(resetBillingSearchInputs, (state) => {
    return produce(state, (draft) => {
      draft.billingSearchInputs = { ...initialSearchInputs };
      draft.tokenLogDataList = [];
      draft.totalTokenData = initialTotalTokenData;
      draft.tokenTotalCount = 0;
    });
  });

export default billing;
