export const DALLE_STYLES = [
  {
    content: "Chalk art",
  },
  {
    content: "Aerial",
  },
  {
    content: "Fauvism",
  },
  {
    content: "Dystopian",
  },
  {
    content: "Golden hour",
  },
  {
    content: "3D Wireframe",
  },
  {
    content: "Acrylic",
  },
  {
    content: "Pop art",
  },
  {
    content: "Origami",
  },
  {
    content: "Retro",
  },
  {
    content: "Wide-angle",
  },
  {
    content: "Moss",
  },
  {
    content: "Art nouveau",
  },
  {
    content: "Ink wash",
  },
  {
    content: "Claymation",
  },
  {
    content: "Film noir",
  },
  {
    content: "Mirrored glass",
  },
  {
    content: "Expressionism",
  },
  {
    content: "Art deco",
  },
  {
    content: "Digital art",
  },
  {
    content: "Graffiti",
  },
  {
    content: "Anime",
  },
  {
    content: "Electronics",
  },
  {
    content: "Cave art",
  },
  {
    content: "Caricature",
  },
  {
    content: "Ice",
  },
  {
    content: "Oil painting",
  },
  {
    content: "Pixel art",
  },
  {
    content: "Analog film",
  },
  {
    content: "Folk art",
  },
  {
    content: "Pinhole camera",
  },
  {
    content: "Steampunk",
  },
  {
    content: "Lava",
  },
  {
    content: "Tapestry",
  },
  {
    content: "Low angle",
  },
  {
    content: "Pencil sketch",
  },
  {
    content: "Linocut",
  },
  {
    content: "Stars",
  },
  {
    content: "Rococo",
  },
  {
    content: "Gothic",
  },
  {
    content: "Dawn",
  },
  {
    content: "Mosaic",
  },
  {
    content: "Feathers",
  },
  {
    content: "Sculpture",
  },
  {
    content: "Futurism",
  },
  {
    content: "Dutch angle",
  },
  {
    content: "Pointillism",
  },
  {
    content: "High contrast",
  },
  {
    content: "Concept art",
  },
  {
    content: "Gold",
  },
  {
    content: "High angle",
  },
  {
    content: "Artificial lighting",
  },
  {
    content: "Ukiyo-e",
  },
  {
    content: "Stone",
  },
  {
    content: "Watercolor",
  },
  {
    content: "Muted",
  },
  {
    content: "Comic book",
  },
  {
    content: "Cubism",
  },
];

export const ASPECT_RATIO_OPTIONS = [
  {
    value: "Square",
    label: "Square",
  },
  {
    value: "Widescreen",
    label: "Widescreen",
  },
  {
    value: "Vertical",
    label: "Vertical",
  },
];
