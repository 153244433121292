export class HttpError extends Error {
  constructor(public code: number, message: string) {
    super(message);
  }
}

export type HttpResponse<T> = {
  errorCode: number;
  errorMsg: string;
  result: T;
};
