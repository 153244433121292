import { RootState } from "../index";

export const selectModelData = (state: RootState) => state.model.modelData;

export const selectModelTotalCnt = (state: RootState) =>
  state.model.modelTotalCount;

export const selectModelPageIndex = (state: RootState) =>
  state.model.modelPageIndex;

export const selectModelsInfo = (state: RootState) => state.model.modelsInfo;
export const selectModelsInfoTotalCnt = (state: RootState) =>
  state.model.modelsInfoTotalCount;
export const selectModelsTags = (state: RootState) => state.model.modelsTags;

export const selectSearchModelData = (state: RootState) =>
  state.model.searchModelData;
