import React from "react";
import TabBtn from "../../../components/userInterface/buttons/TabBtn";
import {
  getOuterServiceRoutes,
  OuterServicePage,
} from "../../../../routes/routes";
import { AdminAllServiceType } from "../../../../features/batchMetadata";

type Props = {
  selectTab: string;
  setSelectTab: React.Dispatch<React.SetStateAction<AdminAllServiceType>>;
  scopeRoute: OuterServicePage;
  loading: boolean;
};

interface ITabInfo {
  tabName: string;
  value: AdminAllServiceType;
  pathName: string;
}
const BillingServiceSelector: React.FC<Props> = ({
  selectTab,
  setSelectTab,
  scopeRoute,
  loading,
}) => {
  const tabList: ITabInfo[] = [
    {
      tabName: "AI Chat",
      value: "aichat",
      pathName: getOuterServiceRoutes(scopeRoute, "aichat").path,
    },
    {
      tabName: "E-Approval",
      value: "eapproval",
      pathName: getOuterServiceRoutes(scopeRoute, "eapproval").path,
    },
    {
      tabName: "Employee",
      value: "employee",
      pathName: getOuterServiceRoutes(scopeRoute, "employee").path,
    },
  ];

  return (
    <>
      {tabList.map((tab) => (
        <TabBtn
          tabName={tab.tabName}
          selectedTab={selectTab}
          value={tab.value}
          onClickHandler={() => setSelectTab(tab.value)}
          pathName={tab.pathName}
          loading={loading}
        />
      ))}
    </>
  );
};

export default BillingServiceSelector;
