import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { selectSuccessMsg } from "../../features/ui_loading";
import { useEffect } from "react";

export const useSuccessSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const success = useSelector(selectSuccessMsg);

  useEffect(() => {
    if (success.msg.length > 0) {
      enqueueSnackbar(success.msg, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
  }, [success]);
};
