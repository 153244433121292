import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import { memo } from "react";

type Props = {
  onClickHandler: () => void;
  btnName: string;
  isDisabled?: boolean;
};

const ModalRegisterBtn: React.FC<Props> = ({
  onClickHandler,
  btnName,
  isDisabled,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <button
      className={`register_modal_btn register_modal_btn_${teamsTheme}`}
      onClick={onClickHandler}
      disabled={isDisabled}
    >
      {btnName}
    </button>
  );
};

export default memo(ModalRegisterBtn);
