import { Route, Routes, useLocation } from "react-router-dom";
import "./MainFrame.scss";
import InfiniteScrollBar from "../../components/sidebar/InfiniteScrollBar";
import {
  selectIsShowSidebar,
  selectTeamsTheme,
  setIsShowSidebar,
} from "../../../features/auth";
import { useSelector } from "react-redux";
import { allRoutes } from "../../../routes/routes";
import {
  Container,
  Section,
  Bar,
  ColumnResizer,
  Resizer,
} from "@column-resizer/react";
import useWindowResize from "../../../libs/hooks/useWindowResize";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../features";
import useResponsiveView from "../../../libs/hooks/useResonsiveView";
import clsx from "clsx";
import {
  deleteChatAsync,
  selectCheckedQaIds,
  selectIsShowDeleteCheckbox,
  setIsShowDeleteCheckbox,
} from "../../../features/chat";
import { RiDeleteBinLine } from "react-icons/ri";
import DeleteChatSideBottomBar from "../common/DeleteChatSideBottomBar";

const SIDEBAR_DEFAULT_WIDTH = 330;
const SIDEBAR_MAX_WIDTH = 520;
const SIDEBAR_MIN_WIDTH = 0;

const MainFrame = () => {
  const { isMobile, isTablet, isDesktop } = useResponsiveView();
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const windowSize = useWindowResize();
  const isShowSidebar = useSelector(selectIsShowSidebar);
  const IsShowDeleteCheckbox = useSelector(selectIsShowDeleteCheckbox);
  const checkedQaIds = useSelector(selectCheckedQaIds);
  const [isShowChatDeletionTitle, setIsShowChatDeletionTitle] = useState(() =>
    isDesktop ? true : false
  );
  const containerRef = useRef<ColumnResizer>(null);

  const location = useLocation();

  useEffect(() => {
    if (!isDesktop) return;

    const container = containerRef.current;
    if (!container) return;

    const resizer = container.getResizer();

    if (!isShowSidebar) {
      resizer.resizeSection(0, {
        toSize: windowSize.width ?? window.innerWidth,
      });
      resizer.resizeSection(2, {
        toSize: 0,
      });
    }

    if (isShowSidebar) {
      const sidebarWidth = resizer.getSectionSize(1);
      dispatch(setIsShowDeleteCheckbox(false));

      if (!windowSize.width) return;
      if (sidebarWidth === SIDEBAR_MIN_WIDTH) {
        resizer.resizeSection(0, {
          toSize: windowSize.width - SIDEBAR_DEFAULT_WIDTH,
        });
        resizer.resizeSection(2, {
          toSize: SIDEBAR_DEFAULT_WIDTH,
        });
      }
    }

    container.applyResizer(resizer);
  }, [isDesktop, isShowSidebar, windowSize.width]);

  const beforeApplyResizer = (resizer: Resizer) => {
    if (!isDesktop) return;
    const sidebarWidth = resizer.getSectionSize(1);

    if (sidebarWidth === SIDEBAR_MIN_WIDTH) {
      dispatch(setIsShowSidebar(false));
    }
    if (sidebarWidth > SIDEBAR_MIN_WIDTH) {
      dispatch(setIsShowSidebar(true));
      dispatch(setIsShowDeleteCheckbox(false));
    }
  };

  const deleteChatHandler = (e: React.MouseEvent) => {
    e.stopPropagation();

    dispatch(deleteChatAsync.request({ listIds: checkedQaIds }));
    dispatch(setIsShowDeleteCheckbox(false));

    // selectDeleteIdHandler("");
  };

  return windowSize.width ? (
    <Container
      className="MainFrame"
      columnResizerRef={containerRef}
      beforeApplyResizer={beforeApplyResizer}
    >
      <Section
        minSize={
          isDesktop ? windowSize.width - SIDEBAR_MAX_WIDTH : windowSize.width
        }
        defaultSize={
          isDesktop
            ? windowSize.width - SIDEBAR_DEFAULT_WIDTH
            : windowSize.width
        }
      >
        <div
          className={clsx({
            "prevent_click_black_bg": IsShowDeleteCheckbox,
          })}
        />
        <div
          className={clsx("CenterLayout", {
            "my_AI_chat_mobile_center_layout":
              isMobile && location.pathname.includes("my_chat_mgmt/edit"),
          })}
        >
          <Routes>
            {Object.values(allRoutes).map((route) => {
              const modifiedPath = route.path.replace(`/private`, "");
              return <Route key={route.path} {...route} path={modifiedPath} />;
            })}
          </Routes>
        </div>
      </Section>

      <Bar
        size={isDesktop ? 10 : 0}
        style={{
          background: "rgba(199, 213, 224, 0.16)",
          cursor: "col-resize",
          display: isDesktop ? "block" : "none",
        }}
      />

      <Section
        minSize={0}
        defaultSize={isDesktop ? SIDEBAR_DEFAULT_WIDTH : 0}
        maxSize={SIDEBAR_MAX_WIDTH}
        disableResponsive={true}
        style={{ zIndex: 900 }}
        onSizeChanged={(size) => {
          if (size < 230) {
            setIsShowChatDeletionTitle(false);
            return;
          }
          setIsShowChatDeletionTitle(true);
        }}
      >
        <div
          className={clsx(
            `desktop_infiniteScroll desktop_infiniteScroll_${teamsTheme}`
          )}
        >
          <InfiniteScrollBar />
        </div>
        <DeleteChatSideBottomBar />
      </Section>
    </Container>
  ) : (
    <></>
  );
};

export default MainFrame;
