import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { selectTeamsTheme } from "../../../features/auth";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import "./admin.scss";
import {
  getOuterServiceRoutes,
  OuterServicePage,
} from "../../../routes/routes";
import {
  DomainType,
  BillingTitle,
  BillingUpperTitle,
  selectTotalTokenCount,
  setBillingSearchInputs,
  getBillingNameListAsync,
  resetBillingSearchInputs,
  selectBillingSearchInputs,
} from "../../../features/billing";
import { openAlert } from "../../../features/alert";
import { useAppDispatch } from "../../../features";
import { BillingSearch } from "./billing/BillingSearch";
import { TokenLogTable } from "./billing/TokenLogTable";
import {
  selectTokenListLoading,
  selectBillingNameListLoading,
} from "../../../features/ui_loading";
import capitalize from "../../../utils/capitalize";
import Pagenator from "../../components/common/Pagenator";
import { AdminAllServiceType } from "../../../features/batchMetadata";
import BillingServiceSelector from "./billing/BillingServiceSelector";
import SelectQueryScope from "../../components/admin/SelectQueryScope";

const billingParams: BillingTitle[] = ["company", "department", "user"];
const allServiceParams: AdminAllServiceType[] = [
  "eapproval",
  "employee",
  "aichat",
];

export const BILLING_QUERY_SCOPE_OPTIONS = [
  {
    value: "company",
    label: "Company Billing Management",
  },
  {
    value: "department",
    label: "Department Billing Management",
  },
  {
    value: "user",
    label: "User Billing Management",
  },
];
export type BillingParams = {
  service: any;
  title: any;
};

function isBillingServiceParam(
  params: BillingParams
): params is { service: AdminAllServiceType; title: BillingTitle } {
  return (
    allServiceParams.includes(params.service) &&
    billingParams.includes(params.title)
  );
}

const BillingMgmtRoute = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const billingNameListLoading = useSelector(selectBillingNameListLoading);
  const teamsTheme = useSelector(selectTeamsTheme);
  const [selectTab, setSelectTab] = useState<AdminAllServiceType>("aichat");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const billInputs = useSelector(selectBillingSearchInputs);
  const [nameTitle, setNameTitle] = useState<BillingUpperTitle>("Company");
  const [queryScopeOption, setQueryScopeOption] = useState(
    BILLING_QUERY_SCOPE_OPTIONS[0]
  );
  const [scopeRoute, setScopeRoute] = useState<OuterServicePage>(
    "BILLING_MGMT_COMPANY"
  );
  const tokenLoading = useSelector(selectTokenListLoading);
  const totalCount = useSelector(selectTotalTokenCount);

  useEffect(() => {
    return () => {
      dispatch(resetBillingSearchInputs());
    };
  }, []);

  const setCurrentPage = (newPageIndex: number) => {
    dispatch(
      setBillingSearchInputs({
        key: "skip",
        value: newPageIndex,
      })
    );
  };

  useEffect(() => {
    if (!params.title || !params.service) return;

    const { title, service } = params;

    if (
      !isBillingServiceParam({
        service,
        title,
      })
    ) {
      navigate(
        getOuterServiceRoutes(
          "BILLING_MGMT_COMPANY",
          service as AdminAllServiceType
        ).path
      );
      return;
    }
    dispatch(resetBillingSearchInputs());

    const scope = `BILLING_MGMT_${title.toUpperCase()}` as OuterServicePage;
    const billingScope = capitalize(title) as BillingUpperTitle;

    setScopeRoute(scope);
    setNameTitle(billingScope);
    setSelectTab(service as AdminAllServiceType);
    setQueryScopeOption({
      value: title,
      label: `${billingScope} Billing Management`,
    });

    dispatch(
      setBillingSearchInputs({
        key: "domainType",
        value: service.toUpperCase() as DomainType,
      })
    );

    if (title === "company") {
      dispatch(
        setBillingSearchInputs({
          key: "filterType",
          value: "COMP",
        })
      );
      dispatch(
        getBillingNameListAsync.request({
          payload: {
            startDate: billInputs.startDate,
            endDate: billInputs.endDate,
            model: Number(billInputs.model),
            domainType: service.toUpperCase() as DomainType,
          },
          title: params.title as BillingTitle,
        })
      );
    }
    if (title === "department") {
      dispatch(
        setBillingSearchInputs({
          key: "filterType",
          value: "DEPT",
        })
      );
    }

    if (title === "user") {
      dispatch(
        setBillingSearchInputs({
          key: "filterType",
          value: "USER",
        })
      );
    }
  }, [params]);

  const changeQueryScope = (newValue: any) => {
    const { value } = newValue;
    const { service } = params;
    const scope = `BILLING_MGMT_${value.toUpperCase()}` as OuterServicePage;

    navigate(getOuterServiceRoutes(scope, service as AdminAllServiceType).path);
  };

  const downloadTokenHandler = async () => {
    setDownloadLoading(true);
    console.log("billInputs", billInputs);
    await axios
      .post(
        `/log/tokenlogDownload`,
        {
          ...billInputs,
          model: Number(billInputs.model),
          skip: 1,
          limit: 50000,
        },
        { responseType: "blob" }
      )
      .then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `billing.xlsx`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setDownloadLoading(false);
      })
      .catch((err) => {
        dispatch(
          openAlert({
            alertMsg: "failed download",
          })
        );
        setDownloadLoading(false);
      });
  };

  return (
    <>
      <div className={`mgmt_page scroll_none`}>
        <hgroup>
          <div className="title_tab_wrap">
            <SelectQueryScope
              options={BILLING_QUERY_SCOPE_OPTIONS}
              value={queryScopeOption}
              onChangeHandler={changeQueryScope}
              isLoading={tokenLoading || billingNameListLoading}
            />
            <div className={`tab_btn_wrap tab_btn_wrap_${teamsTheme}`}>
              <BillingServiceSelector
                selectTab={selectTab}
                setSelectTab={setSelectTab}
                scopeRoute={scopeRoute}
                loading={tokenLoading || billingNameListLoading}
              />
            </div>
          </div>
          <MainColorBtn
            onClickHandler={downloadTokenHandler}
            btnName="Download"
            loading={downloadLoading}
          />
        </hgroup>
        <BillingSearch title={nameTitle} params={params as BillingParams} />
        <TokenLogTable title={nameTitle} />

        <Pagenator
          totalCount={totalCount}
          pageSize={billInputs.limit}
          currentPage={billInputs.skip}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default BillingMgmtRoute;
