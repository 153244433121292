// import "./PreviewSelectSearchTypeList.scss";
import "../../components/chat/SelectChatModel.scss";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../features";
import {
  selectAssistantTypeInfo,
  selectChatLoading,
  setAssistantTypeInfo,
  setInputUploadFiles,
} from "../../../features/chat";
import { selectTeamsTheme } from "../../../features/auth";
import { IAssistantTypeItem } from "../../../constants/chat";
import { clsx } from "clsx";
import { selectMyAIChatForm } from "../../../features/myGpts";
import { useEffect, useState } from "react";
import useResponsiveView from "../../../libs/hooks/useResonsiveView";
import useWindowResize from "../../../libs/hooks/useWindowResize";
import { useIsCanAnalyzeFile } from "../../../libs/hooks/useIsCanAnalyzeFile";

const PreviewSelectSearchTypeItem: React.FC<IAssistantTypeItem> = ({
  title,
  assistantType,
  mobileTitle,
}) => {
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const selectedAssistantTypeInfo = useSelector(selectAssistantTypeInfo);
  const myAIChatForm = useSelector(selectMyAIChatForm);
  const chatLoading = useSelector(selectChatLoading);
  const { isMobile, isTablet } = useResponsiveView();
  const windowSize = useWindowResize();

  const changeType = () => {
    dispatch(
      setAssistantTypeInfo({
        assistantType,
        capabilities: myAIChatForm.capabilities,
      })
    );

    if (assistantType !== "FILE") {
      dispatch(setInputUploadFiles(null));
    }
  };

  return (
    <div className="tooltip_wrap">
      <button
        onClick={changeType}
        disabled={chatLoading}
        className={clsx(`search_type-button ${teamsTheme}`, {
          [`assistant_type-selected ${teamsTheme}`]:
            assistantType === selectedAssistantTypeInfo.assistantType,
          assistant_type_disabled_button: chatLoading,
        })}
      >
        {isMobile || (windowSize.width && windowSize.width < 1290 && !isTablet)
          ? mobileTitle
          : title}
      </button>
    </div>
  );
};

const PreviewSelectSearchTypeList = () => {
  const dispatch = useAppDispatch();
  const myAIChatForm = useSelector(selectMyAIChatForm);
  const [searchType, setSearchType] = useState<IAssistantTypeItem[]>();
  const isCanAnalyzeFile = useIsCanAnalyzeFile();

  useEffect(() => {
    const temp: IAssistantTypeItem[] = [
      {
        assistantType: "DEFAULT",
        title: "Default",
        mobileTitle: "Default",
        description: "",
      },
    ];
    myAIChatForm.capabilities.forEach((elem) => {
      if (elem === "WEB") {
        temp.push({
          assistantType: elem,
          title: "Web content",
          mobileTitle: "Web",
          description: "",
        });
      }
      if (elem === "FILE" && isCanAnalyzeFile) {
        temp.push({
          assistantType: elem,
          title: "Data Analysis",
          mobileTitle: "Analysis",
          description: "",
        });
      }
      if (elem === "DALLE") {
        temp.push({
          assistantType: elem,
          title: "Image Generation",
          mobileTitle: "Image",
          description: "",
        });
      }
    });

    setSearchType(temp);
    dispatch(
      setAssistantTypeInfo({
        assistantType: "DEFAULT",
        capabilities: temp.map((el) => el.assistantType),
      })
    );
  }, [myAIChatForm.capabilities, isCanAnalyzeFile]);

  if (!searchType?.length) {
    return <></>;
  }
  if (searchType.length > 1) {
    return (
      <div className="select_search_type_container">
        {searchType.map((elem, idx) => (
          <PreviewSelectSearchTypeItem
            key={idx}
            title={elem.title}
            description={elem.description}
            assistantType={elem.assistantType}
            mobileTitle={elem.mobileTitle}
          />
        ))}
      </div>
    );
  }
  return <></>;
};

export default PreviewSelectSearchTypeList;
