import "./ChatIntro.scss";
import clsx from "clsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAppDispatch } from "../../../features";
import {
  selectRemoveStartChat,
  setModelEngine,
  IntroChatCarouselItem,
  setSelectedGptOption,
  getAnswerAsync,
  setAssistantTypeInfo,
} from "../../../features/chat";
import { useSelector } from "react-redux";
import {
  openPopup,
  selectTeamsTheme,
  selectTemperature,
  selectUserId,
} from "../../../features/auth";
import React, { useEffect, useState } from "react";
import { selectChatLoading } from "../../../features/chat";
import { ReactComponent as ModelThree } from "../../../assets/svg/logo/ModelThree.svg";
import { ReactComponent as ModelFour } from "../../../assets/svg/logo/ModelFour.svg";
import { ReactComponent as GPTsDefault } from "../../../assets/svg/logo/GPTsDefault.svg";
import { ReactComponent as Dalle } from "../../../assets/svg/logo/Dalle.svg";
import { ReactComponent as DDI } from "../../../assets/svg/logo/DDI.svg";

import Slider from "react-slick";
import { GPT_SELECT_OPTIONS } from "./mainChat/SelectGPTModel";
import {
  MyGPTsData,
  getMyGPTSDetailAsync,
  selectMyGptsSideBarList,
  // setSideBarMyGPTId,
} from "../../../features/myGpts";
import { selectMyGptsSideBarListLoading } from "../../../features/ui_loading";
import { ClipLoader } from "react-spinners";
import { GPT4_CAPABILITIES } from "../../../constants/chat";

const introContent: IntroChatCarouselItem[] = [
  {
    profileImage: <ModelThree />,
    name: "3.5",
    id: "3.5v",
  },
  {
    profileImage: <ModelFour />,
    name: "4.0",
    id: "4.0v",
  },
  {
    profileImage: <Dalle />,
    name: "DALL-E",
    id: "dalle",
  },
];

const COLOR = {
  primary: "#005eb8",
  white: "#fefefe",
};

type IntroMyGPTs = (IntroChatCarouselItem | MyGPTsData)[] | [];

const ChatIntro = React.forwardRef<HTMLDivElement>((_, ref) => {
  const dispatch = useAppDispatch();
  const chatLoading = useSelector(selectChatLoading);
  const removeStartChat = useSelector(selectRemoveStartChat);
  const teamsTheme = useSelector(selectTeamsTheme);
  const myGptList = useSelector(selectMyGptsSideBarList);
  const myGptListLoading = useSelector(selectMyGptsSideBarListLoading);

  const [myIntroApps, setMyIntroApps] = useState<IntroMyGPTs>([]);
  const [sliderSetting, setSliderSetting] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  });

  useEffect(() => {
    const scrollBody = document.getElementById("chat_body");

    if (scrollBody) {
      scrollBody.style.scrollBehavior = "auto";
      scrollBody.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    const introLIst = [...introContent, ...myGptList];
    setMyIntroApps(introLIst);
    if (myGptList.length) {
      setSliderSetting((prev) => ({
        ...prev,
        slidesToShow: 4,
        slidesToScroll: 4,
      }));
    }
  }, [myGptList]);

  const downloadPDF = () => {
    const pdfFilePath = "/pdf/Security_Guidelines.pdf";

    const a = document.createElement("a");
    a.href = pdfFilePath;
    a.download = `AI Chat Security Guidelines.pdf`;
    a.click();
    a.remove();
    window.URL.revokeObjectURL(pdfFilePath);
  };

  // // ❓ 애니메이션 효과는 어떻게 주는거지..?
  // if (selectChatId !== "-1" || firstSelectedId !== "-1") {
  //   return <></>;
  // }

  const clickSliderItem = (id: string | null) => {
    if (!id) return;
    dispatch(
      setAssistantTypeInfo({
        assistantType: "DEFAULT",
        capabilities: GPT4_CAPABILITIES,
      })
    );

    if (id === "3.5v" || id === "4.0v") {
      dispatch(setModelEngine(id));
      // dispatch(setSideBarMyGPTId({ id: "" }));

      const newOption: any = GPT_SELECT_OPTIONS.find(
        (elem) => elem.chatModel === id
      );
      if (newOption) {
        dispatch(
          setSelectedGptOption({
            ...newOption,
            label: newOption.label[0],
          })
        );
      }
    } else if (id === "dalle") {
      dispatch(setModelEngine(id));
      // dispatch(setSideBarMyGPTId({ id: "" }));
    } else {
      dispatch(setModelEngine("myGPTs"));
      dispatch(getMyGPTSDetailAsync.request({ myGPTsId: id }));
      // dispatch(setSideBarMyGPTId({ id }));
      const myApp = myGptList.find((elem) => elem.id === id);
      if (myApp) {
        dispatch(
          setAssistantTypeInfo({
            assistantType: myApp.knowledge.length ? "FILE" : "DEFAULT",
            capabilities: ["DEFAULT", ...myApp.capabilities],
            myGPTsId: myApp.id,
            profileImage: myApp.profileImage,
          })
        );
      }
    }
  };
  // if (!myIntroApps?.length) return <></>;

  return myIntroApps.length ? (
    <div
      ref={ref}
      className={clsx(`chat_intro_container ${teamsTheme}`, {
        [`chat_intro_container_remove ${teamsTheme}`]:
          chatLoading && removeStartChat,
        chat_intro_container_remove_not_animation: removeStartChat,
      })}
    >
      <DDI
        className="chat_intro_logo"
        fill={teamsTheme === "default" ? `${COLOR.primary}` : `${COLOR.white}`}
      />
      <h2>How can I help you with your tasks today?</h2>
      <div className={`guideline_pdf-button_container ${teamsTheme}`}>
        <button
          className={`guideline_pdf-button ${teamsTheme}`}
          onClick={downloadPDF}
        >
          View Security Guidelines
        </button>
      </div>
      <div
        className={clsx("intro-slider_container", {
          "intro-slider-spinner_center": myGptListLoading,
        })}
      >
        {!myGptListLoading ? (
          <Slider className="intro-slider" {...sliderSetting}>
            {myIntroApps.map((elem, idx) => {
              return (
                <div
                  key={idx}
                  className={`intro-slider_element ${teamsTheme}`}
                  onClick={() => clickSliderItem(elem.id)}
                >
                  {!elem.profileImage && <GPTsDefault />}

                  {typeof elem.profileImage === "string" ? (
                    <img
                      className="my_gpt_profile"
                      src={elem.profileImage}
                      alt="My AI Chat"
                    />
                  ) : (
                    <>{elem.profileImage}</>
                  )}

                  <div className="name">{elem.name}</div>
                </div>
              );
            })}
          </Slider>
        ) : (
          <ClipLoader loading={myGptListLoading} size={20} color="#80b1e2" />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
});

export default ChatIntro;
