import React from "react";
import classNames from "classnames";
import "./Pagenator.scss";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";

type Props = {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
};

const Pagenator: React.FC<Props> = ({
  totalCount,
  pageSize,
  currentPage,
  setCurrentPage,
}) => {
  const maxPages = 8;
  const teamsTheme = useSelector(selectTeamsTheme);

  // calculate total pages
  const totalPages = Math.ceil(totalCount / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage: number, endPage: number;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalCount - 1);
  // create an array of pages to ng-repeat in the pager control
  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i
  );

  const onPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const onNext = () => setCurrentPage(currentPage + 1);

  return (
    <div className={`paginator paginator_${teamsTheme}`}>
      <ul>
        <li>
          <div className="totalText">{`Total ${totalCount} records`}</div>
        </li>
        {pages[0] !== 1 && (
          <li>
            <button className="arrow" onClick={onPrev}>
              <BsArrowLeftShort />
            </button>
          </li>
        )}
        {pages.map((pageNumber) => (
          <li onClick={() => setCurrentPage(pageNumber)} key={pageNumber}>
            <button
              className={`button_num ${classNames({
                selected: currentPage === pageNumber,
              })}`}
            >
              {pageNumber}
            </button>
          </li>
        ))}
        {pages[pages.length - 1] !== totalPages && (
          <li>
            <button className="arrow" onClick={onNext}>
              {/* &gt; */}
              <BsArrowRightShort />
            </button>
          </li>
        )}
        <li>
          {totalPages > 10 && (
            <div className="totalText">{`Total ${totalPages} pages`}</div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Pagenator;
