import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import {
  ModelInfo,
  selectModelData,
  updateModelAsync,
} from "../../../../features/model";
import { transformDate } from "../../../../libs/transformDate";
import { BsCheckLg } from "react-icons/bs";
import TableBtn from "../../userInterface/buttons/TableBtn";
import { useAppDispatch } from "../../../../features";
import { ClipLoader } from "react-spinners";
import { selectGetModelsInfoLoading } from "../../../../features/ui_loading";

type Props = {
  setModifyModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<ModelInfo | null>>;
  setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ENDPOINT_TABLE_COLUMNS = [
  "Model Name",
  "Model Version",
  "Deployment Name",
  "Endpoint",
  "Tags",
  "CreateDate",
  "CreateBy",
  "",
];

const RegisteredEndpointTable: React.FC<Props> = ({
  setModifyModal,
  setSelectedData,
  setDeleteModal,
}) => {
  const dispatch = useAppDispatch();
  const modelData = useSelector(selectModelData);
  const teamsTheme = useSelector(selectTeamsTheme);
  const getModelLoading = useSelector(selectGetModelsInfoLoading);

  // const activateItemHandler = (item: ModelInfo) => {
  //   dispatch(updateModelAsync.request({ ...item, activated: !item.activated }));
  // };

  return (
    <table className={`page_table page_table-${teamsTheme}`} width="500">
      <colgroup>
        <col width="20%" />
        <col width="15%" />
        <col width="20%" />
        <col width="45%" />
        <col width="30%" />
        <col width="20%" />
        <col width="23%" />
        <col width="150px" />
      </colgroup>
      <thead>
        {/* <thead className={`thead_${teamsTheme}`}> */}
        <tr>
          {ENDPOINT_TABLE_COLUMNS.map((elem, idx) => (
            <th key={idx} title={elem}>
              {elem}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* <tbody className={`tbody_${teamsTheme}`}> */}
        {modelData.length > 0 ? (
          modelData.slice(0, 10).map((item) => (
            <tr>
              <td className="line_change" title={item.modelName}>
                {item.modelName}
              </td>
              <td className="line_change" title={item.modelVersion}>
                {item.modelVersion}
              </td>
              <td className="line_change" title={item.deploymentName}>
                {item.deploymentName}
              </td>
              <td className="line_change" title={item.endpoint}>
                {item.endpoint ? item.endpoint : "-"}
              </td>
              <td className="line_change" title={item.tags.join(", ")}>
                {item.tags ? item.tags.join(", ") : "-"}
              </td>
              <td
                className="line_change"
                title={item.createDate ? transformDate(item.createDate) : ""}
              >
                {item.createDate ? transformDate(item.createDate) : "-"}
              </td>
              <td className="line_change" title={item.createBy}>
                {item.createBy}
              </td>

              <td className="td_btn_wrap">
                <TableBtn
                  onClickHandler={() => {
                    setModifyModal(true);
                    setSelectedData(item);
                  }}
                  btnName="modify"
                />
                <TableBtn
                  onClickHandler={() => {
                    setDeleteModal(true);
                    setSelectedData(item);
                  }}
                  btnName="delete"
                />
              </td>
            </tr>
          ))
        ) : (
          <tr className="no_data">
            <td colSpan={8} style={{ textAlign: "center" }}>
              {getModelLoading ? (
                <ClipLoader
                  loading={getModelLoading}
                  size={20}
                  color="#80b1e2"
                />
              ) : (
                "No Data"
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default RegisteredEndpointTable;
