import { useEffect, useState } from "react";
import "./Modal.scss";
import { useAppDispatch } from "../../../features";
import {
  AdminUserData,
  addAdminUserDataAsync,
  selectSearchAdminUserData,
  updateAdminUserDataAsync,
} from "../../../features/admin";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";
import InputTextType from "../userInterface/inputs/InputTextType";
import InputCheckboxType from "../userInterface/inputs/InputCheckboxType";
import TextareaType from "../userInterface/inputs/TextareaType";
import ModalCancelBtn from "../userInterface/buttons/ModalCancelBtn";
import ModalRegisterBtn from "../userInterface/buttons/ModalRegisterBtn";

type Props = {
  onClose: () => void;
  modifyData?: AdminUserData;
  title: string;
};

const AdminMgmtModal: React.FC<Props> = ({ onClose, modifyData, title }) => {
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);

  const searchtext = useSelector(selectSearchAdminUserData);

  const [error, setError] = useState(false);
  const [input, setInput] = useState<AdminUserData>({
    userName: "",
    userId: "",
    activated: false,
    userDescription: "",
  });

  useEffect(() => {
    if (modifyData) {
      setInput(modifyData);
    }
  }, []);

  useEffect(() => {
    if (input.userName && input.userId) {
      setError(false);
    }
  }, [input]);

  const registerHandler = () => {
    if (input.userName && input.userId) {
      if (modifyData) {
        dispatch(updateAdminUserDataAsync.request(input));
      } else {
        dispatch(
          addAdminUserDataAsync.request({
            data: input,
            searchText: searchtext,
            pageSize: window.innerWidth <= 1024 ? 6 : 10,
          })
        );
      }

      onClose();
    } else {
      setError(true);
    }
  };

  return (
    <div className={`modal_black_bg modal_black_bg_${teamsTheme}`}>
      <div className={`modal_white_bg modal_white_bg_${teamsTheme}`}>
        <hgroup>
          <h3 className="modal_title">Admin {title}</h3>
        </hgroup>

        <table className={`modal_table_wrap modal_table_wrap_${teamsTheme}`}>
          <colgroup>
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>
                Name <span className="essential">*</span>
              </th>
              <td>
                <InputTextType
                  value={input.userName}
                  onChangeHandler={(e) =>
                    setInput({ ...input, userName: e.target.value })
                  }
                  placeholder="Please enter admin name"
                />
              </td>
            </tr>

            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>
                Email <span className="essential">*</span>
              </th>
              <td>
                <InputTextType
                  value={input.userId}
                  onChangeHandler={(e) =>
                    setInput({ ...input, userId: e.target.value })
                  }
                  placeholder="Please enter admin email"
                />
              </td>
            </tr>

            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>Activated</th>
              <td>
                <InputCheckboxType
                  checked={input.activated}
                  onChangeHandler={(e) =>
                    setInput({
                      ...input,
                      activated: e.target.checked,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>Description</th>
              <td>
                <TextareaType
                  value={input.userDescription}
                  onChangeHandler={(e) =>
                    setInput({ ...input, userDescription: e.target.value })
                  }
                  placeholder="Please enter description"
                />
              </td>
            </tr>
          </tbody>
        </table>

        {error && <p className="modal_error">Please enter required items</p>}

        <div className={`modal_btn_wrap modal_btn_wrap_${teamsTheme}`}>
          <ModalCancelBtn onClickHandler={onClose} btnName="Cancel" />
          <ModalRegisterBtn onClickHandler={registerHandler} btnName={title} />
        </div>
      </div>
    </div>
  );
};

export default AdminMgmtModal;
