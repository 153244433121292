import "./Modal.scss";
import { selectTeamsTheme } from "../../../features/auth";
import { useSelector } from "react-redux";
import ModalCancelBtn from "../userInterface/buttons/ModalCancelBtn";
import ModalRegisterBtn from "../userInterface/buttons/ModalRegisterBtn";
import { IoWarning } from "react-icons/io5";

type Props = {
  buttonName: string;
  modalText: string;
  cautionText: string;
  clickHandler: () => void;
  onClose: () => void;
  isDisabled?: boolean;
};

const CautionModal: React.FC<Props> = ({
  buttonName,
  onClose,
  clickHandler,
  cautionText,
  modalText,
  isDisabled,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <div
      className={`modal_black_bg modal_black_bg_${teamsTheme} caution_modal`}
    >
      <div className={`modal_white_bg modal_white_bg_${teamsTheme}`}>
        <div className="title_wrap">
          <span className="title">
            <IoWarning /> Caution:{" "}
          </span>
          <div className="title_description">{cautionText}</div>
        </div>
        <p className="delete_txt">{modalText}</p>

        <div
          className={`delete_modal_btn_wrap modal_btn_wrap_${teamsTheme} modal_delete_btn_wrap`}
        >
          <ModalCancelBtn onClickHandler={onClose} btnName="Cancel" />
          <ModalRegisterBtn
            onClickHandler={clickHandler}
            btnName={buttonName}
            isDisabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default CautionModal;
