import { useEffect, useState } from "react";
import "./Modal.scss";
import { useAppDispatch } from "../../../features";
import {
  AccessUserData,
  addAccessUserDataAsync,
  selectSearchAccessData,
  updateAccessUserDataAsync,
} from "../../../features/admin";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";
import InputTextType from "../userInterface/inputs/InputTextType";
import InputCheckBoxLabel from "../userInterface/inputs/InputCheckBoxLabel";
import InputCheckboxType from "../userInterface/inputs/InputCheckboxType";
import TextareaType from "../userInterface/inputs/TextareaType";
import ModalCancelBtn from "../userInterface/buttons/ModalCancelBtn";
import ModalRegisterBtn from "../userInterface/buttons/ModalRegisterBtn";

type Props = {
  onClose: () => void;
  modifyData?: AccessUserData;
  title: string;
};

const AccessMgmtUserModal: React.FC<Props> = ({
  onClose,
  modifyData,
  title,
}) => {
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);

  const searchText = useSelector(selectSearchAccessData);

  const [error, setError] = useState(false);
  const [input, setInput] = useState<AccessUserData>({
    userName: "",
    userId: "",
    userDescription: "",
    activated: false,
    canAnalyzeFile: false,
    gptModels: ["gpt3.5"],
  });

  useEffect(() => {
    if (modifyData) {
      setInput({
        ...modifyData,
      });
    }
  }, []);

  useEffect(() => {
    if (input.userName && input.userId) {
      setError(false);
    }
  }, [input]);

  const registerHandler = () => {
    if (input.userName && input.userId) {
      if (modifyData) {
        dispatch(updateAccessUserDataAsync.request(input));
      } else {
        dispatch(
          addAccessUserDataAsync.request({
            data: input,
            searchText: searchText,
            pageSize: window.innerWidth <= 1024 ? 6 : 10,
          })
        );
      }

      onClose();
    } else {
      setError(true);
    }
  };

  const gptVersionCheck = (version: string) => {
    if (input.gptModels.includes(version)) {
      setInput({
        ...input,
        gptModels: input.gptModels.filter((item) => item !== version),
      });
    } else {
      setInput({ ...input, gptModels: [...input.gptModels, version] });
    }
  };

  return (
    <div className={`modal_black_bg modal_black_bg_${teamsTheme}`}>
      <div className={`modal_white_bg modal_white_bg_${teamsTheme}`}>
        <hgroup>
          <h3>User {title}</h3>
        </hgroup>

        <table className={`modal_table_wrap modal_table_wrap_${teamsTheme}`}>
          <colgroup>
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>
                Name <span className="essential">*</span>
              </th>
              <td>
                <InputTextType
                  value={input.userName}
                  onChangeHandler={(e) =>
                    setInput({ ...input, userName: e.target.value })
                  }
                  placeholder="Please enter username"
                />
              </td>
            </tr>

            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>
                Email <span className="essential">*</span>
              </th>
              <td>
                <InputTextType
                  value={input.userId}
                  onChangeHandler={(e) =>
                    setInput({ ...input, userId: e.target.value })
                  }
                  placeholder="Please enter user email"
                />
              </td>
            </tr>

            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>
                Available Version
              </th>
              <td>
                <InputCheckBoxLabel
                  checked={input.gptModels.includes("gpt3.5")}
                  onChangeHandler={() => gptVersionCheck("gpt3.5")}
                  checkboxId="gpt3.5"
                  labelName="gpt3.5"
                  disabled={true}
                />

                <InputCheckBoxLabel
                  checked={input.gptModels.includes("gpt4.0")}
                  onChangeHandler={() => gptVersionCheck("gpt4.0")}
                  checkboxId="gpt4.0"
                  labelName="gpt4.0"
                />
              </td>
            </tr>

            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>Activated</th>
              <td>
                <InputCheckboxType
                  checked={input.activated}
                  onChangeHandler={(e) =>
                    setInput({
                      ...input,
                      activated: e.target.checked,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>Data Analysis</th>
              <td>
                <InputCheckboxType
                  checked={input.canAnalyzeFile}
                  onChangeHandler={(e) =>
                    setInput({
                      ...input,
                      canAnalyzeFile: e.target.checked,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>Description</th>
              <td>
                <TextareaType
                  value={input.userDescription}
                  onChangeHandler={(e) =>
                    setInput({ ...input, userDescription: e.target.value })
                  }
                  placeholder="Please enter description"
                />
              </td>
            </tr>
          </tbody>
        </table>

        {error && <p className="modal_error">Please enter required items</p>}

        <div className={`modal_btn_wrap modal_btn_wrap_${teamsTheme}`}>
          <ModalCancelBtn onClickHandler={onClose} btnName="Cancel" />
          <ModalRegisterBtn onClickHandler={registerHandler} btnName={title} />
        </div>
      </div>
    </div>
  );
};

export default AccessMgmtUserModal;
