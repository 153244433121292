import "./ChatTextarea.scss";
import { useSelector } from "react-redux";
import { selectFontSize, selectTeamsTheme } from "../../../../features/auth";
import {
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  selectAssistantTypeInfo,
  selectChatLoading,
  selectModelEngine,
  selectPromptText,
  selectUploadFiles,
  setPromptText,
} from "../../../../features/chat";
import { ReactComponent as Send } from "../../../../assets/svg/Send.svg";
import { ReactComponent as FileUpload } from "../../../../assets/svg/FileUpload.svg";
import FileCardItem from "../fileUpload/FileCardItem";
import { useFileUpload } from "../../../../libs/hooks/useFileUpload";
import { useAppDispatch } from "../../../../features";
import {
  selectChatLoader,
  selectUploadFileLoading,
} from "../../../../features/ui_loading";
import { ClipLoader } from "react-spinners";
import useWindowResize from "../../../../libs/hooks/useWindowResize";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import {
  ALLOW_FILE_EXTENSION,
  getNameAndExtension,
} from "../../../../services/FileUpload";
import useResponsiveView from "../../../../libs/hooks/useResonsiveView";
import { openAlert } from "../../../../features/alert";

type Props = {
  // prompt: string;
  // setPrompt: Dispatch<React.SetStateAction<string>>;
  addChatHandler: (isRegenerate?: boolean) => void;
};
type UploadElement = HTMLTextAreaElement | HTMLDivElement | HTMLFormElement;

type Color = {
  [key: string]: string;
  default: string;
  dark: string;
  contrast: string;
};

const COLOR: Color = {
  default: "#005eb8",
  dark: "#186dc0",
  contrast: "#fefefe",
  white: "#fefefe",
};

const ChatInputForm: FC<Props> = ({ addChatHandler }) => {
  const { isMobile, isTablet, isDesktop } = useResponsiveView();

  const windowSize = useWindowResize();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { uploadFiles, deleteFile } = useFileUpload();
  const { fileCards } = useSelector(selectUploadFiles);

  const uploadFileLoading = useSelector(selectUploadFileLoading);
  const promptText = useSelector(selectPromptText);
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [cardDragOver, setCardDragOver] = useState<boolean>(false);

  const teamsTheme = useSelector(selectTeamsTheme);
  const chatLoading = useSelector(selectChatLoading);
  const fontsizeChange = useSelector(selectFontSize);
  const fileCardRef = useRef<HTMLDivElement>(null);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const chatFormContainerRef = useRef<HTMLFormElement>(null);
  const modelEngine = useSelector(selectModelEngine);
  const selectedAssistantTypeInfo = useSelector(selectAssistantTypeInfo);
  const answerLoading = useSelector(selectChatLoader);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    dispatch(setPromptText(""));
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (
      !textareaRef?.current ||
      !chatFormContainerRef?.current ||
      !fileCardRef?.current
    ) {
      return;
    }
    if (!windowSize?.width || !windowSize.height) return;
    let MAX_TEXTAREA_HEIGHT = 170;

    if (isMobile) {
      MAX_TEXTAREA_HEIGHT = 80;
    }
    if (isTablet) {
      MAX_TEXTAREA_HEIGHT = 110;
    }

    if (location.pathname.includes("my_chat_mgmt")) {
      MAX_TEXTAREA_HEIGHT = 52;
    }

    const MIN_TEXTAREA_HEIGHT = 52;

    // Reset height - important to shrink on delete
    chatFormContainerRef.current.style.height = "inherit";
    textareaRef.current.style.height = "inherit";

    // Set height
    const fieCardHeight = fileCardRef.current.offsetHeight;
    let newHeight = Math.max(
      textareaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT
    );

    if (newHeight > MAX_TEXTAREA_HEIGHT) {
      newHeight = MAX_TEXTAREA_HEIGHT;
    }

    chatFormContainerRef.current.style.height = `${
      newHeight + fieCardHeight
    }px`;
    textareaRef.current.style.height = `${newHeight}px`;
  }, [promptText, fileCards, windowSize]);

  // enter 클릭시 채팅 요청
  // shift + enter 클릭시 줄바꿈
  const textareaHandler = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // 이 부분은 요청을 보내는 부분으로 변경하셔야 합니다.

      if (!chatLoading) {
        addChatHandler(false);
      }
    }
  };

  const clickUploadButton = (e: any) => {
    e.stopPropagation();
    fileUploadRef.current?.click();
  };

  const fileUploadHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    if (isOverSizeCheck(Array.from(e.target.files))) {
      dispatch(
        openAlert({
          alertMsg: "The file size is too large.",
        })
      );
      return;
    }

    uploadFiles(e.target.files);
  };

  const submitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
  };

  const preventDuplication = (e: any) => {
    e.target.value = null;
  };

  // 드래그 중인 요소가 목표 지점 진입할때
  const handleDragEnter = (e: React.DragEvent<UploadElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedAssistantTypeInfo.assistantType !== "FILE") return;

    setDragOver(true);
    setCardDragOver(true);
  };

  // 드래그 중인 요소가 목표 지점을 벗어날때
  const handleDragLeave = (e: React.DragEvent<UploadElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedAssistantTypeInfo.assistantType !== "FILE") return;

    setDragOver(false);
    setCardDragOver(false);
  };

  // 드래그 중인 요소가 목표 지점에 위치할때
  const handleDragOver = (e: React.DragEvent<UploadElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedAssistantTypeInfo.assistantType !== "FILE") return;

    // if (e.dataTransfer!.files) {
    setDragOver(true);
    setCardDragOver(true);
    // }
  };

  // 드래그 중인 요소가 목표 지점에서 드롭될때
  const handleDrop = (e: React.DragEvent<UploadElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedAssistantTypeInfo.assistantType !== "FILE") return;
    if (uploadFileLoading) {
      dispatch(
        openAlert({ alertMsg: "Your file is currently being uploaded." })
      );
      setDragOver(false);
      setCardDragOver(false);
      return;
    }

    setDragOver(false);
    setCardDragOver(false);
    const [_, fileExtension] = getNameAndExtension(
      e.dataTransfer.files[0].name
    );

    if (!ALLOW_FILE_EXTENSION.includes(fileExtension) || fileExtension === "") {
      dispatch(
        openAlert({
          alertMsg: "The file format you uploaded is not supported.",
        })
      );
      return;
    }

    if (e.dataTransfer) {
      // 드래그되는 데이터 정보와 메서드를 제공하는 dataTransfer 객체 사용
      const droppedFiles = e.dataTransfer.files;
      const fileArray = Array.from(droppedFiles);

      // 동일한 파일명이 있는지 확인
      if (
        fileArray.filter((file: File) =>
          fileCards.find((card) => card.name === file.name)
        ).length > 0
      ) {
        dispatch(
          openAlert({
            alertMsg: "You cannot upload files with the same name.",
          })
        );
        return;
      }

      if (isOverSizeCheck(fileArray)) {
        dispatch(
          openAlert({
            alertMsg:
              "The total upload capacity is 100MB, with a maximum of 10 files.",
          })
        );
        return;
      }

      uploadFiles(droppedFiles);
    }
  };

  // 파일 사이즈 체크
  const isOverSizeCheck = (fileArray: File[]) => {
    let totalSize = 0;
    const maxTotalSize = 100 * 1024 * 1024;

    for (let i = 0; i < fileArray.length; i++) {
      totalSize += fileArray[i].size;
    }

    if (totalSize > maxTotalSize) {
      return true;
    } else {
      return false;
    }
  };

  const checkIsDisabled = useCallback(() => {
    if (chatLoading || answerLoading || uploadFileLoading) {
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);
  }, [chatLoading, answerLoading, uploadFileLoading]);

  useEffect(() => {
    checkIsDisabled();
  }, [checkIsDisabled]);

  return (
    <form
      className={`chat-textarea_container ${teamsTheme}`}
      ref={chatFormContainerRef}
      onSubmit={submitHandler}
    >
      <div
        ref={fileCardRef}
        className={clsx("files_container", {
          drag_and_drop: dragOver === true || cardDragOver === true,
        })}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {fileCards?.map((elem, idx) => (
          <FileCardItem
            key={idx}
            fileId={elem.fileId ?? ""}
            fileName={elem.name}
            extension={elem.extension}
            onDelete={deleteFile}
            imgUrl={elem.imgUrl}
            isLoading={elem.isLoading}
          />
        ))}
      </div>

      <textarea
        name="promptText"
        id="chat_input"
        ref={textareaRef}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onChange={(e) => dispatch(setPromptText(e.target.value))}
        onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => {
          if (chatLoading || answerLoading || uploadFileLoading) return;
          textareaHandler(e);
        }}
        value={promptText}
        className={clsx(`chat-textarea ${teamsTheme} ${fontsizeChange}`, {
          drag_and_drop: dragOver === true || cardDragOver === true,
          notFilePadding: selectedAssistantTypeInfo.assistantType !== "FILE",
          // not_gpt_engine: modelEngine !== "4.0v" && modelEngine !== "3.5v",
        })}
        placeholder={
          dragOver === true || cardDragOver === true
            ? "Drag & Drop"
            : selectedAssistantTypeInfo.assistantType === "FILE"
            ? "Ask questions about file analysis, data visualization, table analysis, etc."
            : selectedAssistantTypeInfo.assistantType === "WEB"
            ? "Feel free to request the most recent online content."
            : selectedAssistantTypeInfo.assistantType === "DALLE"
            ? "Describe the image you'd like to create"
            : ""
        }
      />

      {selectedAssistantTypeInfo.assistantType === "FILE" && (
        <>
          <div className="file_upload-button_wrap">
            <button
              disabled={isDisabled}
              onClick={clickUploadButton}
              className={clsx(`pure_button`, {
                file_disabled_button: isDisabled,
              })}
            >
              <FileUpload fill={COLOR[teamsTheme]} />
            </button>
            <input
              // id="chatFileUpload"
              name="uploadedFiles"
              ref={fileUploadRef}
              onChange={fileUploadHandler}
              multiple={true}
              type="file"
              tabIndex={-1}
              onClick={preventDuplication}
              accept={ALLOW_FILE_EXTENSION}
              className="file_upload_hidden"
            />
          </div>
        </>
      )}
      <div className="send_chat-button_wrap">
        <button
          onClick={() => addChatHandler(false)}
          disabled={isDisabled || promptText.length === 0}
          className={clsx(`pure_button send_chat-button ${teamsTheme}`)}
        >
          {isDisabled ? (
            <ClipLoader loading={isDisabled} size={20} color="#80b1e2" />
          ) : (
            <Send fill={COLOR.white} />
          )}
        </button>
      </div>
    </form>
  );
};

export default ChatInputForm;
