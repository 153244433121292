import axios from "axios";
import { BillingTitle, DomainType } from "./types";

export interface GetTokenParam {
  startDate: Date;
  endDate: Date;
  companyList?: string[];
  model: number; // 0: ALL, 1:Assistant, 2:MyGPTs, 3:DALLE
}

export interface GetBillingNameList {
  payload: {
    startDate: Date;
    endDate: Date;
    model: number; // TOKEN_MODEL_CODE 참고
    domainType: DomainType;
  };
  title: BillingTitle;
}

export interface GetTokenLogResult {
  totalAnswerTokens: number;
  totalQuestionTokens: number;
  totalTokens: number;
  result: TokenLogData[];
  totalCount: number;
}

export interface TokenLogData {
  total_answer_tokens: number;
  total_question_tokens: number;
  total_tokens: number;
  name: string;
  code: string;
  model: string;
}

export type GetTokenCompanyResult = TokenCompanyData[];

export interface TokenCompanyData {
  compCode: string;
  compName: string;
  hasLog: boolean;
}

export interface BillingNameListResult {
  code: string;
  name: string;
  hasLog: boolean;
}

export type GetBillingNameListResult = BillingNameListResult[];
export interface GetTokenLog {
  payload: {
    skip: number;
    limit: number;
    startDate?: Date;
    endDate?: Date;
    model: number; // TOKEN_MODEL_CODE 참고
    filterList: string[];
    filterType?: string;
    domainType: DomainType;
    searchText: string;
  };
  title: BillingTitle;
}
//! billing
export const getTokenLogList = async (param: GetTokenLog) => {
  const { payload, title } = param;
  const res = await axios.post(`/log/tokenlogSearch`, payload);
  let newResult = [];
  if (title === "company") {
    newResult = res.data.result.map((item: any) => ({
      total_answer_tokens: item.total_answer_tokens,
      total_question_tokens: item.total_question_tokens,
      total_tokens: item.total_tokens,
      model: item.model,
      code: item.compCode,
      name: item.compName,
    }));
  }
  if (title === "department") {
    newResult = res.data.result.map((item: any) => ({
      total_answer_tokens: item.total_answer_tokens,
      total_question_tokens: item.total_question_tokens,
      total_tokens: item.total_tokens,
      model: item.model,
      code: item.deptCode,
      name: item.deptName,
    }));
  }
  if (title === "user") {
    newResult = res.data.result.map((item: any) => ({
      total_answer_tokens: item.total_answer_tokens,
      total_question_tokens: item.total_question_tokens,
      total_tokens: item.total_tokens,
      model: item.model,
      code: item.userId,
      name: item.userName,
    }));
  }

  return { ...res.data, result: newResult };
};

// (old) getTokenCompany -> getBillingNameList
export const getTokenCompany = async (param: GetTokenParam) => {
  const res = await axios.post(`/log/compTokenList`, param);
  return res.data;
};

export const getBillingNameList = async (param: GetBillingNameList) => {
  const { title, payload } = param;
  const apiUrls = {
    company: "/log/compTokenList",
    department: "/log/deptTokenList",
    user: "/log/userTokenList",
  };
  const res = await axios.post(apiUrls[title], payload);

  if (title === "company") {
    return res.data.map((item: any) => ({
      code: item.compCode,
      name: item.compName,
      hasLog: item.hasLog,
    }));
  }
  if (title === "department") {
    return res.data.map((item: any) => ({
      code: item.deptCode,
      name: item.deptName,
      hasLog: item.hasLog,
    }));
  }
  if (title === "user") {
    return res.data.map((item: any) => ({
      code: item.userId,
      name: item.userName,
      hasLog: item.hasLog,
    }));
  }

  return res.data;
};
