import { AxiosError } from "axios";
import { createAsyncAction } from "typesafe-actions";
import {
  AccessCompanyData,
  AccessDeptData,
  AccessUserData,
  AddAccessCompanyData,
  AddAccessDeptData,
  AddAccessUserData,
  AddAdminUserData,
  AdminUserData,
  DeleteAccessaParam,
  DeleteAdminUserParam,
  GetAccessCompanyDataResult,
  GetAccessDataParam,
  GetAccessDeptDataResult,
  GetAccessUserDataResult,
  GetAdminUserDataParam,
  GetAdminUserDataResult,
} from "./api";

//! company access
export const GET_ACCESS_COMPANY_DATA = "admin/GET_ACCESS_COMPANY_DATA";
export const GET_ACCESS_COMPANY_DATA_SUCCESS =
  "admin/GET_ACCESS_COMPANY_DATA_SUCCESS";
export const GET_ACCESS_COMPANY_DATA_ERROR =
  "admin/GET_ACCESS_COMPANY_DATA_ERROR";

export const getAccessCompanyDataAsync = createAsyncAction(
  GET_ACCESS_COMPANY_DATA,
  GET_ACCESS_COMPANY_DATA_SUCCESS,
  GET_ACCESS_COMPANY_DATA_ERROR
)<GetAccessDataParam, GetAccessCompanyDataResult, AxiosError>();

export const ADD_ACCESS_COMPANY_DATA = "admin/ADD_ACCESS_COMPANY_DATA";
export const ADD_ACCESS_COMPANY_DATA_SUCCESS =
  "admin/ADD_ACCESS_COMPANY_DATA_SUCCESS";
export const ADD_ACCESS_COMPANY_DATA_ERROR =
  "admin/ADD_ACCESS_COMPANY_DATA_ERROR";

export const addAccessCompanyDataAsync = createAsyncAction(
  ADD_ACCESS_COMPANY_DATA,
  ADD_ACCESS_COMPANY_DATA_SUCCESS,
  ADD_ACCESS_COMPANY_DATA_ERROR
)<AddAccessCompanyData, AccessCompanyData, AxiosError>();

export const UPDATE_ACCESS_COMPANY_DATA = "admin/UPDATE_ACCESS_COMPANY_DATA";
export const UPDATE_ACCESS_COMPANY_DATA_SUCCESS =
  "admin/UPDATE_ACCESS_COMPANY_DATA_SUCCESS";
export const UPDATE_ACCESS_COMPANY_DATA_ERROR =
  "admin/UPDATE_ACCESS_COMPANY_DATA_ERROR";

export const updateAccessCompanyDataAsync = createAsyncAction(
  UPDATE_ACCESS_COMPANY_DATA,
  UPDATE_ACCESS_COMPANY_DATA_SUCCESS,
  UPDATE_ACCESS_COMPANY_DATA_ERROR
)<AccessCompanyData, AccessCompanyData, AxiosError>();

export const DELETE_ACCESS_COMPANY_DATA = "admin/DELETE_ACCESS_COMPANY_DATA";
export const DELETE_ACCESS_COMPANY_DATA_SUCCESS =
  "admin/DELETE_ACCESS_COMPANY_DATA_SUCCESS";
export const DELETE_ACCESS_COMPANY_DATA_ERROR =
  "admin/DELETE_ACCESS_COMPANY_DATA_ERROR";

export const deleteAccessCompanyDataAsync = createAsyncAction(
  DELETE_ACCESS_COMPANY_DATA,
  DELETE_ACCESS_COMPANY_DATA_SUCCESS,
  DELETE_ACCESS_COMPANY_DATA_ERROR
)<DeleteAccessaParam, DeleteAccessaParam, AxiosError>();

//! dept access
export const GET_ACCESS_DEPT_DATA = "admin/GET_ACCESS_DEPT_DATA";
export const GET_ACCESS_DEPT_DATA_SUCCESS =
  "admin/GET_ACCESS_DEPT_DATA_SUCCESS";
export const GET_ACCESS_DEPT_DATA_ERROR = "admin/GET_ACCESS_DEPT_DATA_ERROR";

export const getAccessDeptDataAsync = createAsyncAction(
  GET_ACCESS_DEPT_DATA,
  GET_ACCESS_DEPT_DATA_SUCCESS,
  GET_ACCESS_DEPT_DATA_ERROR
)<GetAccessDataParam, GetAccessDeptDataResult, AxiosError>();

export const ADD_ACCESS_DEPT_DATA = "admin/ADD_ACCESS_DEPT_DATA";
export const ADD_ACCESS_DEPT_DATA_SUCCESS =
  "admin/ADD_ACCESS_DEPT_DATA_SUCCESS";
export const ADD_ACCESS_DEPT_DATA_ERROR = "admin/ADD_ACCESS_DEPT_DATA_ERROR";

export const addAccessDeptDataAsync = createAsyncAction(
  ADD_ACCESS_DEPT_DATA,
  ADD_ACCESS_DEPT_DATA_SUCCESS,
  ADD_ACCESS_DEPT_DATA_ERROR
)<AddAccessDeptData, AccessDeptData, AxiosError>();

export const UPDATE_ACCESS_DEPT_DATA = "admin/UPDATE_ACCESS_DEPT_DATA";
export const UPDATE_ACCESS_DEPT_DATA_SUCCESS =
  "admin/UPDATE_ACCESS_DEPT_DATA_SUCCESS";
export const UPDATE_ACCESS_DEPT_DATA_ERROR =
  "admin/UPDATE_ACCESS_DEPT_DATA_ERROR";

export const updateAccessDeptDataAsync = createAsyncAction(
  UPDATE_ACCESS_DEPT_DATA,
  UPDATE_ACCESS_DEPT_DATA_SUCCESS,
  UPDATE_ACCESS_DEPT_DATA_ERROR
)<AccessDeptData, AccessDeptData, AxiosError>();

export const DELETE_ACCESS_DEPT_DATA = "admin/DELETE_ACCESS_DEPT_DATA";
export const DELETE_ACCESS_DEPT_DATA_SUCCESS =
  "admin/DELETE_ACCESS_DEPT_DATA_SUCCESS";
export const DELETE_ACCESS_DEPT_DATA_ERROR =
  "admin/DELETE_ACCESS_DEPT_DATA_ERROR";

export const deleteAccessDeptDataAsync = createAsyncAction(
  DELETE_ACCESS_DEPT_DATA,
  DELETE_ACCESS_DEPT_DATA_SUCCESS,
  DELETE_ACCESS_DEPT_DATA_ERROR
)<DeleteAccessaParam, DeleteAccessaParam, AxiosError>();

//! user access
export const GET_ACCESS_USER_DATA = "admin/GET_ACCESS_USER_DATA";
export const GET_ACCESS_USER_DATA_SUCCESS =
  "admin/GET_ACCESS_USER_DATA_SUCCESS";
export const GET_ACCESS_USER_DATA_ERROR = "admin/GET_ACCESS_USER_DATA_ERROR";

export const getAccessUserDataAsync = createAsyncAction(
  GET_ACCESS_USER_DATA,
  GET_ACCESS_USER_DATA_SUCCESS,
  GET_ACCESS_USER_DATA_ERROR
)<GetAccessDataParam, GetAccessUserDataResult, AxiosError>();

export const ADD_ACCESS_USER_DATA = "admin/ADD_ACCESS_USER_DATA";
export const ADD_ACCESS_USER_DATA_SUCCESS =
  "admin/ADD_ACCESS_USER_DATA_SUCCESS";
export const ADD_ACCESS_USER_DATA_ERROR = "admin/ADD_ACCESS_USER_DATA_ERROR";

export const addAccessUserDataAsync = createAsyncAction(
  ADD_ACCESS_USER_DATA,
  ADD_ACCESS_USER_DATA_SUCCESS,
  ADD_ACCESS_USER_DATA_ERROR
)<AddAccessUserData, AccessUserData, AxiosError>();

export const UPDATE_ACCESS_USER_DATA = "admin/UPDATE_ACCESS_USER_DATA";
export const UPDATE_ACCESS_USER_DATA_SUCCESS =
  "admin/UPDATE_ACCESS_USER_DATA_SUCCESS";
export const UPDATE_ACCESS_USER_DATA_ERROR =
  "admin/UPDATE_ACCESS_USER_DATA_ERROR";

export const updateAccessUserDataAsync = createAsyncAction(
  UPDATE_ACCESS_USER_DATA,
  UPDATE_ACCESS_USER_DATA_SUCCESS,
  UPDATE_ACCESS_USER_DATA_ERROR
)<AccessUserData, AccessUserData, AxiosError>();

export const DELETE_ACCESS_USER_DATA = "admin/DELETE_ACCESS_USER_DATA";
export const DELETE_ACCESS_USER_DATA_SUCCESS =
  "admin/DELETE_ACCESS_USER_DATA_SUCCESS";
export const DELETE_ACCESS_USER_DATA_ERROR =
  "admin/DELETE_ACCESS_USER_DATA_ERROR";

export const deleteAccessUserDataAsync = createAsyncAction(
  DELETE_ACCESS_USER_DATA,
  DELETE_ACCESS_USER_DATA_SUCCESS,
  DELETE_ACCESS_USER_DATA_ERROR
)<DeleteAccessaParam, DeleteAccessaParam, AxiosError>();

//! admin mgmt
export const GET_ADMIN_USER_DATA = "admin/GET_ADMIN_USER_DATA";
export const GET_ADMIN_USER_DATA_SUCCESS = "admin/GET_ADMIN_USER_DATA_SUCCESS";
export const GET_ADMIN_USER_DATA_ERROR = "admin/GET_ADMIN_USER_DATA_ERROR";

export const getAdminUserDataAsync = createAsyncAction(
  GET_ADMIN_USER_DATA,
  GET_ADMIN_USER_DATA_SUCCESS,
  GET_ADMIN_USER_DATA_ERROR
)<GetAdminUserDataParam, GetAdminUserDataResult, AxiosError>();

export const ADD_ADMIN_USER_DATA = "admin/ADD_ADMIN_USER_DATA";
export const ADD_ADMIN_USER_DATA_SUCCESS = "admin/ADD_ADMIN_USER_DATA_SUCCESS";
export const ADD_ADMIN_USER_DATA_ERROR = "admin/ADD_ADMIN_USER_DATA_ERROR";

export const addAdminUserDataAsync = createAsyncAction(
  ADD_ADMIN_USER_DATA,
  ADD_ADMIN_USER_DATA_SUCCESS,
  ADD_ADMIN_USER_DATA_ERROR
)<AddAdminUserData, AdminUserData, AxiosError>();

export const UPDATE_ADMIN_USER_DATA = "admin/UPDATE_ADMIN_USER_DATA";
export const UPDATE_ADMIN_USER_DATA_SUCCESS =
  "admin/UPDATE_ADMIN_USER_DATA_SUCCESS";
export const UPDATE_ADMIN_USER_DATA_ERROR =
  "admin/UPDATE_ADMIN_USER_DATA_ERROR";

export const updateAdminUserDataAsync = createAsyncAction(
  UPDATE_ADMIN_USER_DATA,
  UPDATE_ADMIN_USER_DATA_SUCCESS,
  UPDATE_ADMIN_USER_DATA_ERROR
)<AdminUserData, AdminUserData, AxiosError>();

export const DELETE_ADMIN_USER_DATA = "admin/DELETE_ADMIN_USER_DATA";
export const DELETE_ADMIN_USER_DATA_SUCCESS =
  "admin/DELETE_ADMIN_USER_DATA_SUCCESS";
export const DELETE_ADMIN_USER_DATA_ERROR =
  "admin/DELETE_ADMIN_USER_DATA_ERROR";

export const deleteAdminUserDataAsync = createAsyncAction(
  DELETE_ADMIN_USER_DATA,
  DELETE_ADMIN_USER_DATA_SUCCESS,
  DELETE_ADMIN_USER_DATA_ERROR
)<DeleteAdminUserParam, DeleteAdminUserParam, AxiosError>();
