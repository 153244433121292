import "./SearchModelAndGPTsUL.scss";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";
import { ReactComponent as Dalle } from "../../../assets/svg/logo/Dalle.svg";
import { ReactComponent as GPTsDefault } from "../../../assets/svg/logo/GPTsDefault.svg";
import { ReactComponent as GptModelGray } from "../../../assets/svg/logo/GptModelGray.svg";
import { useAppDispatch } from "../../../features";
import {
  AssistantType,
  IFileInfoItem,
  ModelEngine,
  clearChat,
  selectChatLoading,
  selectModelEngine,
  selectSelectedGptOption,
  setAssistantTypeInfo,
  setModelEngine,
} from "../../../features/chat";
import clsx from "clsx";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DEFAULT_MODEL_ENGINE } from "../../../features/chat/reducer";
import {
  getMyGPTSDetailAsync,
  getMyGPTsSideBarAsync,
  selectMyGptsSideBarList,
} from "../../../features/myGpts";
import { selectMyGptsSideBarListLoading } from "../../../features/ui_loading";
import { ClipLoader } from "react-spinners";
import { GPT4_CAPABILITIES } from "../../../constants/chat";
import { ReactComponent as MyGPTs } from "../../../assets/svg/MyGPTs.svg";
import useResponsiveView from "../../../libs/hooks/useResonsiveView";
import { openAlert } from "../../../features/alert";

type GPTModelProps = {
  value: ModelEngine;
  name: "GPT" | "DALL·E";
  description: string;
  subValue: string;
  logo: JSX.Element;
};

type MyGPTProps = {
  value: ModelEngine;
  name: string;
  logo: string;
  description: string;
  capabilities: AssistantType[];
  myGPTsId: string;
  knowledge: IFileInfoItem[];
};

const defaultGPTModelList: GPTModelProps[] = [
  {
    logo: (
      <>
        <GptModelGray className="model-svg" />
      </>
    ),
    name: "GPT",
    description: "generate natural text and understand language.",
    value: "4.0v",
    subValue: "3.5v",
  },
  {
    logo: (
      <>
        <Dalle className="model-svg" />
      </>
    ),
    name: "DALL·E",
    description: "generates images from textual descriptions.",
    value: "dalle",
    subValue: "",
  },
];

const SearchGPTModelLI: React.FC<GPTModelProps> = ({
  logo,
  name,
  description,
  value,
  subValue,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const teamsTheme = useSelector(selectTeamsTheme);
  const modelEngine = useSelector(selectModelEngine);
  const selectedGptOption = useSelector(selectSelectedGptOption);
  const chatLoading = useSelector(selectChatLoading);
  const location = useLocation();
  const { isDesktop } = useResponsiveView();

  const clickModelEngine = () => {
    if (chatLoading) {
      dispatch(
        openAlert({
          alertMsg: "You cannot select this menu while it is loading.",
        })
      );
      return;
    }
    dispatch(clearChat({ clear: true }));

    if (name === "GPT") {
      dispatch(setModelEngine(selectedGptOption.chatModel));
    } else {
      // dalle 일때
      dispatch(setModelEngine(value));
    }

    dispatch(
      setAssistantTypeInfo({
        assistantType: "DEFAULT",
        capabilities: GPT4_CAPABILITIES,
        myGPTsId: null,
        profileImage: null,
      })
    );

    navigate("/private/chat");
  };

  return (
    <li
      className={clsx(`search_model_GPTs-li ${teamsTheme}`, {
        "selected_model_GPTs-li":
          (location.pathname.includes("/private/chat") &&
            modelEngine === value) ||
          modelEngine === subValue,
      })}
      onClick={clickModelEngine}
    >
      <div
        className={clsx("model_svg_name_wrap", {
          "desktop_title_wrap": isDesktop,
        })}
      >
        {logo}
        <h3 title={name} className="model_name">
          {name}
        </h3>
      </div>
      {isDesktop && <div className="model_description">{description}</div>}
    </li>
  );
};

const SearchMyGPTsLI: React.FC<MyGPTProps> = ({
  logo,
  name,
  description,
  value,
  myGPTsId,
  capabilities,
  knowledge,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const teamsTheme = useSelector(selectTeamsTheme);
  const chatLoading = useSelector(selectChatLoading);
  const { isDesktop } = useResponsiveView();

  const clickSidebarMyGPT = () => {
    if (chatLoading) {
      dispatch(
        openAlert({
          alertMsg: "You cannot select this menu while it is loading.",
        })
      );
      return;
    }
    dispatch(
      setAssistantTypeInfo({
        assistantType: knowledge.length ? "FILE" : "DEFAULT",
        capabilities: ["DEFAULT", ...capabilities],
        myGPTsId,
        profileImage: logo,
      })
    );

    dispatch(getMyGPTSDetailAsync.request({ myGPTsId }));
    dispatch(clearChat({ clear: true }));

    // clearChat 후에 setModelEngine를 해야함
    dispatch(setModelEngine(value));
    navigate("/private/chat");
  };

  return (
    <li
      className={clsx(`search_model_GPTs-li ${teamsTheme}`, {})}
      onClick={clickSidebarMyGPT}
    >
      <div
        className={clsx("model_svg_name_wrap", {
          "only_title_wrap": !description,
          "desktop_title_wrap": description && isDesktop,
        })}
      >
        {logo ? (
          <img className="my-gpt-img" src={logo} alt="My AI Chat" />
        ) : (
          <GPTsDefault className="model-svg" />
        )}

        <h3 title={name} className="model_name">
          {name}
        </h3>
      </div>
      {isDesktop && description && (
        <div className="model_description">{description}</div>
      )}
    </li>
  );
};

const SearchModelAndGPTsUL = () => {
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const location = useLocation();
  const mySideBarGPTsList = useSelector(selectMyGptsSideBarList);
  const myGptListLoading = useSelector(selectMyGptsSideBarListLoading);
  const navigate = useNavigate();
  const chatLoading = useSelector(selectChatLoading);

  useEffect(() => {
    if (!location.pathname.includes("/private/chat")) {
      dispatch(setModelEngine(DEFAULT_MODEL_ENGINE));
      dispatch(
        setAssistantTypeInfo({
          assistantType: "DEFAULT",
          capabilities: GPT4_CAPABILITIES,
          myGPTsId: null,
          profileImage: null,
        })
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(
      getMyGPTsSideBarAsync.request({
        pageIndex: 1,
        pageSize: 99,
        search: "",
      })
    );
  }, []);

  const navigateMyGPTs = () => {
    if (chatLoading) {
      dispatch(
        openAlert({
          alertMsg: "You cannot select this menu while it is loading.",
        })
      );
      return;
    }
    navigate("/private/my_chat_mgmt/list");
  };

  return (
    <>
      {myGptListLoading ? (
        <div className="sidebar_myGPTs_loading_warp">
          <ClipLoader loading={myGptListLoading} size={20} color="#80b1e2" />
        </div>
      ) : (
        <ul className={`gptModelList scroll scroll_${teamsTheme}`}>
          {defaultGPTModelList.map((elem, idx) => (
            <SearchGPTModelLI
              key={idx}
              logo={elem.logo}
              name={elem.name}
              description={elem.description}
              value={elem.value}
              subValue={elem?.subValue}
            />
          ))}
          <div className="side_bar_divider" />
          <button
            onClick={navigateMyGPTs}
            className={`my_GPTs-button ${teamsTheme}`}
          >
            <MyGPTs className="my_GPTs-svg" />
            My AI Chats
          </button>
          <div className={`myGPTs_list_container scroll scroll_${teamsTheme}`}>
            {mySideBarGPTsList.map((elem) => (
              <SearchMyGPTsLI
                key={elem.id}
                logo={elem.profileImage}
                name={elem.name}
                description={elem.description}
                value={"myGPTs"}
                myGPTsId={elem.id}
                capabilities={elem.capabilities}
                knowledge={elem.knowledge}
              />
            ))}
          </div>
        </ul>
      )}
    </>
  );
};
// c5c6d9
export default SearchModelAndGPTsUL;
