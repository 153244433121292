import moment from "moment";
import { useSelector } from "react-redux";
import { localDate } from "../../../../libs/transformDate";
import { selectTeamsTheme } from "../../../../features/auth";
import FilterPeriod from "../../../components/userInterface/filters/FilterPeriod";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../features";
import { selectBatchMetadataListLoading } from "../../../../features/ui_loading";
import MainColorBtn from "../../../components/userInterface/buttons/MainColorBtn";
import FilterInputTextType from "../../../components/userInterface/filters/FilterInputTextType";
import {
  makeSelectBatchMetadataSearchInputs,
  OuterServiceType,
  setBatchMetadataSearchInputs,
} from "../../../../features/batchMetadata";
import useBatchMetadataList from "./useBatchMetadataList";

export const BatchMetadataSearch = ({ title }: { title: string }) => {
  const { service } = useParams();
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const batchMetadataListLoading = useSelector(selectBatchMetadataListLoading);
  const searchInputs = useSelector(
    makeSelectBatchMetadataSearchInputs(service as OuterServiceType)
  );
  const { getBatchMetadataList } = useBatchMetadataList();

  return (
    <div className={`mgmt_page_filter mgmt_page_filter_${teamsTheme}`}>
      <FilterPeriod
        label="UpdateDate Period"
        startDate={localDate(searchInputs.startDate)}
        endDate={localDate(searchInputs.endDate)}
        max={moment().format("YYYY-MM-DD")}
        startDateChangeHandler={(e) =>
          dispatch(
            setBatchMetadataSearchInputs({
              serviceType: service as OuterServiceType,
              key: "startDate",
              value: new Date(e.target.value),
            })
          )
        }
        endDateChangeHandler={(e) =>
          dispatch(
            setBatchMetadataSearchInputs({
              serviceType: service as OuterServiceType,
              key: "endDate",
              value: new Date(e.target.value),
            })
          )
        }
      />

      <FilterInputTextType
        title="Company"
        value={searchInputs.compName}
        onChangeHandler={(e) =>
          dispatch(
            setBatchMetadataSearchInputs({
              serviceType: service as OuterServiceType,
              key: "compName",
              value: e.target.value,
            })
          )
        }
        deleteInputHandler={() =>
          dispatch(
            setBatchMetadataSearchInputs({
              serviceType: service as OuterServiceType,
              key: "compName",
              value: "",
            })
          )
        }
      />
      <FilterInputTextType
        title="Company Code"
        value={searchInputs.compCode}
        onChangeHandler={(e) =>
          dispatch(
            setBatchMetadataSearchInputs({
              serviceType: service as OuterServiceType,
              key: "compCode",
              value: e.target.value,
            })
          )
        }
        deleteInputHandler={() =>
          dispatch(
            setBatchMetadataSearchInputs({
              serviceType: service as OuterServiceType,
              key: "compCode",
              value: "",
            })
          )
        }
      />
      {"siteId" in searchInputs && (
        <FilterInputTextType
          title="Site Id"
          value={searchInputs.siteId}
          onChangeHandler={(e) =>
            dispatch(
              setBatchMetadataSearchInputs({
                serviceType: service as "employee",
                key: "siteId",
                value: e.target.value,
              })
            )
          }
          deleteInputHandler={() =>
            dispatch(
              setBatchMetadataSearchInputs({
                serviceType: service as "employee",
                key: "siteId",
                value: "",
              })
            )
          }
        />
      )}
      {"downloadUrl" in searchInputs && (
        <FilterInputTextType
          title="Download Url"
          value={searchInputs.downloadUrl}
          onChangeHandler={(e) =>
            dispatch(
              setBatchMetadataSearchInputs({
                serviceType: service as "eapproval",
                key: "downloadUrl",
                value: e.target.value,
              })
            )
          }
          deleteInputHandler={() =>
            dispatch(
              setBatchMetadataSearchInputs({
                serviceType: service as "eapproval",
                key: "downloadUrl",
                value: "",
              })
            )
          }
        />
      )}

      <div className="filter_tool_btns">
        <MainColorBtn
          btnName="Search"
          onClickHandler={() => {
            dispatch(
              setBatchMetadataSearchInputs({
                serviceType: service as OuterServiceType,
                key: "skip",
                value: 1,
              })
            );
            getBatchMetadataList({
              setNameTitle: null,
              currentPage: 1,
            });
          }}
          loading={batchMetadataListLoading}
        />
      </div>
    </div>
  );
};
