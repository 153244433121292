import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import { transformDate } from "../../../../libs/transformDate";
import TableBtn from "../../userInterface/buttons/TableBtn";
import { useAppDispatch } from "../../../../features";
import { ClipLoader } from "react-spinners";
import { selectBatchMetadataListLoading } from "../../../../features/ui_loading";
import {
  IBatchMetadataModal,
  OuterServiceType,
} from "../../../../features/batchMetadata/types";

import TableSecondaryBtn from "../../userInterface/buttons/TableSecondaryBtn";
import { batchTableData } from "./batchTableData";
import {
  EapprovalBatchMetadataItem,
  EmployeeBatchMetadataItem,
  makeSelectBatchMetadataList,
  SetBatchMetadataModifyItem,
} from "../../../../features/batchMetadata";
import { PayloadActionCreator } from "typesafe-actions";

type Props = {
  serviceType: OuterServiceType;
  setIsShowModal: React.Dispatch<React.SetStateAction<IBatchMetadataModal>>;
  setSelectedData: PayloadActionCreator<
    "admin/SET_BATCH_METADATA_MODIFY_ITEM",
    SetBatchMetadataModifyItem
  >;
};

const EAPPROVAL_BATCH_METADATA_TABLE_COLUMNS = [
  "Company",
  "Company Code",
  "Download Url",
  "CreateDate",
  "UpdateDate",
  "TriggerDate",
  "Batch",
  "",
];

const RegisteredMetadataTable: React.FC<Props> = ({
  serviceType,
  setIsShowModal,
  setSelectedData,
}) => {
  const dispatch = useAppDispatch();

  const teamsTheme = useSelector(selectTeamsTheme);

  const batchMetadataListLoading = useSelector(selectBatchMetadataListLoading);

  const batchMetadataList = useSelector(
    makeSelectBatchMetadataList(serviceType)
  );

  if (serviceType === "employee") {
    const dataList = batchMetadataList as EmployeeBatchMetadataItem[];
    return (
      <table className={`page_table page_table-${teamsTheme}`} width="500">
        <colgroup>
          {batchTableData[serviceType].columns.map((elem, idx) => (
            <col width={elem.width} key={idx} />
          ))}
        </colgroup>
        <thead>
          {/* <thead className={`thead_${teamsTheme}`}> */}
          <tr>
            {batchTableData[serviceType].columns.map((elem, idx) => (
              <th key={`${elem.key}_${idx}`} title={elem.title}>
                {elem.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* <tbody className={`tbody_${teamsTheme}`}> */}
          {dataList.length > 0 ? (
            dataList.slice(0, 10).map((item) => (
              <tr key={item._id}>
                <td className="line_change" title="company name">
                  {item.companyInfo
                    .map((company) => company.compName)
                    .join(", ")}
                </td>
                <td className="line_change" title="company code">
                  {item.companyInfo
                    .map((company) => company.compCode)
                    .join(", ")}
                </td>

                <td className="line_change" title={item.siteId}>
                  {item.siteId}
                </td>
                <td className="line_change" title={item.siteName}>
                  {item.siteName}
                </td>
                <td
                  className="ellipsis"
                  title={item.createDate ?? "createDate"}
                >
                  {item.createDate ? transformDate(item.createDate) : "-"}
                </td>
                <td
                  className="ellipsis"
                  title={item.updateDate ?? "updateDate"}
                >
                  {item.updateDate ? transformDate(item.updateDate) : "-"}
                </td>
                <td
                  className="ellipsis"
                  title={item.triggerDate ?? "triggerDate"}
                >
                  {item.triggerDate ? transformDate(item.triggerDate) : "-"}
                </td>
                <td className="line_change" title="Batch">
                  <TableSecondaryBtn
                    onClickHandler={() => {
                      setIsShowModal((prev) => ({ ...prev, trigger: true }));
                      dispatch(setSelectedData({ serviceType, item }));
                    }}
                    btnName="Trigger"
                  ></TableSecondaryBtn>
                </td>
                <td className="td_btn_wrap">
                  <TableBtn
                    onClickHandler={() => {
                      setIsShowModal((prev) => ({ ...prev, modify: true }));
                      dispatch(setSelectedData({ serviceType, item }));
                    }}
                    btnName="modify"
                  />
                  <TableBtn
                    onClickHandler={() => {
                      setIsShowModal((prev) => ({ ...prev, delete: true }));
                      dispatch(setSelectedData({ serviceType, item }));
                    }}
                    btnName="delete"
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className="no_data">
              <td colSpan={9} style={{ textAlign: "center" }}>
                {batchMetadataListLoading ? (
                  <ClipLoader
                    loading={batchMetadataListLoading}
                    size={20}
                    color="#80b1e2"
                  />
                ) : (
                  "No Data"
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
  if (serviceType === "eapproval") {
    const dataList = batchMetadataList as EapprovalBatchMetadataItem[];
    return (
      <table className={`page_table page_table-${teamsTheme}`} width="500">
        <colgroup>
          <col width="16%" />
          <col width="16%" />
          <col width="15%" />
          <col width="15%" />
          <col width="10%" />

          <col width="10%" />
          <col width="80px" />
          <col width="130px" />
        </colgroup>
        <thead>
          {/* <thead className={`thead_${teamsTheme}`}> */}
          <tr>
            {EAPPROVAL_BATCH_METADATA_TABLE_COLUMNS.map((elem, idx) => (
              <th key={idx} title={elem}>
                {elem}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* <tbody className={`tbody_${teamsTheme}`}> */}
          {dataList.length > 0 ? (
            dataList.slice(0, 10).map((item) => (
              <tr>
                <td className="line_change" title="company name">
                  {item.companyInfo
                    .map((company) => company.compName)
                    .join(", ")}
                </td>
                <td className="line_change" title="company code">
                  {item.companyInfo
                    .map((company) => company.compCode)
                    .join(", ")}
                </td>

                <td className="line_change" title={item.downloadUrl}>
                  {item.downloadUrl}
                </td>

                <td
                  className="ellipsis"
                  title={item.createDate ?? "createDate"}
                >
                  {item.createDate ? transformDate(item.createDate) : "-"}
                </td>
                <td
                  className="ellipsis"
                  title={item.updateDate ?? "updateDate"}
                >
                  {item.updateDate ? transformDate(item.updateDate) : "-"}
                </td>
                <td
                  className="ellipsis"
                  title={item.triggerDate ?? "triggerDate"}
                >
                  {item.triggerDate ? transformDate(item.triggerDate) : "-"}
                </td>
                <td className="line_change" title="Batch">
                  <TableSecondaryBtn
                    onClickHandler={() => {
                      setIsShowModal((prev) => ({ ...prev, trigger: true }));
                      dispatch(setSelectedData({ serviceType, item }));
                    }}
                    btnName="Trigger"
                  ></TableSecondaryBtn>
                </td>
                <td className="td_btn_wrap">
                  <TableBtn
                    onClickHandler={() => {
                      setIsShowModal((prev) => ({ ...prev, modify: true }));
                      dispatch(setSelectedData({ serviceType, item }));
                    }}
                    btnName="modify"
                  />
                  <TableBtn
                    onClickHandler={() => {
                      setIsShowModal((prev) => ({ ...prev, delete: true }));
                      dispatch(setSelectedData({ serviceType, item }));
                    }}
                    btnName="delete"
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className="no_data">
              <td colSpan={8} style={{ textAlign: "center" }}>
                {batchMetadataListLoading ? (
                  <ClipLoader
                    loading={batchMetadataListLoading}
                    size={20}
                    color="#80b1e2"
                  />
                ) : (
                  "No Data"
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
  return <></>;
};

export default RegisteredMetadataTable;
