import { RootState } from "../index";

export const selectMyGptsList = (state: RootState) => state.myGpts.myGptsList;

export const selectMyGptsTotalCnt = (state: RootState) =>
  state.myGpts.myGptsTotalCount;

export const selectMyAIChatForm = (state: RootState) =>
  state.myGpts.myAIChatForm;

export const selectSelectedDeleteId = (state: RootState) =>
  state.myGpts.selectedDeleteId;

export const selectSelectedPreviewMyGptId = (state: RootState) =>
  state.myGpts.selectedPreviewMyGptId;

export const selectMyGptsSideBarList = (state: RootState) =>
  state.myGpts.myGptsSideBarList;
