import "./DalleStylePrompt.scss";
import { useEffect, useState } from "react";
import Select from "react-select";
import {
  ASPECT_RATIO_OPTIONS,
  DALLE_STYLES,
} from "../../../../constants/dalleStyle";
import { ReactComponent as ShuffleArrow } from "../../../../assets/svg/ShuffleArrow.svg";
import { useAppDispatch } from "../../../../features";
import { selectPromptText, setPromptText } from "../../../../features/chat";
import { useSelector } from "react-redux";
import { IWindowSize } from "../../../../libs/hooks/useWindowResize";
import { selectTeamsTheme } from "../../../../features/auth";

type DalleStyleITem = {
  content: string;
};

type DalleStylePromptITem = {
  content: string;
  onClickHandler: (text: string) => void;
};

const DALLE_STYLE_COUNT = 4;

export const DalleStylePromptItem: React.FC<DalleStylePromptITem> = ({
  content,
  onClickHandler,
}) => (
  <li onClick={() => onClickHandler(content)} className="dalle_style-li">
    <span className="sub_title">{content}</span>
  </li>
);

type Props = {
  windowSize: IWindowSize;
};

export const DalleStylePromptList: React.FC<Props> = ({ windowSize }) => {
  const dispatch = useAppDispatch();
  const promptText = useSelector(selectPromptText);
  const teamsTheme = useSelector(selectTeamsTheme);
  const [dalleNum, setDalleNum] = useState(DALLE_STYLE_COUNT);
  const [dalleRandomStyle, setDalleRandomStyle] = useState<DalleStyleITem[]>(
    () => getDalleStyles(dalleNum)
  );

  useEffect(() => {
    if (windowSize.width) {
      if (windowSize.width < 479) {
        setDalleNum(2);
        return;
      }
      if (windowSize.width < 768) {
        setDalleNum(3);
        return;
      }
      setDalleNum(DALLE_STYLE_COUNT);
    }
  }, [windowSize]);

  useEffect(() => {
    setDalleRandomStyle(getDalleStyles(dalleNum));
  }, [dalleNum]);

  const changeStyles = () => {
    setDalleRandomStyle(getDalleStyles(dalleNum));
  };

  const addPrompt = (text: string) => {
    if (promptText.length === 0) {
      dispatch(setPromptText(text));
    } else {
      dispatch(setPromptText(`${promptText}, ${text}`));
    }
  };

  return (
    <div className={`dalle_style_prompt_container ${teamsTheme}`}>
      <ul className="dalle_style_prompt-ul">
        {dalleRandomStyle.map(({ content }, idx) => (
          <DalleStylePromptItem
            onClickHandler={addPrompt}
            content={content}
            key={`${content}_${idx}`}
          />
        ))}
        <button className="dalle_shuffle-button" onClick={changeStyles}>
          <ShuffleArrow />
        </button>
      </ul>
      <Select
        className="dalle-select_container"
        classNamePrefix="dalle-select"
        options={ASPECT_RATIO_OPTIONS}
        // value={selectedGptOption}
        isSearchable={false}
        onChange={(newValue) => addPrompt(newValue ? newValue.value : "")}
        placeholder="Aspect Ratio"
        menuPlacement="top"
      />
    </div>
  );
};

const getDalleStyles = (cnt: number) => {
  const randomIdx = new Set<number>();

  while (randomIdx.size < cnt) {
    //  0 <= random <= style.length
    randomIdx.add(Math.floor(Math.random() * DALLE_STYLES.length + 1));
  }

  const randomArr: number[] = Array.from(randomIdx);
  const defaultStyle = {
    content: "Chalk art",
  };
  const randomStyles: DalleStyleITem[] = randomArr.map(
    (elem: number) => DALLE_STYLES.at(elem) ?? defaultStyle
  );

  return randomStyles;
};
