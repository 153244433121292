import React from "react";
import { useSelector } from "react-redux";
import {
  selectMyGptsList,
  selectSelectedDeleteId,
} from "../../../features/myGpts";
import { selectTeamsTheme } from "../../../features/auth";
import { selectGetMyGptsLoading } from "../../../features/ui_loading";
import { useNavigate } from "react-router-dom";
import MobileGridBox from "../mobile/admin/MobileGridBox";
import { transformDate } from "../../../libs/transformDate";
import { innerRoutes } from "../../../routes/routes";
import { MyAIChatTableProps } from "./CreatedMyAIChatTable";

const MobileCreatedMyAIChatTable: React.FC<MyAIChatTableProps> = ({
  setSelectedDeleteId,
  setDeleteModal,
}) => {
  const myGptsList = useSelector(selectMyGptsList);
  const teamsTheme = useSelector(selectTeamsTheme);
  const getMyGptLoading = useSelector(selectGetMyGptsLoading);
  const selectedDeleteId = useSelector(selectSelectedDeleteId);
  const navigate = useNavigate();

  return (
    <div className="mobile_grid_box_wrap">
      {myGptsList.map((item) => (
        <MobileGridBox
          key={item.id}
          data={{
            Name: item.name,

            Description: item.description,

            CreateDate: item.createdDate
              ? transformDate(item.createdDate)
              : "-",
            UpdateDate: item.updatedDate
              ? transformDate(item.updatedDate)
              : "-",
          }}
          modifyHandler={() => {
            navigate(`${innerRoutes.EDIT_MY_CHAT.path}/${item.id}`);
          }}
          deleteHandelr={() => {
            setDeleteModal(true);
            setSelectedDeleteId(item.id);
          }}
        />
      ))}
    </div>
  );
};

export default MobileCreatedMyAIChatTable;
