import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import "./inputs.scss";
import { memo } from "react";

type Props = {
  options: { name: string; value: string }[];
  value: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
};

const SelectType: React.FC<Props> = ({
  options,
  value,
  onChangeHandler,
  disabled,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <select
      className={`select input_theme_${teamsTheme}`}
      onChange={onChangeHandler}
      value={value}
      disabled={disabled}
    >
      {options.map((option, idx) => (
        <option key={`${option.name}_${idx}`} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export default memo(SelectType);
