import createAsyncSaga from "../../libs/createAsyncSaga";
import { all, call, put, takeLeading } from "typed-redux-saga";
import { AxiosError } from "axios";
import {
  ADD_MODEL,
  DELETE_MODEL,
  GET_MODEL,
  GET_MODELS_INFO,
  GET_MODELS_TAG,
  UPDATE_MODEL,
  addModelAsync,
  deleteModelAsync,
  getModelAsync,
  getModelsInfoAsync,
  getModelsTagsAsync,
  updateModelAsync,
} from "./actions";
import {
  addModel,
  deleteModel,
  getModel,
  getModelsInfo,
  getModelsTags,
  updateModel,
} from "./api";
import addPaginationCreateAsyncSaga from "../../libs/addPaginationCreateAsyncSaga";
import deletePaginationCreateAsyncSaga from "../../libs/deletePaginationCreateAsyncSaga";

//! model mgmt
const getModelSaga = createAsyncSaga(getModelAsync, getModel);

const addModelSaga = addPaginationCreateAsyncSaga(
  addModelAsync,
  addModel,
  getModelAsync,
  getModel
);

const updateModelSaga = createAsyncSaga(updateModelAsync, updateModel);
const getModelsInfoSaga = createAsyncSaga(getModelsInfoAsync, getModelsInfo);
const getModelsTagsSaga = createAsyncSaga(getModelsTagsAsync, getModelsTags);

const deleteModelSaga = deletePaginationCreateAsyncSaga(
  deleteModelAsync,
  deleteModel,
  getModelAsync,
  getModel
);

export function* modelSaga() {
  //! model mgmt
  yield* all([yield* takeLeading(GET_MODEL, getModelSaga)]);
  yield* all([yield* takeLeading(ADD_MODEL, addModelSaga)]);
  yield* all([yield* takeLeading(UPDATE_MODEL, updateModelSaga)]);
  yield* all([yield* takeLeading(DELETE_MODEL, deleteModelSaga)]);
  yield* all([yield* takeLeading(GET_MODELS_INFO, getModelsInfoSaga)]);
  yield* all([yield* takeLeading(GET_MODELS_TAG, getModelsTagsSaga)]);
}
