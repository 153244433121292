import { all, takeLatest, takeLeading } from "typed-redux-saga";
import createAsyncSaga from "../../libs/createAsyncSaga";
import {
  GET_BILLING_NAME_LIST,
  GET_TOKEN_COMPANY,
  GET_TOKEN_LOG,
  getBillingNameListAsync,
  getTokenCompanyAsync,
  getTokenLogAsync,
} from "./actions";
import { getBillingNameList, getTokenCompany, getTokenLogList } from "./api";

//! billing mgmt
const getTokenLogSaga = createAsyncSaga(getTokenLogAsync, getTokenLogList);

const getTokenCompanySaga = createAsyncSaga(
  getTokenCompanyAsync,
  getTokenCompany
);

const getBillingNameListSaga = createAsyncSaga(
  getBillingNameListAsync,
  getBillingNameList
);

export function* billingSaga() {
  yield* all([
    yield* takeLatest(GET_TOKEN_LOG, getTokenLogSaga),
    yield* takeLeading(GET_TOKEN_COMPANY, getTokenCompanySaga),
    yield* takeLeading(GET_BILLING_NAME_LIST, getBillingNameListSaga),
  ]);
}
