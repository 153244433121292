import { useEffect, useState } from "react";
import Pagenator from "../../components/common/Pagenator";
import "./admin.scss";
import AccessMgmtUserModal from "../../components/admin/AccessMgmtUserModal";
import DeleteModal from "../../components/common/DeleteModal";
import { useAppDispatch } from "../../../features";
import {
  AccessUserData,
  deleteAccessUserDataAsync,
  getAccessUserDataAsync,
  selectAccessPageIndex,
  selectAccessTotalCnt,
  selectAccessUserData,
} from "../../../features/admin";
import { useSelector } from "react-redux";
import { BsCheckLg } from "react-icons/bs";
import { transformDate } from "../../../libs/transformDate";
import { selectTeamsTheme } from "../../../features/auth";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import FilterInputTextType from "../../components/userInterface/filters/FilterInputTextType";
import TableBtn from "../../components/userInterface/buttons/TableBtn";
import MobileGridBox from "../../components/mobile/admin/MobileGridBox";

const AccessMgmtUserPage = () => {
  const dispatch = useAppDispatch();

  const userData = useSelector(selectAccessUserData);
  const totalCount = useSelector(selectAccessTotalCnt);
  const accessPageIndex = useSelector(selectAccessPageIndex);
  const teamsTheme = useSelector(selectTeamsTheme);

  const [pageIndex, setPageIndex] = useState(1);
  const [modifyModal, setModifyModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState<AccessUserData | null>(null);
  const [searchText, setSearchText] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [pageSize, setPageSize] = useState(window.innerWidth <= 1024 ? 6 : 10);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    getAccessUserData();

    if (window.innerWidth <= 1024) {
      setPageSize(6);
    } else {
      setPageSize(10);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setPageIndex(accessPageIndex);
  }, [accessPageIndex]);

  useEffect(() => {
    if (windowWidth <= 1024) {
      setPageSize(6);
    } else {
      setPageSize(10);
    }
  }, [windowWidth]);

  useEffect(() => {
    getAccessUserData();
  }, [pageSize]);

  const getAccessUserData = (idx?: number, deleteSearch?: boolean) => {
    dispatch(
      getAccessUserDataAsync.request({
        pageIndex: idx ? idx : pageIndex,
        pageSize: pageSize,
        searchText: deleteSearch ? "" : searchText,
      })
    );

    if (deleteSearch) {
      setSearchText("");
    }
  };

  const setCurrentPage = (currentPage: number) => {
    setPageIndex(currentPage);
    getAccessUserData(currentPage);
  };

  const deleteHandler = () => {
    if (selectedData && selectedData._id) {
      dispatch(
        deleteAccessUserDataAsync.request({
          _id: selectedData._id,
          length: userData.length,
          pageIndex: pageIndex,
          searchText: searchText,
          pageSize,
        })
      );

      setDeleteModal(false);
    }
  };

  return (
    <>
      {modifyModal && selectedData && (
        <AccessMgmtUserModal
          onClose={() => setModifyModal(false)}
          modifyData={selectedData}
          title="Modify"
        />
      )}
      {deleteModal && selectedData && (
        <DeleteModal
          deleteHandler={deleteHandler}
          onClose={() => setDeleteModal(false)}
        />
      )}
      <div className={`mgmt_page_filter mgmt_page_filter_${teamsTheme}`}>
        <FilterInputTextType
          title="Search"
          value={searchText}
          onChangeHandler={(e) => setSearchText(e.target.value)}
          deleteInputHandler={() => setSearchText("")}
        />

        <div className="filter_tool_btns">
          <MainColorBtn
            onClickHandler={() => getAccessUserData(1)}
            btnName="Search"
          />
        </div>
      </div>

      <table className={`page_table page_table-${teamsTheme}`} width="500">
        {/* <table className={`page_table page_table_${teamsTheme}`}> */}
        <colgroup>
          <col width="20%" />
          <col width="20%" />
          <col width="15%" />
          <col width="12%" />
          <col width="10%" />
          <col width="12%" />
          <col width="12%" />
          <col width="10%" />
          <col width="10%" />
          <col width="140px" />
        </colgroup>
        <thead>
          <tr>
            <th title="Name">Name</th>
            <th title="Code">Code</th>
            <th title="Description">Description</th>
            <th title="GPT Model">GPT Model</th>
            <th title="CreateBy">CreateBy</th>
            <th title="CreateDate">CreateDate</th>
            <th title="UpdateDate">UpdateDate</th>
            <th title="Activated">Activated</th>
            <th title="DataAnalysis">Data Analysis</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {userData.length > 0 ? (
            userData.slice(0, pageSize).map((item) => (
              <tr>
                <td className="ellipsis" title={item.userName}>
                  {item.userName}
                </td>
                <td className="ellipsis" title={item.userId}>
                  {item.userId}
                </td>
                <td className="ellipsis" title={item.userDescription}>
                  {item.userDescription ? item.userDescription : "-"}
                </td>
                <td
                  className="ellipsis"
                  title={item.gptModels ? item.gptModels.join(", ") : ""}
                >
                  {item.gptModels ? item.gptModels.join(", ") : "-"}
                </td>
                <td className="ellipsis" title={item.createBy}>
                  {item.createBy}
                </td>
                <td
                  className="ellipsis"
                  title={item.createDate ? transformDate(item.createDate) : ""}
                >
                  {item.createDate ? transformDate(item.createDate) : "-"}
                </td>
                <td
                  className="ellipsis"
                  title={item.updateDate ? transformDate(item.updateDate) : ""}
                >
                  {item.updateDate ? transformDate(item.updateDate) : "-"}
                </td>
                <td className="ellipsis">
                  {item.activated ? <BsCheckLg className="check_icon" /> : ""}
                </td>
                <td className="ellipsis">
                  {item.canAnalyzeFile ? (
                    <BsCheckLg className="check_icon" />
                  ) : (
                    ""
                  )}
                </td>
                <td className="td_btn_wrap">
                  <TableBtn
                    onClickHandler={() => {
                      setModifyModal(true);
                      setSelectedData(item);
                    }}
                    btnName="modify"
                  />
                  <TableBtn
                    onClickHandler={() => {
                      setDeleteModal(true);
                      setSelectedData(item);
                    }}
                    btnName="delete"
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className="no_data">
              <td colSpan={10}>No Data</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="mobile_grid_box_wrap">
        {userData.map((user) => (
          <MobileGridBox
            data={{
              Name: user.userName,
              Email: user.userId,
              Description: user.userDescription,
              CreateBy: user.createBy,
              CreateDate: user.createDate
                ? transformDate(user.createDate)
                : "-",
              UpdateDate: user.updateDate
                ? transformDate(user.updateDate)
                : "-",
              Activated: user.activated ? (
                <BsCheckLg className="check_icon" />
              ) : (
                "-"
              ),
              DataAnalysis: user.canAnalyzeFile ? (
                <BsCheckLg className="check_icon" />
              ) : (
                "-"
              ),
            }}
            modifyHandler={() => {
              setModifyModal(true);
              setSelectedData(user);
            }}
            deleteHandelr={() => {
              setDeleteModal(true);
              setSelectedData(user);
            }}
          />
        ))}
      </div>

      <Pagenator
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={pageIndex}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default AccessMgmtUserPage;
