import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import "./admin.scss";
import TabBtn from "../../components/userInterface/buttons/TabBtn";
import { innerRoutes } from "../../../routes/routes";
import { useAppDispatch } from "../../../features";
import {
  BatchMetadataServiceName,
  batchMetadataServiceParams,
} from "../../../features/admin";
import { BatchMetadataSearch } from "./batchMetadata/BatchMetadataSearch";
import RegisteredMetadataTable from "../../components/admin/batchMetadata/RegisteredMetadataTable";
import {
  IBatchMetadataModal,
  OuterServiceType,
} from "../../../features/batchMetadata/types";
import BatchMetadataManagementModal from "../../components/admin/batchMetadata/modal/BatchMetadataManagementModal";
import DeleteModal from "../../components/common/DeleteModal";
import CautionModal from "../../components/common/CautionModal";
import Pagenator from "../../components/common/Pagenator";
import {
  deleteBatchMetadataAsync,
  EmployeeBatchMetadataSearchInput,
  makeSelectBatchMetadataRegisterInputs,
  makeSelectBatchMetadataSearchInputs,
  resetBatchMetadataRegisterInputs,
  resetBatchMetadataSearchInputs,
  selectBatchMetadataTotalCount,
  setBatchMetadataModifyItem,
  setBatchMetadataSearchInputs,
} from "../../../features/batchMetadata";
import useWindowResize from "../../../libs/hooks/useWindowResize";
import useBatchMetadataList from "./batchMetadata/useBatchMetadataList";

function isBatchMetadataServiceParam(
  serviceName: any
): serviceName is OuterServiceType {
  return batchMetadataServiceParams.includes(serviceName);
}

const BatchMetadataRoute = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { service } = useParams();
  const windowSize = useWindowResize();
  const totalCount = useSelector(selectBatchMetadataTotalCount);
  const teamsTheme = useSelector(selectTeamsTheme);
  const searchInputs = useSelector(
    makeSelectBatchMetadataSearchInputs(service as OuterServiceType)
  );
  const batchRegisterInputs = useSelector(
    makeSelectBatchMetadataRegisterInputs(service as OuterServiceType)
  );
  const { getBatchMetadataList } = useBatchMetadataList();
  const [selectTab, setSelectTab] = useState<OuterServiceType>("employee");
  const [nameTitle, setNameTitle] =
    useState<BatchMetadataServiceName>("Employee");
  const [isShowModal, setIsShowModal] = useState<IBatchMetadataModal>({
    register: false,
    modify: false,
    delete: false,
    trigger: false,
    triggerAll: false,
  });

  useEffect(() => {
    return () => {
      dispatch(resetBatchMetadataSearchInputs());
    };
  }, []);

  const setCurrentPage = (newPageIndex: number) => {
    dispatch(
      setBatchMetadataSearchInputs({
        serviceType: service as OuterServiceType,
        key: "skip",
        value: newPageIndex,
      })
    );
    getBatchMetadataList({
      setNameTitle: null,
      currentPage: newPageIndex,
    });
  };

  useEffect(() => {
    if (!service) return;
    if (!windowSize.width) return;
    if (!isBatchMetadataServiceParam(service)) {
      navigate(innerRoutes.BATCH_METADATA_MGMT_EMPLOYEE.path);
      return;
    }

    setSelectTab(service);
    getBatchMetadataList({
      setNameTitle,
      currentPage: 1,
    });
  }, [service, windowSize]);

  const closeModal = (modalType: keyof IBatchMetadataModal) => {
    setIsShowModal((prev) => ({ ...prev, [modalType]: false }));
    dispatch(resetBatchMetadataRegisterInputs());
  };

  const deleteHandler = () => {
    const serviceParam = service as OuterServiceType;
    if (serviceParam === "employee") {
      if (batchRegisterInputs && batchRegisterInputs._id) {
        dispatch(
          deleteBatchMetadataAsync.request({
            deleteData: {
              serviceType: serviceParam,
              param: {
                _id: batchRegisterInputs._id,
              },
            },
            getData: {
              serviceType: serviceParam,
              param: {
                ...(searchInputs as EmployeeBatchMetadataSearchInput),
                skip: 1,
              },
            },
          })
        );
      }
    }
    dispatch(
      setBatchMetadataSearchInputs({
        serviceType: service as OuterServiceType,
        key: "skip",
        value: 1,
      })
    );
    closeModal("delete");
  };

  const triggerHandler = () => {
    closeModal("trigger");
  };

  return (
    <>
      {isShowModal.register && (
        <BatchMetadataManagementModal
          onClose={() =>
            setIsShowModal((prev) => ({ ...prev, register: false }))
          }
          title="Register"
        />
      )}
      {isShowModal.modify && batchRegisterInputs && (
        <BatchMetadataManagementModal
          onClose={() => setIsShowModal((prev) => ({ ...prev, modify: false }))}
          modifyData={batchRegisterInputs}
          title="Modify"
        />
      )}
      {isShowModal.delete && batchRegisterInputs && (
        <DeleteModal
          deleteHandler={deleteHandler}
          onClose={() => closeModal("delete")}
        />
      )}
      {isShowModal.trigger && batchRegisterInputs && (
        <CautionModal
          buttonName="Trigger"
          modalText="Are you sure you want to trigger it now?"
          cautionText={`Running a batch job during working hours \n may cause issues with search functionality.`}
          clickHandler={triggerHandler}
          onClose={() => closeModal("trigger")}
          isDisabled={true} // ❗ 아직 개발 전
        />
      )}
      {/* {isShowModal.trigger && batchRegisterInputs && (
        <CautionModal
          buttonName="Trigger"
          modalText="Are you sure you want to trigger it now?"
          cautionText={`Running a batch job during working hours \n may cause issues with search functionality.`}
          clickHandler={triggerHandler}
          onClose={() =>
            setIsShowModal((prev) => ({ ...prev, trigger: false }))
          }
          isDisabled={true} // ❗ 아직 개발 전
        />
      )} */}
      <div className={`mgmt_page scroll_none`}>
        <hgroup>
          <div className="title_tab_wrap">
            <h3 className={`page_title page_title_${teamsTheme}`}>
              Batch Metadata Management
            </h3>
            <div className={`tab_btn_wrap tab_btn_wrap_${teamsTheme}`}>
              <TabBtn
                tabName="e-Approval"
                selectedTab={selectTab}
                value="eapproval"
                onClickHandler={() => setSelectTab("eapproval")}
                pathName={innerRoutes.BATCH_METADATA_MGMT_EAPROVAL.path}
                loading={false}
                isDisabled={true} // ❗ 아직 개발 전
              />
              <TabBtn
                tabName="Employee"
                selectedTab={selectTab}
                value="employee"
                onClickHandler={() => setSelectTab("employee")}
                pathName={innerRoutes.BATCH_METADATA_MGMT_EMPLOYEE.path}
                loading={false}
              />
            </div>
          </div>
          <div className="main_color_btn_wrap">
            <MainColorBtn
              onClickHandler={() =>
                setIsShowModal((prev) => ({ ...prev, trigger: true }))
              }
              btnName="Trigger All"
              addedClassNames="caution_btn"
            />
            <MainColorBtn
              onClickHandler={() =>
                setIsShowModal((prev) => ({ ...prev, register: true }))
              }
              btnName="Register"
            />
          </div>
        </hgroup>
        <BatchMetadataSearch title={nameTitle} />
        <RegisteredMetadataTable
          serviceType={service as OuterServiceType}
          setIsShowModal={setIsShowModal}
          setSelectedData={setBatchMetadataModifyItem}
        />

        <Pagenator
          totalCount={totalCount}
          // pageSize={limit}
          pageSize={searchInputs.limit}
          currentPage={searchInputs.skip}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default BatchMetadataRoute;
