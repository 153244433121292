import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import { memo } from "react";

type Props = {
  value: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
};

const TextareaType: React.FC<Props> = ({
  value,
  onChangeHandler,
  placeholder,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <textarea
      className={`textarea textarea_${teamsTheme}`}
      placeholder={placeholder ? placeholder : "Search"}
      value={value}
      onChange={onChangeHandler}
    />
  );
};

export default memo(TextareaType);
