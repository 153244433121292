import { RootState } from "../index";

export const selectTotalTokenData = (state: RootState) =>
  state.billing.totalTokenData;
export const selectTokenLogData = (state: RootState) =>
  state.billing.tokenLogDataList;
export const selectTokenCompanyData = (state: RootState) =>
  state.billing.tokenCompanyData;

export const selectBillingNameList = (state: RootState) =>
  state.billing.billingNameList;

export const selectBillingSearchInputs = (state: RootState) =>
  state.billing.billingSearchInputs;

export const selectTotalTokenCount = (state: RootState) =>
  state.billing.tokenTotalCount;
