import { ActionType } from "typesafe-actions";
import * as actions from "./actions";
import {
  EapprovalBatchMetadataItem,
  EapprovalBatchMetadataSearchInput,
  EmployeeBatchMetadataItem,
  EmployeeBatchMetadataSearchInput,
} from "./api";

export type BatchMetadataAction = ActionType<typeof actions>;
export type OuterServiceType = "eapproval" | "employee";
export type AdminAllServiceType = OuterServiceType | "aichat";

export interface IBatchMetadataModal {
  register: boolean;
  modify: boolean;
  delete: boolean;
  trigger: boolean;
  triggerAll: boolean;
}

const batchMetadataApiPaths = {
  employee: {
    register: "/auth/addEmployeeBatchMetadata",
    get: "/auth/getEmployeeBatchMetadata",
    update: "/auth/editEmployeeBatchMetadata",
    delete: "/auth/deleteEmployeeBatchMetadata",
    trigger: "/auth/triggerEmployeeBatchMetadata",
  },
  eapproval: {
    register: "/auth/addEapprovalBatchMetadata",
    get: "/auth/getEapprovalBatchMetadata",
    update: "/auth/editEapprovalBatchMetadata",
    delete: "/auth/deleteEapprovalBatchMetadata",
    trigger: "/auth/triggerEapprovalBatchMetadata",
  },
} as const;

type BatchMetadataApiPaths = typeof batchMetadataApiPaths;
export type BatchMetadataCrudOperation =
  keyof BatchMetadataApiPaths[OuterServiceType];

export function getBatchMetadataApiPath<
  T extends OuterServiceType,
  O extends BatchMetadataCrudOperation
>(serviceType: T, operation: O): BatchMetadataApiPaths[T][O] {
  return batchMetadataApiPaths[serviceType][operation];
}

export type BatchMetadataState = {
  batchMetadataTotalCount: number;
  employeeBatchSearchInputs: EmployeeBatchMetadataSearchInput;
  eapprovalBatchSearchInputs: EapprovalBatchMetadataSearchInput;
  employeeBatchMetadataList: EmployeeBatchMetadataItem[];
  eapprovalBatchMetadataList: EapprovalBatchMetadataItem[];
  employeeBatchRegisterInputs: EmployeeBatchMetadataItem;
  eapprovalBatchRegisterInputs: EapprovalBatchMetadataItem;
};
