import "./Markdown.scss";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import { memo, useEffect, useState } from "react";
import CodeBlock from "./markdown/CodeBlock";
import { MdDownload } from "react-icons/md";
import clsx from "clsx";

type CProps = {
  imgUrl: string;
  isDalleUrl?: boolean;
};

const CustomImg: React.FC<CProps> = ({ imgUrl, isDalleUrl }) => {
  const [zoom, setZoom] = useState(false);
  const [imageLoading, setImageLoading] = useState({
    loading: true,
    imageSrc: "",
  });

  const imgDownloadHandler = () => {
    const binaryString = atob(imgUrl.split(",")[1]);

    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      view[i] = binaryString.charCodeAt(i) & 0xff;
    }

    const blob = new Blob([arrayBuffer], { type: "image/png" });
    const downloadUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "downloaded_image.jpg";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const img = new Image();
    if (imgUrl !== "") {
      img.src = imgUrl;
      img.onload = () => {
        setImageLoading({
          loading: false,
          imageSrc: img.src,
        });
      };
    }
  }, [imgUrl]);

  return (
    <>
      {zoom && (
        <div className="zoom-img-black-bg" onClick={() => setZoom(false)}>
          <div className="zoom-img-wrap">
            <img src={imgUrl} className="zoom-img" alt="zoom-dalle" />
          </div>
        </div>
      )}
      {!imageLoading.loading && (
        <div
          className={clsx({
            "custom_img_wrap": !isDalleUrl,
            "custom_dalle_img_wrap": isDalleUrl,
          })}
        >
          {!isDalleUrl && (
            <button className="download_button" onClick={imgDownloadHandler}>
              <MdDownload className="img_download_icon" />
              download
            </button>
          )}
          <img
            onClick={() => setZoom(true)}
            src={imgUrl}
            className="custom_img"
            alt="dalle"
          />
        </div>
      )}
    </>
  );
};

type Props = {
  answer: string;
  isDalleUrl?: boolean;
};

const Markdown: React.FC<Props> = ({ answer, isDalleUrl = false }) => {
  return (
    <ReactMarkdown
      children={answer}
      remarkPlugins={[remarkGfm, remarkBreaks, remarkMath]}
      rehypePlugins={[rehypeKatex]}
      // className="markdown_wrap"
      components={{
        img: (props) => (
          <CustomImg imgUrl={props.src ?? ""} isDalleUrl={isDalleUrl} />
        ),
        a: (props) => (
          <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
          </a>
        ),
        code: CodeBlock,
      }}
    />
  );
};

export default memo(Markdown);
