import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../features";
import { selectTeamsTheme } from "../../../../../features/auth";
import { useCallback, useEffect, useState } from "react";
import "../../MgmtModal.scss";
import {
  BatchMetadataServiceParam,
  ModalTitleTypes,
} from "../../../../../features/admin";
import InputTextType from "../../../userInterface/inputs/InputTextType";
import PairedTextInputs from "../../../userInterface/inputs/PairedTextInputs";
import ModalCancelBtn from "../../../userInterface/buttons/ModalCancelBtn";
import ModalRegisterBtn from "../../../userInterface/buttons/ModalRegisterBtn";
import {
  EapprovalBatchMetadataItem,
  EmployeeBatchMetadataItem,
  EmployeeBatchMetadataSearchInput,
  makeSelectBatchMetadataRegisterInputs,
  makeSelectBatchMetadataSearchInputs,
  OuterServiceType,
  registerEmployeeBatchMetadataAsync,
  resetBatchMetadataRegisterInputs,
  resetBatchMetadataSearchInputs,
  setBatchMetadataRegisterInputs,
  updateBatchMetadataAsync,
} from "../../../../../features/batchMetadata";
import { useParams } from "react-router-dom";
import { initialBatchEmployeeMetadataSearchInputs } from "../../../../../features/batchMetadata/reducer";

type Props = {
  onClose: () => void;
  modifyData?: EmployeeBatchMetadataItem | EapprovalBatchMetadataItem;
  title: ModalTitleTypes;
};

const initialPairedInput = {
  compCode: "",
  compName: "",
};

const BatchMetadataManagementModal: React.FC<Props> = ({ onClose, title }) => {
  const dispatch = useAppDispatch();
  const { service } = useParams();
  const searchInputs = useSelector(
    makeSelectBatchMetadataSearchInputs(service as OuterServiceType)
  );
  const batchRegisterInputs = useSelector(
    makeSelectBatchMetadataRegisterInputs(service as OuterServiceType)
  );
  const teamsTheme = useSelector(selectTeamsTheme);

  const [error, setError] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetBatchMetadataRegisterInputs());
    };
  }, []);

  useEffect(() => {
    if (batchRegisterInputs.companyInfo.length === 0) {
      dispatch(
        setBatchMetadataRegisterInputs({
          serviceType: service as OuterServiceType,
          key: "companyInfo",
          value: [initialPairedInput],
        })
      );
    }
    if (batchRegisterInputs.companyInfo.length > 0) {
      const lastItem =
        batchRegisterInputs.companyInfo[
          batchRegisterInputs.companyInfo.length - 1
        ];

      if (lastItem.compCode.trim() && lastItem.compName.trim()) {
        dispatch(
          setBatchMetadataRegisterInputs({
            serviceType: service as OuterServiceType,
            key: "companyInfo",
            value: [...batchRegisterInputs.companyInfo, initialPairedInput],
          })
        );
      }
    }
  }, [batchRegisterInputs.companyInfo]);

  const submitHandler = () => {
    console.log("submitHandler");
    console.log(batchRegisterInputs);
    if (batchRegisterInputs.companyInfo.length === 1) {
      const item = batchRegisterInputs.companyInfo[0];
      if (!item.compCode.trim() || !item.compName.trim()) {
        setError(true);
        return;
      }
    } else {
      batchRegisterInputs.companyInfo.slice(0, -1).forEach((item) => {
        if (!item.compCode.trim() || !item.compName.trim()) {
          setError(true);
          return;
        }
      });
    }
    const serviceParam = service as BatchMetadataServiceParam;
    if (serviceParam === "employee") {
      const inputs = batchRegisterInputs as EmployeeBatchMetadataItem;
      if (
        inputs.siteId.trim() === "" ||
        !inputs.siteName ||
        inputs.siteName.trim() === ""
      ) {
        setError(true);
        return;
      }
    }
    if (serviceParam === "eapproval") {
      const inputs = batchRegisterInputs as EapprovalBatchMetadataItem;
      if (inputs.downloadUrl.trim() === "") {
        setError(true);
        return;
      }
    }

    if (title === "Register") {
      registerHandler();
    } else {
      modifyHandler();
    }
  };

  const registerHandler = () => {
    const serviceParam = service as BatchMetadataServiceParam;

    if (serviceParam === "employee") {
      const inputs = batchRegisterInputs as EmployeeBatchMetadataItem;
      dispatch(
        registerEmployeeBatchMetadataAsync.request({
          addData: {
            serviceType: serviceParam,
            param: {
              companyInfo: inputs.companyInfo.slice(0, -1),
              siteId: inputs.siteId,
              siteName: inputs.siteName ?? "",
            },
          },
          getData: {
            serviceType: serviceParam,
            param: {
              ...(initialBatchEmployeeMetadataSearchInputs as EmployeeBatchMetadataSearchInput),
              limit: searchInputs.limit,
            },
          },
        })
      );
    }
    dispatch(resetBatchMetadataSearchInputs());
    onClose();
  };

  const modifyHandler = () => {
    const serviceParam = service as BatchMetadataServiceParam;

    if (serviceParam === "employee") {
      const inputs = batchRegisterInputs as EmployeeBatchMetadataItem;
      console.log(inputs);
      dispatch(
        updateBatchMetadataAsync.request({
          serviceType: serviceParam,
          param: {
            id: inputs._id,
            companyInfo: inputs.companyInfo.slice(0, -1),
            siteId: inputs.siteId,
            siteName: inputs.siteName ?? "",
          },
        })
      );
    }
    onClose();
  };

  const handleInputChange = useCallback(({ name, value }) => {
    dispatch(
      setBatchMetadataRegisterInputs({
        serviceType: service as OuterServiceType,
        key: name,
        value,
      })
    );
  }, []);

  const handleCompanyInputChange = ({
    idx,
    name,
    value,
  }: {
    idx: number;
    name: string;
    value: string;
  }) => {
    const changedItem = batchRegisterInputs.companyInfo.map((item, preIdx) =>
      preIdx === idx ? { ...item, [name]: value } : item
    );

    dispatch(
      setBatchMetadataRegisterInputs({
        serviceType: service as OuterServiceType,
        key: "companyInfo",
        value: changedItem,
      })
    );
  };

  const handleCompanyInfoDelete = (deleteIndex: number) => {
    const restItem = batchRegisterInputs.companyInfo.filter(
      (_, idx) => idx !== deleteIndex
    );
    console.log("restItem", restItem);
    dispatch(
      setBatchMetadataRegisterInputs({
        serviceType: service as OuterServiceType,
        key: "companyInfo",
        value: restItem,
      })
    );
  };

  return (
    <div className={`modal_black_bg modal_black_bg-${teamsTheme}`}>
      <div className={`modal_white_bg wide_width modal_white_bg-${teamsTheme}`}>
        <hgroup>
          <h3>Batch Metadata Management</h3>
          <p>
            If you register the metadata for batch jobs, they will automatically
            run at midnight.
          </p>
        </hgroup>
        <table className={`modal_table_wrap modal_table_wrap_${teamsTheme}`}>
          <colgroup>
            <col />
            <col />
          </colgroup>
          <tbody>
            {"siteId" in batchRegisterInputs && (
              <tr>
                <th className={`modal_table_th_${teamsTheme}`}>
                  Site Id <span className="essential">*</span>
                </th>
                <td>
                  <InputTextType
                    value={batchRegisterInputs.siteId}
                    onChangeHandler={(e) => {
                      handleInputChange({
                        name: "siteId",
                        value: e.target.value,
                      });
                    }}
                    placeholder="Please enter site id"
                  />
                </td>
              </tr>
            )}
            {"siteName" in batchRegisterInputs && (
              <tr>
                <th className={`modal_table_th_${teamsTheme}`}>
                  Site Name <span className="essential">*</span>
                </th>
                <td>
                  <InputTextType
                    value={batchRegisterInputs.siteName ?? ""}
                    onChangeHandler={(e) => {
                      handleInputChange({
                        name: "siteName",
                        value: e.target.value,
                      });
                    }}
                    placeholder="Please enter site name"
                  />
                </td>
              </tr>
            )}

            {"downloadUrl" in batchRegisterInputs && (
              <tr>
                <th className={`modal_table_th_${teamsTheme}`}>
                  Site Name <span className="essential">*</span>
                </th>
                <td>
                  <InputTextType
                    value={batchRegisterInputs.downloadUrl}
                    onChangeHandler={(e) => {
                      handleInputChange({
                        name: "downloadUrl",
                        value: e.target.value,
                      });
                    }}
                    placeholder="Please enter download url"
                  />
                </td>
              </tr>
            )}

            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>
                Company Information
                <span className="essential">*</span>
              </th>
              <td className="td__flex-direction--column">
                {batchRegisterInputs.companyInfo.map((item, idx) => (
                  <PairedTextInputs
                    key={idx}
                    currentIndex={idx}
                    content={item}
                    onChangeHandler={(e) => {
                      const { name, value } = e.target;
                      handleCompanyInputChange({ idx, name, value });
                    }}
                    onClickDeleteHandler={() => handleCompanyInfoDelete(idx)}
                    isDisabledDelete={
                      batchRegisterInputs.companyInfo.length === 1
                    }
                    title={{ id: "Company Code", value: "Company Name" }}
                  />
                ))}
              </td>
            </tr>
          </tbody>
        </table>

        {error && <p className="modal_error">Please enter required items.</p>}
        <div className={`modal_btn_wrap modal_btn_wrap_${teamsTheme}`}>
          <ModalCancelBtn onClickHandler={onClose} btnName="Cancel" />
          <ModalRegisterBtn onClickHandler={submitHandler} btnName={title} />
        </div>
      </div>
    </div>
  );
};

export default BatchMetadataManagementModal;
