import { useEffect, useRef, useState, memo } from "react";
import "./Prompt.scss";
import { FileCard, IFileInfoItem } from "../../../features/chat";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";
import FileCardItem from "./fileUpload/FileCardItem";
import { getNameAndExtension } from "../../../services/FileUpload";

type Props = {
  prompt: string;
  indexCheck: boolean;
  fileCards?: FileCard[] | undefined;
  fileInfo?: IFileInfoItem[];
};

const Prompt: React.FC<Props> = ({ prompt, indexCheck, fileInfo }) => {
  const lastQuestionRef = useRef<HTMLDivElement>(null);
  const teamsTheme = useSelector(selectTeamsTheme);
  const [fileCards, setFileCards] = useState<FileCard[]>();

  useEffect(() => {
    if (fileInfo?.length) {
      const tempFileCards = fileInfo.map((elem) => {
        const [_, extension] = getNameAndExtension(elem.fileName);
        return { name: elem.fileName, extension, imgUrl: "", isLoading: false };
      });

      setFileCards(tempFileCards);
    }
  }, [fileInfo]);

  return (
    <div ref={indexCheck ? lastQuestionRef : null} className="prompt_wrap">
      <div className={`prompt prompt_${teamsTheme} prompt_flex`}>
        <div className="files_container">
          {fileCards?.map((elem, idx) => (
            <FileCardItem
              key={idx}
              fileId={elem.fileId ?? ""}
              fileName={elem.name}
              extension={elem.extension}
              imgUrl={elem.imgUrl}
              isLoading={elem.isLoading}
            />
          ))}
        </div>
        {prompt}
      </div>
    </div>
  );
};

export default memo(Prompt);
