import "./ChatControlButton.scss";
import { useSelector } from "react-redux";
import {
  AssistantType,
  cancelChatAsync,
  getAnswerAsync,
  getDalleAnswerAsync,
  selectAssistantTypeInfo,
  selectChatList,
  selectChatLoading,
  selectFileInfoList,
  selectModelEngine,
  selectPrevMessages,
  selectPromptText,
} from "../../../../features/chat";
import { useAppDispatch } from "../../../../features";
import { IoMdRefresh } from "react-icons/io";
import { BsFillSquareFill } from "react-icons/bs";
import {
  selectTeamsTheme,
  selectTemperature,
  selectUserId,
} from "../../../../features/auth";
import {
  selectMyAIChatForm,
  // selectSelectedSideBarMyGptId,
} from "../../../../features/myGpts";
import { useLocation } from "react-router-dom";

type Props = {
  // prompt: string;
  addChatHandler: (isRegenerate?: boolean) => void;
};

const ChatControlButton: React.FC<Props> = ({ addChatHandler }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const promptText = useSelector(selectPromptText);
  const teamsTheme = useSelector(selectTeamsTheme);
  const chatList = useSelector(selectChatList);
  const chatLoading = useSelector(selectChatLoading);
  const modelEngine = useSelector(selectModelEngine);
  const fileInfoList = useSelector(selectFileInfoList);
  const assistantTypeInfo = useSelector(selectAssistantTypeInfo);
  // const selectedMyGptsId = useSelector(selectSelectedSideBarMyGptId);
  const temperature = useSelector(selectTemperature);
  const myAIChatForm = useSelector(selectMyAIChatForm);

  // 채팅이 완료되지 않았을 때, continue 버튼을 누르면 이어서 채팅이 진행되는 함수
  const continueAnswer = () => {
    const listId = chatList[chatList.length - 1].listId;

    const value: AssistantType = "DALLE";

    if (modelEngine === "dalle") {
      const chatPayload = {
        prompt: "continue",
        gptVersion: null,
        temperature,
        listId,
        fileInfo: [],
        assistantType: value,
        myGPTsId: null,
      };
      dispatch(getDalleAnswerAsync.request(chatPayload));

      return;
    }
    if (modelEngine === "myGPTs") {
      const myGPTsId = location.pathname.includes("my_chat_mgmt")
        ? myAIChatForm.id
        : assistantTypeInfo.myGPTsId;

      dispatch(
        getAnswerAsync.request({
          prompt: "continue",
          temperature,
          listId,
          fileInfo: fileInfoList,
          assistantType: assistantTypeInfo.assistantType,
          myGPTsId: myGPTsId,
          isPreviewChat: location.pathname.includes("my_chat_mgmt")
            ? true
            : false,
        })
      );
      return;
    }

    dispatch(
      getAnswerAsync.request({
        prompt: "continue",
        gptVersion: modelEngine,
        temperature,
        listId,
        fileInfo: fileInfoList,
        assistantType: assistantTypeInfo.assistantType,
        myGPTsId: assistantTypeInfo.myGPTsId ?? null,
      })
    );
  };

  // 채팅중에는 답변을 취소할 수 있는 버튼이 표시된다
  if (chatLoading && chatList?.length) {
    return (
      <div
        className="stop_chat-container"
        id="stop_chat"
        // id="stop_chat-container"
      >
        <button
          className={`stop_chat-button ${teamsTheme}`}
          onClick={() => dispatch(cancelChatAsync({ prompt: promptText }))}
        >
          <BsFillSquareFill className="stop-icon" />
          <span>Stop Responding</span>
        </button>
      </div>
    );
  }

  //    채팅이 종료되면 재질문할 수 있는 버튼이 표시된다
  if (!chatLoading && chatList?.length) {
    return (
      <div
        className="stop_chat-container"
        id="stop_chat"
        // id="stop_chat-container"
      >
        <button
          className={`stop_chat-button ${teamsTheme}`}
          onClick={() => addChatHandler(true)}
        >
          <IoMdRefresh className="regenerate-icon" />
          <span>Regenerate</span>
        </button>

        {/* 채팅이 종료되고, 토큰제한으로 인해 아직 보여지지 않은 답변이 있다면 해당 버튼이 표시된다. */}
        {chatList[chatList.length - 1].finishReason === "length" && (
          <div>
            <button
              className={`continue_chat-button stop_chat-button ${teamsTheme}`}
              onClick={() => continueAnswer()}
            >
              <span>Continue</span>
            </button>
          </div>
        )}
      </div>
    );
  }

  return <></>;
};

export default ChatControlButton;
