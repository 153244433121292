import { IAADParams, IAADUserInfo, IAgreeParams, ISetting } from "./api";
import { createAction, createAsyncAction } from "typesafe-actions";
import { AxiosError } from "axios";

export const AAD_LOGIN = "auth/AAD_LOGIN";
export const AAD_LOGIN_SUCCESS = "auth/AAD_LOGIN_SUCCESS";
export const AAD_LOGIN_ERROR = "auth/AAD_LOGIN_ERROR";

export const aadLoginAsync = createAsyncAction(
  AAD_LOGIN,
  AAD_LOGIN_SUCCESS,
  AAD_LOGIN_ERROR
)<IAADParams, IAADUserInfo, AxiosError>();

export const LOGOUT = "auth/LOGOUT";
export const logout = createAction(LOGOUT)();

export const MOBILE_CHECK = "auth/MOBILE_CHECK";

export const AGREE_TERMS_AND_CONDITIONS = "auth/AGREE_TERMS_AND_CONDITIONS";
export const AGREE_TERMS_AND_CONDITIONS_SUCCESS =
  "auth/AGREE_TERMS_AND_CONDITIONS_SUCCESS";
export const AGREE_TERMS_AND_CONDITIONS_ERROR =
  "auth/AGREE_TERMS_AND_CONDITIONS_ERROR";

export const agreeTermsAndConditionsAsync = createAsyncAction(
  AGREE_TERMS_AND_CONDITIONS,
  AGREE_TERMS_AND_CONDITIONS_SUCCESS,
  AGREE_TERMS_AND_CONDITIONS_ERROR
)<IAgreeParams, IAgreeParams, AxiosError>();

export const OPEN_POPUP = "auth/OPEN_POPUP";
export const openPopup = createAction(OPEN_POPUP)<{ open: boolean }>();

export const GET_SETTING = "auth/GET_SETTING";
export const GET_SETTING_SUCCESS = "auth/GET_SETTING_SUCCESS";
export const GET_SETTING_ERROR = "auth/GET_SETTING_ERROR";

export const getSettingAsync = createAsyncAction(
  GET_SETTING,
  GET_SETTING_SUCCESS,
  GET_SETTING_ERROR
)<any, ISetting, AxiosError>();

export const UPDATE_SETTING = "auth/UPDATE_SETTING";
export const UPDATE_SETTING_SUCCESS = "auth/UPDATE_SETTING_SUCCESS";
export const UPDATE_SETTING_ERROR = "auth/UPDATE_SETTING_ERROR";

export const updateSettingAsync = createAsyncAction(
  UPDATE_SETTING,
  UPDATE_SETTING_SUCCESS,
  UPDATE_SETTING_ERROR
)<ISetting, ISetting, AxiosError>();

export const SET_THEME = "auth/SET_THEME";
export const setTheme = createAction(SET_THEME)<{ theme: string }>();

export const SET_IS_SHOW_SIDEBAR = "auth/SET_IS_SHOW_SIDEBAR";
export const setIsShowSidebar = createAction(SET_IS_SHOW_SIDEBAR)<boolean>();
