import AccessMgmtRoute from "../pages/pages/admin/AccessMgmtRoute";
import AdminMgmtPage from "../pages/pages/admin/AdminMgmtPage";
import LogMgmtRoute from "../pages/pages/admin/LogMgmtRoute";
import ModelDevelopmentMgmtPage from "../pages/pages/admin/ModelDevelopmentMgmtPage";
import Chat from "../pages/pages/chat/Chat";
import { ReactElement } from "react";
import MyAIChatsMgmgRoute from "../pages/pages/myAIChats/MyAIChatsMgmgRoute";
import BillingMgmtRoute from "../pages/pages/admin/BillingMgmtRoute";
import BatchMetadataRoute from "../pages/pages/admin/BatchMetadataRoute";
import { AdminAllServiceType } from "../features/batchMetadata";

export type Routes = {
  title: string;
  element: ReactElement;
  path: string;
};

type Page = (typeof pages)[number];

export const pages = [
  "CHAT",
  "ADMIN_MGMT",
  "ACCESS_MGMT",
  "LOG_MGMT",
  "BILLING_MGMT",
  "ENDPOINT_MGMT",
];

const ADMIN = "admin";
const PRIVATE = "private";

export const allRoutes: Record<Page, Routes> = {
  CHAT: {
    title: "채팅",
    element: <Chat />,
    path: `/${PRIVATE}/chat`,
  },
  MY_CHAT: {
    title: "My AI Chats",
    element: <MyAIChatsMgmgRoute />,
    path: `/${PRIVATE}/my_chat_mgmt/*`,
  },
  ADMIN_MGMT: {
    title: "관리자 관리",
    element: <AdminMgmtPage />,
    path: `/${PRIVATE}/${ADMIN}/admin_mgmt`,
  },
  ACCESS_MGMT: {
    title: "액세스 관리",
    element: <AccessMgmtRoute />,
    path: `/${PRIVATE}/${ADMIN}/access_mgmt/*`,
  },
  LOG_MGMT: {
    title: "로그 관리",
    element: <LogMgmtRoute />,
    path: `/${PRIVATE}/${ADMIN}/log_mgmt/*`,
  },
  BILLING_MGMT: {
    title: "빌링 관리",
    element: <BillingMgmtRoute />,
    path: `/${PRIVATE}/${ADMIN}/billing_mgmt/:service/:title`,
  },
  ENDPOINT_MGMT: {
    title: "엔드포인트 관리",
    element: <ModelDevelopmentMgmtPage />,
    path: `/${PRIVATE}/${ADMIN}/model_development_mgmt`,
  },
  BATCH_METADATA_MGMT: {
    title: "배치 메타데이터 관리",
    element: <BatchMetadataRoute />,
    path: `/${PRIVATE}/${ADMIN}/batch_metadata_mgmt/:service`,
  },
};

type InnerPage = (typeof innerPages)[number];

export const innerPages = [
  "ACCESS_MGMT_COMPANY",
  "ACCESS_MGMT_DEPARTMENT",
  "ACCESS_MGMT_USER",
  "BATCH_METADATA_MGMT_EAPROVAL",
  "BATCH_METADATA_MGMT_EMPLOYEE",
];

export type InnerRoutes = {
  title: string;
  path: string;
};

export const innerRoutes: Record<InnerPage, InnerRoutes> = {
  ACCESS_MGMT_COMPANY: {
    title: "회사 액세스 관리",
    path: `/${PRIVATE}/${ADMIN}/access_mgmt/company`,
  },
  ACCESS_MGMT_DEPARTMENT: {
    title: "부서 액세스 관리",
    path: `/${PRIVATE}/${ADMIN}/access_mgmt/dept`,
  },
  ACCESS_MGMT_USER: {
    title: "사용자 액세스 관리",
    path: `/${PRIVATE}/${ADMIN}/access_mgmt/user`,
  },
  LOG_MGMT_QNA: {
    title: "Q&A 로그 관리",
    path: `/${PRIVATE}/${ADMIN}/log_mgmt/log`,
  },
  LOG_MGMT_EVENT: {
    title: "이벤트 로그 관리",
    path: `/${PRIVATE}/${ADMIN}/log_mgmt/event_log`,
  },
  MY_CHAT: {
    title: "My AI Chats",
    path: `/${PRIVATE}/my_chat_mgmt/list`,
  },
  CREATE_MY_CHAT: {
    title: "My AI Chat 만들기",
    path: `/${PRIVATE}/my_chat_mgmt/create`,
  },
  EDIT_MY_CHAT: {
    title: "My AI Chat 수정",
    path: `/${PRIVATE}/my_chat_mgmt/edit`,
  },
  BATCH_METADATA_MGMT_EAPROVAL: {
    title: "e-Approval 배치 메타데이터 관리",
    path: `/${PRIVATE}/${ADMIN}/batch_metadata_mgmt/eapproval`,
  },
  BATCH_METADATA_MGMT_EMPLOYEE: {
    title: "Employee 배치 메타데이터 관리",
    path: `/${PRIVATE}/${ADMIN}/batch_metadata_mgmt/employee`,
  },
};

export type OuterServiceRoutes = {
  [key in AdminAllServiceType]: InnerRoutes;
};

export type OuterServicePage =
  | "BILLING_MGMT_COMPANY"
  | "BILLING_MGMT_DEPARTMENT"
  | "BILLING_MGMT_USER";

export const outerServiceRoutes: Record<OuterServicePage, OuterServiceRoutes> =
  {
    BILLING_MGMT_COMPANY: {
      eapproval: {
        title: "e-Approval 회사 빌링 관리",
        path: `/${PRIVATE}/${ADMIN}/billing_mgmt/eapproval/company`,
      },
      employee: {
        title: "Employee 회사 빌링 관리",
        path: `/${PRIVATE}/${ADMIN}/billing_mgmt/employee/company`,
      },
      aichat: {
        title: "AI Chat 회사 빌링 관리",
        path: `/${PRIVATE}/${ADMIN}/billing_mgmt/aichat/company`,
      },
    },
    BILLING_MGMT_DEPARTMENT: {
      eapproval: {
        title: "e-Approval 부서 빌링 관리",
        path: `/${PRIVATE}/${ADMIN}/billing_mgmt/eapproval/department`,
      },
      employee: {
        title: "Employee 부서 빌링 관리",
        path: `/${PRIVATE}/${ADMIN}/billing_mgmt/employee/department`,
      },
      aichat: {
        title: "AI Chat 부서 빌링 관리",
        path: `/${PRIVATE}/${ADMIN}/billing_mgmt/aichat/department`,
      },
    },
    BILLING_MGMT_USER: {
      eapproval: {
        title: "e-Approval 사용자 빌링 관리",
        path: `/${PRIVATE}/${ADMIN}/billing_mgmt/eapproval/user`,
      },
      employee: {
        title: "Employee 사용자 빌링 관리",
        path: `/${PRIVATE}/${ADMIN}/billing_mgmt/employee/user`,
      },
      aichat: {
        title: "AI Chat 사용자 빌링 관리",
        path: `/${PRIVATE}/${ADMIN}/billing_mgmt/aichat/user`,
      },
    },
  };

export const getOuterServiceRoutes = (
  serviceRoute: OuterServicePage,
  service: AdminAllServiceType
) => {
  return outerServiceRoutes[serviceRoute][service];
};
