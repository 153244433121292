import { AxiosError } from "axios";
import { createAction, createAsyncAction } from "typesafe-actions";
import {
  AddMyGptsParam,
  DeleteMyGPTsParam,
  GetMyGPTsParam,
  GetMyGPTsResult,
  GetMyGptsDetailParam,
  MyGPTsData,
  SetMyGptsParam,
} from "./api";

export const SET_GPT_DATA = "myGPT/SET_GPT_DATA";
export const setMyAIChatForm = createAction(SET_GPT_DATA)<SetMyGptsParam>();

export const PAGE_CHANGE_DELETE_MYCHAT_ID =
  "myGPT/PAGE_CHANGE_DELETE_MYCHAT_ID";
export const pageChangeDeleteMyChatId = createAction(
  PAGE_CHANGE_DELETE_MYCHAT_ID
)<{ delete: true }>();

export const GET_MY_GPTS = "myGPT/GET_MY_GPTS";
export const GET_MY_GPTS_SUCCESS = "myGPT/GET_MY_GPTS_SUCCESS";
export const GET_MY_GPTS_ERROR = "myGPT/GET_MY_GPTS_ERROR";

export const getMyGPTsAsync = createAsyncAction(
  GET_MY_GPTS,
  GET_MY_GPTS_SUCCESS,
  GET_MY_GPTS_ERROR
)<GetMyGPTsParam, GetMyGPTsResult, AxiosError>();

export const GET_MY_GPTS_SIDEBAR = "myGPT/GET_MY_GPTS_SIDEBAR";
export const GET_MY_GPTS_SIDEBAR_SUCCESS = "myGPT/GET_MY_GPTS_SIDEBAR_SUCCESS";
export const GET_MY_GPTS_SIDEBAR_ERROR = "myGPT/GET_MY_GPTS_SIDEBAR_ERROR";

export const getMyGPTsSideBarAsync = createAsyncAction(
  GET_MY_GPTS_SIDEBAR,
  GET_MY_GPTS_SIDEBAR_SUCCESS,
  GET_MY_GPTS_SIDEBAR_ERROR
)<GetMyGPTsParam, GetMyGPTsResult, AxiosError>();

export const DELETE_MY_GPTS = "myGPT/DELETE_MY_GPTS";
export const DELETE_MY_GPTS_SUCCESS = "myGPT/DELETE_MY_GPTS_SUCCESS";
export const DELETE_MY_GPTS_ERROR = "myGPT/DELETE_MY_GPTS_ERROR";

export const deleteMyGPTSAsync = createAsyncAction(
  DELETE_MY_GPTS,
  DELETE_MY_GPTS_SUCCESS,
  DELETE_MY_GPTS_ERROR
)<DeleteMyGPTsParam, { myGPTsId: string }, AxiosError>();

export const UPDATE_MY_GPTS = "myGPT/UPDATE_MY_GPTS";
export const UPDATE_MY_GPTS_SUCCESS = "myGPT/UPDATE_MY_GPTS_SUCCESS";
export const UPDATE_MY_GPTS_ERROR = "myGPT/UPDATE_MY_GPTS_ERROR";

export const updateMyGPTsAsync = createAsyncAction(
  UPDATE_MY_GPTS,
  UPDATE_MY_GPTS_SUCCESS,
  UPDATE_MY_GPTS_ERROR
)<AddMyGptsParam, MyGPTsData, AxiosError>();

export const ADD_MY_GPTS = "myGPT/ADD_MY_GPTS";
export const ADD_MY_GPTS_SUCCESS = "myGPT/ADD_MY_GPTS_SUCCESS";
export const ADD_MY_GPTS_ERROR = "myGPT/ADD_MY_GPTS_ERROR";

export const addMyGPTSAsync = createAsyncAction(
  ADD_MY_GPTS,
  ADD_MY_GPTS_SUCCESS,
  ADD_MY_GPTS_ERROR
)<AddMyGptsParam, MyGPTsData, AxiosError>();

export const EDIT_MY_GPTS = "myGPT/EDIT_MY_GPTS";
export const EDIT_MY_GPTS_SUCCESS = "myGPT/EDIT_MY_GPTS_SUCCESS";
export const EDIT_MY_GPTS_ERROR = "myGPT/EDIT_MY_GPTS_ERROR";

export const editMyGPTSAsync = createAsyncAction(
  EDIT_MY_GPTS,
  EDIT_MY_GPTS_SUCCESS,
  EDIT_MY_GPTS_ERROR
)<AddMyGptsParam, MyGPTsData, AxiosError>();

export const GET_MY_GPTS_DETAIL = "myGPT/GET_MY_GPTS_DETAIL";
export const GET_MY_GPTS_DETAIL_SUCCESS = "myGPT/GET_MY_GPTS_DETAIL_SUCCESS";
export const GET_MY_GPTS_DETAIL_ERROR = "myGPT/GET_MY_GPTS_DETAIL_ERROR";

export const getMyGPTSDetailAsync = createAsyncAction(
  GET_MY_GPTS_DETAIL,
  GET_MY_GPTS_DETAIL_SUCCESS,
  GET_MY_GPTS_DETAIL_ERROR
)<GetMyGptsDetailParam, MyGPTsData, AxiosError>();

export const FOCUS_OUT_SAVE_MY_GPTS = "myGPT/FOCUS_OUT_SAVE_MY_GPTS";
export const FOCUS_OUT_SAVE_MY_GPTS_SUCCESS =
  "myGPT/FOCUS_OUT_SAVE_MY_GPTS_SUCCESS";
export const FOCUS_OUT_SAVE_MY_GPTS_ERROR =
  "myGPT/FOCUS_OUT_SAVE_MY_GPTS_ERROR";

export const focusOutSaveMyGptsAsync = createAsyncAction(
  FOCUS_OUT_SAVE_MY_GPTS,
  FOCUS_OUT_SAVE_MY_GPTS_SUCCESS,
  FOCUS_OUT_SAVE_MY_GPTS_ERROR
)<SetMyGptsParam, MyGPTsData, AxiosError>();

export const FOCUS_OUT_MODIFY_MY_GPTS = "myGPT/FOCUS_OUT_MODIFY_MY_GPTS";
export const FOCUS_OUT_MODIFY_MY_GPTS_SUCCESS =
  "myGPT/FOCUS_OUT_MODIFY_MY_GPTS_SUCCESS";
export const FOCUS_OUT_MODIFY_MY_GPTS_ERROR =
  "myGPT/FOCUS_OUT_MODIFY_MY_GPTS_ERROR";

export const focusOutModifyMyGptsAsync = createAsyncAction(
  FOCUS_OUT_MODIFY_MY_GPTS,
  FOCUS_OUT_MODIFY_MY_GPTS_SUCCESS,
  FOCUS_OUT_MODIFY_MY_GPTS_ERROR
)<SetMyGptsParam, MyGPTsData, AxiosError>();
