import "./PreviewChat.scss";
import { useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  clearChat,
  selectChatList,
  getAnswerAsync,
  selectChatLoading,
  selectSelectedChatId,
  regenerateAnswerAsync,
  selectFirstSelectedChatId,
  selectFileInfoList,
  selectAssistantTypeInfo,
  setPromptText,
  setInputUploadFiles,
  selectPromptText,
  selectFileCards,
  selectModelEngine,
  setModelEngine,
} from "../../../features/chat";
import {
  selectTeamsTheme,
  selectTemperature,
  selectIsContractAgreed,
} from "../../../features/auth";
import CHAT from "../../../constants/chat";
import { useAppDispatch } from "../../../features";
// import StartChat from "../../components/chat/StartChat";

import TokenGuide from "../../components/chat/mainChat/TokenGuide";
import ChatContent from "../../components/chat/mainChat/ChatContent";

import InitialConsentPopup from "../../components/common/InitialConsentPopup";
import { getMaxTokenNumberByVersion } from "../../../utils/getMaxTokenNumber";
import ChatControlButton from "../../components/chat/mainChat/ChatControlButton";
import ChatInputForm from "../../components/chat/mainChat/ChatInputForm";
import {
  selectMyAIChatForm,
  selectSelectedPreviewMyGptId,
} from "../../../features/myGpts";
import PreviewSelectSearchTypeList from "./PreviewSelectSearchTypeList";
import { selectUpdateMyGPTsLoading } from "../../../features/ui_loading";
import useWindowResize from "../../../libs/hooks/useWindowResize";
import MyAIChatIntro from "../chat/MyAIChatIntro";

import useResponsiveView from "../../../libs/hooks/useResonsiveView";
import clsx from "clsx";
import ScrollButton from "../chat/previewChat/ScrollButton";

const { SUPPORTED_BY_DOOSAN, EMPTY_CHAT_INDEX } = CHAT;
// const encoding = encoding_for_model("gpt-3.5-turbo");
const removeSupportedText = (content: string) =>
  content.replace(SUPPORTED_BY_DOOSAN, "");

const PreviewChat = () => {
  const { isMobile, isTablet, isDesktop } = useResponsiveView();
  const windowSize = useWindowResize();
  const dispatch = useAppDispatch();
  const promptText = useSelector(selectPromptText);
  const teamsTheme = useSelector(selectTeamsTheme);
  const selectChatId = useSelector(selectSelectedChatId);
  const firstChatId = useSelector(selectFirstSelectedChatId);
  const chatList = useSelector(selectChatList);
  const fileCards = useSelector(selectFileCards);
  const isContractAgreed = useSelector(selectIsContractAgreed);
  const myAIChatForm = useSelector(selectMyAIChatForm);
  const fileInfoList = useSelector(selectFileInfoList);
  const modelEngine = useSelector(selectModelEngine);
  const assistantTypeInfo = useSelector(selectAssistantTypeInfo);
  const temperature = useSelector(selectTemperature);

  const updateMyGptLoading = useSelector(selectUpdateMyGPTsLoading);
  const chatLoading = useSelector(selectChatLoading);
  const previewChatTextContainerRef = useRef<HTMLDivElement>(null);
  const previewChatIntroContainerRef = useRef<HTMLDivElement>(null);
  const selectedMyGptId = useSelector(selectSelectedPreviewMyGptId);

  const [scrollDown, setScrollDown] = useState(false);
  // const [tokenCount, setTokenCount] = useState(4000);

  // // 토큰 계산
  // useEffect(() => {
  //   const token = encoding.encode(promptText);
  //   setTokenCount(token.length);
  // }, [promptText]);

  useEffect(() => {
    return () => {
      dispatch(clearChat({ clear: true }));
      // dispatch(setModelEngine("4.0v"));
    };
  }, []);

  // useLayoutEffect(() => {
  //   if (
  //     !previewChatTextContainerRef?.current ||
  //     !previewChatIntroContainerRef?.current
  //   )
  //     return;
  //   if (!windowSize.width || !windowSize.height) return;

  //   previewChatIntroContainerRef.current.style.top = "40% !important";
  //   const textareaHeight = previewChatTextContainerRef.current.offsetHeight;
  //   const introHeight = previewChatIntroContainerRef.current.offsetHeight;
  //   const introTop = previewChatIntroContainerRef.current.offsetTop;

  //   if (isDesktop) {
  //     if (textareaHeight > introHeight / 2 - 94) {
  //       const top = introTop + (introHeight / 2 - 94 - textareaHeight);
  //       previewChatIntroContainerRef.current.style.top = `${top}px`;
  //       return;
  //     }
  //   }
  //   if (isMobile || isTablet) {
  //     const isInitTabMenu =
  //       textareaHeight === 0 && introHeight === 0 && introTop === 0;
  //     if (isInitTabMenu) return;

  //     const tempNum = isMobile ? 50 : 120;
  //     if (textareaHeight > introHeight / 2 - tempNum) {
  //       const top = introTop + (introHeight / 2 - tempNum - textareaHeight);

  //       previewChatIntroContainerRef.current.style.top = `${top}px`;
  //       return;
  //     }
  //   }
  // }, [promptText, fileCards, windowSize]);

  useEffect(() => {
    setScrollDown(false);
  }, [selectChatId]);

  const requestRegenerateChat = (isInitChat: boolean, listId: string) => {
    const lastChat = chatList[chatList.length - 1];
    const regeneratePayload = {
      // listId: "",
      prompt: removeSupportedText(lastChat.question),
      gptVersion: modelEngine,
      temperature,
      fileInfo: fileInfoList,
      assistantType: assistantTypeInfo.assistantType,
      myGPTsId: myAIChatForm.id,
      itemId: lastChat._id,
    };

    if (isInitChat) {
      // 채팅방의 첫 질문이고, 선택된 챗이 없을 때 실행.  listId의 여부로 달라진다
      dispatch(regenerateAnswerAsync.request(regeneratePayload));
      return;
    }

    dispatch(
      regenerateAnswerAsync.request({
        ...regeneratePayload,
        listId,
        // itemId: regeneratePayload.itemId,
      })
    );
  };

  const submitChatHandler = (isRegenerate?: boolean) => {
    // 채팅 버전에 맞춰진 토큰개수가 초과되면 early return
    // if (getMaxTokenNumberByVersion(modelEngine) < tokenCount) return;

    if (updateMyGptLoading) return;
    if (chatLoading) return;

    // input창의 질문을 삭제하고, 채팅 타입을 3.5v로 변경
    dispatch(setPromptText(""));
    dispatch(setInputUploadFiles(null));

    // 내용이 없을 경우 return
    if (promptText.replace(/\s/gi, "").length === 0) {
      return;
    }

    postGPTChat();
  };

  const postGPTChat = (isRegenerate?: boolean) => {
    const isInitChat =
      firstChatId === EMPTY_CHAT_INDEX && selectChatId === EMPTY_CHAT_INDEX;
    const existedChatId =
      selectChatId === EMPTY_CHAT_INDEX ? firstChatId : selectChatId;

    if (isRegenerate) {
      requestRegenerateChat(isInitChat, existedChatId);
      return;
    }
    const chatPayload = {
      prompt: removeSupportedText(promptText),
      gptVersion: modelEngine,
      temperature,
      listId: existedChatId === EMPTY_CHAT_INDEX ? null : existedChatId,
      fileInfo: fileInfoList,
      assistantType: assistantTypeInfo.assistantType,
      myGPTsId: myAIChatForm.id,
      isPreviewChat: true,
    };

    if (isInitChat) {
      dispatch(getAnswerAsync.request(chatPayload));
      return;
    }

    dispatch(
      getAnswerAsync.request({
        ...chatPayload,
        listId: existedChatId,
      })
    );
  };

  const handleScroll = () => {
    const scrollBody = document.getElementById("preview_chat_body");

    const scrollTop = scrollBody?.scrollTop;
    const scrollHeight = scrollBody?.scrollHeight;
    const clientHeight = scrollBody?.clientHeight;

    if (scrollTop && scrollHeight && clientHeight) {
      if (scrollTop + clientHeight <= scrollHeight - 100) {
        setScrollDown(true);
      } else {
        setScrollDown(false);
      }
    }
  };

  useEffect(() => {
    const scrollBody = document.getElementById("preview_chat_body");

    if (scrollBody) {
      const maxScrollHeight = scrollBody.scrollHeight - scrollBody.clientHeight;
      scrollBody.scrollTop = maxScrollHeight;
    }
  }, [chatList]);

  return (
    <>
      {!isContractAgreed && <InitialConsentPopup />}
      <div
        className={clsx(`preview_chat-page_container ${teamsTheme}`, {
          "editor_desktop_width": isDesktop,
          "tablet_mobile_chat_page_container": isMobile || isTablet,
        })}
      >
        {chatList.length === 0 && (
          <MyAIChatIntro ref={previewChatIntroContainerRef} />
        )}

        <div className="preview_chat_reading_container scroll_none">
          {chatList.length !== 0 && (
            <div
              className="preview_chat_reading_view"
              onScroll={handleScroll}
              id="preview_chat_body"
            >
              <ChatContent />
            </div>
          )}
        </div>

        <div
          className="preview_chat_writing_container"
          ref={previewChatTextContainerRef}
        >
          <div className="preview_chat_writing_view">
            {/* <ChatControlButton addChatHandler={submitChatHandler} /> */}
            {scrollDown && chatList.length > 0 && <ScrollButton />}
            <div className="toggle_margin">
              <PreviewSelectSearchTypeList />
            </div>
            <ChatInputForm addChatHandler={submitChatHandler} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewChat;
