import "./admin.scss";
import { useEffect, useState } from "react";
import Pagenator from "../../components/common/Pagenator";
import AdminMgmtModal from "../../components/admin/AdminMgmtModal";
import { useAppDispatch } from "../../../features";
import DeleteModal from "../../components/common/DeleteModal";
import {
  AdminUserData,
  deleteAdminUserDataAsync,
  getAdminUserDataAsync,
  selectAdminPageIndex,
  selectAdminTotalCnt,
  selectAdminUserData,
} from "../../../features/admin";
import { useSelector } from "react-redux";
import { transformDate } from "../../../libs/transformDate";
import { BsCheckLg } from "react-icons/bs";
import { selectTeamsTheme } from "../../../features/auth";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import FilterInputTextType from "../../components/userInterface/filters/FilterInputTextType";
import TableBtn from "../../components/userInterface/buttons/TableBtn";
import MobileGridBox from "../../components/mobile/admin/MobileGridBox";

// ❓ userData 확인해보기 (0509)
const AdminMgmtPage = () => {
  const dispatch = useAppDispatch();
  const userData = useSelector(selectAdminUserData);
  const totalCount = useSelector(selectAdminTotalCnt);
  const adminPageIndex = useSelector(selectAdminPageIndex);
  const teamsTheme = useSelector(selectTeamsTheme);

  const [pageIndex, setPageIndex] = useState(1);
  const [addModal, setAddModal] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState<AdminUserData | null>(null);
  const [searchText, setSearchText] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [pageSize, setPageSize] = useState(window.innerWidth <= 1024 ? 6 : 10);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // 첫 페이지 로딩시 데이터 호출
  useEffect(() => {
    getAdaminUserData();

    // window 사이즈에 따라 불러오는 데이터 갯수 변경
    if (window.innerWidth <= 1024) {
      setPageSize(6);
    } else {
      setPageSize(10);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setPageIndex(adminPageIndex);
  }, [adminPageIndex]);

  // window 사이즈에 따라 불러오는 데이터 갯수 변경
  useEffect(() => {
    if (windowWidth <= 1024) {
      setPageSize(6);
    } else {
      setPageSize(10);
    }
  }, [windowWidth]);

  useEffect(() => {
    getAdaminUserData();
  }, [pageSize]);

  // 데이터 호출
  const getAdaminUserData = (idx?: number, deleteSearch?: boolean) => {
    dispatch(
      getAdminUserDataAsync.request({
        pageIndex: idx ? idx : pageIndex,
        pageSize: pageSize,
        searchText: deleteSearch ? "" : searchText,
      })
    );

    if (deleteSearch) {
      setSearchText("");
    }
  };

  // 페이지 변경시 데이터 호출
  const setCurrentPage = (currentPage: number) => {
    setPageIndex(currentPage);
    getAdaminUserData(currentPage);
  };

  // 삭제
  const deleteHandler = () => {
    if (selectedData && selectedData._id) {
      dispatch(
        deleteAdminUserDataAsync.request({
          _id: selectedData._id,
          length: userData.length,
          pageIndex: pageIndex,
          searchText: searchText,
          pageSize,
        })
      );

      setDeleteModal(false);
    }
  };

  return (
    <>
      {addModal && (
        <AdminMgmtModal onClose={() => setAddModal(false)} title="Register" />
      )}
      {modifyModal && selectedData && (
        <AdminMgmtModal
          onClose={() => setModifyModal(false)}
          modifyData={selectedData}
          title="Modify"
        />
      )}
      {deleteModal && selectedData && (
        <DeleteModal
          deleteHandler={deleteHandler}
          onClose={() => setDeleteModal(false)}
        />
      )}
      <div className={`mgmt_page scroll_none`}>
        <hgroup>
          <h3 className={`page_title page_title_${teamsTheme}`}>
            Admin Management
          </h3>

          <MainColorBtn
            onClickHandler={() => setAddModal(true)}
            btnName="Register"
          />
        </hgroup>

        <div className={`mgmt_page_filter mgmt_page_filter_${teamsTheme}`}>
          <FilterInputTextType
            title="Search"
            value={searchText}
            onChangeHandler={(e) => setSearchText(e.target.value)}
            deleteInputHandler={() => setSearchText("")}
          />

          <div className="filter_tool_btns">
            <MainColorBtn
              btnName="Search"
              onClickHandler={() => getAdaminUserData(1)}
            />
          </div>
        </div>

        <table className={`page_table page_table-${teamsTheme}`} width="500">
          {/* <table className={`page_table page_table_${teamsTheme}`}> */}
          <colgroup>
            <col width="10%" />
            <col width="20%" />
            <col width="15%" />
            <col width="15%" />
            <col width="12%" />
            <col width="12%" />
            <col width="8%" />
            <col width="150px" />
          </colgroup>
          <thead className={`thead_${teamsTheme}`}>
            <tr>
              <th title="Name">Name</th>
              <th title="Code">Code</th>
              <th title="Description">Description</th>
              <th title="CreateBy">CreateBy</th>
              <th title="CreateDate">CreateDate</th>
              <th title="UpdateDate">UpdateDate</th>
              <th title="Activated">Activated</th>
              <th></th>
            </tr>
          </thead>
          <tbody className={`tbody_${teamsTheme}`}>
            {userData.length > 0 ? (
              userData.slice(0, 10).map((item) => (
                <tr>
                  <td className="ellipsis" title={item.userName}>
                    {item.userName}
                  </td>
                  <td className="ellipsis" title={item.userId}>
                    {item.userId}
                  </td>
                  <td className="ellipsis" title={item.userDescription}>
                    {item.userDescription ? item.userDescription : "-"}
                  </td>
                  <td className="ellipsis" title={item.createBy}>
                    {item.createBy}
                  </td>
                  <td
                    className="ellipsis"
                    title={
                      item.createDate ? transformDate(item.createDate) : ""
                    }
                  >
                    {item.createDate ? transformDate(item.createDate) : "-"}
                  </td>
                  <td
                    className="ellipsis"
                    title={
                      item.updateDate ? transformDate(item.updateDate) : ""
                    }
                  >
                    {item.updateDate ? transformDate(item.updateDate) : "-"}
                  </td>
                  <td className="ellipsis">
                    {item.activated ? <BsCheckLg className="check_icon" /> : ""}
                  </td>
                  <td className="td_btn_wrap">
                    <TableBtn
                      onClickHandler={() => {
                        setModifyModal(true);
                        setSelectedData(item);
                      }}
                      btnName="modify"
                    />
                    <TableBtn
                      onClickHandler={() => {
                        setDeleteModal(true);
                        setSelectedData(item);
                      }}
                      btnName="delete"
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="no_data">
                <td colSpan={8}>No Data</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="mobile_grid_box_wrap">
          {userData.map((user) => (
            <MobileGridBox
              data={{
                Name: user.userName,
                Code: user.userId,
                Description: user.userDescription,
                CreateBy: user.createBy,
                CreateDate: user.createDate
                  ? transformDate(user.createDate)
                  : "-",
                UpdateDate: user.updateDate
                  ? transformDate(user.updateDate)
                  : "-",
                Activated: user.activated ? (
                  <BsCheckLg className="check_icon" />
                ) : (
                  "-"
                ),
              }}
              modifyHandler={() => {
                setModifyModal(true);
                setSelectedData(user);
              }}
              deleteHandelr={() => {
                setDeleteModal(true);
                setSelectedData(user);
              }}
            />
          ))}
        </div>

        <Pagenator
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={pageIndex}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default AdminMgmtPage;
