import axios from "axios";

export interface GetAccessDataParam {
  pageIndex: number;
  pageSize: number;
  searchText: string;
}

export interface GetAccessCompanyDataResult {
  totalCount: number;
  codeList: AccessCompanyData[];
  searchText: string;
  pageIndex: number;
}

export interface GetAccessDeptDataResult {
  totalCount: number;
  codeList: AccessDeptData[];
  searchText: string;
  pageIndex: number;
}

export interface GetAccessUserDataResult {
  totalCount: number;
  codeList: AccessUserData[];
  searchText: string;
  pageIndex: number;
}

export interface AccessCompanyData {
  _id?: string;
  compName: string;
  compCode: string;
  compDescription: string;
  activated: boolean;
  createBy?: string;
  createDate?: string;
  updateDate?: string;
  gptModels: string[];
  canAnalyzeFile: boolean;
}

export interface AccessUserData {
  _id?: string;
  userName: string;
  userId: string;
  userDescription: string;
  activated: boolean;
  createBy?: string;
  createDate?: string;
  updateDate?: string;
  gptModels: string[];
  canAnalyzeFile: boolean;
}

export interface AccessDeptData {
  _id?: string;
  deptName: string;
  deptCode: string;
  deptDescription: string;
  activated: boolean;
  createBy?: string;
  createDate?: string;
  updateDate?: string;
  gptModels: string[];
  canAnalyzeFile: boolean;
}

export interface AddAccessCompanyData {
  data: AccessCompanyData;
  searchText: string;
  pageSize: number;
}

export interface AddAccessDeptData {
  data: AccessDeptData;
  searchText: string;
  pageSize: number;
}

export interface AddAccessUserData {
  data: AccessUserData;
  searchText: string;
  pageSize: number;
}

export interface DeleteAccessaParam {
  _id: string;
  length: number;
  pageIndex: number;
  searchText: string;
  pageSize: number;
}

//! admin user mgmt
export interface GetAdminUserDataParam {
  pageIndex: number;
  pageSize: number;
  searchText: string;
}

export interface GetAdminUserDataResult {
  totalCount: number;
  codeList: AdminUserData[];
  searchText: string;
  pageIndex: number;
}

export interface AddAdminUserData {
  data: AdminUserData;
  searchText: string;
  pageSize: number;
}

export interface AdminUserData {
  _id?: string;
  userName: string;
  userId: string;
  activated: boolean;
  userDescription: string;
  createBy?: string;
  createDate?: string;
  updateDate?: string;
}

export interface DeleteAdminUserParam {
  _id: string;
  length: number;
  pageIndex: number;
  searchText: string;
  pageSize: number;
}

//! company access
export const getAccessCompanyData = async (param: GetAccessDataParam) => {
  const res = await axios.get(
    `/auth/getCompCode/?skip=${param.pageIndex}&limit=${param.pageSize}&searchText=${param.searchText}`
  );

  return {
    ...res.data,
    searchText: param.searchText,
    pageIndex: param.pageIndex,
  };
};

export const addAccessCompanyData = async (param: AccessCompanyData) => {
  const res = await axios.post(`/auth/addCompCode`, param);

  return res.data;
};

export const updateAccessCompanyData = async (param: AccessCompanyData) => {
  const res = await axios.put(`/auth/updateCompCode`, param);

  return res.data;
};

export const deleteAccessCompanyData = async (param: DeleteAccessaParam) => {
  const res = await axios.delete(`/auth/deleteCompCode/${param._id}`);

  return res;
};

//! dept access
export const getAccessDeptData = async (param: GetAccessDataParam) => {
  const res = await axios.get(
    `/auth/getDeptCode/?skip=${param.pageIndex}&limit=${param.pageSize}&searchText=${param.searchText}`
  );

  return {
    ...res.data,
    searchText: param.searchText,
    pageIndex: param.pageIndex,
  };
};

export const addAccessDeptData = async (param: AccessDeptData) => {
  const res = await axios.post(`/auth/addDeptCode`, param);

  return res.data;
};

export const updateAccessDeptData = async (param: AccessDeptData) => {
  const res = await axios.put(`/auth/updateDeptCode`, param);

  return res.data;
};

export const deleteAccessDeptData = async (param: DeleteAccessaParam) => {
  const res = await axios.delete(`/auth/deleteDeptCode/${param._id}`);

  return res;
};

//! user access
export const getAccessUserData = async (param: GetAccessDataParam) => {
  const res = await axios.get(
    `/auth/getUserCode/?skip=${param.pageIndex}&limit=${param.pageSize}&searchText=${param.searchText}`
  );

  return {
    ...res.data,
    searchText: param.searchText,
    pageIndex: param.pageIndex,
  };
};

export const addAccessUserData = async (param: AccessUserData) => {
  const res = await axios.post(`/auth/addUserCode`, param);

  return res.data;
};

export const updateAccessUserData = async (param: AccessUserData) => {
  const res = await axios.put(`/auth/updateUserCode`, param);

  return res.data;
};

export const deleteAccessUserData = async (param: DeleteAccessaParam) => {
  const res = await axios.delete(`/auth/deleteUserCode/${param._id}`);

  return res;
};

//! admin mgmt
export const getAdminUserData = async (param: GetAdminUserDataParam) => {
  const res = await axios.get(
    `/auth/getAdmin/?skip=${param.pageIndex}&limit=${param.pageSize}&searchText=${param.searchText}`
  );

  return {
    ...res.data,
    searchText: param.searchText,
    pageIndex: param.pageIndex,
  };
};

export const addAdminUserData = async (param: AddAdminUserData) => {
  const res = await axios.post(`/auth/addAdmin`, param);

  return res.data;
};

export const updateAdminUserData = async (param: AdminUserData) => {
  const res = await axios.put(`/auth/updateAdmin`, param);

  return res.data;
};

export const deleteAdminUserData = async (param: DeleteAdminUserParam) => {
  const res = await axios.delete(`/auth/deleteAdmin/${param._id}`);

  return res;
};
