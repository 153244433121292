import "./SelectChatModel.scss";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../features";
import {
  selectAssistantTypeInfo,
  selectChatLoading,
  selectModelEngine,
  setAssistantTypeInfo,
  setInputUploadFiles,
} from "../../../features/chat";
import { selectTeamsTheme } from "../../../features/auth";
import {
  GPT4_ASSISTANT_TYPE_LIST,
  IAssistantTypeItem,
  MY_ASSISTANT_TYPE_LIST,
} from "../../../constants/chat";
import parse from "html-react-parser";
import { clsx } from "clsx";
import { selectQAItemLoading } from "../../../features/ui_loading";
import useResponsiveView from "../../../libs/hooks/useResonsiveView";
import { useEffect, useState } from "react";
import { useIsCanAnalyzeFile } from "../../../libs/hooks/useIsCanAnalyzeFile";

const SelectSearchTypeItem: React.FC<IAssistantTypeItem> = ({
  title,
  description,
  assistantType,
  mobileTitle,
}) => {
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const { isMobile } = useResponsiveView();
  const assistantInfo = useSelector(selectAssistantTypeInfo);
  const chatLoading = useSelector(selectChatLoading);
  const qaLoading = useSelector(selectQAItemLoading);

  const changeType = () => {
    dispatch(
      setAssistantTypeInfo({
        ...assistantInfo,
        assistantType,
      })
    );

    if (assistantType !== "FILE") {
      dispatch(setInputUploadFiles(null));
    }
  };

  return (
    <div className="tooltip_wrap">
      <button
        onClick={changeType}
        disabled={chatLoading || qaLoading}
        className={clsx(`search_type-button ${teamsTheme}`, {
          [`assistant_type-selected ${teamsTheme}`]:
            assistantType === assistantInfo.assistantType,
          "assistant_type_disabled_button": chatLoading || qaLoading,
        })}
      >
        {isMobile ? mobileTitle : title}
      </button>
      <div className={`tooltip ${teamsTheme}`}>{parse(description)}</div>
    </div>
  );
};

const SelectSearchTypeList = () => {
  const modelEngine = useSelector(selectModelEngine);
  const assistantInfo = useSelector(selectAssistantTypeInfo);
  const [assistantList, setAssistantList] = useState<IAssistantTypeItem[]>([]);
  const isCanAnalyzeFile = useIsCanAnalyzeFile();

  useEffect(() => {
    if (modelEngine === "myGPTs") {
      if (!isCanAnalyzeFile) {
        setAssistantList(
          MY_ASSISTANT_TYPE_LIST.filter((elem) => elem.assistantType !== "FILE")
        );
        return;
      }
      setAssistantList(MY_ASSISTANT_TYPE_LIST);
    }
    if (modelEngine === "4.0v") {
      if (!isCanAnalyzeFile) {
        setAssistantList(
          GPT4_ASSISTANT_TYPE_LIST.filter(
            (elem) => elem.assistantType !== "FILE"
          )
        );
        return;
      }
      setAssistantList(GPT4_ASSISTANT_TYPE_LIST);
    }
  }, [modelEngine, isCanAnalyzeFile]);

  if (modelEngine === "myGPTs") {
    if (
      assistantInfo.assistantType === "DEFAULT" &&
      assistantInfo.capabilities?.length === 1
    ) {
      return (
        <>
          <div className="select_search_type_container">
            <div className="tooltip_wrap">
              <button className="hidden_button">Not used</button>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className="select_search_type_container">
        {assistantList
          .filter((data) =>
            assistantInfo.capabilities?.includes(data.assistantType)
          )
          .map((elem, idx) => (
            <SelectSearchTypeItem
              key={idx}
              title={elem.title}
              mobileTitle={elem.mobileTitle}
              description={elem.description}
              assistantType={elem.assistantType}
            />
          ))}
      </div>
    );
  }

  if (modelEngine === "4.0v") {
    return (
      <div className="select_search_type_container">
        {assistantList.map((elem, idx) => (
          <SelectSearchTypeItem
            key={idx}
            title={elem.title}
            mobileTitle={elem.mobileTitle}
            description={elem.description}
            assistantType={elem.assistantType}
          />
        ))}
      </div>
    );
  }
  return <></>;
};

export default SelectSearchTypeList;
