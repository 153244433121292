import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import "./inputs.scss";
import { memo } from "react";

type Props = {
  value: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickHandler?: () => void;
  placeholder?: string;
};

const InputTextType: React.FC<Props> = ({
  value,
  onChangeHandler,
  onClickHandler,
  placeholder,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <input
      className={`input_text input_theme_${teamsTheme}`}
      placeholder={placeholder ? placeholder : "Search"}
      value={value}
      onChange={onChangeHandler}
      onClick={onClickHandler}
      type="text"
    />
  );
};

export default memo(InputTextType);
