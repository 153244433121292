import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectTeamsTheme } from "../../../../features/auth";
import { memo } from "react";

type Props = {
  tabName: string;
  selectedTab: string;
  value: string;
  onClickHandler: (value: string) => void;
  pathName?: string;
  loading?: boolean;
  isDisabled?: boolean;
};

const TabBtn: React.FC<Props> = ({
  tabName,
  selectedTab,
  value,
  onClickHandler,
  pathName,
  loading,
  isDisabled,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  if (pathName && !isDisabled) {
    return (
      <button
        className={`tab_btn ellipsis tab_btn_${teamsTheme} ${
          selectedTab === value && "tab_select"
        }`}
        onClick={() => {
          if (loading) return;
          onClickHandler(value);
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "progress",
            }}
          >
            <span>{tabName}</span>
          </div>
        ) : (
          <Link to={pathName}>{tabName}</Link>
        )}
      </button>
    );
  }

  return (
    <button
      className={`tab_btn tab_btn_${teamsTheme} ${
        selectedTab === value && "tab_select"
      }`}
      disabled={isDisabled}
      onClick={() => {
        if (loading) return;
        onClickHandler(value);
      }}
    >
      <span>{tabName}</span>
    </button>
  );
};

export default memo(TabBtn);
