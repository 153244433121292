import { memo } from "react";

type Props = {
  checked: boolean;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxId?: string;
  disabled?: boolean;
};

const InputCheckboxType: React.FC<Props> = ({
  checked,
  onChangeHandler,
  checkboxId,
  disabled,
}) => {
  return (
    <input
      className={`input_checkbox_radio`}
      checked={checked}
      onChange={onChangeHandler}
      type="checkbox"
      id={checkboxId ? checkboxId : ""}
      disabled={disabled}
    />
  );
};

export default memo(InputCheckboxType);
