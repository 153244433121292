import { createReducer } from "typesafe-actions";
import produce from "immer";
import { AdminState, AdminAction } from "./types";
import {
  addAccessCompanyDataAsync,
  addAccessDeptDataAsync,
  addAccessUserDataAsync,
  addAdminUserDataAsync,
  deleteAccessCompanyDataAsync,
  deleteAccessDeptDataAsync,
  deleteAccessUserDataAsync,
  deleteAdminUserDataAsync,
  getAccessCompanyDataAsync,
  getAccessDeptDataAsync,
  getAccessUserDataAsync,
  getAdminUserDataAsync,
  updateAccessCompanyDataAsync,
  updateAccessDeptDataAsync,
  updateAccessUserDataAsync,
  updateAdminUserDataAsync,
} from "./actions";

const initialState = {
  accessUserData: [],
  accessCompanyData: [],
  accessDeptData: [],
  accessTotalCount: 0,
  searchAccessData: "",
  accessPageIndex: 1,
  adminUserData: [],
  adminTotalCount: 0,
  searchAdminUserData: "",
  adminPageIndex: 1,
};

const admin = createReducer<AdminState, AdminAction>(initialState)
  .handleAction(getAccessCompanyDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessCompanyData = payload.codeList;
      draft.accessTotalCount = payload.totalCount;
      draft.searchAccessData = payload.searchText;
      draft.accessPageIndex = payload.pageIndex;
    });
  })
  .handleAction(addAccessCompanyDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessCompanyData = [payload, ...state.accessCompanyData];
      draft.accessTotalCount = state.accessTotalCount + 1;
    });
  })
  .handleAction(updateAccessCompanyDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessCompanyData = state.accessCompanyData.map((item) =>
        item._id === payload._id ? payload : item
      );
    });
  })
  .handleAction(deleteAccessCompanyDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessCompanyData = state.accessCompanyData.filter(
        (item) => item._id !== payload._id
      );
      draft.accessTotalCount = state.accessTotalCount - 1;
    });
  })
  .handleAction(getAccessDeptDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessDeptData = payload.codeList;
      draft.accessTotalCount = payload.totalCount;
      draft.searchAccessData = payload.searchText;
      draft.accessPageIndex = payload.pageIndex;
    });
  })
  .handleAction(addAccessDeptDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessDeptData = [payload, ...state.accessDeptData];
      draft.accessTotalCount = state.accessTotalCount + 1;
    });
  })
  .handleAction(updateAccessDeptDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessDeptData = state.accessDeptData.map((item) =>
        item._id === payload._id ? payload : item
      );
    });
  })
  .handleAction(deleteAccessDeptDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessDeptData = state.accessDeptData.filter(
        (item) => item._id !== payload._id
      );
      draft.accessTotalCount = state.accessTotalCount - 1;
    });
  })
  .handleAction(getAccessUserDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessUserData = payload.codeList;
      draft.accessTotalCount = payload.totalCount;
      draft.searchAccessData = payload.searchText;
      draft.accessPageIndex = payload.pageIndex;
    });
  })
  .handleAction(addAccessUserDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessUserData = [payload, ...state.accessUserData];
      draft.accessTotalCount = state.accessTotalCount + 1;
    });
  })
  .handleAction(updateAccessUserDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessUserData = state.accessUserData.map((item) =>
        item._id === payload._id ? payload : item
      );
    });
  })
  .handleAction(deleteAccessUserDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.accessUserData = state.accessUserData.filter(
        (item) => item._id !== payload._id
      );
      draft.accessTotalCount = state.accessTotalCount - 1;
    });
  })
  .handleAction(getAdminUserDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.adminUserData = payload.codeList;
      draft.adminTotalCount = payload.totalCount;
      draft.searchAdminUserData = payload.searchText;
      draft.adminPageIndex = payload.pageIndex;
    });
  })
  .handleAction(addAdminUserDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.adminUserData = [payload, ...state.adminUserData];
      draft.adminTotalCount = state.adminTotalCount + 1;
    });
  })
  .handleAction(updateAdminUserDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.adminUserData = state.adminUserData.map((item) =>
        item._id === payload._id ? payload : item
      );
    });
  })
  .handleAction(deleteAdminUserDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.adminUserData = state.adminUserData.filter(
        (item) => item._id !== payload._id
      );
      draft.adminTotalCount = state.adminTotalCount - 1;
    });
  });

export default admin;
