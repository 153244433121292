import { v4 as uuidv4 } from "uuid";
import { getNameAndExtension, isImageFile } from "../../services/FileUpload";
import {
  IUploadFileInfo,
  ONLY_CLIENT_FILE,
  deleteUploadFileAsync,
  selectAssistantTypeInfo,
  selectFirstSelectedChatId,
  selectModelEngine,
  selectSelectedChatId,
  setInputUploadFiles,
  uploadFileAsync,
} from "../../features/chat";
import { useAppDispatch } from "../../features";
import mime from "mime";
import { useSelector } from "react-redux";
import CHAT from "../../constants/chat";
import {
  selectMyAIChatForm,
  selectSelectedPreviewMyGptId,
} from "../../features/myGpts";
import { useLocation } from "react-router-dom";
import { openAlert } from "../../features/alert";
import { useState } from "react";
const { EMPTY_CHAT_INDEX } = CHAT;

export const useFileUpload = () => {
  const dispatch = useAppDispatch();
  const selectChatId = useSelector(selectSelectedChatId);
  const firstChatId = useSelector(selectFirstSelectedChatId);
  const assistantTypeInfo = useSelector(selectAssistantTypeInfo);
  const location = useLocation();
  const modelEngine = useSelector(selectModelEngine);
  const selectedMyGptId = useSelector(selectSelectedPreviewMyGptId);
  const [formDataList, setFormDataList] = useState<IUploadFileInfo[]>([]);

  // const uploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
  const uploadFiles = (fileList: FileList) => {
    if (fileList.length === 0) {
      return;
    }
    const dataList = [];
    const isInitChat =
      firstChatId === EMPTY_CHAT_INDEX && selectChatId === EMPTY_CHAT_INDEX;
    const existedChatId =
      selectChatId === EMPTY_CHAT_INDEX ? firstChatId : selectChatId;

    for (const file of fileList) {
      let imgUrl = "";
      let extension = "";
      if (file.type) {
        extension = mime.getExtension(file.type) ?? "";
      } else {
        const [, fileExtension] = getNameAndExtension(file.name);
        extension = fileExtension;
      }

      if (isImageFile(extension)) {
        imgUrl = URL.createObjectURL(file);
      }

      const formData = new FormData();
      const listId = existedChatId === EMPTY_CHAT_INDEX ? null : existedChatId;

      if (location.pathname.includes("my_chat_mgmt")) {
        if (!selectedMyGptId) {
          dispatch(openAlert({ alertMsg: "myGPTs를 먼저 선택해주세요" }));
          return;
        }
        formData.append("myGPTsId", selectedMyGptId);
      } else if (modelEngine === "myGPTs") {
        if (!assistantTypeInfo.myGPTsId) {
          dispatch(openAlert({ alertMsg: "myGPTs를 먼저 선택해주세요" }));
          return;
        }
        formData.append("myGPTsId", assistantTypeInfo.myGPTsId);
      }

      if (listId) {
        formData.append("listId", listId);
      }

      formData.append("file", file);

      dispatch(
        setInputUploadFiles({
          fileId: ONLY_CLIENT_FILE,
          name: file.name,
          extension: extension,
          imgUrl,
          isLoading: true,
        })
      );

      setFormDataList((formDataList) => {
        const uploadList = [
          ...formDataList,
          {
            param: formData,
            uploadFileData: {
              fileCards: {
                name: file.name,
                extension: extension,
                imgUrl,
                isLoading: true,
              },
              fileData: file,
            },
          },
        ];
        return uploadList;
      });

      dataList.push({
        param: formData,
        uploadFileData: {
          fileCards: {
            name: file.name,
            extension: extension,
            imgUrl,
            isLoading: true,
          },
          fileData: file,
        },
      });
    }

    if (isInitChat) {
      dispatch(
        uploadFileAsync.request({
          fileList: dataList,
        })
      );
    } else if (existedChatId) {
      dispatch(
        uploadFileAsync.request({
          fileList: dataList,
        })
      );
    }
  };

  const deleteFile = (fileId: string) => {
    dispatch(deleteUploadFileAsync.request(fileId));
  };

  return { uploadFiles, deleteFile };
};
