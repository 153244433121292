import { useEffect, useState } from "react";
import {
  GetEventLogDataParam,
  getEventLogDataAsync,
  selectEventLogData,
  selectEventLogTotalCnt,
} from "../../../features/log";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../features";
import Pagenator from "../../components/common/Pagenator";
import { localDate, transformDate } from "../../../libs/transformDate";
import moment from "moment";
import { selectTeamsTheme } from "../../../features/auth";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import "./admin.scss";
import NoneBackgroundBtn from "../../components/userInterface/buttons/NoneBackgroundBtn";
import FilterInputTextType from "../../components/userInterface/filters/FilterInputTextType";
import FilterPeriod from "../../components/userInterface/filters/FilterPeriod";

type Props = {
  inputs: GetEventLogDataParam;
  setInputs: React.Dispatch<React.SetStateAction<GetEventLogDataParam>>;
};

const LogEventMgmtPage: React.FC<Props> = ({ inputs, setInputs }) => {
  const dispatch = useAppDispatch();

  const eventLogData = useSelector(selectEventLogData);
  const teamsTheme = useSelector(selectTeamsTheme);

  const pageSize = 10;
  const totalCount = useSelector(selectEventLogTotalCnt);
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    getEventLogDataHandler(1);
  }, []);

  const setCurrentPage = (currentPage: number) => {
    setPageIndex(currentPage);
    getEventLogDataHandler(currentPage);
  };

  const resetHandler = () => {
    setInputs({
      userId: "",
      logLevel: "",
      event: "",
      startDate: null,
      endDate: null,
    });

    dispatch(
      getEventLogDataAsync.request({
        userId: "",
        logLevel: "",
        event: "",
        startDate: null,
        endDate: null,
        limit: pageSize,
        skip: 1,
      })
    );

    setPageIndex(1);
  };

  useEffect(() => {
    if (inputs.startDate && !inputs.endDate) {
      const newEndDate = new Date(inputs.startDate);
      newEndDate.setHours(23, 59, 59); // 마지막 시간으로 설정
      setInputs({
        ...inputs,
        endDate: newEndDate,
      });
    }

    if (
      inputs.startDate &&
      inputs.endDate &&
      inputs.startDate > inputs.endDate
    ) {
      const newEndDate = new Date(inputs.startDate);
      newEndDate.setHours(23, 59, 59); // 마지막 시간으로 설정
      setInputs({
        ...inputs,
        endDate: newEndDate,
      });
    }
  }, [inputs.startDate]);

  const getEventLogDataHandler = (idx?: number) => {
    dispatch(
      getEventLogDataAsync.request({
        ...inputs,
        limit: pageSize,
        skip: idx ? idx : pageIndex,
      })
    );

    if (idx) {
      setPageIndex(idx);
    }
  };

  return (
    <>
      <div className={`mgmt_page_filter mgmt_page_filter_${teamsTheme}`}>
        <FilterPeriod
          startDate={localDate(inputs.startDate)}
          endDate={localDate(inputs.endDate)}
          max={moment().format("YYYY-MM-DD")}
          startDateChangeHandler={(e) =>
            setInputs({
              ...inputs,
              startDate: new Date(e.target.value),
            })
          }
          endDateChangeHandler={(e) =>
            setInputs({
              ...inputs,
              endDate: new Date(e.target.value + "T23:59:59"),
            })
          }
        />

        <FilterInputTextType
          title="User Email"
          value={inputs.userId}
          onChangeHandler={(e) =>
            setInputs({
              ...inputs,
              userId: e.target.value,
            })
          }
          deleteInputHandler={() =>
            setInputs({
              ...inputs,
              userId: "",
            })
          }
        />

        <FilterInputTextType
          title="Log Level"
          value={inputs.logLevel}
          onChangeHandler={(e) =>
            setInputs({
              ...inputs,
              logLevel: e.target.value,
            })
          }
          deleteInputHandler={() =>
            setInputs({
              ...inputs,
              logLevel: "",
            })
          }
        />

        <FilterInputTextType
          title="Event"
          value={inputs.event}
          onChangeHandler={(e) =>
            setInputs({
              ...inputs,
              event: e.target.value,
            })
          }
          deleteInputHandler={() =>
            setInputs({
              ...inputs,
              event: "",
            })
          }
        />

        <div className="filter_tool_btns">
          <NoneBackgroundBtn onClickHandler={resetHandler} btnName="Reset" />
          <MainColorBtn
            onClickHandler={() => getEventLogDataHandler(1)}
            btnName="Search"
          />
        </div>
      </div>
      <table className={`page_table page_table-${teamsTheme}`} width="500">
        {/* <table className={`page_table page_table_${teamsTheme}`}> */}
        <colgroup>
          <col width="15%" />
          <col width="5%" />
          <col width="15%" />
          <col width="15%" />
          <col width="15%" />
          <col width="15%" />
          <col width="5%" />
        </colgroup>
        <thead className={`thead_${teamsTheme}`}>
          <tr>
            <th title="User Email">User Email</th>
            <th title="Log Level">Log Level</th>
            <th title="Event">Event</th>
            <th title="Activity">Activity</th>
            <th title="Error Detail">Error Detail</th>
            <th title="Timestamp">Timestamp</th>
            <th title="Latency">Latency</th>
          </tr>
        </thead>
        <tbody className={`tbody_${teamsTheme}`}>
          {eventLogData.length > 0 ? (
            eventLogData.map((item) => (
              <tr>
                <td className="line_change">{item.userId}</td>
                <td className="line_change">{item.logLevel}</td>
                <td className="line_change">{item.event}</td>
                <td className="line_change">{item.activity}</td>
                <td className="line_change">{item.error_detail}</td>
                <td className="line_change">{transformDate(item.timestamp)}</td>
                <td className="line_change">{item.latency}</td>
              </tr>
            ))
          ) : (
            <tr className="no_data">
              <td colSpan={7}>No Data</td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagenator
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={pageIndex}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default LogEventMgmtPage;
