import CloseSVG from "../../../components/chat/fileUpload/CloseSVG";

type Props = {
  htmlFor: string;
  label: string;
  placeholder: string;
  inputType: "default" | "textarea" | "removeInput";
  onChange: (e: any) => void;
  value: string;
};

const LabelAndInput: React.FC<Props> = ({
  htmlFor,
  label,
  placeholder,
  inputType,
  onChange,
  value,
}) => {
  return (
    <div className="form_item_wrap">
      <label htmlFor={htmlFor}>{label} </label>

      {inputType === "default" && (
        <input
          placeholder={placeholder}
          type="text"
          name={htmlFor}
          id={htmlFor}
          onChange={onChange}
          value={value}
        />
      )}
      {inputType === "textarea" && (
        <textarea
          placeholder={placeholder}
          name={htmlFor}
          id={htmlFor}
          onChange={onChange}
          value={value}
        />
      )}
      {inputType === "removeInput" && (
        <div className="remove_input_wrap">
          <input
            placeholder={placeholder}
            type="text"
            name={htmlFor}
            id={htmlFor}
            onChange={onChange}
            value={value}
          />
          <button className="close_button">
            <CloseSVG />
          </button>
        </div>
      )}
    </div>
  );
};

export default LabelAndInput;
