const IMG_MD_REGEX = /!\[.*?\]\(.*?\)/g;

const isImgMarkDown = (text: string) => {
  const res = IMG_MD_REGEX.test(text);

  return res;
};

export const useImgRegex = () => {
  const changeImgText = (text: string) => {
    if (isImgMarkDown(text)) {
      return text.replace(IMG_MD_REGEX, "[ 생성된 이미지 ]");
    } else {
      return text;
    }
  };

  return [changeImgText];
};
