import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../features";
import { selectTeamsTheme } from "../../../../../features/auth";
import {
  ModelInfo,
  RegisteredModelInfo,
  getModelsTagsAsync,
  selectModelTotalCnt,
  selectModelsInfo,
  selectModelsInfoTotalCnt,
} from "../../../../../features/model";
import ModalControlButtons from "./ModalControlButtons";
import { useEffect, useState } from "react";
import EndpointAllList from "./EndpointAllList";
import "../../MgmtModal.scss";
import Pagenator from "../../../common/Pagenator";
import SelectedEndpointInfo from "./SelectedEndpointInfo";
import { ENDPOINT_DEFAULT_PAGE_SIZE } from "../../../../pages/admin/ModelDevelopmentMgmtPage";
import { ModalTitleTypes } from "../../../../../features/admin";

type Props = {
  onClose: () => void;
  modifyData?: ModelInfo;
  title: ModalTitleTypes;
};

const EndpointManagementModal: React.FC<Props> = ({
  onClose,
  modifyData,
  title,
}) => {
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const totalCount = useSelector(selectModelsInfoTotalCnt);
  const modelList = useSelector(selectModelsInfo);

  const [pageCount, setPageCount] = useState(() => {
    if (title === "Register") {
      return 1;
    }
    if (title === "Modify") {
      return 2;
    }
    return 1;
  });
  const [pageIndex, setPageIndex] = useState(1);
  const [error, setError] = useState(false);
  const [allCheck, setAllCheck] = useState(false);
  const [selectDevelopmentIds, setSelectDevelopmentIds] = useState<
    { development: RegisteredModelInfo; tags: string[] }[]
  >([]);

  useEffect(() => {
    const modelListCopy = modelList
      .slice(pageIndex * 10 - 10, pageIndex * 10)
      .filter((model) => !model.isInDB);

    const includesModelIds = modelListCopy
      .filter((model) =>
        selectDevelopmentIds.some(
          (el) => el.development.deploymentId === model.deploymentId
        )
      )
      .filter((model) => !model.isInDB);

    if (modelListCopy.length === includesModelIds.length) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }
  }, [pageIndex]);

  const setCurrentPage = (currentPage: number) => {
    setPageIndex(currentPage);
  };

  useEffect(() => {
    if (modifyData) {
      setSelectDevelopmentIds([
        {
          development: {
            aoaiResourceName: modifyData.resourceName,
            deploymentId: modifyData.deploymentId,
            deploymentName: modifyData.deploymentName,
            modelName: modifyData.modelName,
            modelVersion: modifyData.modelVersion,
            isInDB: true,
          },
          tags: modifyData.tags,
        },
      ]);
    }

    getModelsTagsHandler();
  }, []);

  const getModelsTagsHandler = () => {
    dispatch(getModelsTagsAsync.request({}));
  };

  return (
    <div className={`modal_black_bg modal_black_bg-${teamsTheme}`}>
      <div className={`modal_white_bg wide_width modal_white_bg-${teamsTheme}`}>
        <hgroup>
          <h3>{title} Endpoint</h3>
        </hgroup>
        {pageCount === 1 && (
          <EndpointAllList
            pageIndex={pageIndex}
            allCheck={allCheck}
            setAllCheck={setAllCheck}
            selectDevelopmentIds={selectDevelopmentIds}
            setSelectDevelopmentIds={setSelectDevelopmentIds}
          />
        )}
        {pageCount === 2 && (
          <SelectedEndpointInfo
            selectDevelopmentIds={selectDevelopmentIds}
            setSelectDevelopmentIds={setSelectDevelopmentIds}
          />
        )}
        {pageCount === 1 && (
          <Pagenator
            totalCount={totalCount}
            pageSize={ENDPOINT_DEFAULT_PAGE_SIZE}
            currentPage={pageIndex}
            setCurrentPage={setCurrentPage}
          />
        )}
        {error && <p className="modal_error">Please enter required items.</p>}

        <ModalControlButtons
          pageCount={pageCount}
          setPageCount={setPageCount}
          title={title}
          onClose={onClose}
          selectDevelopmentIds={selectDevelopmentIds}
          modifyData={modifyData}
        />
      </div>
    </div>
  );
};

export default EndpointManagementModal;
