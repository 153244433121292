import axios from "axios";
import { getBatchMetadataApiPath, OuterServiceType } from "./types";

export interface ICompanyInfo {
  compName: string;
  compCode: string;
}

interface BatchBaseMetadataSearchInput extends ICompanyInfo {
  startDate: Date;
  endDate: Date;
  skip: number; //  currentPage
  limit: number; // pageSize(한 페이지에서 보이는 콘텐츠 수) // default: 10
}

// 서비스 타입별 검색 입력 인터페이스
export interface EmployeeBatchMetadataInput {
  siteId: string;
  siteName?: string;
}
export interface EapprovalBatchMetadataInput {
  downloadUrl: string;
}
export type EmployeeBatchMetadataSearchInput = BatchBaseMetadataSearchInput &
  EmployeeBatchMetadataInput;

export type EapprovalBatchMetadataSearchInput = BatchBaseMetadataSearchInput &
  EapprovalBatchMetadataInput;

// // 서비스 타입별 등록 및 수정 인터페이스
export interface EmployeeBatchMetadataRegisterInput {
  id?: string | null;
  companyInfo: ICompanyInfo[];
  siteId: string;
  siteName: string;
}

export interface EapprovalBatchMetadataRegisterInput {
  id?: string | null;
  companyInfo: ICompanyInfo[];
  downloadUrl: string;
}
export interface EmployeeBatchMetadataAddData {
  serviceType: "employee";
  param: EmployeeBatchMetadataRegisterInput;
}
export interface EapprovaleBatchMetadataAddData {
  serviceType: "eapproval";
  param: EapprovalBatchMetadataRegisterInput;
}

export interface EmployeeBatchMetadataRegisterInputPayload {
  addData: EmployeeBatchMetadataAddData;
  getData: GetBatchMetadataListPayload;
}
export interface EapprovalBatchMetadataRegisterInputPayload {
  addData: EapprovaleBatchMetadataAddData;
  getData: GetBatchMetadataListPayload;
}

export interface DeleteBatchMetadataPayload {
  deleteData: DeleteBatchMetadataDeleteData;
  getData: GetBatchMetadataListPayload;
}
export interface DeleteBatchMetadataDeleteData {
  serviceType: OuterServiceType;
  param: {
    _id: string;
  };
}
// 서비스 타입별 요청 페이로드
interface EmployeeGetBatchMetadataListPayload {
  serviceType: "employee";
  param: EmployeeBatchMetadataSearchInput;
}

interface EapprovalGetBatchMetadataListPayload {
  serviceType: "eapproval";
  param: EapprovalBatchMetadataSearchInput;
}

export type GetBatchMetadataListPayload =
  | EmployeeGetBatchMetadataListPayload
  | EapprovalGetBatchMetadataListPayload;

interface BatchBaseMetadataItem {
  _id: string | null;
  companyInfo: ICompanyInfo[];
  createDate: string | null;
  updateDate: string | null;
  triggerDate: string | null;
}

export type EmployeeBatchMetadataItem = BatchBaseMetadataItem &
  EmployeeBatchMetadataInput;

export type EapprovalBatchMetadataItem = BatchBaseMetadataItem &
  EapprovalBatchMetadataInput;

export interface BatchBaseMetadataList {
  totalCount: number;
  batchMetadataList:
    | EmployeeBatchMetadataItem[]
    | EapprovalBatchMetadataItem[]
    | [];
}

// }
export type SetBatchMetadataSearchInput = {
  [S in OuterServiceType]: {
    serviceType: S;
    key:
      | keyof EmployeeBatchMetadataSearchInput
      | keyof EapprovalBatchMetadataSearchInput;
    value:
      | EmployeeBatchMetadataSearchInput[keyof EmployeeBatchMetadataSearchInput]
      | EapprovalBatchMetadataSearchInput[keyof EapprovalBatchMetadataSearchInput];
  };
}[OuterServiceType];

export type SetBatchMetadataRegiserInputs = {
  [S in OuterServiceType]: {
    serviceType: S;
    key:
      | keyof EmployeeBatchMetadataRegisterInput
      | keyof EapprovalBatchMetadataRegisterInput;
    value:
      | EmployeeBatchMetadataRegisterInput[keyof EmployeeBatchMetadataRegisterInput]
      | EapprovalBatchMetadataRegisterInput[keyof EapprovalBatchMetadataRegisterInput];
  };
}[OuterServiceType];

export type SetBatchMetadataModifyItem =
  | {
      serviceType: "employee";
      item: EmployeeBatchMetadataItem;
    }
  | { serviceType: "eapproval"; item: EapprovalBatchMetadataItem };

export const getBatchMetadataList = async (
  payload: GetBatchMetadataListPayload
) => {
  const apiUrl = getBatchMetadataApiPath(payload.serviceType, "get");
  console.log(apiUrl);
  console.log(payload);
  const res = await axios.post(apiUrl, payload.param);
  return res.data;
};

export const registerBatchMetadata = async (
  payload: EmployeeBatchMetadataAddData
) => {
  console.log(payload);
  if (!payload.serviceType) return;
  const apiUrl = getBatchMetadataApiPath(payload.serviceType, "register");
  console.log(apiUrl);
  console.log(payload);
  const res = await axios.post(apiUrl, payload.param);
  return res.data;
};

export const updateBatchMetadata = async (
  payload: EmployeeBatchMetadataAddData
) => {
  if (!payload.serviceType) return;
  const apiUrl = getBatchMetadataApiPath(payload.serviceType, "update");
  console.log(apiUrl);
  console.log(payload);
  const res = await axios.put(apiUrl, payload.param);

  return res.data;
};

export const deleteBatchMetadata = async (
  payload: DeleteBatchMetadataDeleteData
) => {
  const apiUrl = getBatchMetadataApiPath(payload.serviceType, "delete");
  console.log(apiUrl);
  console.log(payload);
  const res = await axios.delete(`${apiUrl}/${payload.param._id}`);
  return res.data;
};
