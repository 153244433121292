import "./ErrorPage.scss";
import { BiSolidError } from "react-icons/bi";

const ErrorPage403 = () => {
  return (
    <div className="ErrorPage_bg">
      <div className="ErrorPage">
        <hgroup>
          <BiSolidError className="error_icon" />
          <h3>
            <p>시스템 점검 안내</p>
            <p>System Maintenance</p>
          </h3>
        </hgroup>

        <div className="ErrorPage_txt">
          <p>
            서비스 이용에 불편을 드려서 죄송합니다. 점검 후 다시 이용해 주세요
          </p>
          <p>일정: 6월 13일 (목) 18:00 ~ 6월 16일 (일) 22:00</p>
          <p>
            We apologize for the inconvenience. Please use the service again
            after the maintenance.
          </p>
          <p>Schedule: June 13th (Thursday) 18:00 ~ June 16th (Sunday) 22:00</p>
          <p style={{ marginTop: "15px" }}>
            Doosan AI Chat 고도화로 인한 서비스 점검
          </p>
          <p>Service maintenance due to Doosan AI Chat enhancement</p>
          <p>
            If the problem persists, feel free to contact our support team for
            assistance.
          </p>
          <p>- IT Service Center (1588-1896)</p>
          <br />
          <p>
            If Doosan AI Chat is not accessible or errors occur, <br /> it may
            be due to a Teams cache issue. <br />
            Please follow the guide in the link below to clear the cache and
            restart the service.{" "}
          </p>
          Clear the MS Teams client cache (
          <a
            href="https://learn.microsoft.com/ko-kr/microsoftteams/troubleshoot/teams-administration/clear-teams-cache"
            target="_blank"
            rel="noreferrer"
          >
            Korean
          </a>
          /
          <a
            href="https://learn.microsoft.com/en-us/microsoftteams/troubleshoot/teams-administration/clear-teams-cache"
            target="_blank"
            rel="noreferrer"
          >
            English
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage403;
