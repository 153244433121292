export const errHandler = (status: number, code?: string) => {
  if (status === 403 || (code && code === "ERR_NETWORK")) {
    window.location.href = "/error/403";
  }
  if (status === 400 || status === 404 || status === 405 || status === 500) {
    window.location.href = `/error/${status}`;
  }
  if (status === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
};
