import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LogMgmtPage from "./LogMgmtPage";
import LogEventMgmtPage from "./LogEventMgmtPage";
import { selectTeamsTheme } from "../../../features/auth";
import { useSelector } from "react-redux";
import "./admin.scss";
import TabBtn from "../../components/userInterface/buttons/TabBtn";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import { useAppDispatch } from "../../../features";
import {
  GetEventLogDataParam,
  GetLogDataParam,
  downloadEventLogDataAsync,
  downloadLogDataAsync,
} from "../../../features/log";
import moment from "moment";
import {
  selectEventLogDownloadLoader,
  selectLogDownloadLoader,
} from "../../../features/ui_loading";
import { innerRoutes } from "../../../routes/routes";

const LogMgmtRoute = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const teamsTheme = useSelector(selectTeamsTheme);
  const logDownloadLoading = useSelector(selectLogDownloadLoader);
  const eventLogDownloadLoading = useSelector(selectEventLogDownloadLoader);

  const [selectTab, setSelectTab] = useState<"log" | "event_log">("log");
  const [logInputs, setLogInputs] = useState<GetLogDataParam>({
    userId: "",
    compCode: "",
    deptCode: "",
    favorCode: [],
    startDate: new Date(moment().subtract(1, "months").format("YYYY-MM-DD")),
    endDate: new Date(moment().format("YYYY-MM-DD") + "T23:59:59"),
  });
  const [eventLogInputs, setEventLogInputs] = useState<GetEventLogDataParam>({
    userId: "",
    logLevel: "",
    event: "",
    startDate: new Date(moment().subtract(1, "months").format("YYYY-MM-DD")),
    endDate: new Date(moment().format("YYYY-MM-DD") + "T23:59:59"),
  });

  useEffect(() => {
    const lastPath = location.pathname.split("/").pop();

    setSelectTab(lastPath as "log" | "event_log");
  }, [location.pathname]);

  const downloadLogHandler = () => {
    dispatch(downloadLogDataAsync.request({ ...logInputs, limit: 50000 }));
  };

  const downloadEventLogHandler = () => {
    dispatch(
      downloadEventLogDataAsync.request({ ...eventLogInputs, limit: 50000 })
    );
  };

  return (
    <div className={`mgmt_page scroll_none`}>
      <hgroup>
        <div className="title_tab_wrap">
          <h3 className={`page_title page_title_${teamsTheme}`}>
            Log Management
          </h3>
          <div className={`tab_btn_wrap tab_btn_wrap_${teamsTheme}`}>
            <TabBtn
              tabName="Q&A"
              selectedTab={selectTab}
              value="log"
              onClickHandler={() => setSelectTab("log")}
              pathName={innerRoutes.LOG_MGMT_QNA.path}
            />
            <TabBtn
              tabName="Event"
              selectedTab={selectTab}
              value="event_log"
              onClickHandler={() => setSelectTab("event_log")}
              pathName={innerRoutes.LOG_MGMT_EVENT.path}
            />
          </div>
        </div>

        {selectTab === "log" ? (
          <MainColorBtn
            btnName="Log Download"
            onClickHandler={downloadLogHandler}
            loading={logDownloadLoading}
          />
        ) : (
          <MainColorBtn
            btnName="Event Log Download"
            onClickHandler={downloadEventLogHandler}
            loading={eventLogDownloadLoading}
          />
        )}
      </hgroup>

      <Routes>
        <Route
          path="/log"
          element={<LogMgmtPage inputs={logInputs} setInputs={setLogInputs} />}
        />
        <Route
          path="/event_log"
          element={
            <LogEventMgmtPage
              inputs={eventLogInputs}
              setInputs={setEventLogInputs}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default LogMgmtRoute;
