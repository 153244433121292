import "./filter.scss";
import InputTextType from "../inputs/InputTextType";
import FilterTextDeleteBtn from "../buttons/FilterTextDeleteBtn";
import { memo } from "react";

type Props = {
  title: string;
  value: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  deleteInputHandler: () => void;
};

const FilterInputTextType: React.FC<Props> = ({
  title,
  value,
  onChangeHandler,
  deleteInputHandler,
}) => {
  return (
    <div className="filter_tool filter_tool_input_text">
      <h5>{title}</h5>

      <div className="input_wrap">
        <InputTextType value={value} onChangeHandler={onChangeHandler} />
        {value.length > 0 && (
          <FilterTextDeleteBtn deleteInputHandler={deleteInputHandler} />
        )}
      </div>
    </div>
  );
};

export default memo(FilterInputTextType);
