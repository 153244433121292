import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import { memo } from "react";

type Props = {
  onClickHandler: (item: any) => void;
  btnName: string;
};

const TableBtn: React.FC<Props> = ({ onClickHandler, btnName }) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <button
      className={`table_btn table_btn_${teamsTheme}`}
      onClick={onClickHandler}
    >
      {btnName}
    </button>
  );
};

export default memo(TableBtn);
