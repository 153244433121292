import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../../features/auth";
import Checkbox from "../../../../../ui/inputs/Checkbox";
import {
  RegisteredModelInfo,
  getModelsTagsAsync,
  selectModelsInfo,
} from "../../../../../features/model";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { selectGetModelsInfoLoading } from "../../../../../features/ui_loading";

type Props = {
  pageIndex: number;
  allCheck: boolean;
  setAllCheck: React.Dispatch<React.SetStateAction<boolean>>;
  selectDevelopmentIds: {
    development: RegisteredModelInfo;
    tags: string[];
  }[];
  setSelectDevelopmentIds: React.Dispatch<
    React.SetStateAction<
      {
        development: RegisteredModelInfo;
        tags: string[];
      }[]
    >
  >;
};
const EndpointAllList: React.FC<Props> = ({
  pageIndex,
  allCheck,
  setAllCheck,
  selectDevelopmentIds,
  setSelectDevelopmentIds,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);
  const modelList = useSelector(selectModelsInfo);
  const loading = useSelector(selectGetModelsInfoLoading);

  const allModelCheckHandler = (e: any) => {
    setAllCheck(e.target.checked);

    if (e.target.checked) {
      const notIncludesModels = modelList
        .slice(pageIndex * 10 - 10, pageIndex * 10)
        .filter((model) =>
          selectDevelopmentIds.filter(
            (el) => el.development.deploymentId !== model.deploymentId
          )
        )
        .filter((model) => !model.isInDB);

      const notIncludesModelTransform = notIncludesModels.map((model) => {
        return {
          development: model,
          tags: [],
        };
      });

      setSelectDevelopmentIds((prev) => [
        ...prev,
        ...notIncludesModelTransform,
      ]);
    } else {
      const modelIds = modelList
        .slice(pageIndex * 10 - 10, pageIndex * 10)
        .map((el) => el.deploymentId);

      const notIncludeData = selectDevelopmentIds.filter(
        (model) =>
          !modelIds.includes(model.development.deploymentId) &&
          !model.development.isInDB
      );

      setSelectDevelopmentIds(notIncludeData);
    }
  };
  const modelCheckHandler = (checkData: RegisteredModelInfo) => {
    if (
      selectDevelopmentIds.some(
        (data) => data.development.deploymentId === checkData.deploymentId
      )
    ) {
      setSelectDevelopmentIds((prev: any) =>
        prev.filter(
          (development: any) =>
            development.development.deploymentId !== checkData.deploymentId
        )
      );
    } else {
      setSelectDevelopmentIds((prev: any) => [
        ...prev,
        { development: checkData, tags: [] },
      ]);
    }
  };

  return (
    <table className={`page_table page_table-${teamsTheme} modal_table`}>
      <colgroup>
        <col width="50px" />
        <col width="40%" />
        <col width="40%" />
        <col width="40%" />
        <col width="50%" />
      </colgroup>
      <thead>
        <tr>
          <th>
            <Checkbox
              checked={allCheck && selectDevelopmentIds.length > 0}
              onChange={allModelCheckHandler}
              disabled={modelList.filter((data) => !data.isInDB).length === 0}
            />
          </th>
          <th>Resource Name</th>
          <th>Deployment Name</th>
          <th>Model Name</th>
          <th>Model Version</th>
        </tr>
      </thead>
      <tbody>
        {modelList.length > 0 ? (
          modelList.slice(pageIndex * 10 - 10, pageIndex * 10).map((item) => (
            <tr>
              <td>
                <Checkbox
                  checked={
                    item.deploymentId
                      ? selectDevelopmentIds.some(
                          (el) =>
                            el.development.deploymentId === item.deploymentId
                        )
                      : false
                  }
                  onChange={() => modelCheckHandler(item)}
                  disabled={item.isInDB}
                />
              </td>
              <td title={item.aoaiResourceName} className="ellipsis">
                {item.aoaiResourceName}
              </td>
              <td title={item.deploymentName} className="ellipsis">
                {item.deploymentName}
              </td>
              <td title={item.modelName} className="ellipsis">
                {item.modelName}
              </td>
              <td title={item.modelVersion} className="ellipsis">
                {item.modelVersion}
              </td>
            </tr>
          ))
        ) : (
          <tr className="no_data">
            <td colSpan={5} style={{ textAlign: "center" }}>
              {loading ? (
                <ClipLoader loading={loading} size={20} color="#835197" />
              ) : (
                "No Data"
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
export default EndpointAllList;
