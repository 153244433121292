import { RootState } from "../index";

export const selectChatList = (state: RootState) => state.chat.chatList;

export const selectQaList = (state: RootState) => state.chat.qaList;

export const selectSelectedChatId = (state: RootState) =>
  state.chat.selectedChatId;

export const selectFirstSelectedChatId = (state: RootState) =>
  state.chat.firstSelectedChatId;

export const selectSelectedData = (state: RootState) => state.chat.selectedData;

export const selectHasMore = (state: RootState) => state.chat.hasMore;

// export const selectIsWebBrowsing = (state: RootState) =>
//   state.chat.isWebBrowsing;

export const selectPrevMessages = (state: RootState) => state.chat.prevMessages;

export const selectSelectedLog = (state: RootState) => state.chat.selectedLog;

export const selectRemoveStartChat = (state: RootState) =>
  state.chat.removeStartChat;

export const selectModelEngine = (state: RootState) => state.chat.modelEngine;

export const selectAssistantTypeInfo = (state: RootState) =>
  state.chat.assistantTypeInfo;

export const selectSelectedGptOption = (state: RootState) =>
  state.chat.selectedGptOption;

export const selectPinnedQaList = (state: RootState) => state.chat.pinnedQaList;

export const selectChatLoading = (state: RootState) => state.chat.chatLoading;

export const selectUploadFiles = (state: RootState) =>
  state.chat.inputUploadFiles;

export const selectPromptText = (state: RootState) => state.chat.promptText;

export const selectFileCards = (state: RootState) =>
  state.chat.inputUploadFiles.fileCards;

export const selectQaListId = (state: RootState) => state.chat.qaListId;
export const selectFileInfoList = (state: RootState) => state.chat.fileInfoList;

export const selectIsShowDeleteCheckbox = (state: RootState) =>
  state.chat.isShowDeleteCheckbox;
export const selectCheckedQaIds = (state: RootState) => state.chat.checkedQaIds;

export const selectQaListPageSize = (state: RootState) =>
  state.chat.qaListPageSize;

export const selectIsScrollToBottom = (state: RootState) =>
  state.chat.isScrollToBottom;
