import { AxiosError } from "axios";
import { createAsyncAction } from "typesafe-actions";
import {
  AddModelParam,
  DeleteModelParam,
  GetModelsInfo,
  ModelInfo,
  UpdateModelParam,
  getModelParam,
  getModelResult,
} from "./api";

//! model
export const GET_MODEL = "admin/GET_MODEL";
export const GET_MODEL_SUCCESS = "admin/GET_MODEL_SUCCESS";
export const GET_MODEL_ERROR = "admin/GET_MODEL_ERROR";

export const getModelAsync = createAsyncAction(
  GET_MODEL,
  GET_MODEL_SUCCESS,
  GET_MODEL_ERROR
)<getModelParam, getModelResult, AxiosError>();

export const GET_MODELS_INFO = "admin/GET_MODELS_INFO";
export const GET_MODELS_INFO_SUCCESS = "admin/GET_MODELS_INFO_SUCCESS";
export const GET_MODELS_INFO_ERROR = "admin/GET_MODELS_INFO_ERROR";

export const getModelsInfoAsync = createAsyncAction(
  GET_MODELS_INFO,
  GET_MODELS_INFO_SUCCESS,
  GET_MODELS_INFO_ERROR
)<any, GetModelsInfo, AxiosError>();

export const GET_MODELS_TAG = "admin/GET_MODELS_TAG";
export const GET_MODELS_TAG_SUCCESS = "admin/GET_MODELS_TAG_SUCCESS";
export const GET_MODELS_TAG_ERROR = "admin/GET_MODELS_TAG_ERROR";

export const getModelsTagsAsync = createAsyncAction(
  GET_MODELS_TAG,
  GET_MODELS_TAG_SUCCESS,
  GET_MODELS_TAG_ERROR
)<any, string[], AxiosError>();

export const UPDATE_MODEL = "admin/UPDATE_MODEL";
export const UPDATE_MODEL_SUCCESS = "admin/UPDATE_MODEL_SUCCESS";
export const UPDATE_MODEL_ERROR = "admin/UPDATE_MODEL_ERROR";

export const updateModelAsync = createAsyncAction(
  UPDATE_MODEL,
  UPDATE_MODEL_SUCCESS,
  UPDATE_MODEL_ERROR
)<UpdateModelParam, ModelInfo, AxiosError>();

export const ADD_MODEL = "admin/ADD_MODEL";
export const ADD_MODEL_SUCCESS = "admin/ADD_MODEL_SUCCESS";
export const ADD_MODEL_ERROR = "admin/ADD_MODEL_ERROR";

export const addModelAsync = createAsyncAction(
  ADD_MODEL,
  ADD_MODEL_SUCCESS,
  ADD_MODEL_ERROR
)<AddModelParam, ModelInfo[], AxiosError>();

export const DELETE_MODEL = "admin/DELETE_MODEL";
export const DELETE_MODEL_SUCCESS = "admin/DELETE_MODEL_SUCCESS";
export const DELETE_MODEL_ERROR = "admin/DELETE_MODEL_ERROR";

export const deleteModelAsync = createAsyncAction(
  DELETE_MODEL,
  DELETE_MODEL_SUCCESS,
  DELETE_MODEL_ERROR
)<DeleteModelParam, any, AxiosError>();
