import axios from "axios";
import { HttpError } from "./types";
import { getAuthTokenHandler } from "./invalidToken";
import { errHandler } from "../libs/errHandler";

const setAxios = (): void => {
  axios.defaults.baseURL = process.env.REACT_APP_SERVICE_URL;

  // Add a request interceptor
  axios.interceptors.request.use(async (config: any) => {
    console.log(`인터셉터 요청[${config.url}]`, config);
    if (config.url !== "/auth/login") {
      const currentToken = await getAuthTokenHandler();
      config.headers.Authorization = "Bearer " + currentToken;
    }

    return config;
  });

  axios.interceptors.response.use((response) => {
    console.log(`인터셉터 응답[${response.config.url}]`, response);
    if (response.data.errorCode && response.data.errorCode !== 0) {
      console.log("response________", response);
      errHandler(response.request.status);

      throw new HttpError(response.data.errorCode, response.data.errorMsg);
    }
    return response;
  });
};

setAxios();
