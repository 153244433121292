import { useSelector } from "react-redux";
import { selectSettingChatType } from "../../../../features/auth";
import { selectChatList } from "../../../../features/chat";
import PromptComment from "../PromptComment";
import AnswerComment from "../AnswerComment";
import LoadingAnswerComment from "../LoadingAnswerComment";
import Prompt from "../Prompt";
import Answer from "../Answer";
import LoadingAnswer from "../LoadingAnswer";
import { selectQAItemLoading } from "../../../../features/ui_loading";
import { ClipLoader } from "react-spinners";

const ChatContent = () => {
  const chatList = useSelector(selectChatList);
  const chatUIType = useSelector(selectSettingChatType);
  const qaItemLoading = useSelector(selectQAItemLoading);

  if (!qaItemLoading && chatList.length === 0) {
    return null;
  }
  // if (qaListId !== EMPTY_CHAT_INDEX && qaItemLoading) {
  if (qaItemLoading) {
    return (
      <div className="chat_content_loading">
        <ClipLoader loading={qaItemLoading} size={40} color="#80b1e2" />
      </div>
    );
  }

  if (chatUIType === "comment") {
    return (
      <>
        {chatList.map((chat, idx) => (
          <div key={chat._id + idx} className="preview_chat_wrap">
            <PromptComment
              indexCheck={idx === chatList.length - 1}
              prompt={chat.question}
              fileCards={chat.fileCards}
              fileInfo={chat.fileInfo}
            />
            {chat.answer ? (
              <AnswerComment
                answer={chat}
                lastChat={chatList.length === idx + 1}
                source={chat.source ?? []}
              />
            ) : (
              <LoadingAnswerComment />
            )}
          </div>
        ))}
      </>
    );
  }
  if (chatUIType === "chatting") {
    return (
      <>
        {chatList.map((chat, idx) => (
          <div key={chat._id + idx} className="preview_chat_wrap">
            <Prompt
              indexCheck={idx === chatList.length - 1}
              prompt={chat.question}
              fileCards={chat.fileCards}
              fileInfo={chat.fileInfo}
            />
            {chat.answer ? (
              <Answer
                answer={chat}
                lastChat={chatList.length === idx + 1}
                source={chat.source ?? []}
              />
            ) : (
              <LoadingAnswer />
            )}
          </div>
        ))}
      </>
    );
  }
  return <></>;
};

export default ChatContent;
