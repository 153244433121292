import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import * as actions from "./actions";
import {
  GetBillingNameList,
  GetBillingNameListResult,
  GetTokenCompanyResult,
  GetTokenLog,
  GetTokenLogResult,
  GetTokenParam,
} from "./api";
import { AxiosError } from "axios";
import { SetBillingSearchInput } from "./types";

export type BillingAction = ActionType<typeof actions>;

export const GET_TOKEN_LOG = "admin/GET_TOKEN_LOG";
export const GET_TOKEN_LOG_SUCCESS = "admin/GET_TOKEN_LOG_SUCCESS";
export const GET_TOKEN_LOG_ERROR = "admin/GET_TOKEN_LOG_ERROR";

export const getTokenLogAsync = createAsyncAction(
  GET_TOKEN_LOG,
  GET_TOKEN_LOG_SUCCESS,
  GET_TOKEN_LOG_ERROR
)<GetTokenLog, GetTokenLogResult, AxiosError>();

export const GET_TOKEN_COMPANY = "admin/GET_TOKEN_COMPANY";
export const GET_TOKEN_COMPANY_SUCCESS = "admin/GET_TOKEN_COMPANY_SUCCESS";
export const GET_TOKEN_COMPANY_ERROR = "admin/GET_TOKEN_COMPANY_ERROR";

export const getTokenCompanyAsync = createAsyncAction(
  GET_TOKEN_COMPANY,
  GET_TOKEN_COMPANY_SUCCESS,
  GET_TOKEN_COMPANY_ERROR
)<GetTokenParam, GetTokenCompanyResult, AxiosError>();

export const GET_BILLING_NAME_LIST = "admin/GET_BILLING_NAME_LIST";
export const GET_BILLING_NAME_LIST_SUCCESS =
  "admin/GET_BILLING_NAME_LIST_SUCCESS";
export const GET_BILLING_NAME_LIST_ERROR = "admin/GET_BILLING_NAME_LIST_ERROR";

export const getBillingNameListAsync = createAsyncAction(
  GET_BILLING_NAME_LIST,
  GET_BILLING_NAME_LIST_SUCCESS,
  GET_BILLING_NAME_LIST_ERROR
)<GetBillingNameList, GetBillingNameListResult, AxiosError>();

export const setBillingSearchInputs = createAction(
  "admin/SET_BILLING_SEARCH_INPUTS"
)<SetBillingSearchInput>();

export const resetBillingSearchInputs = createAction(
  "admin/RESET_BILLING_SEARCH_INPUTS"
)();
