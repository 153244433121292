import { memo } from "react";

type Props = {
  onClickHandler: () => void;
  btnName: string;
};

const ModalCancelBtn: React.FC<Props> = ({ onClickHandler, btnName }) => {
  return (
    <button className="cancel_modal_btn" onClick={onClickHandler}>
      {btnName}
    </button>
  );
};

export default memo(ModalCancelBtn);
