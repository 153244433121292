import React, { useEffect, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useAppDispatch } from "../../../features";
import useResponsiveView from "../../../libs/hooks/useResonsiveView";
import { useSelector } from "react-redux";
import {
  deleteChatAsync,
  selectCheckedQaIds,
  selectIsShowDeleteCheckbox,
  selectPinnedQaList,
  selectQaList,
  setCheckedQaIds,
  setIsShowDeleteCheckbox,
} from "../../../features/chat";
import clsx from "clsx";
import { selectTeamsTheme } from "../../../features/auth";

const DeleteChatSideBottomBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useResponsiveView();
  const teamsTheme = useSelector(selectTeamsTheme);
  const checkedQaIds = useSelector(selectCheckedQaIds);
  const IsShowDeleteCheckbox = useSelector(selectIsShowDeleteCheckbox);
  const qaList = useSelector(selectQaList);
  const pinnedQaList = useSelector(selectPinnedQaList);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isShowChatDeletionTitle, setIsShowChatDeletionTitle] = useState(() =>
    isDesktop ? true : false
  );

  const deleteChatHandler = (e: React.MouseEvent) => {
    e.stopPropagation();

    dispatch(deleteChatAsync.request({ listIds: checkedQaIds }));
    dispatch(setIsShowDeleteCheckbox(false));

    // const qaListIds = qaList.map((qa) => qa._id);
    // console.log(qaListIds);
  };

  const selectAllQaList = () => {
    const qaListIds = qaList.map((qa) => qa._id);
    const qaPinnedListIds = pinnedQaList.map((qa) => qa._id);
    const uniqueQaIds = [...new Set(qaListIds), ...new Set(qaPinnedListIds)];

    dispatch(setCheckedQaIds({ type: "All", checkedIds: uniqueQaIds }));
  };

  useEffect(() => {
    if (checkedQaIds.length === qaList.length + pinnedQaList.length) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [checkedQaIds, qaList, pinnedQaList]);

  return (
    <div className={`qa_list_manage_container ${teamsTheme}`}>
      <div className="qa_list_manage">
        <RiDeleteBinLine className="trash_icon" />
        {isShowChatDeletionTitle && (
          <>
            {checkedQaIds.length ? (
              <span>{checkedQaIds.length} selected</span>
            ) : (
              <span>Delete</span>
            )}
          </>
        )}
      </div>
      <div className="manage_btn_wrap">
        {IsShowDeleteCheckbox ? (
          <>
            <div className={`delete_selected_all-checkbox_wrap ${teamsTheme}`}>
              <input
                type="checkbox"
                className="delete_selected_all-checkbox"
                id="DELETE_ALL"
                onChange={selectAllQaList}
                checked={isAllChecked}
              />
              <label htmlFor="DELETE_ALL">All</label>
            </div>
            <button
              className={`delete_chat_btn ${teamsTheme}`}
              disabled={!checkedQaIds.length}
              onClick={deleteChatHandler}
            >
              delete
            </button>
            <button
              className={`delete_chat_outline_btn ${teamsTheme}`}
              onClick={() => dispatch(setIsShowDeleteCheckbox(false))}
            >
              cancel
            </button>
          </>
        ) : (
          <button
            className={clsx(
              `delete_chat_outline_btn  ${teamsTheme}`,
              "delete_chat_min_width"
            )}
            onClick={() =>
              dispatch(setIsShowDeleteCheckbox(!IsShowDeleteCheckbox))
            }
          >
            Delete Chat
          </button>
        )}
      </div>
    </div>
  );
};

export default DeleteChatSideBottomBar;
