import "./MyAIChatEditorPage.scss";
import { useSelector } from "react-redux";
import ConfigureForm from "../../components/myAIChats/ConfigureForm";
import PreviewChat from "../../components/myAIChats/PreviewChat";
import {
  selectGetMyGptsDetailLoading,
  selectUpdateMyGPTsLoading,
} from "../../../features/ui_loading";
import LoginSpinner from "../../components/spinner/LoginSpinner";
import { useEffect, useRef, useState } from "react";
import { SetMyGptsParam, selectMyAIChatForm } from "../../../features/myGpts";
import { isEqual, cloneDeep, omit } from "lodash";
import useResponsiveView from "../../../libs/hooks/useResonsiveView";
import clsx from "clsx";
import { selectTeamsTheme } from "../../../features/auth";

const MyAIChatEditorPage = () => {
  const { isDesktop, isMobile, isTablet } = useResponsiveView();

  const [isDisabledPreviewChat, setIsDisabledPreviewChat] = useState(true);
  const [isDisabledSave, setIsDisabledSave] = useState(false);

  const [isInit, setIsInit] = useState(true);
  const teamsTheme = useSelector(selectTeamsTheme);
  const updateMyGPTsLoading = useSelector(selectUpdateMyGPTsLoading);
  const getMyGptDetailLoading = useSelector(selectGetMyGptsDetailLoading);
  const myAIChatForm = useSelector(selectMyAIChatForm);
  const prevGPTsDataRef = useRef<SetMyGptsParam | null>(myAIChatForm);

  useEffect(() => {
    if (!myAIChatForm.id) return;

    const prevData = omit(prevGPTsDataRef.current, "updatedDate");
    const currentData = omit(myAIChatForm, "updatedDate");

    if (!isEqual(prevData, currentData)) {
      if (isInit) {
        prevGPTsDataRef.current = cloneDeep(myAIChatForm); // 이전 값을 현재 값으로 업데이트
        setIsInit(false);
        setIsDisabledSave(false);
        setIsDisabledPreviewChat(true);
      } else {
        setIsDisabledSave(false);
        setIsDisabledPreviewChat(true);
      }
    } else {
      if (isInit) {
        setIsInit(false);
        return;
      }
      setIsDisabledSave(true); // 저장 버튼 비활성화
      setIsDisabledPreviewChat(false); // 미리보기 비활성화
    }
  }, [myAIChatForm, isInit]);

  useEffect(() => {
    return () => {
      setIsInit(true);
    };
  }, [myAIChatForm.updatedDate]);

  if (isDesktop) {
    return (
      <>
        <div className={`my_ai_chat_editor_container ${teamsTheme}`}>
          {(updateMyGPTsLoading || getMyGptDetailLoading) && (
            <div className="configure_loading">
              <LoginSpinner />
            </div>
          )}

          <div className={`editor_section_wrap scroll scroll_${teamsTheme}`}>
            <h4 className="title">Configure</h4>
            <ConfigureForm isDisabledSave={isDisabledSave} />
          </div>
          <div className={`editor_section_wrap ${teamsTheme} editor_preview`}>
            <h4 className="title preview_header">Preview</h4>
            {isDisabledPreviewChat && (
              <div className="preview_loading">
                <div>
                  To use the chat feature in the preview, please click the
                  <span>Save</span>
                  button first.
                </div>
              </div>
            )}
            <PreviewChat />
          </div>
        </div>
      </>
    );
  } else if (isMobile || isTablet) {
    return (
      <div className={`tab_my_ai_chat_editor_container ${teamsTheme}`}>
        <input id="configure" type="radio" name="tab_item" defaultChecked />
        <label className="tab_item" htmlFor="configure">
          Configure
        </label>
        <input
          id="preview_chat"
          type="radio"
          name="tab_item"
          disabled={isDisabledPreviewChat}
        />
        <label
          title="To use the chat feature in the preview, please click the [Save] button first."
          className={clsx("tab_item", {
            "tab_item_disabled": isDisabledPreviewChat,
          })}
          htmlFor="preview_chat"
        >
          Preview
        </label>

        <div className={`tab_content ${teamsTheme}`} id="configure_content">
          <ConfigureForm isDisabledSave={isDisabledSave} />
        </div>
        <div
          className={`tab_editor_preview tab_content ${teamsTheme}`}
          id="preview_chat_content"
        >
          <PreviewChat />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default MyAIChatEditorPage;
