import "./LogBar.scss";
import { useSelector } from "react-redux";
import NewChatBtn from "../NewChatBtn";
import InfiniteScrollBar from "../InfiniteScrollBar";
import { useEffect, useRef, useState } from "react";
import { selectTeamsTheme, selectUserName } from "../../../../features/auth";
import DeleteChatSideBottomBar from "../../../pages/common/DeleteChatSideBottomBar";

type Props = {
  onClose: () => void;
};

const LogBar: React.FC<Props> = ({ onClose }) => {
  const closeModal = useRef(null);

  const userName = useSelector(selectUserName);
  const teamsTheme = useSelector(selectTeamsTheme);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (windowWidth > 1024) {
      onClose();
    }
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeModalHandler = (e: React.MouseEvent) => {
    if (closeModal.current === e.target) onClose();
  };

  return (
    <div
      className="LogBar_black_bg"
      onClick={closeModalHandler}
      ref={closeModal}
    >
      <div className={`LogBar_white_bg LogBar_white_bg_${teamsTheme}`}>
        <div
          className={`LogBar_new_Chat_btn LogBar_new_Chat_btn_${teamsTheme}`}
        >
          <NewChatBtn onClose={onClose} />
        </div>

        <div
          className={`mobile_infiniteScroll mobile_infiniteScroll_${teamsTheme}`}
        >
          <InfiniteScrollBar onClose={onClose} />
        </div>
        <DeleteChatSideBottomBar />

        {/* <div className={`mobile_userInfo mobile_userInfo_${teamsTheme}`}>
          <div className="user_icon_wrap">
            <AiOutlineUser className="user_icon" />
          </div>

          <span>{userName}</span>
        </div> */}
      </div>
    </div>
  );
};

export default LogBar;
