import { ICompanyInfo } from "../../../../features/batchMetadata";

export const batchTableData = {
  employee: {
    columns: [
      {
        title: "Company",
        dataIndex: "companyInfo",
        key: "companyName",
        render: (companyInfo: ICompanyInfo[]) =>
          companyInfo.map((company) => `${company.compName}`).join(", "),
        width: "16%",
      },
      {
        title: "Company Code",
        dataIndex: "companyInfo",
        key: "companyCode",
        render: (companyInfo: ICompanyInfo[]) =>
          companyInfo.map((company) => `${company.compCode}`).join(", "),
        width: "16%",
      },
      {
        title: "Site Id",
        dataIndex: "siteId",
        key: "siteId",
        width: "16%",
      },
      {
        title: "Site Name",
        dataIndex: "siteName",
        key: "siteName",
        width: "16%",
      },
      {
        title: "Create Date",
        dataIndex: "createDate",
        key: "createDate",
        width: "10%",
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate",
        width: "10%",
      },
      {
        title: "Trigger Date",
        dataIndex: "triggerDate",
        key: "triggerDate",
        width: "10%",
      },
      {
        title: "Batch",
        dataIndex: "batch",
        key: "batch",
        width: "80px",
      },
      {
        title: "",
        dataIndex: "event",
        key: "event",
        width: "130px",
      },
    ],
  },
};
