type Props = {
  checked: boolean;
  onChange: (e: any) => void;
  checkboxId?: string;
  disabled?: boolean;
};

const Checkbox: React.FC<Props> = ({
  checked,
  onChange,
  checkboxId,
  disabled,
}) => (
  <input
    className={`input_checkbox_radio`}
    checked={checked}
    onChange={onChange}
    type="checkbox"
    id={checkboxId ? checkboxId : ""}
    disabled={disabled}
  />
);

export default Checkbox;
