import { useEffect, useState } from "react";
import {
  GetLogDataParam,
  getLogDataAsync,
  selectLogData,
  selectLogTotalCnt,
} from "../../../features/log";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../features";
import { BsHandThumbsDownFill, BsHandThumbsUpFill } from "react-icons/bs";
import Pagenator from "../../components/common/Pagenator";
import { localDate } from "../../../libs/transformDate";
import moment from "moment";
import { selectTeamsTheme } from "../../../features/auth";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import "./admin.scss";
import FilterPeriod from "../../components/userInterface/filters/FilterPeriod";
import FilterInputTextType from "../../components/userInterface/filters/FilterInputTextType";
import InputCheckBoxLabel from "../../components/userInterface/inputs/InputCheckBoxLabel";
import NoneBackgroundBtn from "../../components/userInterface/buttons/NoneBackgroundBtn";
import { useImgRegex } from "../../../libs/hooks/useImgRegex";

const favorData = [
  {
    name: "Default",
    code: 0,
  },
  {
    name: "Like",
    code: 1,
  },
  {
    name: "DisLike",
    code: -1,
  },
];

type Props = {
  inputs: GetLogDataParam;
  setInputs: React.Dispatch<React.SetStateAction<GetLogDataParam>>;
};

const LogMgmtPage: React.FC<Props> = ({ inputs, setInputs }) => {
  const dispatch = useAppDispatch();
  const [changeImgText] = useImgRegex();
  const logData = useSelector(selectLogData);
  const teamsTheme = useSelector(selectTeamsTheme);

  const pageSize = 10;
  const totalCount = useSelector(selectLogTotalCnt);
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    getLogDataHandler(1);
  }, []);

  const setCurrentPage = (currentPage: number) => {
    setPageIndex(currentPage);
    getLogDataHandler(currentPage);
  };

  const resetHandler = () => {
    setInputs({
      userId: "",
      compCode: "",
      deptCode: "",
      favorCode: [],
      startDate: null,
      endDate: null,
    });

    dispatch(
      getLogDataAsync.request({
        userId: "",
        compCode: "",
        deptCode: "",
        favorCode: [],
        startDate: null,
        endDate: null,
        limit: pageSize,
        skip: 1,
      })
    );

    setPageIndex(1);
  };

  useEffect(() => {
    if (inputs.startDate && !inputs.endDate) {
      const newEndDate = new Date(inputs.startDate);
      newEndDate.setHours(23, 59, 59); // 마지막 시간으로 설정
      setInputs({
        ...inputs,
        endDate: newEndDate,
      });
    }

    if (
      inputs.startDate &&
      inputs.endDate &&
      inputs.startDate > inputs.endDate
    ) {
      const newEndDate = new Date(inputs.startDate);
      newEndDate.setHours(23, 59, 59); // 마지막 시간으로 설정
      setInputs({
        ...inputs,
        endDate: newEndDate,
      });
    }
  }, [inputs.startDate]);

  const getLogDataHandler = (idx?: number) => {
    dispatch(
      getLogDataAsync.request({
        ...inputs,
        limit: pageSize,
        skip: idx ? idx : pageIndex,
      })
    );

    if (idx) {
      setPageIndex(idx);
    }
  };

  return (
    <>
      <div className={`mgmt_page_filter mgmt_page_filter_${teamsTheme}`}>
        <FilterPeriod
          startDate={localDate(inputs.startDate)}
          endDate={localDate(inputs.endDate)}
          max={moment().format("YYYY-MM-DD")}
          startDateChangeHandler={(e) =>
            setInputs({
              ...inputs,
              startDate: new Date(e.target.value),
            })
          }
          endDateChangeHandler={(e) =>
            setInputs({
              ...inputs,
              endDate: new Date(e.target.value + "T23:59:59"),
            })
          }
        />

        <FilterInputTextType
          title="Company Name"
          value={inputs.compCode}
          onChangeHandler={(e) =>
            setInputs({
              ...inputs,
              compCode: e.target.value,
            })
          }
          deleteInputHandler={() =>
            setInputs({
              ...inputs,
              compCode: "",
            })
          }
        />

        <FilterInputTextType
          title="User Email"
          value={inputs.userId}
          onChangeHandler={(e) =>
            setInputs({
              ...inputs,
              userId: e.target.value,
            })
          }
          deleteInputHandler={() =>
            setInputs({
              ...inputs,
              userId: "",
            })
          }
        />

        <div className="filter_tool">
          <h5>Like it or Not</h5>

          <div className="checkbox_wrap">
            {favorData.map((favor) => (
              <InputCheckBoxLabel
                key={favor.name}
                checked={inputs.favorCode.includes(favor.code)}
                onChangeHandler={(e) =>
                  setInputs({
                    ...inputs,
                    favorCode: inputs.favorCode.includes(favor.code)
                      ? inputs.favorCode.filter((code) => code !== favor.code)
                      : [...inputs.favorCode, favor.code],
                  })
                }
                checkboxId={`${favor.code}`}
                labelName={favor.name}
              />
            ))}
          </div>
        </div>

        <div className="filter_tool_btns">
          <NoneBackgroundBtn onClickHandler={resetHandler} btnName="Reset" />
          <MainColorBtn
            btnName="Search"
            onClickHandler={() => getLogDataHandler(1)}
          />
        </div>
      </div>

      <table className={`page_table page_table-${teamsTheme}`} width="500">
        {/* <table className={`page_table page_table_${teamsTheme}`}> */}
        <colgroup>
          <col width="25%" />
          <col width="25%" />
          <col width="15%" />
          <col width="5%" />
          <col width="10%" />
          <col width="10%" />
        </colgroup>
        <thead>
          {/* <thead className={`thead_${teamsTheme}`}> */}
          <tr>
            <th title="Question">Question</th>
            <th title="Answer">Answer</th>
            <th title="User Email">User Email</th>
            <th title="Latency">Latency</th>
            <th title="Company Name">Company Name</th>
            <th title="Like it or Not">Like it or Not</th>
          </tr>
        </thead>
        <tbody>
          {/* <tbody className={`tbody_${teamsTheme}`}> */}
          {logData.length > 0 ? (
            logData.map((item) => (
              <tr>
                <td className="line_change">{item.question}</td>
                {/* <td className="line_change">{item.answer}</td> */}
                <td className="line_change">{changeImgText(item.answer)}</td>
                <td className="line_change">{item.createdBy}</td>
                <td className="line_change">{item.latency}</td>
                <td className="line_change">{item.compCode}</td>
                <td className="line_change">
                  {item.favor === 0 ? (
                    "-"
                  ) : item.favor === 1 ? (
                    <BsHandThumbsUpFill className="thumbs_icon" />
                  ) : (
                    <BsHandThumbsDownFill className="thumbs_icon" />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr className="no_data">
              <td colSpan={6}>No Data</td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagenator
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={pageIndex}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default LogMgmtPage;
