import { AssistantType } from "../features/chat";

const CHAT = {
  SUPPORTED_BY_DOOSAN: "\n\n[Supported by Doosan AI Chat]",
  EMPTY_CHAT_INDEX: "-1",
  DEFAULT_QA_LIST_PAGE_SIZE: 30,
};

export interface IAssistantTypeItem {
  assistantType: AssistantType;
  title: string;
  mobileTitle: string;
  description: string;
  length?: number;
}

export const MY_ASSISTANT_TYPE_LIST: IAssistantTypeItem[] = [
  {
    assistantType: "DEFAULT",
    title: "Default",
    mobileTitle: "Default",
    description: `<p>GPT provides answers to user questions based on its trained knowledge.</p>`,
  },
  {
    assistantType: "WEB",
    title: "Web content",
    mobileTitle: "Web",
    description: `<p>It offers answers based on web searches.</p>`,
  },
  {
    assistantType: "FILE",
    title: "Data Analysis",
    mobileTitle: "Analysis",
    description: `<p>It provides analysis and visualization for business documents, images and data</p>
                  <p>(the total upload capacity is 100MB, with a maximum of 10 files)</p>`,
  },
  {
    assistantType: "DALLE",
    title: "Image Generation",
    mobileTitle: "Image",
    description: `<p>Images are generated upon user request.</p>`,
  },
];

export const GPT4_CAPABILITIES: AssistantType[] = [
  "DEFAULT",
  "WEB",
  "FILE",
  "DALLE",
];
export const GPT4_ASSISTANT_TYPE_LIST: IAssistantTypeItem[] = [
  {
    assistantType: "DEFAULT",
    title: "Default",
    mobileTitle: "Default",
    description: `<p>GPT provides answers to user questions based on its trained knowledge.</p>`,
  },
  {
    assistantType: "WEB",
    title: "Web content",
    mobileTitle: "Web",
    description: `<p>It offers answers based on web searches.</p>`,
  },
  {
    assistantType: "FILE",
    title: "Data Analysis",
    mobileTitle: "Analysis",
    description: `<p>It provides analysis and visualization for business documents, images and data</p>
                  <p>(the total upload capacity is 100MB, with a maximum of 10 files)</p>`,
  },
  {
    assistantType: "DALLE",
    title: "Image Generation",
    mobileTitle: "Image",
    description: `<p>Images are generated upon user request.</p>`,
  },
];

export default CHAT;
