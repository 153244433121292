import { createReducer } from "typesafe-actions";
import produce from "immer";
import { LogState, LogAction } from "./types";
import { getEventLogDataAsync, getLogDataAsync } from "./actions";

const initialState = {
  logData: [],
  logTotalCount: 0,
  eventLogData: [],
  eventLogTotalCount: 0,
};

const log = createReducer<LogState, LogAction>(initialState)
  .handleAction(getLogDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.logData = payload.result;
      draft.logTotalCount = payload.totalCount;
    });
  })
  .handleAction(getEventLogDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.eventLogData = payload.result;
      draft.eventLogTotalCount = payload.totalCount;
    });
  });

export default log;
