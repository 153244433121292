import { AxiosError } from "axios";
import { createAsyncAction } from "typesafe-actions";
import {
  GetEventLogDataParam,
  GetEventLogDataResult,
  GetLogDataParam,
  GetLogDataResult,
} from "./api";

//! log mgmt
export const GET_LOG_DATA = "admin/GET_LOG_DATA";
export const GET_LOG_DATA_SUCCESS = "admin/GET_LOG_DATA_SUCCESS";
export const GET_LOG_DATA_ERROR = "admin/GET_LOG_DATA_ERROR";

export const getLogDataAsync = createAsyncAction(
  GET_LOG_DATA,
  GET_LOG_DATA_SUCCESS,
  GET_LOG_DATA_ERROR
)<GetLogDataParam, GetLogDataResult, AxiosError>();

export const GET_EVENT_LOG_DATA = "admin/GET_EVENT_LOG_DATA";
export const GET_EVENT_LOG_DATA_SUCCESS = "admin/GET_EVENT_LOG_DATA_SUCCESS";
export const GET_EVENT_LOG_DATA_ERROR = "admin/GET_EVENT_LOG_DATA_ERROR";

export const getEventLogDataAsync = createAsyncAction(
  GET_EVENT_LOG_DATA,
  GET_EVENT_LOG_DATA_SUCCESS,
  GET_EVENT_LOG_DATA_ERROR
)<GetEventLogDataParam, GetEventLogDataResult, AxiosError>();

export const DOWNLOAD_EVENT_LOG_DATA = "admin/DOWNLOAD_EVENT_LOG_DATA";
export const DOWNLOAD_EVENT_LOG_DATA_SUCCESS =
  "admin/DOWNLOAD_EVENT_LOG_DATA_SUCCESS";
export const DOWNLOAD_EVENT_LOG_DATA_ERROR =
  "admin/DOWNLOAD_EVENT_LOG_DATA_ERROR";

export const downloadEventLogDataAsync = createAsyncAction(
  DOWNLOAD_EVENT_LOG_DATA,
  DOWNLOAD_EVENT_LOG_DATA_SUCCESS,
  DOWNLOAD_EVENT_LOG_DATA_ERROR
)<GetEventLogDataParam, any, AxiosError>();

export const DOWNLOAD_LOG_DATA = "admin/DOWNLOAD_LOG_DATA";
export const DOWNLOAD_LOG_DATA_SUCCESS = "admin/DOWNLOAD_LOG_DATA_SUCCESS";
export const DOWNLOAD_LOG_DATA_ERROR = "admin/DOWNLOAD_LOG_DATA_ERROR";

export const downloadLogDataAsync = createAsyncAction(
  DOWNLOAD_LOG_DATA,
  DOWNLOAD_LOG_DATA_SUCCESS,
  DOWNLOAD_LOG_DATA_ERROR
)<GetLogDataParam, any, AxiosError>();
