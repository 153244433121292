import "./NewChatBtn.scss";
import { useSelector } from "react-redux";
import {
  selectChatLoading,
  setAssistantTypeInfo,
  setModelEngine,
} from "../../../features/chat";
import { useAppDispatch } from "../../../features";
import { clearChat } from "../../../features/chat";
import { BiPlus } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { selectTeamsTheme } from "../../../features/auth";
import { memo } from "react";
// import { setSideBarMyGPTId } from "../../../features/myGpts";
import { GPT4_CAPABILITIES } from "../../../constants/chat";
import { openAlert } from "../../../features/alert";

type Props = {
  onClose?: () => void;
};

const NewChatBtn: React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const loading = useSelector(selectChatLoading);
  const navigate = useNavigate();

  const newChatHandler = () => {
    if (loading) {
      dispatch(
        openAlert({
          alertMsg: "You cannot select this menu while it is loading.",
        })
      );
      return;
    }
    if (!loading) {
      dispatch(clearChat({ clear: true }));
      dispatch(setModelEngine("4.0v"));
      // dispatch(setSideBarMyGPTId({ id: "" }));
      dispatch(
        setAssistantTypeInfo({
          assistantType: "DEFAULT",
          capabilities: GPT4_CAPABILITIES,
          myGPTsId: null,
          profileImage: null,
        })
      );

      if (onClose) onClose();

      navigate("/private/chat");
    }
  };

  return (
    <button
      onClick={newChatHandler}
      className={`new_chat-button ${teamsTheme}`}
      disabled={loading}
    >
      <div className="text_wrap">
        <BiPlus className="plus_icon" />
        <span>NEW CHAT</span>
      </div>
    </button>
  );
};

export default memo(NewChatBtn);
