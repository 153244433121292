import { DetailLink } from "./DetailLink";
import "./DetailInfo.scss";
import { MessageSourceInfo } from "../../../../features/chat";
import { selectTeamsTheme } from "../../../../features/auth";
import { useSelector } from "react-redux";
import he from "he";

type Props = {
  source: MessageSourceInfo[];
};
export const DetailInfoList: React.FC<Props> = ({ source }) => {
  const teamsTheme = useSelector(selectTeamsTheme);
  return (
    <div className={`detail_info_container ${teamsTheme}`}>
      <h5 className="detail_info_title">자세한 정보</h5>
      <ul className="detail_info-ul">
        {source.map((elem, idx) => (
          <DetailLink
            key={idx}
            index={idx + 1}
            name={he.decode(elem.bingSourceName)}
            url={elem.bingSourceUrl}
          />
        ))}
      </ul>
    </div>
  );
};
