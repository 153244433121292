import { createReducer } from "typesafe-actions";
import produce from "immer";
import { ModelState, ModelAction } from "./types";
import {
  addModelAsync,
  getModelAsync,
  getModelsInfoAsync,
  getModelsTagsAsync,
  updateModelAsync,
} from "./actions";

const initialState = {
  modelData: [],
  searchModelData: "",
  modelPageIndex: 1,
  modelTotalCount: 0,
  modelsInfo: [],
  modelsInfoTotalCount: 0,
  modelsTags: [],
};

const model = createReducer<ModelState, ModelAction>(initialState)
  .handleAction(getModelAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.modelData = payload.result;
      draft.modelTotalCount = payload.totalCount;
      draft.searchModelData = payload.searchText;
      draft.modelPageIndex = payload.pageIndex;
    });
  })
  .handleAction(addModelAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.modelData = [...payload, ...state.modelData];
      draft.modelTotalCount = state.modelTotalCount + payload.length;
    });
  })
  .handleAction(updateModelAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.modelData = state.modelData.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }
        return item;
      });
    });
  })
  .handleAction(getModelsInfoAsync.request, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.modelsInfo = [];
      draft.modelsInfoTotalCount = 0;
    });
  })
  .handleAction(getModelsInfoAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.modelsInfo = payload.result;
      draft.modelsInfoTotalCount = payload.totalCount;
    });
  })
  .handleAction(getModelsTagsAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.modelsTags = payload;
    });
  });

export default model;
