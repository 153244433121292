import { useEffect, useRef, useState } from "react";
import "./Prompt.scss";
import {
  FileCard,
  IFileInfoItem,
  selectChatList,
} from "../../../features/chat";
import { useSelector } from "react-redux";
import { AiOutlineUser } from "react-icons/ai";
import { selectTeamsTheme } from "../../../features/auth";
import { memo } from "react";
import { getNameAndExtension } from "../../../services/FileUpload";
import FileCardItem from "./fileUpload/FileCardItem";

type Props = {
  prompt: string;
  indexCheck: boolean;
  fileCards?: FileCard[] | undefined;
  fileInfo?: IFileInfoItem[];
};

const PromptComment: React.FC<Props> = ({ prompt, indexCheck, fileInfo }) => {
  const lastQuestionRef = useRef<HTMLDivElement>(null);
  const teamsTheme = useSelector(selectTeamsTheme);
  const chatList = useSelector(selectChatList);
  const [fileCards, setFileCards] = useState<FileCard[]>();

  useEffect(() => {
    if (fileInfo?.length) {
      const tempFileCards = fileInfo.map((elem) => {
        const [_, extension] = getNameAndExtension(elem.fileName);
        return { name: elem.fileName, extension, imgUrl: "", isLoading: false };
      });
      setFileCards(tempFileCards);
    }
  }, [fileInfo]);

  useEffect(() => {
    const scrollBody = document.getElementById("chat_body");
    const inputChat = document.getElementById("chat_input");

    if (scrollBody) {
      const maxScrollHeight = scrollBody.scrollHeight - scrollBody.clientHeight;
      scrollBody.scrollTop = maxScrollHeight;
    }

    if (inputChat) inputChat.focus();
  }, [chatList]);

  return (
    <div
      ref={indexCheck ? lastQuestionRef : null}
      className={`comment_prompt_wrap comment_prompt_wrap_${teamsTheme}`}
    >
      <div className="comment_prompt">
        <div
          className={`comment_prompt_userimg_wrap comment_prompt_userimg_wrap_${teamsTheme}`}
        >
          <AiOutlineUser className="user_icon" />
        </div>
        <div className={`prompt prompt_${teamsTheme} prompt_flex`}>
          <div className="files_container">
            {fileCards?.map((elem, idx) => (
              <FileCardItem
                key={idx}
                fileId={elem.fileId ?? ""}
                fileName={elem.name}
                extension={elem.extension}
                imgUrl={elem.imgUrl}
                isLoading={elem.isLoading}
              />
            ))}
          </div>
          {prompt}
        </div>
      </div>
    </div>
  );
};

export default memo(PromptComment);
