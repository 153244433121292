import { useEffect, useRef, useState } from "react";
import { ReactComponent as ProfileRegisterButton } from "../../../assets/svg/ProfileRegisterButton.svg";
import LabelAndInput from "../../pages/myAIChats/form/LabelAndInput";
import KnowledgeUpload from "./KnowledgeUpload";
import CloseSVG from "../chat/fileUpload/CloseSVG";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";
import {
  getMyGPTSDetailAsync,
  pageChangeDeleteMyChatId,
  selectSelectedPreviewMyGptId,
  selectMyAIChatForm,
  setMyAIChatForm,
  updateMyGPTsAsync,
} from "../../../features/myGpts";
import { useAppDispatch } from "../../../features";
import { useLocation } from "react-router-dom";
import { AssistantType } from "../../../features/chat";
import clsx from "clsx";
import useResponsiveView from "../../../libs/hooks/useResonsiveView";
import { useIsCanAnalyzeFile } from "../../../libs/hooks/useIsCanAnalyzeFile";

const checkboxArr = [
  { name: "Web content", value: "WEB" },
  { name: "Image Generation", value: "DALLE" },
  {
    name: "Data Analysis",
    value: "FILE",
    tooltip:
      "Please note that uploading a file to Knowledge requires the use of a code interpreter.",
  },
];
const MAX_LENGTH_CONVERSATION_STARTERS = 4;
const MIN_LENGTH_CONVERSATION_STARTERS = 1;
type Props = {
  isDisabledSave?: boolean;
};

const ConfigureForm: React.FC<Props> = ({ isDisabledSave }) => {
  const { isDesktop } = useResponsiveView();
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const myAIChatForm = useSelector(selectMyAIChatForm);
  const location = useLocation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const selectedMyGptId = useSelector(selectSelectedPreviewMyGptId);
  const isCanAnalyzeFile = useIsCanAnalyzeFile();
  const [capabilitiesCheckList, setCapabilitiesCheckList] =
    useState(checkboxArr);

  const [profileImage, setProfileImage] = useState<string | ArrayBuffer | null>(
    myAIChatForm.profileImage || null
  );

  useEffect(() => {
    const myGPTsId = location.pathname.split("/").pop();

    if (location.pathname.includes("edit") && myGPTsId) {
      dispatch(getMyGPTSDetailAsync.request({ myGPTsId: myGPTsId }));
    }

    return () => {
      dispatch(pageChangeDeleteMyChatId({ delete: true }));
    };
  }, []);

  useEffect(() => {
    if (isCanAnalyzeFile) {
      setCapabilitiesCheckList(checkboxArr);
    }
    if (!isCanAnalyzeFile) {
      setCapabilitiesCheckList(
        checkboxArr.filter((item) => item.value !== "FILE")
      );
    }
  }, [isCanAnalyzeFile]);

  useEffect(() => {
    if (myAIChatForm.profileImage) setProfileImage(myAIChatForm.profileImage);
  }, [myAIChatForm.profileImage]);

  const checkTheCheckbox = (value: AssistantType) => {
    if (value === "FILE" && myAIChatForm.capabilities.includes("FILE")) {
      if (myAIChatForm.knowledge.length) return;
      if (myAIChatForm.modifyKnowledge?.length) return;
    }

    if (myAIChatForm.capabilities.includes(value)) {
      dispatch(
        setMyAIChatForm({
          ...myAIChatForm,
          capabilities: myAIChatForm.capabilities.filter(
            (capability) => capability !== value
          ),
        })
      );
      return;
    } else {
      dispatch(
        setMyAIChatForm({
          ...myAIChatForm,
          capabilities: [...myAIChatForm.capabilities, value],
        })
      );
    }
  };

  const addConversationStarter = (idx: number, text: string) => {
    const isLastConversationStarter =
      myAIChatForm.conversationStarters.length ===
      MAX_LENGTH_CONVERSATION_STARTERS;

    const conversationStarters = [...myAIChatForm.conversationStarters];
    conversationStarters[idx] = text;

    if (text === "") {
      if (
        myAIChatForm.conversationStarters.length ===
        MIN_LENGTH_CONVERSATION_STARTERS
      ) {
        dispatch(
          setMyAIChatForm({
            ...myAIChatForm,
            conversationStarters: [""],
          })
        );
        return;
      }

      if (!isLastConversationStarter) {
        conversationStarters.splice(idx, 1);
      }

      dispatch(
        setMyAIChatForm({
          ...myAIChatForm,
          conversationStarters,
        })
      );
    }

    if (text !== "") {
      const isCreateNextEmpty =
        idx === myAIChatForm.conversationStarters.length - 1;

      if (isCreateNextEmpty) {
        const newConversationStarters = [...conversationStarters];

        if (!isLastConversationStarter) {
          newConversationStarters.push("");
        }

        dispatch(
          setMyAIChatForm({
            ...myAIChatForm,
            conversationStarters: newConversationStarters,
          })
        );
      } else {
        dispatch(setMyAIChatForm({ ...myAIChatForm, conversationStarters }));
      }
    }
  };

  const deleteConversationStarter = (index: number) => {
    const isLastConversationStarter =
      myAIChatForm.conversationStarters.length ===
      MAX_LENGTH_CONVERSATION_STARTERS;
    if (
      myAIChatForm.conversationStarters[index] === "" &&
      !isLastConversationStarter
    ) {
      return;
    }
    if (
      myAIChatForm.conversationStarters.length ===
      MIN_LENGTH_CONVERSATION_STARTERS
    ) {
      dispatch(
        setMyAIChatForm({
          ...myAIChatForm,
          conversationStarters: [""],
        })
      );
      return;
    }

    dispatch(
      setMyAIChatForm({
        ...myAIChatForm,
        conversationStarters: myAIChatForm.conversationStarters.filter(
          (starter, idx) => idx !== index
        ),
      })
    );
  };

  const handleButtonClick = () => {
    // 파일 입력 요소를 클릭하도록 함
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const profileImageUpload = (e: any) => {
    e.preventDefault(); // 기본 동작을 막음
    e.stopPropagation(); // 이벤트 전파를 막음
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        dispatch(
          setMyAIChatForm({ ...myAIChatForm, profileImage: reader.result })
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const updateConfigureForm = () => {
    dispatch(
      updateMyGPTsAsync.request({
        param: {
          ...myAIChatForm,
          conversationStarters: myAIChatForm.conversationStarters.filter(
            (conversation) => conversation !== ""
          ),
          id: selectedMyGptId ? selectedMyGptId : null,
        },
        navigate: null,
      })
    );
  };

  return (
    <div
      className={clsx(
        `configure-from ${teamsTheme} scroll scroll_${teamsTheme}`,
        {
          editor_desktop_width: isDesktop,
        }
      )}
    >
      <div className="profile-button-wrap">
        {myAIChatForm.profileImage ? (
          <>
            <img
              onClick={handleButtonClick}
              className="profile-img"
              src={profileImage as string}
              alt="profile"
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={profileImageUpload}
              multiple={false}
            />
          </>
        ) : (
          <>
            <button onClick={handleButtonClick} className="profile-button">
              <ProfileRegisterButton />
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={profileImageUpload}
              multiple={false}
            />
          </>
        )}
      </div>
      <LabelAndInput
        inputType="default"
        htmlFor="name"
        label="Name"
        placeholder="Name your GPT"
        onChange={(e) =>
          dispatch(setMyAIChatForm({ ...myAIChatForm, name: e.target.value }))
        }
        value={myAIChatForm.name}
      />
      <LabelAndInput
        inputType="default"
        htmlFor="description"
        label="Description"
        placeholder="Add a short  description about what this GPT does"
        onChange={(e) =>
          dispatch(
            setMyAIChatForm({ ...myAIChatForm, description: e.target.value })
          )
        }
        value={myAIChatForm.description}
      />

      <LabelAndInput
        inputType="textarea"
        htmlFor="instructions"
        label="Instructions"
        placeholder="What does this GPT do? How does it behave? What should it avoid doing?"
        onChange={(e) =>
          dispatch(
            setMyAIChatForm({ ...myAIChatForm, instructions: e.target.value })
          )
        }
        value={myAIChatForm.instructions}
      />

      <div className={`form_item_wrap ${teamsTheme}`}>
        <label htmlFor="conversationStarters">Conversation Starters</label>

        {myAIChatForm.conversationStarters.map((text, idx) => (
          <div key={idx} className="remove_input_wrap">
            <input
              type="text"
              name="conversationStarters"
              id="conversationStarters"
              value={text}
              onChange={(e) => addConversationStarter(idx, e.target.value)}
              // required={input.conversationStarters.length === 1}
            />
            <button
              onClick={() => deleteConversationStarter(idx)}
              className="close_button"
            >
              <CloseSVG />
            </button>
          </div>
        ))}
      </div>

      {isCanAnalyzeFile && <KnowledgeUpload />}

      <div className={`form_item_wrap ${teamsTheme}`}>
        <label>capabilities</label>

        {capabilitiesCheckList.map((item) => (
          <div
            key={item.name}
            className="checkbox_wrap"
            title={item.tooltip ? item.tooltip : ""}
          >
            <input
              type="checkbox"
              id={item.name}
              checked={myAIChatForm.capabilities.includes(
                item.value as AssistantType
              )}
              onChange={() => checkTheCheckbox(item.value as AssistantType)}
            />
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
      <div className="form_item-button_wrap">
        <button
          className="save_button"
          onClick={updateConfigureForm}
          disabled={isDisabledSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ConfigureForm;
