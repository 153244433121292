import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import "./MobileGridBox.scss";
import { memo, useMemo } from "react";

type Props = {
  data: any;
  modifyHandler: () => void;
  deleteHandelr: () => void;
};

const MobileGridBox: React.FC<Props> = ({
  data,
  modifyHandler,
  deleteHandelr,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  const entries = useMemo(() => Object.entries(data), [data]);

  return (
    <div className={`mobile_grid_box mobile_grid_box_${teamsTheme}`}>
      {entries.map(([key, value]) => (
        <div key={key} className="box_item">
          <h5>{key}</h5>
          <p>{value}</p>
        </div>
      ))}
      <div className="box_item_btn_wrap">
        <button
          className={`box_item_btn box_item_btn_${teamsTheme}`}
          onClick={modifyHandler}
        >
          modify
        </button>
        <button
          className={`box_item_btn box_item_btn_${teamsTheme}`}
          onClick={deleteHandelr}
        >
          delete
        </button>
      </div>
    </div>
  );
};

export default memo(MobileGridBox);
