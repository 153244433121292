import { ReactNode, memo } from "react";

type Props = {
  icon: ReactNode;
  changeIcon?: ReactNode;
  onClickHandler: () => void;
  iconChangeFilter?: boolean;
  classChangeFilter: boolean;
};

const ThBtn: React.FC<Props> = ({
  icon,
  changeIcon,
  onClickHandler,
  iconChangeFilter,
  classChangeFilter,
}) => {
  return (
    <button
      className={`th_btn ${classChangeFilter && "not_select_btn"}`}
      onClick={onClickHandler}
    >
      {iconChangeFilter ? changeIcon : icon}
    </button>
  );
};

export default memo(ThBtn);
