import moment from "moment";
import { createReducer } from "typesafe-actions";
import {
  getBatchMetadataListAsync,
  registerEmployeeBatchMetadataAsync,
  resetBatchMetadataRegisterInputs,
  resetBatchMetadataSearchInputs,
  setBatchMetadataModifyItem,
  setBatchMetadataRegisterInputs,
  setBatchMetadataSearchInputs,
  updateBatchMetadataAsync,
} from "./actions";
import produce from "immer";
import { BatchMetadataAction, BatchMetadataState } from "./types";
import { EapprovalBatchMetadataItem, EmployeeBatchMetadataItem } from "./api";

export const BATCH_METADATA_DEFAULT_PAGE_SIZE = 10;
export const BATCH_METADATA_MOBILE_PAGE_SIZE = 6;

export const initialBatchEmployeeMetadataSearchInputs = {
  startDate: new Date(moment().subtract(1, "months").format("YYYY-MM-DD")),
  endDate: new Date(moment().format("YYYY-MM-DD")),
  compName: "",
  compCode: "",
  siteId: "",
  siteName: "",
  skip: 1,
  limit: BATCH_METADATA_DEFAULT_PAGE_SIZE,
};

export const initialBatchEapprovalMetadataSearchInputs = {
  startDate: new Date(moment().subtract(1, "months").format("YYYY-MM-DD")),
  endDate: new Date(moment().format("YYYY-MM-DD")),
  compName: "",
  compCode: "",
  downloadUrl: "",
  skip: 1,
  limit: BATCH_METADATA_DEFAULT_PAGE_SIZE,
};
const initialBatchEmployeeMetadataRegisterInputs = {
  _id: null,
  companyInfo: [{ compName: "", compCode: "" }],
  siteId: "",
  siteName: "",
  createDate: null,
  updateDate: null,
  triggerDate: null,
};

const initialBatchEapprovalMetadataRegisterInputs = {
  _id: null,
  companyInfo: [{ compName: "", compCode: "" }],
  downloadUrl: "",
  createDate: null,
  updateDate: null,
  triggerDate: null,
};
const initialState = {
  employeeBatchMetadataList: [],
  eapprovalBatchMetadataList: [],
  batchMetadataTotalCount: 0,
  employeeBatchSearchInputs: initialBatchEmployeeMetadataSearchInputs,
  eapprovalBatchSearchInputs: initialBatchEapprovalMetadataSearchInputs,
  employeeBatchRegisterInputs: initialBatchEmployeeMetadataRegisterInputs,
  eapprovalBatchRegisterInputs: initialBatchEapprovalMetadataRegisterInputs,
};

const batchMetadata = createReducer<BatchMetadataState, BatchMetadataAction>(
  initialState
)
  .handleAction(setBatchMetadataSearchInputs, (state, { payload }) => {
    const serviceType = payload.serviceType;

    if (serviceType === "employee") {
      return produce(state, (draft) => {
        draft.employeeBatchSearchInputs = {
          ...state.employeeBatchSearchInputs,
          [payload.key]: payload.value,
        };
      });
    }
    if (serviceType === "eapproval") {
      return produce(state, (draft) => {
        draft.eapprovalBatchSearchInputs = {
          ...state.eapprovalBatchSearchInputs,
          [payload.key]: payload.value,
        };
      });
    }
    return state;
  })

  .handleAction(setBatchMetadataRegisterInputs, (state, { payload }) => {
    const serviceType = payload.serviceType;

    if (serviceType === "employee") {
      return produce(state, (draft) => {
        draft.employeeBatchRegisterInputs = {
          ...state.employeeBatchRegisterInputs,
          [payload.key]: payload.value,
        };
      });
    }
    if (serviceType === "eapproval") {
      return produce(state, (draft) => {
        draft.eapprovalBatchRegisterInputs = {
          ...state.eapprovalBatchRegisterInputs,
          [payload.key]: payload.value,
        };
      });
    }
    return state;
  })
  .handleAction(setBatchMetadataModifyItem, (state, { payload }) => {
    const serviceType = payload.serviceType;
    console.log(payload);
    if (serviceType === "employee") {
      return produce(state, (draft) => {
        draft.employeeBatchRegisterInputs = payload.item;
      });
    }

    if (serviceType === "eapproval") {
      return produce(state, (draft) => {
        draft.eapprovalBatchRegisterInputs = payload.item;
      });
    }
    return state;
  })

  .handleAction(getBatchMetadataListAsync.success, (state, { payload }) => {
    console.log("getBatchMetadataListAsync success");
    console.log(payload);
    if (payload.batchMetadataList.length === 0) {
      return {
        ...state,
        employeeBatchMetadataList: [],
        eapprovalBatchMetadataList: [],
        batchMetadataTotalCount: 0,
      };
    }
    if ("siteId" in payload.batchMetadataList[0]) {
      const newList = payload.batchMetadataList as EmployeeBatchMetadataItem[];
      return produce(state, (draft) => {
        draft.employeeBatchMetadataList = newList;
        draft.batchMetadataTotalCount = payload.totalCount;
      });
    }
    if ("downloadUrl" in payload.batchMetadataList[0]) {
      const newList = payload.batchMetadataList as EapprovalBatchMetadataItem[];
      return produce(state, (draft) => {
        draft.eapprovalBatchMetadataList = newList;
        draft.batchMetadataTotalCount = payload.totalCount;
      });
    }
    return state;
  })
  .handleAction(
    registerEmployeeBatchMetadataAsync.success,
    (state, { payload }) => {
      console.log("registerEmployeeBatchMetadataAsync success");
      console.log(payload);
      return produce(state, (draft) => {
        draft.batchMetadataTotalCount = state.batchMetadataTotalCount + 1;
        draft.employeeBatchRegisterInputs = payload;
      });
    }
  )
  .handleAction(updateBatchMetadataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.employeeBatchMetadataList = state.employeeBatchMetadataList.map(
        (item) => {
          if (item._id === payload._id) {
            return payload;
          }
          return item;
        }
      );
    });
  })
  .handleAction(resetBatchMetadataRegisterInputs, (state) => {
    return produce(state, (draft) => {
      draft.employeeBatchRegisterInputs = {
        ...initialBatchEmployeeMetadataRegisterInputs,
      };
      draft.eapprovalBatchRegisterInputs = {
        ...initialBatchEapprovalMetadataRegisterInputs,
      };
    });
  })
  .handleAction(resetBatchMetadataSearchInputs, (state) => {
    return produce(state, (draft) => {
      draft.employeeBatchSearchInputs = {
        ...initialBatchEmployeeMetadataSearchInputs,
      };
      draft.eapprovalBatchSearchInputs = {
        ...initialBatchEapprovalMetadataSearchInputs,
      };
    });
  });

export default batchMetadata;
