import { createReducer } from "typesafe-actions";
import produce from "immer";
import { AlertState, AlertAction } from "./types";
import { closeAlert, openAlert } from "./actions";

const initialState = {
  alertMsg: "",
  openAlert: false,
};

const alert = createReducer<AlertState, AlertAction>(initialState)
  .handleAction(openAlert, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.alertMsg = payload.alertMsg;
      draft.openAlert = true;
    });
  })
  .handleAction(closeAlert, (state, action) => {
    return produce(state, (draft) => {
      draft.alertMsg = "";
      draft.openAlert = false;
    });
  });

export default alert;
