import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { localDate } from "../../../../libs/transformDate";
import { selectTeamsTheme } from "../../../../features/auth";
import { TOKEN_MODEL_CODE } from "../../../../constants/tokenManagement";
import FilterPeriod from "../../../components/userInterface/filters/FilterPeriod";
import FilterSelectType from "../../../components/userInterface/filters/FilterSelectType";
import FilterInputTextInCheckLableModal from "../../../components/userInterface/filters/FilterInputTextInCheckLableModal";
import {
  BillingTitle,
  getTokenLogAsync,
  selectBillingNameList,
  selectBillingSearchInputs,
  setBillingSearchInputs,
} from "../../../../features/billing";
import { useAppDispatch } from "../../../../features";
import { selectBillingNameListLoading } from "../../../../features/ui_loading";
import MainColorBtn from "../../../components/userInterface/buttons/MainColorBtn";
import FilterInputTextType from "../../../components/userInterface/filters/FilterInputTextType";
import { AdminAllServiceType } from "../../../../features/batchMetadata";

const models = [
  { name: "ALL", value: TOKEN_MODEL_CODE.ALL },
  { name: "My AI Chats", value: TOKEN_MODEL_CODE.MY_GPTS },
  { name: "DALL·E", value: TOKEN_MODEL_CODE.DALLE },
  { name: "Assistant_All", value: TOKEN_MODEL_CODE.ASSISTANT_ALL },
  { name: "Assistant_Default", value: TOKEN_MODEL_CODE.ASSISTANT_DEFAULT },
  { name: "Assistant_Web", value: TOKEN_MODEL_CODE.ASSISTANT_WEB },
  { name: "Assistant_File", value: TOKEN_MODEL_CODE.ASSISTANT_FILE },
  { name: "Assistant_Dalle", value: TOKEN_MODEL_CODE.ASSISTANT_DALLE },
];

type Params = {
  title: BillingTitle;
  service: AdminAllServiceType;
};
export const BillingSearch = ({
  title,
  params,
}: {
  title: string;
  params: Params;
}) => {
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const billingNameList = useSelector(selectBillingNameList);
  const billingNameListLoading = useSelector(selectBillingNameListLoading);
  const billInputs = useSelector(selectBillingSearchInputs);

  const getTokenLogData = (newSkip?: number) => {
    console.log("title", title);
    if (title === "Company") {
      dispatch(
        getTokenLogAsync.request({
          payload: {
            ...billInputs,
            model: Number(billInputs.model),
            filterList: billInputs.filterList.length
              ? billInputs.filterList
              : [],
            filterType: billInputs.filterType,
            searchText: "",
            skip: newSkip ? newSkip : billInputs.skip,
          },
          title: params.title,
        })
      );
    } else {
      dispatch(
        getTokenLogAsync.request({
          payload: {
            ...billInputs,
            model: Number(billInputs.model),
            filterList: [],
            filterType: billInputs.filterType,
            skip: newSkip ? newSkip : billInputs.skip,
          },
          title: params.title,
        })
      );
    }
  };

  useEffect(() => {
    console.log(billInputs);
    if (billInputs.filterType === undefined || billingNameListLoading) return;
    getTokenLogData();
  }, [
    billingNameListLoading,
    billingNameList,
    billInputs.filterType,
    billInputs.domainType,
    billInputs.skip,
  ]);

  //! filter company 변경
  const onChangeCheckName = (item: { name: string; value: string }) => {
    const { filterList } = billInputs;
    if (filterList.includes(item.value)) {
      const newFilterList = filterList.filter((name) => name !== item.value);
      dispatch(
        setBillingSearchInputs({
          key: "filterList",
          value: newFilterList,
        })
      );
      return;
    }
    dispatch(
      setBillingSearchInputs({
        key: "filterList",
        value: [...filterList, item.value],
      })
    );
  };

  const onChangeAllCheckName = () => {
    const { filterList } = billInputs;

    if (filterList.length === billingNameList.length) {
      dispatch(
        setBillingSearchInputs({
          key: "filterList",
          value: [],
        })
      );
      return;
    }
    dispatch(
      setBillingSearchInputs({
        key: "filterList",
        value: billingNameList.map((item) => item.code),
      })
    );
  };

  return (
    <div className={`mgmt_page_filter mgmt_page_filter_${teamsTheme}`}>
      <FilterPeriod
        startDate={localDate(billInputs.startDate)}
        endDate={localDate(billInputs.endDate)}
        max={moment().format("YYYY-MM-DD")}
        startDateChangeHandler={(e) =>
          dispatch(
            setBillingSearchInputs({
              key: "startDate",
              value: new Date(e.target.value),
            })
          )
        }
        endDateChangeHandler={(e) =>
          dispatch(
            setBillingSearchInputs({
              key: "endDate",
              value: new Date(e.target.value),
            })
          )
        }
      />
      {params.service === "aichat" && (
        <FilterSelectType
          title="Model"
          value={billInputs.model.toString()}
          onChangeHandler={(e) =>
            dispatch(
              setBillingSearchInputs({
                key: "model",
                value: e.target.value,
              })
            )
          }
          options={models.map((model) => {
            return { name: model.name, value: model.value.toString() };
          })}
        />
      )}

      {title === "Company" ? (
        <FilterInputTextInCheckLableModal
          title={title + " Name"}
          onChangeHandler={onChangeCheckName}
          allCheckHandler={onChangeAllCheckName}
          checkedData={billInputs.filterList}
          defaultData={billingNameList.map((item) => ({
            name: item.name,
            value: item.code,
          }))}
        />
      ) : (
        <FilterInputTextType
          title={title + " Name"}
          value={billInputs.searchText as string}
          onChangeHandler={(e) =>
            dispatch(
              setBillingSearchInputs({
                key: "searchText",
                value: e.target.value,
              })
            )
          }
          deleteInputHandler={() =>
            dispatch(
              setBillingSearchInputs({
                key: "searchText",
                value: "",
              })
            )
          }
        />
      )}

      <div className="filter_tool_btns">
        <MainColorBtn
          btnName="Search"
          onClickHandler={() => {
            console.log(billInputs);
            if (billInputs.skip !== 1) {
              dispatch(
                setBillingSearchInputs({
                  key: "skip",
                  value: 1,
                })
              );
            } else {
              getTokenLogData();
            }
          }}
          loading={billingNameListLoading}
        />
      </div>
    </div>
  );
};
