type Props = {
  index: number;
  name: string;
  url: string;
};

export const DetailLink: React.FC<Props> = ({ index, name, url }) => (
  <li className="detail_info-li">
    <a href={url} target="_blank" rel="noreferrer">
      {name}
    </a>
  </li>
);
