import { all, call, put, takeLeading } from "typed-redux-saga";
import * as authAPI from "./api";
import {
  AAD_LOGIN,
  AAD_LOGIN_SUCCESS,
  aadLoginAsync,
  AGREE_TERMS_AND_CONDITIONS,
  agreeTermsAndConditionsAsync,
  GET_SETTING,
  getSettingAsync,
  LOGOUT,
  UPDATE_SETTING,
  updateSettingAsync,
} from "./actions";
import { errorAction, startAction, stopAction } from "../ui_loading";
import createAsyncSaga from "../../libs/createAsyncSaga";
import { validateMSToken } from "../../services/AADService";

//! logout
export function* logoutSaga() {
  yield localStorage.clear();
}

const agreeTermsAndConditionsSaga = createAsyncSaga(
  agreeTermsAndConditionsAsync,
  authAPI.agreeTermsAndConditions
);

const getSettingSaga = createAsyncSaga(getSettingAsync, authAPI.getSetting);
const updateSettingSaga = createAsyncSaga(
  updateSettingAsync,
  authAPI.updateSetting
);

export const callAADLoginApi = async (accessToken: string, loginType: number) =>
  await authAPI.aadLogin({
    accessToken,
    loginType,
  });
// .catch((err) => {
//   console.error(err);
//   return err;
// });

function* authorizeAADSaga(action: any) {
  try {
    yield* put(startAction({ name: AAD_LOGIN }));

    const response = yield* call(
      validateMSToken,
      action.payload.tenantId,
      action.payload.hash
    );

    if (response) {
      console.log(response);
      yield put({ type: AAD_LOGIN_SUCCESS, payload: response });
    }
    yield* put(stopAction({ name: AAD_LOGIN }));
  } catch (e: any) {
    console.log("error:", e);

    if (e.response) {
      yield* put(
        errorAction({
          type: aadLoginAsync.failure.toString(),
          errorCode: e.response.status,
          msg: e.response.data?.message ? e.response.data.message : "",
        })
      );
    }
  } finally {
    yield* put(stopAction({ name: AAD_LOGIN }));
  }
}

export function* authSaga() {
  yield* all([
    yield* takeLeading(AAD_LOGIN, authorizeAADSaga),
    yield* takeLeading(AGREE_TERMS_AND_CONDITIONS, agreeTermsAndConditionsSaga),
    yield* takeLeading(UPDATE_SETTING, updateSettingSaga),
    yield* takeLeading(GET_SETTING, getSettingSaga),
    yield* takeLeading(LOGOUT, logoutSaga),
  ]);
}
