import axios from "axios";

//! LOG
export interface GetLogDataParam {
  skip?: number;
  limit?: number;
  userId: string;
  startDate: Date | null;
  endDate: Date | null;
  compCode: string;
  deptCode: string;
  favorCode: number[];
}

export interface GetLogDataResult {
  totalCount: number;
  result: LogData[];
}

export interface LogData {
  _id: string;
  listId: string;
  question: string;
  keyword: string;
  answer: string;
  source: SourceInfo[];
  createdBy: string;
  createdDate: string;
  updatedDate: string;
  QuestionTokens: number;
  AnswerTokens: number;
  latency: number;
  deleteFlag: boolean;
  filtered: boolean;
  bingSearch: boolean;
  model: string;
  temperature: number;
  favor: number;
  finishReason: string;
  compCode: string;
  deptCode: string;
}

export interface SourceInfo {
  bingType: string;
  bingSourceUrl: string;
  bingSourceName: string;
  bingSourceContext: string;
  bingSourceProvider: string;
}

export interface GetEventLogDataParam {
  skip?: number;
  limit?: number;
  userId: string;
  startDate: Date | null;
  endDate: Date | null;
  logLevel: string;
  event: string;
}

export interface GetEventLogDataResult {
  totalCount: number;
  result: EventLogData[];
}

export interface EventLogData {
  _id: string;
  userId: string;
  logLevel: string;
  event: string;
  activity: string;
  error_detail: string;
  timestamp: string;
  latency: number;
}

//! log mgmt
export const getLogData = async (param: GetLogDataParam) => {
  const res = await axios.post(`/log/logSearch`, param);

  return res.data;
};

export const downloadLogData = async (param: GetLogDataParam) => {
  await axios
    .post(`/log/logDownload`, param, { responseType: "blob" })
    .then((res) => {
      const blob = new Blob([res.data]);

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `log.xlsx`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    });
};

export const getEventLogData = async (param: GetEventLogDataParam) => {
  const res = await axios.post(`/log/eventlogSearch`, param);

  return res.data;
};

export const downloadEventLogData = async (param: GetEventLogDataParam) => {
  await axios
    .post(`/log/eventlogDownload`, param, { responseType: "blob" })
    .then((res) => {
      const blob = new Blob([res.data]);

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `event_log.xlsx`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    });
};
