import "../../components/chat/fileUpload/FileCard.scss";
import { useState } from "react";
import clsx from "clsx";

import { ClipLoader } from "react-spinners";
import { FileExtensionIcon } from "../chat/fileUpload/FileExtensionIcon";
import CloseSVG from "../chat/fileUpload/CloseSVG";

type Props = {
  fileId: string;
  fileName: string;
  extension: string;
  imgUrl?: string;
  onDelete?: (fileId: string) => void;
  isLoading?: boolean;
};

const KnowledgeFileCardItem: React.FC<Props> = ({
  fileName,
  extension,
  onDelete,
  imgUrl,
  fileId,
  isLoading,
}) => {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <>
      <div
        className="file_card_container"
        onMouseOver={() => setIsHidden(false)}
        onMouseOut={() => setIsHidden(true)}
      >
        {isLoading ? (
          <ClipLoader loading={false} size={20} color="#80b1e2" />
        ) : (
          <>
            <FileExtensionIcon extension={extension} />
            <div className="file_info">
              <h4 className="file_title line_ellipsis">{fileName}</h4>
              <p className="file_extension line_ellipsis">{extension}</p>
            </div>
            {onDelete && (
              <button
                onClick={() => onDelete(fileId)}
                className={clsx("file_remove_button", {
                  force_hidden: isHidden === true,
                })}
              >
                <CloseSVG />
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
  // }
};

export default KnowledgeFileCardItem;
