import { Route, Routes, useLocation } from "react-router-dom";
import AccessMgmtUserPage from "./AccessMgmtUserPage";
import AccessMgmtCompanyPage from "./AccessMgmtCompanyPage";
import AccessMgmtDeptPage from "./AccessMgmtDeptPage";
import { useEffect, useState } from "react";
import AccessMgmtCompanyModal from "../../components/admin/AccessMgmtCompanyModal";
import AccessMgmtDeptModal from "../../components/admin/AccessMgmtDeptModal";
import AccessMgmtUserModal from "../../components/admin/AccessMgmtUserModal";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import "./admin.scss";
import TabBtn from "../../components/userInterface/buttons/TabBtn";
import { innerRoutes } from "../../../routes/routes";

const AccessMgmtRoute = () => {
  const location = useLocation();
  const teamsTheme = useSelector(selectTeamsTheme);

  const [selectTab, setSelectTab] = useState<"company" | "dept" | "user">(
    "company"
  );
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const lastPath = location.pathname.split("/").pop();

    setSelectTab(lastPath as "company" | "dept" | "user");
  }, [location.pathname]);

  return (
    <>
      {openModal && selectTab === "company" && (
        <AccessMgmtCompanyModal
          onClose={() => setOpenModal(false)}
          title="Register"
        />
      )}
      {openModal && selectTab === "dept" && (
        <AccessMgmtDeptModal
          onClose={() => setOpenModal(false)}
          title="Register"
        />
      )}
      {openModal && selectTab === "user" && (
        <AccessMgmtUserModal
          onClose={() => setOpenModal(false)}
          title="Register"
        />
      )}
      <div className={`mgmt_page scroll_none`}>
        <hgroup>
          <div className="title_tab_wrap">
            <h3 className={`page_title page_title_${teamsTheme}`}>
              Access Rights Management
            </h3>
            <div className={`tab_btn_wrap tab_btn_wrap_${teamsTheme}`}>
              <TabBtn
                tabName="Company"
                selectedTab={selectTab}
                value="company"
                onClickHandler={() => setSelectTab("company")}
                pathName={innerRoutes.ACCESS_MGMT_COMPANY.path}
              />
              <TabBtn
                tabName="Department"
                selectedTab={selectTab}
                value="dept"
                onClickHandler={() => setSelectTab("dept")}
                pathName={innerRoutes.ACCESS_MGMT_DEPARTMENT.path}
              />
              <TabBtn
                tabName="User"
                selectedTab={selectTab}
                value="user"
                onClickHandler={() => setSelectTab("user")}
                pathName={innerRoutes.ACCESS_MGMT_USER.path}
              />
            </div>
          </div>

          <MainColorBtn
            onClickHandler={() => setOpenModal(true)}
            btnName="Register"
          />
        </hgroup>

        <Routes>
          <Route path="/company" element={<AccessMgmtCompanyPage />} />
          <Route path="/dept" element={<AccessMgmtDeptPage />} />
          <Route path="/user" element={<AccessMgmtUserPage />} />
        </Routes>
      </div>
    </>
  );
};

export default AccessMgmtRoute;
