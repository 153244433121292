import { createReducer } from "typesafe-actions";
import {
  deleteChatAsync,
  getBeforeChatListAsync,
  clearChat,
  getAnswerAsync,
  getSelectChatInfoAsync,
  cancelChatAsync,
  selectChatLog,
  modifyChatAsync,
  likeAnswerAsync,
  regenerateAnswerAsync,
  modifyChatPinAsync,
  streamUpdate,
  setModelEngine,
  getDalleAnswerAsync,
  setInputUploadFiles,
  uploadFileAsync,
  setSelectedGptOption,
  setPromptText,
  deleteUploadFileAsync,
  setAssistantTypeInfo,
  setIsShowDeleteCheckbox,
  setCheckedQaIds,
  setIsScrollToBottom,
} from "./actions";
import produce from "immer";
import { ChatState, ChatAction, ONLY_CLIENT_FILE } from "./types";
import { MessageInfo } from "./api";
import CHAT from "../../constants/chat";
import parse from "html-react-parser";

const { EMPTY_CHAT_INDEX, DEFAULT_QA_LIST_PAGE_SIZE } = CHAT;
export const DEFAULT_MODEL_ENGINE = "4.0v";

const initialState: ChatState = {
  promptText: "",
  chatList: [],
  prevMessages: [],
  qaList: [],
  qaListPageSize: DEFAULT_QA_LIST_PAGE_SIZE,
  qaListId: EMPTY_CHAT_INDEX,
  selectedChatId: EMPTY_CHAT_INDEX,
  firstSelectedChatId: EMPTY_CHAT_INDEX,
  selectedData: false,
  hasMore: true,
  selectedLog: "",
  removeStartChat: false,
  pinnedQaList: [],
  chatLoading: false,
  modelEngine: DEFAULT_MODEL_ENGINE,
  isScrollToBottom: true,
  selectedGptOption: {
    value: "4.0",
    label: parse("4.0"),
    description: "Advanced model for complex tasks",
    chatModel: DEFAULT_MODEL_ENGINE,
  },
  inputUploadFiles: {
    fileCards: [],
    fileData: [],
  },
  isUploadFile: false,
  fileInfoList: [],
  assistantTypeInfo: {
    myGPTsId: null,
    assistantType: "DEFAULT",
    capabilities: null,
    profileImage: null,
  },
  checkedQaIds: [],
  isShowDeleteCheckbox: false,
};

const chat = createReducer<ChatState, ChatAction>(initialState)
  .handleAction(setPromptText, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.promptText = payload;
    });
  })
  .handleAction(setIsScrollToBottom, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.isScrollToBottom = payload;
    });
  })
  .handleAction(getAnswerAsync.request, (state, { payload }) => {
    return produce(state, (draft) => {
      if (state.chatList.length === 0) {
        draft.removeStartChat = true;
      }

      const lastChat: MessageInfo = {
        _id: EMPTY_CHAT_INDEX,
        listId: EMPTY_CHAT_INDEX,
        question: payload.prompt,
        keyword: "",
        answer: "",
        source: [],
        createdBy: "",
        createdDate: "",
        updatedDate: "",
        failure: false,
        bingSearch: false,
        favor: 0,
        latency: 0,
        finishReason: "stop",
        fileInfo: payload.fileInfo ?? [],
        dalleUrl: "",
      };

      draft.chatLoading = true;
      draft.chatList = [...state.chatList, lastChat];
    });
  })
  .handleAction(getDalleAnswerAsync.request, (state, { payload }) => {
    return produce(state, (draft) => {
      if (state.chatList.length === 0) {
        draft.removeStartChat = true;
      }
      const lastChat: MessageInfo = {
        _id: EMPTY_CHAT_INDEX,
        listId: EMPTY_CHAT_INDEX,
        question: payload.prompt,
        keyword: "",
        answer: "",
        source: [],
        createdBy: "",
        createdDate: "",
        updatedDate: "",
        failure: false,
        bingSearch: false,
        favor: 0,
        latency: 0,
        finishReason: "stop",
        fileInfo: payload.fileInfo ?? [],
        dalleUrl: "",
      };

      draft.chatLoading = true;
      draft.chatList = [...state.chatList, lastChat];
    });
  })
  .handleAction(getAnswerAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      // draft.isWebBrowsing = false;

      draft.chatList = [
        ...state.chatList.slice(0, state.chatList.length - 1),
        { ...payload, failure: false, answerDone: true },
      ];
      draft.prevMessages = [...state.prevMessages, payload];
      draft.selectedData = true;
      draft.chatLoading = false;

      if (payload?.isPreviewChat) return;
      if (state.chatList.length === 1) {
        draft.firstSelectedChatId = payload.listId;
        draft.qaListId = payload.listId;
        // ❓❓❓
        // draft.qaItemId = payload.listId;
        draft.qaList = [
          {
            _id: payload.listId,
            title: payload.keyword,
            description: "",
            createdDate: payload.createdDate,
            createdBy: payload.createdBy,
            updatedDate: payload.createdDate,
            pinned: false,
            chatType: null,
            fileInfo: payload.fileInfo,
            qaModelEngine: draft.modelEngine,
          },
          ...state.qaList,
        ];
      } else {
        draft.qaList = state.qaList.map((qaItem) => {
          if (qaItem._id === payload.listId) {
            return {
              ...qaItem,
              fileInfo: qaItem.fileInfo.length
                ? qaItem.fileInfo
                : payload.fileInfo,
              updatedDate: payload.updatedDate,
            };
          } else {
            return qaItem;
          }
        });
      }
    });
  })
  .handleAction(getDalleAnswerAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.chatList = [
        ...state.chatList.slice(0, state.chatList.length - 1),
        { ...payload, failure: false, answerDone: true },
      ];
      draft.prevMessages = [...state.prevMessages, payload];

      draft.selectedData = true;
      draft.chatLoading = false;

      if (state.chatList.length === 1) {
        draft.firstSelectedChatId = payload.listId;
        draft.qaListId = payload.listId;
        draft.qaList = [
          {
            _id: payload.listId,
            title: payload.keyword,
            description: "",
            createdDate: payload.createdDate,
            createdBy: payload.createdBy,
            updatedDate: payload.createdDate,
            pinned: false,
            chatType: null,
            fileInfo: payload.fileInfo,
            qaModelEngine: draft.modelEngine,
          },
          ...state.qaList,
        ];
      }
    });
  })
  .handleAction(getAnswerAsync.failure, (state, { payload }) => {
    return produce(state, (draft) => {
      const lastChat = state.chatList[state.chatList.length - 1];

      draft.chatList = [
        ...state.chatList.slice(0, state.chatList.length - 1),
        {
          ...lastChat,
          answer: payload.errMsg,
          failure: true,
          answerDone: true,
        },
      ];

      draft.selectedData = true;
      draft.chatLoading = false;
    });
  })
  .handleAction(getDalleAnswerAsync.failure, (state, { payload }) => {
    return produce(state, (draft) => {
      const lastChat = state.chatList[state.chatList.length - 1];

      draft.chatList = [
        ...state.chatList.slice(0, state.chatList.length - 1),
        {
          ...lastChat,
          answer: payload.errMsg,
          failure: true,
          answerDone: true,
        },
      ];

      draft.selectedData = true;
      draft.chatLoading = false;
    });
  })
  .handleAction(regenerateAnswerAsync.request, (state, { payload }) => {
    return produce(state, (draft) => {
      const lastChat = state.chatList[state.chatList.length - 1];

      const newLastChat: MessageInfo = {
        ...lastChat,
        answer: "",
        failure: false,
      };

      draft.chatList = [
        ...state.chatList.slice(0, state.chatList.length - 1),
        newLastChat,
      ];
      draft.chatLoading = true;
    });
  })
  .handleAction(regenerateAnswerAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.chatList = [
        ...state.chatList.slice(0, state.chatList.length - 1),
        { ...payload, failure: false, answerDone: true },
      ];
      draft.prevMessages = [...state.prevMessages, payload];

      draft.selectedChatId = payload.listId;
      draft.qaListId = payload.listId;

      draft.selectedData = true;
      draft.chatLoading = false;
    });
  })
  .handleAction(regenerateAnswerAsync.failure, (state, { payload }) => {
    return produce(state, (draft) => {
      const lastChat = state.chatList[state.chatList.length - 1];

      draft.chatList = [
        ...state.chatList.slice(0, state.chatList.length - 1),
        {
          ...lastChat,
          answer: payload.errMsg,
          failure: true,
          answerDone: true,
        },
      ];

      draft.selectedData = true;
      draft.chatLoading = false;
    });
  })
  .handleAction(getBeforeChatListAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      if (
        payload.result.length +
          payload.pinned.length -
          (draft.qaList.length + draft.pinnedQaList.length) <
        30
      ) {
        // mobile 햄버거 클릭시 다시 불러오면서 hasMore false로 변경되는 현상 방지
        if (payload.pageSize > 30) {
          draft.hasMore = false;
        } else {
          draft.hasMore = true;
        }
      }

      if (payload.result.length === 0) {
        draft.qaList = [];
      } else {
        draft.qaList = payload.result;
      }

      if (payload.pinned.length === 0) {
        draft.pinnedQaList = [];
      } else {
        draft.pinnedQaList = payload.pinned;
      }
      draft.qaListPageSize = payload.pageSize + 30;
    });
  })
  .handleAction(deleteChatAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      const { listIds } = payload;

      draft.pinnedQaList = state.pinnedQaList.filter(
        (item) => !listIds.includes(item._id)
      );
      draft.qaList = state.qaList.filter((item) => !listIds.includes(item._id));
      draft.selectedChatId = EMPTY_CHAT_INDEX;
      draft.qaListId = EMPTY_CHAT_INDEX;
      draft.firstSelectedChatId = EMPTY_CHAT_INDEX;
      draft.chatList = [];
      draft.checkedQaIds = [];
      draft.qaListPageSize = DEFAULT_QA_LIST_PAGE_SIZE;
    });
  })
  .handleAction(getSelectChatInfoAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.removeStartChat = false;
      if (!payload.messageList.length) {
        // result: [] 일때 처리 필요
        // alert("에러가 발생한 채팅, 에러 처리 필요");
      } else {
        draft.chatList = payload.messageList.map((message) => {
          if (message?.failure) {
            return { ...message, failure: true };
          } else {
            return { ...message, failure: false };
          }
        });
      }
      draft.prevMessages = payload.messageList;
      draft.selectedChatId = payload.id;
      draft.qaListId = payload.id;
      draft.isScrollToBottom = true;
      draft.firstSelectedChatId = EMPTY_CHAT_INDEX;
      draft.selectedData = false;

      if (!payload.gptAssistantInfo) {
        draft.assistantTypeInfo.myGPTsId = null;
        draft.assistantTypeInfo.assistantType = "DEFAULT";
        draft.assistantTypeInfo.capabilities = null;
        draft.assistantTypeInfo.profileImage = null;
        return;
      }

      draft.assistantTypeInfo.myGPTsId =
        payload.gptAssistantInfo?.myGPTsId ?? null;
      draft.assistantTypeInfo.assistantType =
        payload.gptAssistantInfo?.lastQAType ?? "DEFAULT";

      if (payload.gptAssistantInfo?.assistantCapabilities) {
        draft.assistantTypeInfo.capabilities =
          ["DEFAULT", ...payload.gptAssistantInfo.assistantCapabilities] ??
          null;
      }
      draft.assistantTypeInfo.profileImage =
        payload.gptAssistantInfo?.profileImage ?? null;

      if (payload.gptAssistantInfo?.myGPTsId) {
        draft.modelEngine = payload.gptAssistantInfo.qaModelEngine;
      }
    });
  })
  .handleAction(clearChat, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.removeStartChat = false;
      draft.chatList = [];
      draft.prevMessages = [];
      draft.selectedChatId = EMPTY_CHAT_INDEX;
      draft.firstSelectedChatId = EMPTY_CHAT_INDEX;
      draft.qaListId = EMPTY_CHAT_INDEX;
      draft.selectedData = false;
      draft.inputUploadFiles.fileCards = [];
      draft.inputUploadFiles.fileData = [];
      draft.fileInfoList = [];
      draft.selectedGptOption = {
        value: "4.0",
        label: parse("4.0"),
        description: "Advanced model for complex tasks",
        chatModel: DEFAULT_MODEL_ENGINE,
      };
    });
  })

  .handleAction(cancelChatAsync, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.removeStartChat = true;
      const lastChat = state.chatList[state.chatList.length - 1];

      draft.selectedData = false;
      draft.chatLoading = false;

      draft.chatList = [
        ...state.chatList.slice(0, state.chatList.length - 1),
        {
          ...lastChat,
          answer: "Your response has been canceled.",
          failure: true,
        },
      ];
    });
  })

  .handleAction(selectChatLog, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.selectedLog = payload.id;
    });
  })
  .handleAction(modifyChatAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      if (payload.pinned) {
        draft.pinnedQaList = state.pinnedQaList.map((chat) => {
          if (chat._id === payload._id) {
            return payload;
          } else {
            return chat;
          }
        });
      } else {
        draft.qaList = state.qaList.map((chat) => {
          if (chat._id === payload._id) {
            return payload;
          } else {
            return chat;
          }
        });
      }
    });
  })
  .handleAction(modifyChatPinAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      if (payload.pinned) {
        draft.pinnedQaList = [payload, ...state.pinnedQaList];
        draft.qaList = state.qaList.filter((qa) => qa._id !== payload._id);
      } else {
        draft.pinnedQaList = state.pinnedQaList.filter(
          (qa) => qa._id !== payload._id
        );
        draft.qaList = [payload, ...state.qaList];
      }
    });
  })
  .handleAction(likeAnswerAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.chatList = state.chatList.map((chat) => {
        if (chat._id === payload._id) {
          return { ...payload, answerDone: true };
        } else {
          return chat;
        }
      });
    });
  })
  .handleAction(streamUpdate, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.chatList[state.chatList.length - 1].answer = payload.answer;
    });
  })
  .handleAction(setModelEngine, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.modelEngine = payload;
    });
  })
  .handleAction(setAssistantTypeInfo, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.assistantTypeInfo = {
        assistantType: payload.assistantType,
        capabilities: payload.capabilities,
        profileImage: payload.profileImage,
        myGPTsId: payload.myGPTsId,
        // capabilities: payload.capabilities,
      };
    });
  })
  .handleAction(setSelectedGptOption, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.selectedGptOption = { ...payload };
    });
  })
  .handleAction(setInputUploadFiles, (state, { payload }) => {
    return produce(state, (draft) => {
      if (payload) {
        draft.inputUploadFiles.fileCards.push(payload);
      } else {
        draft.inputUploadFiles.fileCards = [];
        draft.inputUploadFiles.fileData = [];
        draft.fileInfoList = [];
      }
    });
  })
  .handleAction(uploadFileAsync.success, (state, { payload }) => {
    const { fileData, fileInfoResponse } = payload;
    return produce(state, (draft) => {
      if (fileInfoResponse?.listId) {
        draft.qaListId = fileInfoResponse.listId;
        draft.selectedChatId = fileInfoResponse.listId;
      }
      draft.fileInfoList.push(fileInfoResponse.fileInfo);

      const res = draft.inputUploadFiles.fileCards.map((file) => {
        if (
          file.name === fileInfoResponse.fileInfo.fileName &&
          file.fileId === ONLY_CLIENT_FILE
        ) {
          return {
            ...file,
            isLoading: false,
            fileId: fileInfoResponse.fileInfo.fileId,
          };
        } else {
          return file;
        }
      });

      draft.inputUploadFiles.fileCards = res;
      draft.inputUploadFiles.fileData.push(fileData);
    });
  })
  .handleAction(uploadFileAsync.failure, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.inputUploadFiles.fileCards =
        state.inputUploadFiles.fileCards.filter(
          (elem) => elem.fileId !== ONLY_CLIENT_FILE
        );
    });
  })
  .handleAction(deleteUploadFileAsync.request, (state, { payload }) => {
    return produce(state, (draft) => {
      const res = draft.inputUploadFiles.fileCards.map((file) => {
        if (file.fileId === payload) {
          return {
            ...file,
            isLoading: true,
          };
        } else {
          return file;
        }
      });
      draft.inputUploadFiles.fileCards = res;
    });
  })
  .handleAction(deleteUploadFileAsync.success, (state, { payload }) => {
    const { fileId } = payload;

    return produce(state, (draft) => {
      const indexVale = draft.inputUploadFiles.fileCards.findIndex(
        (elem) => elem.fileId === fileId
      );
      draft.inputUploadFiles.fileCards.splice(indexVale, 1);
      draft.inputUploadFiles.fileData.splice(indexVale, 1);
      draft.fileInfoList.splice(indexVale, 1);
    });
  })
  .handleAction(setIsShowDeleteCheckbox, (state, { payload }) => {
    return produce(state, (draft) => {
      if (!payload) {
        draft.checkedQaIds = [];
      }
      draft.isShowDeleteCheckbox = payload;
    });
  })
  .handleAction(setCheckedQaIds, (state, { payload }) => {
    return produce(state, (draft) => {
      const { type, checkedIds } = payload;

      if (type === "All") {
        if (
          state.checkedQaIds.length ===
          state.qaList.length + state.pinnedQaList.length
        ) {
          draft.checkedQaIds = [];
        } else {
          draft.checkedQaIds = checkedIds;
        }
      }
      if (type === "Single") {
        if (state.checkedQaIds.includes(checkedIds)) {
          draft.checkedQaIds = state.checkedQaIds.filter(
            (id) => id !== checkedIds
          );
        }
        if (!state.checkedQaIds.includes(checkedIds)) {
          draft.checkedQaIds.push(checkedIds);
        }
      }
    });
  });

export default chat;
