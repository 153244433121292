import React from "react";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { selectTeamsTheme } from "../../../features/auth";
import TableBtn from "../userInterface/buttons/TableBtn";
import { transformDate } from "../../../libs/transformDate";
import { useAppDispatch } from "../../../features";
import {
  selectGetMyGptsLoading,
  selectUploadFileLoading,
} from "../../../features/ui_loading";
import {
  selectMyGptsList,
  selectSelectedDeleteId,
} from "../../../features/myGpts";
import { useNavigate } from "react-router-dom";
import { innerRoutes } from "../../../routes/routes";

export type MyAIChatTableProps = {
  setSelectedDeleteId: React.Dispatch<React.SetStateAction<string>>;
  setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ENDPOINT_TABLE_COLUMNS = [
  // "Profile Image",
  "Name",
  "Description",
  // "Instructions",
  // "Conversation Starters",
  // "Knowledge",
  "Created Date",
  "Updated Date",
  "",
];

const CreatedMyAIChatTable: React.FC<MyAIChatTableProps> = ({
  setSelectedDeleteId,
  setDeleteModal,
}) => {
  const dispatch = useAppDispatch();
  const myGptsList = useSelector(selectMyGptsList);
  const teamsTheme = useSelector(selectTeamsTheme);
  const getMyGptLoading = useSelector(selectGetMyGptsLoading);
  const selectedDeleteId = useSelector(selectSelectedDeleteId);
  const navigate = useNavigate();

  // const activateItemHandler = (item: ModelInfo) => {
  //   dispatch(updateModelAsync.request({ ...item, activated: !item.activated }));
  // };

  return (
    <table className={`page_table page_table-${teamsTheme}`} width="500">
      <colgroup>
        {/* <col width="15%" /> */}
        <col width="40%" />
        <col width="40%" />
        {/* <col width="45%" />
        <col width="30%" /> */}
        {/* <col width="30%" /> */}
        <col width="15%" />
        <col width="15%" />
        <col width="150px" />
      </colgroup>
      <thead>
        {/* <thead className={`thead_${teamsTheme}`}> */}
        <tr>
          {ENDPOINT_TABLE_COLUMNS.map((elem, idx) => (
            <th key={idx} title={elem}>
              {elem}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* <tbody className={`tbody_${teamsTheme}`}> */}
        {myGptsList.length > 0 ? (
          myGptsList.slice(0, 10).map((item, idx) => (
            <React.Fragment key={`${idx}_${item.id}`}>
              {selectedDeleteId.includes(item.id) ? (
                <tr className="deleting_msg" key={item.id}>
                  <td colSpan={9} style={{ textAlign: "center" }}>
                    삭제중입니다...
                  </td>
                </tr>
              ) : (
                <tr key={item.id}>
                  {/* <td className="line_change">
                    {item.profileImage ? (
                      <img className="td_img" src={item.profileImage} />
                    ) : (
                      "-"
                    )}
                  </td> */}
                  <td className="line_change" title={item.name}>
                    {item.name}
                  </td>
                  <td className="line_change" title={item.description}>
                    {item.description ? item.description : "-"}
                  </td>
                  {/* <td className="line_change" title={item.instructions}>
                    {item.instructions ? item.instructions : "-"}
                  </td>
                  <td
                    className="line_change"
                    title={item.conversationStarters.join(", ")}
                  >
                    {item.conversationStarters.length > 0
                      ? item.conversationStarters.join(", ")
                      : "-"}
                  </td> */}
                  {/* <td
                className="line_change"
                title={
                  item.knowledge
                    ? item.knowledge.map((data) => data.fileName).join(", ")
                    : ""
                }
              >
                {item.knowledge.length > 0
                  ? item.knowledge.flatMap((data) => data.fileName).join(", ")
                  : "-"}
              </td> */}
                  <td
                    className="line_change"
                    title={
                      item.createdDate ? transformDate(item.createdDate) : ""
                    }
                  >
                    {item.createdDate ? transformDate(item.createdDate) : "-"}
                  </td>
                  <td
                    className="line_change"
                    title={
                      item.updatedDate ? transformDate(item.updatedDate) : ""
                    }
                  >
                    {item.updatedDate ? transformDate(item.updatedDate) : "-"}
                  </td>

                  <td className="td_btn_wrap">
                    <TableBtn
                      onClickHandler={() =>
                        navigate(`${innerRoutes.EDIT_MY_CHAT.path}/${item.id}`)
                      }
                      btnName="modify"
                    />
                    <TableBtn
                      onClickHandler={() => {
                        setDeleteModal(true);
                        setSelectedDeleteId(item.id);
                      }}
                      btnName="delete"
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        ) : (
          <tr className="no_data" key={`no_data`}>
            <td colSpan={9} style={{ textAlign: "center" }}>
              {getMyGptLoading ? (
                <ClipLoader
                  loading={getMyGptLoading}
                  size={20}
                  color="#80b1e2"
                />
              ) : (
                "No Data"
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default CreatedMyAIChatTable;
