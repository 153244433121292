import { memo } from "react";

type Props = {
  onClickHandler: () => void;
  btnName: string;
};

const NoneBackgroundBtn: React.FC<Props> = ({ onClickHandler, btnName }) => {
  return (
    <button className={`none_background_btn`} onClick={onClickHandler}>
      {btnName}
    </button>
  );
};

export default memo(NoneBackgroundBtn);
