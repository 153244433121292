import { ReactComponent as PPTFile } from "../../../../assets/svg/fileExtension/PPTFile.svg";
import { ReactComponent as ExcelFile } from "../../../../assets/svg/fileExtension/ExcelFile.svg";
import { ReactComponent as DocsFile } from "../../../../assets/svg/fileExtension/DocsFile.svg";
import { ReactComponent as DefaultFile } from "../../../../assets/svg/fileExtension/DefaultFile.svg";
import { getFileType } from "../../../../services/FileUpload";
import { useCallback, useEffect, useState } from "react";

type Props = {
  extension: string;
};

export const FileExtensionIcon: React.FC<Props> = ({ extension }) => {
  const [FileIcon, setFileIcon] = useState<React.SVGProps<SVGSVGElement>>();

  const changeFileIcon = useCallback(() => {
    if (getFileType(extension) === "Spreadsheet") {
      setFileIcon(<ExcelFile />);
      return;
    }
    if (getFileType(extension) === "Document") {
      setFileIcon(<DocsFile />);
      return;
    }
    if (getFileType(extension) === "PDF") {
      setFileIcon(<DocsFile />);
      return;
    }
    if (getFileType(extension) === "Presentation") {
      setFileIcon(<PPTFile />);
      return;
    }
    setFileIcon(<DefaultFile />);
  }, [extension]);

  useEffect(() => {
    changeFileIcon();
  }, [changeFileIcon]);

  return <>{FileIcon}</>;
};
