import createAsyncSaga from "../../libs/createAsyncSaga";
import { all, takeLeading } from "typed-redux-saga";

import {
  DOWNLOAD_EVENT_LOG_DATA,
  DOWNLOAD_LOG_DATA,
  GET_EVENT_LOG_DATA,
  GET_LOG_DATA,
  downloadEventLogDataAsync,
  downloadLogDataAsync,
  getEventLogDataAsync,
  getLogDataAsync,
} from "./actions";
import {
  downloadEventLogData,
  downloadLogData,
  getEventLogData,
  getLogData,
} from "./api";

const getLogDataSaga = createAsyncSaga(getLogDataAsync, getLogData);
const getEventLogDataSaga = createAsyncSaga(
  getEventLogDataAsync,
  getEventLogData
);

const downloadEventLogDataSaga = createAsyncSaga(
  downloadEventLogDataAsync,
  downloadEventLogData
);

const downloadLogDataSaga = createAsyncSaga(
  downloadLogDataAsync,
  downloadLogData
);

export function* logSaga() {
  //! log mgmt
  yield* all([yield* takeLeading(GET_LOG_DATA, getLogDataSaga)]);
  yield* all([yield* takeLeading(GET_EVENT_LOG_DATA, getEventLogDataSaga)]);
  yield* all([
    yield* takeLeading(DOWNLOAD_EVENT_LOG_DATA, downloadEventLogDataSaga),
  ]);
  yield* all([yield* takeLeading(DOWNLOAD_LOG_DATA, downloadLogDataSaga)]);
}
