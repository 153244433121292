import createAsyncSaga from "../../libs/createAsyncSaga";
import {
  all,
  call,
  getContext,
  put,
  race,
  take,
  takeLeading,
} from "typed-redux-saga";
import {
  DELETE_CHAT,
  DELETE_UPLOAD_FILE,
  GET_ANSWER,
  GET_BEFORE_CHAT_LIST,
  GET_DALLE_ANSWER,
  GET_SELECT_CHAT_INFO,
  LIKE_ANSWER,
  MODIFY_CHAT,
  MODIFY_CHAT_PIN,
  REGENERATE_ANSWER,
  // REGENERATE_DALLE_ANSWER,
  UPLOAD_FILE,
  cancelChatAsync,
  deleteChatAsync,
  deleteUploadFileAsync,
  getAnswerAsync,
  getBeforeChatListAsync,
  getDalleAnswerAsync,
  getSelectChatInfoAsync,
  likeAnswerAsync,
  modifyChatAsync,
  modifyChatPinAsync,
  regenerateAnswerAsync,
  streamUpdate,
  uploadFileAsync,
} from "./actions";
import {
  deleteCheckedChats,
  deleteItem,
  getBeforeChatList,
  getSelectChatInfo,
  likeAnswer,
  modifyChat,
  getStreamAnswer,
  uploadFile,
  deleteFile,
} from "./api";
import { startAction, stopAction } from "../ui_loading";
import { openPopup } from "../auth";
import { errHandler } from "../../libs/errHandler";
import CHAT from "../../constants/chat";
import { openAlert } from "../alert";

const { EMPTY_CHAT_INDEX } = CHAT;

function* getAnswerSaga(action: any): any {
  const params = action.payload;

  const isPreviewChat = params?.isPreviewChat ?? null;
  let typeCheckAction;

  // // action type에 따라 다른 action을 dispatch
  if (action.type === GET_ANSWER) {
    typeCheckAction = getAnswerAsync;
  }
  if (action.type === REGENERATE_ANSWER) {
    typeCheckAction = regenerateAnswerAsync;
  }
  if (action.type === GET_DALLE_ANSWER) {
    typeCheckAction = getDalleAnswerAsync;
  }
  if (!typeCheckAction) {
    console.log("error", "action type is not defined");
    return;
  }
  // const typeCheckAction =
  //   action.type === GET_ANSWER ? getAnswerAsync : regenerateAnswerAsync;

  const dispatch = yield getContext("dispatch");
  const dispatchHandler = {
    streamUpdate: (answer: string) => {
      dispatch(streamUpdate({ answer }));
    },
  };

  // 약관 동의 여부 확인
  if (localStorage.getItem("isAgreed") === "false") {
    yield* put(openPopup({ open: true }));
    return;
  }

  yield* put(startAction({ name: action.type }));

  try {
    const ctrl = new AbortController();

    const { response, cancel } = yield race({
      response: call(getStreamAnswer, params, dispatchHandler, ctrl.signal),
      cancel: take(cancelChatAsync),
    });

    // cancel action이 dispatch되면 race를 통해 getStreamAnswer을 취소
    if (cancel) {
      ctrl.abort();
      return;
    }
    if (response) {
      yield put(typeCheckAction.success({ ...response, isPreviewChat }));
    }

    // regenerateAnswer의 경우 itemId가 -1이 아닐 때만 해당 채팅 삭제 후 다시 생성
    if (
      action.type === REGENERATE_ANSWER &&
      action.payload.itemId !== EMPTY_CHAT_INDEX
    ) {
      yield* call(deleteItem, action.payload.itemId);
    }
  } catch (error: any) {
    console.log("error", error);

    try {
      errHandler(error.response.request.status);
    } catch {
      console.log("error", error);
    }
    yield put(getAnswerAsync.failure({ errMsg: `${error}` }));
  } finally {
    yield* put(stopAction({ name: action.type }));
  }
}

function* getBeforeChatListSaga(action: any) {
  const param = action.payload;
  try {
    yield* put(startAction({ name: action.type }));

    const response = yield* call(getBeforeChatList, param);
    yield* put(
      getBeforeChatListAsync.success({ ...response, pageSize: param.pageSize })
    );
  } catch (error: any) {
    console.log("error", error);
    try {
      errHandler(error.response.request.status, error.code);
    } catch {
      console.log("error", error);
    }
    yield put(getBeforeChatListAsync.failure(error));
  } finally {
    yield* put(stopAction({ name: action.type }));
  }
}

function* uploadFileSaga(action: any) {
  const { fileList } = action.payload;

  if (fileList.length !== 0) {
    try {
      yield* put(startAction({ name: action.type }));

      const fileInfoResponse = yield* call(uploadFile, fileList[0].param);
      const listId = fileInfoResponse.listId;

      yield* put(
        uploadFileAsync.success({
          ...fileList[0].uploadFileData,
          fileInfoResponse,
        })
      );

      for (let i = 1; i < fileList.length; i++) {
        fileList[i].param.append("listId", listId);
        const fileInfoResponse = yield* call(uploadFile, fileList[i].param);

        yield* put(
          uploadFileAsync.success({
            ...fileList[0].uploadFileData,
            fileInfoResponse,
          })
        );
      }
    } catch (error: any) {
      console.log("error", error);
      try {
        if (error.response.request.status === 418) {
          yield put(openAlert({ alertMsg: error.response.data.detail }));
        }
        errHandler(error.response.request.status, error.code);
      } catch {
        console.log("error", error);
      }
      yield put(uploadFileAsync.failure(error));
    } finally {
      yield* put(stopAction({ name: action.type }));
    }
  } else {
    try {
      yield* put(startAction({ name: action.type }));

      const fileInfoResponse = yield* call(uploadFile, fileList[0].param);
      yield* put(
        uploadFileAsync.success({
          ...fileList[0].uploadFileData,
          fileInfoResponse,
        })
      );
    } catch (error: any) {
      console.log("error", error);
      try {
        errHandler(error.response.request.status, error.code);
      } catch {
        console.log("error", error);
      }
      yield put(uploadFileAsync.failure(error));
    } finally {
      yield* put(stopAction({ name: action.type }));
    }
  }
}

const deleteChatSaga = createAsyncSaga(deleteChatAsync, deleteCheckedChats);
const getSelectChatInfoSaga = createAsyncSaga(
  getSelectChatInfoAsync,
  getSelectChatInfo
);
const modifyChatSaga = createAsyncSaga(modifyChatAsync, modifyChat);
const modifyChatPinSaga = createAsyncSaga(modifyChatPinAsync, modifyChat);
const likeAnswerSaga = createAsyncSaga(likeAnswerAsync, likeAnswer);
const deleteFileSaga = createAsyncSaga(deleteUploadFileAsync, deleteFile);

export function* chatSaga() {
  yield* all([yield* takeLeading(GET_ANSWER, getAnswerSaga)]);
  yield* all([yield* takeLeading(REGENERATE_ANSWER, getAnswerSaga)]);
  yield* all([yield* takeLeading(GET_DALLE_ANSWER, getAnswerSaga)]);
  // yield* all([yield* takeLeading(REGENERATE_DALLE_ANSWER, getAnswerSaga)]);
  yield* all([yield* takeLeading(UPLOAD_FILE, uploadFileSaga)]);
  yield* all([yield* takeLeading(DELETE_UPLOAD_FILE, deleteFileSaga)]);
  yield* all([yield* takeLeading(GET_BEFORE_CHAT_LIST, getBeforeChatListSaga)]);
  yield* all([yield* takeLeading(DELETE_CHAT, deleteChatSaga)]);
  yield* all([yield* takeLeading(GET_SELECT_CHAT_INFO, getSelectChatInfoSaga)]);
  yield* all([yield* takeLeading(MODIFY_CHAT, modifyChatSaga)]);
  yield* all([yield* takeLeading(MODIFY_CHAT_PIN, modifyChatPinSaga)]);
  yield* all([yield* takeLeading(LIKE_ANSWER, likeAnswerSaga)]);
}
