import { ActionType } from "typesafe-actions";
import * as actions from "./actions";
import {
  BeforeChatData,
  IFileInfoItem,
  IFileUploadResponse,
  MessageInfo,
} from "./api";
import { maxTokenInfo } from "../../utils/getMaxTokenNumber";

export type ChatAction = ActionType<typeof actions>;

export type ModelVersion = keyof typeof maxTokenInfo;
export type ModelEngine = ModelVersion | "dalle" | "myGPTs";
export const ONLY_CLIENT_FILE = "ONLY_CLIENT_FILE";

export type FileCard = {
  fileId?: string;
  listId?: string;
  name: string;
  extension: string;
  imgUrl: string;
  isLoading: boolean;
};
export type LocalUploadFile = {
  fileCards: FileCard;
  fileData: File;
};
export type UploadFileSuccess = {
  fileCards: FileCard;
  fileData: File;
  fileInfoResponse: IFileUploadResponse;
};

export type InputUploadFileObj = {
  fileCards: FileCard[];
  fileData: File[];
};

export type SelectGPTOption = {
  value: string;
  label: string | JSX.Element | JSX.Element[];
  description: string;
  chatModel: ModelVersion;
};
export type AssistantType = "DEFAULT" | "WEB" | "FILE" | "DALLE";
export type AssistantTypeInfo = {
  myGPTsId?: string | null;
  assistantType: AssistantType;
  capabilities: null | AssistantType[];
  profileImage?: string | null;
};

export type ChatState = {
  promptText: string;
  chatList: MessageInfo[];
  qaListId: string;
  qaItemId?: string;
  prevMessages: MessageInfo[];
  selectedChatId: string;
  firstSelectedChatId: string;
  selectedData: boolean;
  hasMore: boolean;
  selectedLog: string;
  removeStartChat: boolean;
  qaList: BeforeChatData[];
  qaListPageSize: number;
  pinnedQaList: BeforeChatData[];
  chatLoading: boolean;
  modelEngine: ModelEngine;
  inputUploadFiles: InputUploadFileObj;
  selectedGptOption: SelectGPTOption;
  isUploadFile: boolean;
  fileInfoList: IFileInfoItem[];
  assistantTypeInfo: AssistantTypeInfo;
  checkedQaIds: string[];
  isShowDeleteCheckbox: boolean;
  isScrollToBottom: boolean;
};

export type IntroChatCarouselItem = {
  profileImage: JSX.Element;
  name: string;
  id: ModelEngine;
};
