import { useEffect, useState } from "react";
import "../admin/admin.scss";
import { useAppDispatch } from "../../../features";
import { ModelInfo } from "../../../features/model";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import EndpointManagementModal from "../../components/admin/endpoint/modal/EndpointManagementModal";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MyAIChatEditorPage from "./MyAIChatEditorPage";
import MyAIChatMgmtPage from "./MyAIChatMgmtPage";
import { allRoutes, innerRoutes } from "../../../routes/routes";
import { IoMdArrowBack } from "react-icons/io";
import {
  addMyGPTSAsync,
  editMyGPTSAsync,
  selectSelectedPreviewMyGptId,
  selectMyAIChatForm,
  setMyAIChatForm,
  updateMyGPTsAsync,
} from "../../../features/myGpts";
import {
  selectGetMyGptsDetailLoading,
  selectUpdateMyGPTsLoading,
} from "../../../features/ui_loading";
import { openAlert } from "../../../features/alert";

export const ENDPOINT_DEFAULT_PAGE_SIZE = 10;

const MyAIChatsMgmgRoute = () => {
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const navigate = useNavigate();
  const location = useLocation();
  const myAIChatForm = useSelector(selectMyAIChatForm);
  const updateMyGptLoading = useSelector(selectUpdateMyGPTsLoading);
  const getMyGptDetailLoading = useSelector(selectGetMyGptsDetailLoading);
  const selectedMyGptId = useSelector(selectSelectedPreviewMyGptId);

  const [addModal, setAddModal] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [selectedData, setSelectedData] = useState<ModelInfo | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (location.pathname.includes("list")) {
      dispatch(
        setMyAIChatForm({
          id: "",
          profileImage: null,
          name: "",
          description: "",
          instructions: "",
          conversationStarters: [""],
          knowledge: [],
          capabilities: [],
          deleteAttachId: [],
        })
      );
    }
  }, [location.pathname]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // const addMyAiChatHandler = () => {
  //   if (focusModifyLoading || focusSaveLoading) {
  //     navigate(innerRoutes.MY_CHAT.path);
  //     return;
  //   }
  //   if (
  //     myAIChatForm.profileImage === null &&
  //     myAIChatForm.name === "" &&
  //     myAIChatForm.description === "" &&
  //     myAIChatForm.instructions === "" &&
  //     myAIChatForm.conversationStarters[0] === "" &&
  //     myAIChatForm.knowledge.length === 0 &&
  //     myAIChatForm.capabilities.length === 0
  //   ) {
  //     return;
  //   }

  //   dispatch(
  //     addMyGPTSAsync.request({
  //       param: {
  //         ...myAIChatForm,
  //         conversationStarters: myAIChatForm.conversationStarters.filter(
  //           (conversation) => conversation !== ""
  //         ),
  //       },
  //       navigate: navigate,
  //     })
  //   );
  // };

  // const editMyAiChatHandler = () => {
  //   if (focusModifyLoading || focusSaveLoading) {
  //     navigate(innerRoutes.MY_CHAT.path);
  //     return;
  //   }

  //   if (
  //     myAIChatForm.profileImage === null &&
  //     myAIChatForm.name === "" &&
  //     myAIChatForm.description === "" &&
  //     myAIChatForm.instructions === "" &&
  //     myAIChatForm.conversationStarters[0] === "" &&
  //     myAIChatForm.knowledge.length === 0 &&
  //     myAIChatForm.capabilities.length === 0
  //   ) {
  //     return;
  //   }

  //   dispatch(
  //     editMyGPTSAsync.request({
  //       param: {
  //         ...myAIChatForm,
  //         conversationStarters: myAIChatForm.conversationStarters.filter(
  //           (conversation) => conversation !== ""
  //         ),
  //         myGPTsId: selectedMyGptId,
  //       },
  //       navigate: navigate,
  //     })
  //   );
  // };

  const updateMyAiChat = () => {
    if (updateMyGptLoading) {
      navigate(innerRoutes.MY_CHAT.path);
      return;
    }
    if (
      myAIChatForm.profileImage === null &&
      myAIChatForm.name === "" &&
      myAIChatForm.description === "" &&
      myAIChatForm.instructions === "" &&
      myAIChatForm.conversationStarters[0] === "" &&
      myAIChatForm.knowledge.length === 0 &&
      myAIChatForm.capabilities.length === 0
    ) {
      dispatch(
        openAlert({
          alertMsg: "Please fill in the required fields.",
        })
      );
      return;
    }

    dispatch(
      updateMyGPTsAsync.request({
        param: {
          ...myAIChatForm,
          conversationStarters: myAIChatForm.conversationStarters.filter(
            (conversation) => conversation !== ""
          ),
          id: selectedMyGptId ? selectedMyGptId : null,
        },
        navigate,
      })
    );
  };
  return (
    <>
      {addModal && (
        <EndpointManagementModal
          onClose={() => setAddModal(false)}
          title="Register"
        />
      )}
      {modifyModal && selectedData && (
        <EndpointManagementModal
          onClose={() => setModifyModal(false)}
          modifyData={selectedData}
          title="Modify"
        />
      )}

      <div className={`mgmt_page scroll_none`}>
        <hgroup>
          <h3 className={`page_title page_title_${teamsTheme}`}>
            {(location.pathname.includes("create") ||
              location.pathname.includes("edit")) && (
              <button
                className={`back_btn ${updateMyGptLoading && "disabled"}`}
                onClick={() => navigate(innerRoutes.MY_CHAT.path)}
                disabled={updateMyGptLoading}
              >
                <IoMdArrowBack className="back_icon" />
              </button>
            )}
            {allRoutes.MY_CHAT.title}
          </h3>

          {location.pathname.includes("list") && (
            <MainColorBtn
              onClickHandler={() => navigate(innerRoutes.CREATE_MY_CHAT.path)}
              btnName="Create"
            />
          )}

          {location.pathname.includes("create") && (
            <MainColorBtn
              onClickHandler={updateMyAiChat}
              btnName="Create"
              loading={updateMyGptLoading}
            />
          )}

          {location.pathname.includes("edit") && (
            <MainColorBtn
              onClickHandler={updateMyAiChat}
              btnName="Modify"
              loading={updateMyGptLoading || getMyGptDetailLoading}
            />
          )}
        </hgroup>
        <Routes>
          <Route path="/list" element={<MyAIChatMgmtPage />} />
          <Route path="/create" element={<MyAIChatEditorPage />} />
          <Route path="/edit/:id" element={<MyAIChatEditorPage />} />
        </Routes>
      </div>
    </>
  );
};

export default MyAIChatsMgmgRoute;
