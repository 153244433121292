import { useSelector } from "react-redux";
import InputCheckboxType from "./InputCheckboxType";
import { selectTeamsTheme } from "../../../../features/auth";
import { memo } from "react";

type Props = {
  checked: boolean;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxId: string;
  labelName: string;
  disabled?: boolean;
};

const InputCheckBoxLabel: React.FC<Props> = ({
  checked,
  onChangeHandler,
  checkboxId,
  labelName,
  disabled,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <div className="input_label_wrap">
      <InputCheckboxType
        checked={checked}
        onChangeHandler={onChangeHandler}
        checkboxId={checkboxId}
        disabled={disabled}
      />
      <label
        className={`checkbox_label_${teamsTheme}`}
        htmlFor={`${checkboxId}`}
      >
        {labelName}
      </label>
    </div>
  );
};

export default memo(InputCheckBoxLabel);
