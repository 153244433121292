import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  EapprovalBatchMetadataSearchInput,
  EmployeeBatchMetadataSearchInput,
  getBatchMetadataListAsync,
  makeSelectBatchMetadataSearchInputs,
  OuterServiceType,
  setBatchMetadataSearchInputs,
} from "../../../../features/batchMetadata";
import useWindowResize from "../../../../libs/hooks/useWindowResize";
import {
  BATCH_METADATA_DEFAULT_PAGE_SIZE,
  BATCH_METADATA_MOBILE_PAGE_SIZE,
} from "../../../../features/batchMetadata/reducer";

const useBatchMetadataList = () => {
  const dispatch = useDispatch();
  const { service } = useParams();
  const windowSize = useWindowResize();
  const [limit, setLimit] = useState(BATCH_METADATA_DEFAULT_PAGE_SIZE);
  const searchInputs = useSelector(
    makeSelectBatchMetadataSearchInputs(service as OuterServiceType)
  );

  useEffect(() => {
    if (!windowSize.width) return;

    if (windowSize.width <= 1024) {
      setLimit(BATCH_METADATA_MOBILE_PAGE_SIZE);
    } else {
      setLimit(BATCH_METADATA_DEFAULT_PAGE_SIZE);
    }
  }, [windowSize.width]);

  const getBatchMetadataList = useCallback(
    ({ setNameTitle, currentPage }) => {
      const serviceType = service as OuterServiceType;
      console.log(searchInputs);

      if (serviceType === "employee") {
        setNameTitle && setNameTitle("Employee");

        dispatch(
          getBatchMetadataListAsync.request({
            serviceType,
            param: {
              ...(searchInputs as EmployeeBatchMetadataSearchInput),
              skip: currentPage,
              limit,
            },
          })
        );
      }

      if (serviceType === "eapproval") {
        setNameTitle && setNameTitle("e-Approval");
        dispatch(
          getBatchMetadataListAsync.request({
            serviceType,
            param: {
              ...(searchInputs as EapprovalBatchMetadataSearchInput),
              skip: currentPage,
              limit,
            },
          })
        );
      }

      dispatch(
        setBatchMetadataSearchInputs({
          serviceType: service as OuterServiceType,
          key: "limit",
          value: limit,
        })
      );
    },
    [dispatch, service, searchInputs, limit]
  );

  return { getBatchMetadataList };
};

export default useBatchMetadataList;
