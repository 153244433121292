import { AAD_LOGIN } from "../auth";
import {
  deleteUploadFileAsync,
  getAnswerAsync,
  getBeforeChatListAsync,
  getSelectChatInfoAsync,
  regenerateAnswerAsync,
  uploadFileAsync,
} from "../chat";
import { RootState } from "../index";
import { downloadEventLogDataAsync, downloadLogDataAsync } from "../log";
import { getModelsInfoAsync } from "../model";
import {
  deleteMyGPTSAsync,
  getMyGPTSDetailAsync,
  getMyGPTsAsync,
  getMyGPTsSideBarAsync,
  updateMyGPTsAsync,
} from "../myGpts";
import { getBatchMetadataListAsync } from "../batchMetadata";
import { getBillingNameListAsync, getTokenLogAsync } from "../billing";

// 로그인
export const selectLoginLoader = (state: RootState): boolean =>
  state.loading.actions.find((loader) => loader.name === AAD_LOGIN) !==
  undefined;

export const selectSuccessMsg = (state: RootState) => state.loading.success;

export const selectErrorMsg = (state: RootState) => state.loading.error;

export const selectChatLoader = (state: RootState) =>
  state.loading.actions.find(
    (loader) =>
      loader.name === getAnswerAsync.request.toString() ||
      loader.name === regenerateAnswerAsync.request.toString()
  ) !== undefined;

export const selectLogDownloadLoader = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === downloadLogDataAsync.request.toString()
  ) !== undefined;

export const selectEventLogDownloadLoader = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === downloadEventLogDataAsync.request.toString()
  ) !== undefined;

export const selectGetModelsInfoLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === getModelsInfoAsync.request.toString()
  ) !== undefined;

export const selectUploadFileLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) =>
      loader.name === uploadFileAsync.request.toString() ||
      loader.name === deleteUploadFileAsync.request.toString()
  ) !== undefined;

export const selectGetMyGptsLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === getMyGPTsAsync.request.toString()
  ) !== undefined;

export const selectUpdateMyGPTsLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === updateMyGPTsAsync.request.toString()
  ) !== undefined;

// export const selectAddMyGptsLoading = (state: RootState) =>
//   state.loading.actions.find(
//     (loader) => loader.name === addMyGPTSAsync.request.toString()
//   ) !== undefined;

// export const selectEditMyGptsLoading = (state: RootState) =>
//   state.loading.actions.find(
//     (loader) => loader.name === editMyGPTSAsync.request.toString()
//   ) !== undefined;

export const selectGetMyGptsDetailLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === getMyGPTSDetailAsync.request.toString()
  ) !== undefined;

export const selectDeleteMyGptsLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === deleteMyGPTSAsync.request.toString()
  ) !== undefined;

// export const selectFocusOutModifyLoading = (state: RootState) =>
//   state.loading.actions.find(
//     (loader) => loader.name === focusOutModifyMyGptsAsync.request.toString()
//   ) !== undefined;

// export const selectFocusOutSaveLoading = (state: RootState) =>
//   state.loading.actions.find(
//     (loader) => loader.name === focusOutSaveMyGptsAsync.request.toString()
//   ) !== undefined;

export const selectMyGptsSideBarListLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === getMyGPTsSideBarAsync.request.toString()
  ) !== undefined;

export const selectQAItemLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === getSelectChatInfoAsync.request.toString()
  ) !== undefined;

export const selectQaListLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === getBeforeChatListAsync.request.toString()
  ) !== undefined;

export const selectBillingNameListLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === getBillingNameListAsync.request.toString()
  ) !== undefined;

export const selectTokenListLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === getTokenLogAsync.request.toString()
  ) !== undefined;

export const selectBatchMetadataListLoading = (state: RootState) =>
  state.loading.actions.find(
    (loader) => loader.name === getBatchMetadataListAsync.request.toString()
  ) !== undefined;
