import "./buttons.scss";
import { TiDelete } from "react-icons/ti";
import { memo } from "react";

type Props = {
  deleteInputHandler: () => void;
};

const FilterTextDeleteBtn: React.FC<Props> = ({ deleteInputHandler }) => {
  return (
    <button onClick={deleteInputHandler} className="searchText_delete_btn">
      <TiDelete />
    </button>
  );
};

export default memo(FilterTextDeleteBtn);
