import "./App.scss";
import {
  getSettingAsync,
  selectFont,
  selectFontSize,
  selectIsLogin,
  selectTeamsTheme,
  setTheme,
} from "./features/auth";
import clsx from "clsx";
import NavBar from "./pages/components/layout/NavBar";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import LoginPage from "./pages/pages/login/LoginPage";
import ErrorPage from "./pages/pages/common/ErrorPage";
import MainFrame from "./pages/pages/main/MainFrame";
import { useEffect, useState } from "react";
import { app } from "@microsoft/teams-js";
import { useAppDispatch } from "./features";
import { useSuccessSnackbar } from "./libs/hooks/useSuccessSnackbar";
import { useErrorSnackbar } from "./libs/hooks/useErrorSnackbar";
import { selectModelEngine } from "./features/chat";
import * as microsoftTeams from "@microsoft/teams-js";
import { set } from "lodash";
import Alert from "./pages/components/common/Alert";
import ErrorPage403 from "./pages/pages/common/ErrorPage403";

const App = () => {
  useSuccessSnackbar();
  useErrorSnackbar();
  const dispatch = useAppDispatch();
  const isLogin = useSelector(selectIsLogin);

  const localLocation = useLocation();
  const fontsizeChange = useSelector(selectFontSize);
  const font = useSelector(selectFont);
  const teamsTheme = useSelector(selectTeamsTheme);
  const modelEngine = useSelector(selectModelEngine);
  const [bgColor, setBgColor] = useState("");
  const [fontStyle, setFontStyle] = useState("");

  useEffect(() => {
    if (document.referrer.includes("teams")) {
      app.initialize();
      app.getContext().then((context) => {
        const theme = context.app.theme || "";

        dispatch(setTheme({ theme: theme }));
      });

      const themeChangeHandler = (newTheme: string) => {
        dispatch(setTheme({ theme: newTheme }));
      };

      app.registerOnThemeChangeHandler(themeChangeHandler);

      dispatch(getSettingAsync.request(1));

      return () => {
        app.registerOnThemeChangeHandler(themeChangeHandler);
      };
    }
  }, []);

  useEffect(() => {
    if (teamsTheme === "default") {
      if (modelEngine === "3.5v" || modelEngine === "4.0v") {
        setBgColor("theme_default");
      }
      if (modelEngine === "myGPTs") {
        setBgColor("theme_default_myGPTs");
      }
      if (modelEngine === "dalle") {
        setBgColor("theme_default_dalle");
      }
      return;
    }

    if (modelEngine === "3.5v" || modelEngine === "4.0v") {
      setBgColor("theme_dark");
    }

    if (modelEngine === "myGPTs") {
      setBgColor("theme_dark_myGPTs");
    }
    if (modelEngine === "dalle") {
      setBgColor("theme_dark_dalle");
    }
  }, [teamsTheme, modelEngine]);

  useEffect(() => {
    if (!font) return;
    if (font === "noto sans kr") {
      setFontStyle("noto_sans_kr");
      return;
    }
    if (font === "malgun gothic") {
      setFontStyle("malgun_gothic");
      return;
    }
    setFontStyle("gulim");
  }, [font]);

  return (
    <>
      <Alert />
      <div className={`App ${fontsizeChange} ${fontStyle} ${bgColor}`}>
        <NavBar />
        <Routes>
          <Route path="/error/403" element={<ErrorPage403 />} />
          <Route path="/error/*" element={<ErrorPage />} />
          <Route
            path="/login/*"
            element={!isLogin ? <LoginPage /> : <Navigate to="/private/chat" />}
          />
          <Route
            path="/private/*"
            element={
              isLogin ? (
                <MainFrame />
              ) : (
                <Navigate
                  to={`/login${
                    localLocation.hash.length > 0
                      ? `/${localLocation.hash}`
                      : ""
                  }`}
                />
              )
            }
          />
          <Route
            path="/"
            element={
              <Navigate
                to={
                  isLogin
                    ? "/private/chat"
                    : `/login${
                        localLocation.hash.length > 0
                          ? `/${localLocation.hash}`
                          : ""
                      }`
                }
              />
            }
          />
          <Route
            path="/networkError"
            element={
              <Navigate
                to={
                  isLogin
                    ? "/private/chat"
                    : `/login${
                        localLocation.hash.length > 0
                          ? `/${localLocation.hash}`
                          : ""
                      }`
                }
              />
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default App;
