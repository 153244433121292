import Select from "react-select";
import { selectTeamsTheme } from "../../../features/auth";
import { useSelector } from "react-redux";
import clsx from "clsx";
import "./SelectQueryScope.scss";

type Props = {
  options: any;
  value: any;
  onChangeHandler: (newValue: any) => void;
  isLoading: boolean;
};

const SelectQueryScope: React.FC<Props> = ({
  options,
  value,
  onChangeHandler,
  isLoading,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);
  console.log(value);
  return (
    <Select
      isDisabled={isLoading}
      isLoading={isLoading}
      className={clsx(`scope-select_container`, {
        [`${teamsTheme}`]: teamsTheme !== "default",
      })}
      classNamePrefix="scope-select"
      options={options}
      // defaultValue={value}
      value={value}
      menuPlacement="top"
      onChange={onChangeHandler}
    />
  );
};

export default SelectQueryScope;
