import "./filter.scss";
import SelectType from "../inputs/SelectType";
import { memo } from "react";

type Props = {
  title: string;
  value: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: { name: string; value: string }[];
};

const FilterSelectType: React.FC<Props> = ({
  title,
  value,
  onChangeHandler,
  options,
}) => {
  return (
    <div className="filter_tool filter_tool_select">
      <h5>{title}</h5>

      <div className="input_wrap">
        <SelectType
          options={options}
          value={value}
          onChangeHandler={onChangeHandler}
        />
      </div>
    </div>
  );
};

export default memo(FilterSelectType);
