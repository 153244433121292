import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../features";
import { selectMyAIChatForm, setMyAIChatForm } from "../../../features/myGpts";
import { useSelector } from "react-redux";
import KnowledgeFileCardItem from "./KnowledgeFileCardItem";
import {
  ALLOW_FILE_EXTENSION,
  getNameAndExtension,
} from "../../../services/FileUpload";
import { openAlert } from "../../../features/alert";

const KnowledgeUpload = () => {
  const dispatch = useAppDispatch();
  const myAIChatForm = useSelector(selectMyAIChatForm);

  const [files, setFiles] = useState<File[]>(myAIChatForm.knowledge || []);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFiles(myAIChatForm.knowledge || []);
  }, [myAIChatForm.knowledge]);

  const handleUploadButtonClick = () => {
    if (fileUploadRef.current) fileUploadRef.current.click();
  };

  const isOverSizeCheck = (fileArray: File[]) => {
    let totalSize = 0;
    const maxTotalSize = 100 * 1024 * 1024;

    for (let i = 0; i < fileArray.length; i++) {
      totalSize += fileArray[i].size;
    }
    if (totalSize > maxTotalSize) {
      return true;
    } else {
      return false;
    }
  };
  const fileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault(); // 기본 동작을 막음
    e.stopPropagation(); // 이벤트 전파를 막음
    if (!e.target.files) return;

    const uploadedFiles = Array.from(e.target.files || []);

    if (isOverSizeCheck(uploadedFiles)) {
      dispatch(
        openAlert({
          alertMsg:
            "The total upload capacity is 100MB, with a maximum of 10 files.",
        })
      );
      return;
    }

    const duplicatedFile = uploadedFiles.filter((inputFile: File) =>
      files.find((file) => file.name === inputFile.name)
    ).length;

    if (duplicatedFile > 0) {
      dispatch(
        openAlert({
          alertMsg: "You cannot upload files with the same name.",
        })
      );
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);

    // if (myAIChatForm.capabilities.includes("FILE")) {
    dispatch(
      setMyAIChatForm({
        ...myAIChatForm,
        knowledge: [...myAIChatForm.knowledge, ...uploadedFiles],
        capabilities: myAIChatForm.capabilities.includes("FILE")
          ? myAIChatForm.capabilities
          : [...myAIChatForm.capabilities, "FILE"],
      })
    );
    // return;
    // }
    // dispatch(
    //   setMyAIChatForm({
    //     ...myAIChatForm,
    //     knowledge: [...myAIChatForm.knowledge, ...uploadedFiles],
    //     capabilities: [...myAIChatForm.capabilities, "FILE"],
    //   })
    // );
  };

  const preventDuplication = (e: any) => {
    e.target.value = null;
  };

  // 기존의 저장된 파일 삭제
  const deleteDefaultFile = (fileId: string) => {
    let newFiles: {
      fileId: string;
      fileName: string;
    }[] = [];

    if (
      myAIChatForm.modifyKnowledge &&
      myAIChatForm.modifyKnowledge.length > 0
    ) {
      newFiles = myAIChatForm.modifyKnowledge.filter(
        (file, idx) => file.fileId !== fileId
      );
    }

    dispatch(
      setMyAIChatForm({
        ...myAIChatForm,
        modifyKnowledge: newFiles,
        deleteAttachId: [...myAIChatForm.deleteAttachId, fileId],
      })
    );
  };

  // 새로 추가된 파일 삭제
  const deleteModifyFile = (fileId: string) => {
    let newFiles: File[] = [];

    if (files && files.length > 0) {
      newFiles = files.filter((file, idx) => idx.toString() !== fileId);
    }

    setFiles(newFiles);
    dispatch(
      setMyAIChatForm({
        ...myAIChatForm,
        knowledge: newFiles,
      })
    );
  };

  return (
    <div className="form_item_wrap">
      <label htmlFor="knowledge">Knowledge</label>
      <span className="knowledge_placeholder">
        If you upload files under Knowledge, conversations with your GPT may
        include file contents. Files can be downloaded when Code Interpreter is
        enabled
      </span>
      <div className="files_container"></div>

      <div>
        {/* {modifyFiles.map((file, idx) => (
          <div>{file.fileName}</div>
        ))} */}
        {files.map((file, idx) => (
          <KnowledgeFileCardItem
            key={idx}
            fileName={file.name}
            extension={getNameAndExtension(file.name)[1]}
            onDelete={deleteModifyFile}
            fileId={idx.toString()}
          />
        ))}
        {myAIChatForm.modifyKnowledge &&
          myAIChatForm.modifyKnowledge.map((file, idx) => (
            <KnowledgeFileCardItem
              key={idx}
              fileName={file.fileName}
              extension={getNameAndExtension(file.fileName)[1]}
              onDelete={deleteDefaultFile}
              fileId={file.fileId}
            />
          ))}
      </div>

      <button
        onClick={handleUploadButtonClick}
        className="upload_button"
        title="The total upload capacity is 100MB, with a maximum of 10 files."
      >
        Upload files
      </button>
      <input
        name="uploadedFiles"
        ref={fileUploadRef}
        onChange={fileUpload}
        multiple={true}
        type="file"
        tabIndex={-1}
        onClick={preventDuplication}
        accept={ALLOW_FILE_EXTENSION}
        className="hidden"
      />
    </div>
  );
};

export default KnowledgeUpload;
