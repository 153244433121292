import "./FileCard.scss";
import { useState } from "react";
import clsx from "clsx";
import CloseSVG from "./CloseSVG";
import { isImageFile } from "../../../../services/FileUpload";
import { FileExtensionIcon } from "./FileExtensionIcon";
import { useSelector } from "react-redux";
import { selectUploadFileLoading } from "../../../../features/ui_loading";
import { ClipLoader } from "react-spinners";
import { selectTeamsTheme } from "../../../../features/auth";

type Props = {
  fileId: string;
  fileName: string;
  extension: string;
  imgUrl: string;
  onDelete?: (fileId: string) => void;
  isLoading: boolean;
};

const FileCardItem: React.FC<Props> = ({
  fileName,
  extension,
  onDelete,
  imgUrl,
  fileId,
  isLoading,
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const uploadFileLoading = useSelector(selectUploadFileLoading);
  const teamsTheme = useSelector(selectTeamsTheme);

  // if (isImageFile(extension)) {
  //   return (
  //     <div
  //       className="img_preview_container"
  //       onMouseOver={() => setIsHidden(false)}
  //       onMouseOut={() => setIsHidden(true)}
  //     >
  //       <img src={imgUrl} alt="img" />
  //       {onDelete && (
  //         <button
  //           onClick={() => onDelete(fileId)}
  //           className={clsx("file_remove_button", {
  //             "force_hidden": isHidden === true,
  //           })}
  //         >
  //           <CloseSVG />
  //         </button>
  //       )}
  //     </div>
  //   );
  // } else {
  return (
    <>
      <div
        className={`file_card_container ${teamsTheme}`}
        onMouseOver={() => setIsHidden(false)}
        onMouseOut={() => setIsHidden(true)}
      >
        {isLoading ? (
          <ClipLoader loading={uploadFileLoading} size={20} color="#80b1e2" />
        ) : (
          <>
            <FileExtensionIcon extension={extension} />
            <div className="file_info">
              <h4 className="file_title line_ellipsis">{fileName}</h4>
              <p className="file_extension line_ellipsis">{extension}</p>
            </div>
            {onDelete && (
              <button
                onClick={() => onDelete(fileId)}
                className={clsx("file_remove_button", {
                  "force_hidden": isHidden === true,
                })}
              >
                <CloseSVG />
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
  // }
};

export default FileCardItem;
