import "./RecommendedPrompt.scss";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../features";
import { getAnswerAsync, selectModelEngine } from "../../../../features/chat";
import { selectTeamsTheme, selectTemperature } from "../../../../features/auth";

type ItemProps = {
  title: string;
  subTitle?: string;
  onClickHandler: (prompt: string) => void;
};

const FAKE_STARTERS = [
  {
    title: "Explain why popcorn pops",
    subTitle: "to a kid who loves watching it in the microwave",
  },
  {
    title: "Design a database schema",
    subTitle: "for an online merch store",
  },
  {
    title: "Compare design principles",
    subTitle: "for mobile apps and desktop software",
  },
  {
    title: "Plan a trip",
    subTitle: "for a photography expedition in Iceland",
  },
];
export const ConversationStarterItem: React.FC<ItemProps> = ({
  title,
  subTitle,
  onClickHandler,
}) => (
  <li
    onClick={() => onClickHandler(`${title} ${subTitle}`)}
    className="conversation-li"
  >
    <h5 className="title">{title}</h5>
    {subTitle && <span className="sub_title">{subTitle}</span>}
  </li>
);

export const ConversationStarterList = () => {
  const dispatch = useAppDispatch();
  const modelEngine = useSelector(selectModelEngine);
  const temperature = useSelector(selectTemperature);
  const teamsTheme = useSelector(selectTeamsTheme);

  const getAnswer = (prompt: string) => {
    if (modelEngine === "3.5v" || modelEngine === "4.0v") {
      dispatch(
        getAnswerAsync.request({
          gptVersion: modelEngine,
          temperature,
          listId: null,
          prompt,
          fileInfo: [],
        })
      );
    }
  };
  return (
    <ul className={`conversation_prompt-ul ${teamsTheme}`}>
      {FAKE_STARTERS.map(({ title, subTitle }, idx) => (
        <ConversationStarterItem
          key={idx}
          onClickHandler={getAnswer}
          title={title}
          subTitle={subTitle}
        />
      ))}
    </ul>
  );
};
