import { memo } from "react";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import "./inputs.scss";
import { ReactComponent as CircleDelete } from "../../../../assets/svg/CircleDelete.svg";
import clsx from "clsx";

export interface IPairedTextInputContent {
  compCode: string;
  compName: string;
}
type Props = {
  currentIndex: number;
  content: IPairedTextInputContent;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickDeleteHandler: () => void;
  placeholder?: string;
  isDisabledDelete?: boolean;
  title: { id: string; value: string };
};

const PairedTextInputs: React.FC<Props> = ({
  content,
  currentIndex,
  onChangeHandler,
  onClickDeleteHandler,
  isDisabledDelete,
  placeholder,
  title,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <div
      className={clsx("input_paired-text_container", {
        [`${teamsTheme}`]: teamsTheme !== "default",
      })}
    >
      <div className="grid_container">
        <div>
          <p className={`input_label input_label_theme_${teamsTheme}`}>
            {title.value}
          </p>
          <input
            className={`input_text input_theme_${teamsTheme}`}
            placeholder={placeholder ? placeholder : title.value}
            value={content.compName}
            onChange={onChangeHandler}
            type="text"
            name="compName"
          />
        </div>
        <div>
          <p className={`input_label input_label_theme_${teamsTheme}`}>
            {title.id}
          </p>
          <input
            className={`input_text input_theme_${teamsTheme}`}
            placeholder={placeholder ? placeholder : title.id}
            value={content.compCode}
            onChange={onChangeHandler}
            type="text"
            name="compCode"
          />
        </div>
      </div>
      <button disabled={isDisabledDelete} onClick={onClickDeleteHandler}>
        <CircleDelete />
      </button>
    </div>
  );
};

export default memo(PairedTextInputs);
