import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../../features/auth";
import {
  RegisteredModelInfo,
  selectModelsTags,
} from "../../../../../features/model";
import { MdArrowForwardIos, MdCancel } from "react-icons/md";

type Props = {
  //   pageIndex: number;
  //   allCheck: boolean;
  //   setAllCheck: React.Dispatch<React.SetStateAction<boolean>>;
  selectDevelopmentIds: {
    development: RegisteredModelInfo;
    tags: string[];
  }[];
  setSelectDevelopmentIds: React.Dispatch<
    React.SetStateAction<
      {
        development: RegisteredModelInfo;
        tags: string[];
      }[]
    >
  >;
};

const SelectedEndpointInfo: React.FC<Props> = ({
  setSelectDevelopmentIds,
  selectDevelopmentIds,
}) => {
  const tagList = useSelector(selectModelsTags);
  const teamsTheme = useSelector(selectTeamsTheme);

  const addTags = (idx: number, tag: string) => {
    const selectData = selectDevelopmentIds[idx];

    if (!selectData.tags.includes(tag)) {
      setSelectDevelopmentIds((prev: any) => [
        ...prev.slice(0, idx),
        { ...selectData, tags: [...selectData.tags, tag] },
        ...prev.slice(idx + 1),
      ]);
    }
  };
  const deleteTags = (idx: number, tag: string) => {
    const selectData = selectDevelopmentIds[idx];

    selectData.tags = selectData.tags.filter((el) => el !== tag);

    setSelectDevelopmentIds((prev: any) => [
      ...prev.slice(0, idx),
      selectData,
      ...prev.slice(idx + 1),
    ]);
  };
  return (
    <div className={`select_model_in_tags scroll scroll-${teamsTheme}`}>
      {selectDevelopmentIds.map((data, idx) => (
        <div
          key={data.development.deploymentId}
          className="select_model_in_tag"
        >
          <div className="not_tag_data_wrap">
            <div className="data text_data">
              <h5>Resource Name</h5>
              <p className={`text ellipsis text-${teamsTheme}`}>
                {data.development.aoaiResourceName}
              </p>
            </div>
            <div className="data text_data">
              <h5>Deployment Name</h5>
              <p className={`text ellipsis text-${teamsTheme}`}>
                {data.development.deploymentName}
              </p>
            </div>
            <div className="data text_data">
              <h5>Model Name</h5>
              <p className={`text ellipsis text-${teamsTheme}`}>
                {data.development.modelName}
              </p>
            </div>
            <div className="data text_data">
              <h5>Model Version</h5>
              <p className={`text ellipsis text-${teamsTheme}`}>
                {data.development.modelVersion}
              </p>
            </div>
          </div>
          <div className="data tag_data">
            <h5>Tags</h5>

            <div className="tag_data">
              <select
                onChange={(e) => addTags(idx, e.target.value)}
                className={`input_text input_theme-${teamsTheme}`}
                value={
                  data.tags.length > 0 ? data.tags[data.tags.length - 1] : ""
                }
              >
                <option value="" disabled>
                  tag를 선택해주세요
                </option>
                {tagList.map((tag) => (
                  <option key={tag} value={tag}>
                    {tag}
                  </option>
                ))}
              </select>

              {data.tags.length > 0 && (
                <div className="divide">
                  <MdArrowForwardIos />
                </div>
              )}

              {data.tags.map((tag) => (
                <div key={"selected" + tag} className="tag">
                  <p>{tag}</p>
                  <button
                    onClick={() => deleteTags(idx, tag)}
                    className="tag_delete_btn"
                  >
                    <MdCancel className="delete_icon" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default SelectedEndpointInfo;
