import "./LoginPage.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../features";
import { aadLoginAsync, selectTeamsTheme } from "../../../features/auth";
import { selectLoginLoader } from "../../../features/ui_loading";
import LoginSpinner from "../../components/spinner/LoginSpinner";
import { isTeamsAppLogin } from "../../../services/AADService";

const LOADING = "LOADING";

const LoginPage = () => {
  const [isWebLogin, setIsWebLogin] = useState<typeof LOADING | boolean>(
    LOADING
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const loginLoading = useSelector(selectLoginLoader);
  const teamsTheme = useSelector(selectTeamsTheme);
  const from: any = location || { hash: "" };

  useEffect(() => {
    checkLoginType();

    //  from.hash: msal redirect url
    if (from.hash) {
      dispatchLogin();
    }
  }, []);

  const checkLoginType = async () => {
    const isTeamsApp = await isTeamsAppLogin();
    setIsWebLogin(!isTeamsApp);
    if (isTeamsApp) dispatchLogin();
  };

  const dispatchLogin = () => {
    dispatch(
      aadLoginAsync.request({
        tenantId: process.env.REACT_APP_TENANT_ID as string,
        hash: from.hash,
      })
    );
  };

  return (
    <>
      {loginLoading && (
        <div className="login_loading_spinner">
          <LoginSpinner />
        </div>
      )}
      <div className="LoginPage_container">
        <p className={`LoginPage_txt LoginPage_txt_${teamsTheme}`}>
          Welcome to Doosan AI Chat
        </p>

        {isWebLogin === true && (
          <button onClick={dispatchLogin} className="login_btn">
            LOGIN
          </button>
        )}
      </div>
    </>
  );
};

export default LoginPage;
