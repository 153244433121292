import { all, put, takeLatest, takeLeading } from "typed-redux-saga";
import {
  DELETE_BATCH_METADATA,
  deleteBatchMetadataAsync,
  GET_BATCH_METADATA_LIST,
  getBatchMetadataListAsync,
  REGISTER_EMPLOYEE_BATCH_METADATA,
  registerEmployeeBatchMetadataAsync,
  UPDATE_EMPLOYEE_BATCH_METADATA,
  UPDATE_EMPLOYEE_BATCH_METADATA_SUCCESS,
  updateBatchMetadataAsync,
} from "./actions";
import createAsyncSaga from "../../libs/createAsyncSaga";
import * as batchMetadata from "./api";
import addPaginationCreateAsyncSaga from "../../libs/addPaginationCreateAsyncSaga";
import deletePaginationCreateAsyncSaga from "../../libs/deletePaginationCreateAsyncSaga";
import { successMessageAction } from "../ui_loading";

const getBatchMetadataListSaga = createAsyncSaga(
  getBatchMetadataListAsync,
  batchMetadata.getBatchMetadataList
);

const registerBatchMetadataSaga = addPaginationCreateAsyncSaga(
  registerEmployeeBatchMetadataAsync,
  batchMetadata.registerBatchMetadata,
  getBatchMetadataListAsync,
  batchMetadata.getBatchMetadataList
);

const updateBatchMetadataSaga = createAsyncSaga(
  updateBatchMetadataAsync,
  batchMetadata.updateBatchMetadata
);
function* updateBatchMetadataSuccessSaga() {
  yield* put(
    successMessageAction({
      type: updateBatchMetadataAsync.success.toString(),
    })
  );
}

const deleteBatchMetadataSaga = deletePaginationCreateAsyncSaga(
  deleteBatchMetadataAsync,
  batchMetadata.deleteBatchMetadata,
  getBatchMetadataListAsync,
  batchMetadata.getBatchMetadataList
);

export function* batchMetadataSaga() {
  yield* all([
    yield* takeLatest(GET_BATCH_METADATA_LIST, getBatchMetadataListSaga),
    yield* takeLeading(
      REGISTER_EMPLOYEE_BATCH_METADATA,
      registerBatchMetadataSaga
    ),
    yield* takeLeading(UPDATE_EMPLOYEE_BATCH_METADATA, updateBatchMetadataSaga),
    yield* takeLeading(
      UPDATE_EMPLOYEE_BATCH_METADATA_SUCCESS,
      updateBatchMetadataSuccessSaga
    ),
    yield* takeLeading(DELETE_BATCH_METADATA, deleteBatchMetadataSaga),
  ]);
}
