import { AxiosError } from "axios";
import { createAsyncAction, createAction } from "typesafe-actions";
import {
  DeleteChatParam,
  GetAnswerParam,
  GetBeforeChatListParam,
  GetBeforeChatListResult,
  GetSelectChatInfoParam,
  GetSelectChatInfoResult,
  IAssistantParam,
  LikeAnswerParam,
  MessageInfo,
  ModifyChatParam,
  ModifyChatResult,
  IRegenerateParam,
  IDalleParam,
  IUploadFileParam,
  IDeleteFileResponse,
  // IRegenerateDalleParam,
  IAssistantDalleParam,
} from "./api";
import {
  AssistantTypeInfo,
  FileCard,
  ModelEngine,
  ModelVersion,
  SelectGPTOption,
  UploadFileSuccess,
} from "./types";

export const CLEAR_CHAT = "chat/CLEAR_CHAT";
export const clearChat = createAction(CLEAR_CHAT)<{
  clear: true;
}>();

export const CANCEL_CHAT = "chat/CANCEL_CHAT";
export const cancelChatAsync = createAction(CANCEL_CHAT)<{
  prompt: string;
}>();

export const GET_BEFORE_CHAT_LIST = "chat/GET_BEFORE_CHAT_LIST";
export const GET_BEFORE_CHAT_LIST_SUCCESS = "chat/GET_BEFORE_CHAT_LIST_SUCCESS";
export const GET_BEFORE_CHAT_LIST_ERROR = "chat/GET_BEFORE_CHAT_LIST_ERROR";

export const getBeforeChatListAsync = createAsyncAction(
  GET_BEFORE_CHAT_LIST,
  GET_BEFORE_CHAT_LIST_SUCCESS,
  GET_BEFORE_CHAT_LIST_ERROR
)<GetBeforeChatListParam, GetBeforeChatListResult, AxiosError>();

export const DELETE_CHAT = "chat/DELETE_CHAT";
export const DELETE_CHAT_SUCCESS = "chat/DELETE_CHAT_SUCCESS";
export const DELETE_CHAT_ERROR = "chat/DELETE_CHAT_ERROR";

export const deleteChatAsync = createAsyncAction(
  DELETE_CHAT,
  DELETE_CHAT_SUCCESS,
  DELETE_CHAT_ERROR
)<DeleteChatParam, DeleteChatParam, AxiosError>();

export const MODIFY_CHAT = "chat/MODIFY_CHAT";
export const MODIFY_CHAT_SUCCESS = "chat/MODIFY_CHAT_SUCCESS";
export const MODIFY_CHAT_ERROR = "chat/MODIFY_CHAT_ERROR";

export const modifyChatAsync = createAsyncAction(
  MODIFY_CHAT,
  MODIFY_CHAT_SUCCESS,
  MODIFY_CHAT_ERROR
)<ModifyChatParam, ModifyChatResult, AxiosError>();

export const MODIFY_CHAT_PIN = "chat/MODIFY_CHAT_PIN";
export const MODIFY_CHAT_PIN_SUCCESS = "chat/MODIFY_CHAT_PIN_SUCCESS";
export const MODIFY_CHAT_PIN_ERROR = "chat/MODIFY_CHAT_PIN_ERROR";

export const modifyChatPinAsync = createAsyncAction(
  MODIFY_CHAT_PIN,
  MODIFY_CHAT_PIN_SUCCESS,
  MODIFY_CHAT_PIN_ERROR
)<ModifyChatParam, ModifyChatResult, AxiosError>();

export const GET_SELECT_CHAT_INFO = "chat/GET_SELECT_CHAT_INFO";
export const GET_SELECT_CHAT_INFO_SUCCESS = "chat/GET_SELECT_CHAT_INFO_SUCCESS";
export const GET_SELECT_CHAT_INFO_ERROR = "chat/GET_SELECT_CHAT_INFO_ERROR";

export const getSelectChatInfoAsync = createAsyncAction(
  GET_SELECT_CHAT_INFO,
  GET_SELECT_CHAT_INFO_SUCCESS,
  GET_SELECT_CHAT_INFO_ERROR
)<GetSelectChatInfoParam, GetSelectChatInfoResult, AxiosError>();

export const GET_ANSWER = "chat/GET_ANSWER";
export const GET_ANSWER_SUCCESS = "chat/GET_ANSWER_SUCCESS";
export const GET_ANSWER_ERROR = "chat/GET_ANSWER_ERROR";

export const getAnswerAsync = createAsyncAction(
  GET_ANSWER,
  GET_ANSWER_SUCCESS,
  GET_ANSWER_ERROR
)<IAssistantParam, MessageInfo, { errMsg: string }>();

export const GET_DALLE_ANSWER = "chat/GET_DALLE_ANSWER";
export const GET_DALLE_ANSWER_SUCCESS = "chat/GET_DALLE_ANSWER_SUCCESS";
export const GET_DALLE_ANSWER_ERROR = "chat/GET_DALLE_ANSWER_ERROR";

export const getDalleAnswerAsync = createAsyncAction(
  GET_DALLE_ANSWER,
  GET_DALLE_ANSWER_SUCCESS,
  GET_DALLE_ANSWER_ERROR
)<IAssistantDalleParam, MessageInfo, { errMsg: string }>();

export const CONTINUE_ANSWER = "chat/CONTINUE_ANSWER";
export const CONTINUE_ANSWER_SUCCESS = "chat/CONTINUE_ANSWER_SUCCESS";
export const CONTINUE_ANSWER_ERROR = "chat/CONTINUE_ANSWER_ERROR";

export const continueAnswerAsync = createAsyncAction(
  CONTINUE_ANSWER,
  CONTINUE_ANSWER_SUCCESS,
  CONTINUE_ANSWER_ERROR
)<GetAnswerParam, MessageInfo, { errMsg: string }>();

export const REGENERATE_ANSWER = "chat/REGENERATE_ANSWER";
export const REGENERATE_ANSWER_SUCCESS = "chat/REGENERATE_ANSWER_SUCCESS";
export const REGENERATE_ANSWER_ERROR = "chat/REGENERATE_ANSWER_ERROR";

export const regenerateAnswerAsync = createAsyncAction(
  REGENERATE_ANSWER,
  REGENERATE_ANSWER_SUCCESS,
  REGENERATE_ANSWER_ERROR
)<IRegenerateParam, MessageInfo, { errMsg: string }>();

export const SELECT_CHAT_LOG = "chat/SELECT_CHAT_LOG";
export const selectChatLog = createAction(SELECT_CHAT_LOG)<{
  id: string;
}>();

export const LIKE_ANSWER = "chat/LIKE_ANSWER";
export const LIKE_ANSWER_SUCCESS = "chat/LIKE_ANSWER_SUCCESS";
export const LIKE_ANSWER_ERROR = "chat/LIKE_ANSWER_ERROR";

export const likeAnswerAsync = createAsyncAction(
  LIKE_ANSWER,
  LIKE_ANSWER_SUCCESS,
  LIKE_ANSWER_ERROR
)<LikeAnswerParam, MessageInfo, AxiosError>();

export const STREAM_UPDATE = "chat/STREAM_UPDATE";
export const streamUpdate = createAction(STREAM_UPDATE)<{
  answer: string;
}>();

export const SET_MODEL_ENGINE = "chat/SET_MODEL_ENGINE";
export const setModelEngine = createAction(SET_MODEL_ENGINE)<ModelEngine>();

export const SET_ASSISTANT_TYPE_INFO = "chat/SET_ASSISTANT_TYPE_INFO";
export const setAssistantTypeInfo = createAction(
  SET_ASSISTANT_TYPE_INFO
)<AssistantTypeInfo>();

export const SET_SELECTED_GPT_OPTION = "chat/SET_SELECTED_GPT_OPTION";
export const setSelectedGptOption = createAction(
  SET_SELECTED_GPT_OPTION
)<SelectGPTOption>();

export const SET_INPUT_UPLOAD_FILES = "chat/SET_INPUT_UPLOAD_FILES";
export const setInputUploadFiles = createAction(
  SET_INPUT_UPLOAD_FILES
)<FileCard | null>();

export const DELETE_UPLOAD_FILE = "chat/DELETE_UPLOAD_FILE";
export const DELETE_UPLOAD_FILE_SUCCESS = "chat/DELETE_UPLOAD_FILE_SUCCESS";
export const DELETE_UPLOAD_FILE_ERROR = "chat/DELETE_UPLOAD_FILE_ERROR";

export const deleteUploadFileAsync = createAsyncAction(
  DELETE_UPLOAD_FILE,
  DELETE_UPLOAD_FILE_SUCCESS,
  DELETE_UPLOAD_FILE_ERROR
)<string, IDeleteFileResponse, AxiosError>();

export const UPLOAD_FILE = "chat/UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "chat/UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_ERROR = "chat/UPLOAD_FILE_ERROR";

export const uploadFileAsync = createAsyncAction(
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR
)<IUploadFileParam, UploadFileSuccess, AxiosError>();

export const SET_PROMPT_TEXT = "chat/SET_PROMPT_TEXT";
export const setPromptText = createAction(SET_PROMPT_TEXT)<string>();

export const SET_IS_SHOW_DELETE_CHECKBOX = "chat/SET_IS_SHOW_DELETE_CHECKBOX";
export const setIsShowDeleteCheckbox = createAction(
  SET_IS_SHOW_DELETE_CHECKBOX
)<boolean>();

type CheckedAll = {
  type: "All";
  checkedIds: string[];
};
type CheckedSingle = {
  type: "Single";
  checkedIds: string;
};

type setCheckedQaIdsParam = CheckedAll | CheckedSingle;

export const SET_CHECKED_QA_IDS = "chat/SET_CHECKED_QA_IDS";
export const setCheckedQaIds =
  createAction(SET_CHECKED_QA_IDS)<setCheckedQaIdsParam>();

export const setIsScrollToBottom = createAction(
  "chat/SET_IS_SCROLL_BOTTOM"
)<boolean>();
