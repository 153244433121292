import "../mainChat/ScrollButton.scss";
import { BsArrowBarDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";

const ScrollButton = () => {
  const teamsTheme = useSelector(selectTeamsTheme);

  const handleScrollDown = () => {
    const scrollBody = document.getElementById("preview_chat_body");

    if (scrollBody) {
      scrollBody.style.scrollBehavior = "smooth";
      scrollBody.scrollTo(0, scrollBody.scrollHeight);
    }
  };
  return (
    <button
      onClick={() => handleScrollDown()}
      className={`scroll-button ${teamsTheme}`}
    >
      <BsArrowBarDown />
    </button>
  );
};

export default ScrollButton;
