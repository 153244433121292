import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";
import {
  selectAssistantTypeInfo,
  selectModelEngine,
} from "../../../features/chat";
import { ReactComponent as GPTsDefault } from "../../../assets/svg/logo/GPTsDefault.svg";
import LOGO from "../../../assets/img/logo_fav.png";
import LOGO_WHITE from "../../../assets/img/logo_fav_white.png";
import {
  selectSelectedPreviewMyGptId,
  selectMyAIChatForm,
} from "../../../features/myGpts";

export const AnswerProfileImage = () => {
  const teamsTheme = useSelector(selectTeamsTheme);
  const assistantInfo = useSelector(selectAssistantTypeInfo);
  const selectedMyGptId = useSelector(selectSelectedPreviewMyGptId);
  const myAIChatForm = useSelector(selectMyAIChatForm);
  const modelEngine = useSelector(selectModelEngine);

  if (selectedMyGptId) {
    if (modelEngine === "myGPTs") {
      if (myAIChatForm.profileImage) {
        return (
          <img
            className="my_gpt-img"
            src={myAIChatForm.profileImage as string}
            alt="My AI Chat"
          />
        );
      } else {
        if (!assistantInfo.profileImage) {
          return <GPTsDefault />;
        }
      }
    }
  }

  if (assistantInfo.myGPTsId) {
    if (modelEngine === "myGPTs") {
      if (assistantInfo.profileImage) {
        return (
          <img
            className="my_gpt-img"
            src={assistantInfo.profileImage}
            alt="My AI Chat"
          />
        );
      } else {
        if (!assistantInfo.profileImage) {
          return <GPTsDefault />;
        }
      }
    } else {
      if (!assistantInfo.profileImage) {
        return <GPTsDefault />;
      }
    }
  }

  return (
    <>
      {teamsTheme === "default" ? (
        <img className="search_type" src={LOGO} alt="doosan" />
      ) : (
        <img className="search_type" src={LOGO_WHITE} alt="doosan" />
      )}
    </>
  );
};
