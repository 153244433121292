import createAsyncSaga from "../../libs/createAsyncSaga";
import { all, call, put, takeLeading } from "typed-redux-saga";
import {
  DELETE_MY_GPTS,
  GET_MY_GPTS,
  GET_MY_GPTS_DETAIL,
  GET_MY_GPTS_SIDEBAR,
  UPDATE_MY_GPTS,
  deleteMyGPTSAsync,
  getMyGPTSDetailAsync,
  getMyGPTsAsync,
  getMyGPTsSideBarAsync,
  updateMyGPTsAsync,
} from "./actions";
import {
  DeleteMyGPTsParam,
  IUpdateMyGPTsPayload,
  deleteMyGpts,
  getMyGpts,
  getMyGptsDetail,
  updateMyGpts,
} from "./api";
import { errorAction, startAction, stopAction } from "../ui_loading";
import { openAlert } from "../alert";
import { errHandler } from "../../libs/errHandler";

const getMyGptsSaga = createAsyncSaga(getMyGPTsAsync, getMyGpts);

const getMyGptsDetailSaga = createAsyncSaga(
  getMyGPTSDetailAsync,
  getMyGptsDetail
);

function* deleteMyGptsSaga(action: any) {
  try {
    yield* put(startAction({ name: action.type }));
    const {
      search,
      pageIndex,
      pageSize,
      myGPTsId,
      isNewSidebarMyGPTs,
    }: DeleteMyGPTsParam = action.payload;
    const result = yield* call(deleteMyGpts, myGPTsId);

    if (result) {
      yield put(deleteMyGPTSAsync.success(result));

      try {
        yield* put(startAction({ name: getMyGPTsAsync.request.toString() }));

        if (isNewSidebarMyGPTs) {
          const newSidebarMyGPTs = yield* call(getMyGpts, {
            pageIndex: 1,
            pageSize: 99,
            search: "",
          });
          if (newSidebarMyGPTs) {
            yield put(getMyGPTsSideBarAsync.success(newSidebarMyGPTs));
          }
        }

        const myGPTsListResult = yield* call(getMyGpts, {
          pageIndex,
          pageSize,
          search,
        });

        if (myGPTsListResult) {
          if (myGPTsListResult.rows.length === 0 && pageIndex > 1) {
            const beforePageResult = yield* call(getMyGpts, {
              pageIndex: pageIndex - 1,
              pageSize,
              search,
            });
            yield put(getMyGPTsAsync.success(beforePageResult));
          } else {
            yield put(getMyGPTsAsync.success(myGPTsListResult));
          }
        }
      } catch (error) {
        errorAction({
          type: getMyGPTsAsync.failure.toString(),
          errorCode: (error as any).response?.status,
          msg: (error as any).response?.data.message
            ? (error as any).response?.data.message
            : "",
        });
      } finally {
        yield* put(stopAction({ name: getMyGPTsAsync.request.toString() }));
      }
    }
  } catch (error) {
    yield* put(
      errorAction({
        type: action.type.failure.toString(),
        errorCode: (error as any).response?.status,
        msg: (error as any).response?.data.message
          ? (error as any).response?.data.message
          : "",
      })
    );
  } finally {
    yield* put(stopAction({ name: action.type }));
  }
}

const getMyGPTsSideBarSaga = createAsyncSaga(getMyGPTsSideBarAsync, getMyGpts);

function* updateMyGptsSaga(action: any) {
  try {
    yield* put(startAction({ name: action.type }));

    const { param, navigate }: IUpdateMyGPTsPayload = action.payload;
    const result = yield* call(updateMyGpts, param);

    if (result) {
      yield put(updateMyGPTsAsync.success(result));

      if (navigate) navigate("/private/my_chat_mgmt/list");
    }
  } catch (error: any) {
    if (error.response.request.status === 418) {
      yield put(openAlert({ alertMsg: error.response.data.detail }));
    }

    errHandler(error.response.request.status, error.code);

    // 에러시 myGPTS 상세 조회
    yield put(
      getMyGPTSDetailAsync.request({ myGPTsId: action.payload.param.id })
    );
  } finally {
    yield* put(stopAction({ name: action.type }));
  }
}

export function* myGptsSaga() {
  yield* all([yield* takeLeading(GET_MY_GPTS, getMyGptsSaga)]);
  yield* all([yield* takeLeading(UPDATE_MY_GPTS, updateMyGptsSaga)]);
  yield* all([yield* takeLeading(GET_MY_GPTS_DETAIL, getMyGptsDetailSaga)]);
  yield* all([yield* takeLeading(DELETE_MY_GPTS, deleteMyGptsSaga)]);
  yield* all([yield* takeLeading(GET_MY_GPTS_SIDEBAR, getMyGPTsSideBarSaga)]);
}
