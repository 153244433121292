import moment from "moment";

export const transformDate = (date: string) => {
  // const newDate = moment.utc(date).toDate();
  const localTime = moment(date).format("YYYY-MM-DD HH:mm");

  return localTime;
};

export const localDate = (date: Date | null) => {
  if (date) {
    // const newDate = moment.utc(date).toDate();
    const localTime = moment(date).format("YYYY-MM-DD");

    return localTime;
  } else {
    return "";
  }
};
