import "./Loading.scss";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";

const LoadingAnswer = () => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <div className="LoadingAnswer_container">
      <div className={`LoadingAnswer LoadingAnswer_${teamsTheme}`}>
        <div className="LoadingAnswer_wrap">
          Generating answer
          <span className="loading">.</span>
          <span className="loading">.</span>
          <span className="loading">.</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnswer;
