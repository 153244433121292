import { RootState } from "../index";

export const selectAccessCompanyData = (state: RootState) =>
  state.admin.accessCompanyData;

export const selectAccessDeptData = (state: RootState) =>
  state.admin.accessDeptData;

export const selectAccessUserData = (state: RootState) =>
  state.admin.accessUserData;

export const selectAccessTotalCnt = (state: RootState) =>
  state.admin.accessTotalCount;

export const selectSearchAccessData = (state: RootState) =>
  state.admin.searchAccessData;

export const selectAccessPageIndex = (state: RootState) =>
  state.admin.accessPageIndex;

export const selectAdminUserData = (state: RootState) =>
  state.admin.adminUserData;

export const selectAdminTotalCnt = (state: RootState) =>
  state.admin.adminTotalCount;

export const selectSearchAdminUserData = (state: RootState) =>
  state.admin.searchAdminUserData;

export const selectAdminPageIndex = (state: RootState) =>
  state.admin.adminPageIndex;
