import { put, call } from "typed-redux-saga";
import { AsyncActionCreatorBuilder, PayloadAction } from "typesafe-actions";
import {
  errorAction,
  startAction,
  stopAction,
  successMessageAction,
} from "../features/ui_loading";
import { logout } from "../features/auth";

type PromiseCreatorFunction<P, T> =
  | ((payload: P) => Promise<T>)
  | (() => Promise<T>);

function isPayloadAction<P>(action: any): action is PayloadAction<string, P> {
  return action.payload !== null;
}

export default function addPaginationCreateAsyncSaga<P1, P2, P3, T1, T2, T3>(
  addActionCreator: AsyncActionCreatorBuilder<
    [string, [{ addData: P1; getData: T1 }, undefined]],
    [string, [P2, undefined]],
    [string, [P3, undefined]]
  >,
  addPromiseCreator: PromiseCreatorFunction<P1, P2>,
  getActionCreator: AsyncActionCreatorBuilder<
    [string, [T1, undefined]],
    [string, [T2, undefined]],
    [string, [T3, undefined]]
  >,
  getPromiseCreator: PromiseCreatorFunction<T1, T2>
) {
  return function* saga(action: ReturnType<typeof addActionCreator.request>) {
    try {
      yield* put(startAction({ name: action.type }));
      const addResult = isPayloadAction<P1>(action)
        ? yield* call(addPromiseCreator, action.payload.addData)
        : yield* call(addPromiseCreator as any);

      if (addResult) {
        const getResult = isPayloadAction<T1>(action)
          ? yield* call(getPromiseCreator, action.payload.getData)
          : yield* call(getPromiseCreator as any);

        if (getResult) {
          yield put(getActionCreator.success(getResult as any));
        }
      }

      yield* put(
        successMessageAction({
          type: addActionCreator.success.toString(),
          successMsg:
            addResult && (addResult as any).create_rag_message
              ? (addResult as any).create_rag_message
              : "",
        })
      );
    } catch (error: any) {
      console.log("에러 발생", error);
      if (error.response) {
        console.log(
          "요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다."
        );
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log(addActionCreator.failure.toString());
        if (error.response.status === 401) {
          yield* put(logout());
        }
        console.log(error);
        if (error.response.data && error.response.data.error_message) {
          yield* put(
            errorAction({
              type: addActionCreator.failure.toString(),
              errorCode: error.response.status,
              msg: error.response.data.error_message,
            })
          );
        } else {
          yield* put(
            errorAction({
              type: addActionCreator.failure.toString(),
              errorCode: 0,
              msg: "",
            })
          );
        }
      } else if (error.request) {
        console.log("요청이 이루어 졌으나 응답을 받지 못했습니다.");
        // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
        // Node.js의 http.ClientRequest 인스턴스입니다.
        console.log(error.request);
        yield* put(
          errorAction({ msg: "네트워크 에러", type: "", errorCode: 0 })
        );
      } else {
        console.log(
          "오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다."
        );
        console.log(error, action);
        console.log("Error", error.message);
        yield* put(errorAction({ msg: error.message, type: "", errorCode: 0 }));
      }
      console.log("에러 config", error.config);
      // yield put(asyncActionCreator.failure(e));
    } finally {
      yield* put(stopAction({ name: action.type }));
    }
  };
}
