import { createAction, createAsyncAction } from "typesafe-actions";
import {
  BatchBaseMetadataList,
  DeleteBatchMetadataPayload,
  EmployeeBatchMetadataAddData,
  EmployeeBatchMetadataItem,
  EmployeeBatchMetadataRegisterInputPayload,
  GetBatchMetadataListPayload,
  SetBatchMetadataModifyItem,
  SetBatchMetadataRegiserInputs,
  SetBatchMetadataSearchInput,
} from "./api";
import { AxiosError } from "axios";

export const GET_BATCH_METADATA_LIST = "admin/GET_BATCH_METADATA_LIST";
export const GET_BATCH_METADATA_LIST_SUCCESS =
  "admin/GET_BATCH_METADATA_LIST_SUCCESS";
export const GET_BATCH_METADATA_LIST_ERROR =
  "admin/GET_BATCH_METADATA_LIST_ERROR";

export const getBatchMetadataListAsync = createAsyncAction(
  GET_BATCH_METADATA_LIST,
  GET_BATCH_METADATA_LIST_SUCCESS,
  GET_BATCH_METADATA_LIST_ERROR
)<GetBatchMetadataListPayload, BatchBaseMetadataList, AxiosError>();

export const setBatchMetadataSearchInputs = createAction(
  "admin/SET_BATCH_METADATA_SEARCH_INPUT"
)<SetBatchMetadataSearchInput>();

export const resetBatchMetadataSearchInputs = createAction(
  "admin/RESET_BATCH_METADATA_SEARCH_INPUT"
)();
export const setBatchMetadataRegisterInputs = createAction(
  "admin/SET_BATCH_METADATA_REGISTER_INPUT"
)<SetBatchMetadataRegiserInputs>();

export const resetBatchMetadataRegisterInputs = createAction(
  "admin/RESET_BATCH_METADATA_REGISTER_INPUT"
)();

export const setBatchMetadataModifyItem = createAction(
  "admin/SET_BATCH_METADATA_MODIFY_ITEM"
)<SetBatchMetadataModifyItem>();

export const REGISTER_EMPLOYEE_BATCH_METADATA =
  "admin/REGISTER_EMPLOYEE_BATCH_METADATA";
export const REGISTER_EMPLOYEE_BATCH_METADATA_SUCCESS =
  "admin/REGISTER_EMPLOYEE_BATCH_METADATA_SUCCESS";
export const REGISTER_EMPLOYEE_ATCH_METADATA_ERROR =
  "admin/REGISTER_EMPLOYEE_BATCH_METADATA_ERROR";

export const registerEmployeeBatchMetadataAsync = createAsyncAction(
  REGISTER_EMPLOYEE_BATCH_METADATA,
  REGISTER_EMPLOYEE_BATCH_METADATA_SUCCESS,
  REGISTER_EMPLOYEE_ATCH_METADATA_ERROR
)<EmployeeBatchMetadataRegisterInputPayload, any, AxiosError>();

export const UPDATE_EMPLOYEE_BATCH_METADATA =
  "admin/UPDATE_EMPLOYEE_BATCH_METADATA";
export const UPDATE_EMPLOYEE_BATCH_METADATA_SUCCESS =
  "admin/UPDATE_EMPLOYEE_BATCH_METADATA_SUCCESS";
export const UPDATE_EMPLOYEE_BATCH_METADATA_ERROR =
  "admin/UPDATE_EMPLOYEE_BATCH_METADATA_ERROR";

export const updateBatchMetadataAsync = createAsyncAction(
  UPDATE_EMPLOYEE_BATCH_METADATA,
  UPDATE_EMPLOYEE_BATCH_METADATA_SUCCESS,
  UPDATE_EMPLOYEE_BATCH_METADATA_ERROR
)<EmployeeBatchMetadataAddData, EmployeeBatchMetadataItem, AxiosError>();

export const DELETE_BATCH_METADATA = "admin/DELETE_BATCH_METADATA";
export const DELETE_BATCH_METADATA_SUCCESS =
  "admin/DELETE_BATCH_METADATA_SUCCESS";
export const DELETE_BATCH_METADATA_ERROR = "admin/DELETE_BATCH_METADATA_ERROR";

export const deleteBatchMetadataAsync = createAsyncAction(
  DELETE_BATCH_METADATA,
  DELETE_BATCH_METADATA_SUCCESS,
  DELETE_BATCH_METADATA_ERROR
)<DeleteBatchMetadataPayload, boolean, AxiosError>();
