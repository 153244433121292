export const TOKEN_MODEL_CODE = {
  ALL: 0,
  ASSISTANT_ALL: 1,
  MY_GPTS: 2,
  DALLE: 3,
  ASSISTANT_DEFAULT: 4,
  ASSISTANT_WEB: 5,
  ASSISTANT_FILE: 6,
  ASSISTANT_DALLE: 7,
};
