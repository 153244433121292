import "./BeforeChat.scss";
import { useEffect, useRef, memo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../features";
import {
  BeforeChatData,
  IFileInfoItem,
  deleteChatAsync,
  modifyChatAsync,
  modifyChatPinAsync,
  selectCheckedQaIds,
  selectFirstSelectedChatId,
  selectIsShowDeleteCheckbox,
  selectSelectedChatId,
  setCheckedQaIds,
} from "../../../features/chat";
import { selectTeamsTheme, selectUserId } from "../../../features/auth";
import {
  RiCheckFill,
  RiDeleteBack2Fill,
  RiDeleteBinLine,
} from "react-icons/ri";
import { PiPencilSimpleLineBold } from "react-icons/pi";
import { transformDate } from "../../../libs/transformDate";
import { AiFillPushpin, AiOutlinePushpin } from "react-icons/ai";
import { ReactComponent as FileTag } from "../../../assets/svg/fileExtension/FileTag.svg";
import clsx from "clsx";
import { getNameAndExtension } from "../../../services/FileUpload";

type Props = {
  chat: BeforeChatData;
  selectDeleteIdHandler: (id: string) => void;
  selectModifyIdHandler: (id: string) => void;
  deleteId: string;
  modifyId: string;
  onClose?: () => void;
  setModifyTxt: React.Dispatch<React.SetStateAction<string>>;
  modifyTxt: string;
};

type FileMarkProps = {
  files: IFileInfoItem[];
};

const FileMark: React.FC<FileMarkProps> = ({ files }) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <div className="file_mark_wrap">
      <div className={`file_mark ${teamsTheme}}`}>
        {/* <FileTag fill="#fff" /> */}
        {getNameAndExtension(files[0].fileName)[1].toUpperCase()}
      </div>
    </div>
  );
};

const BeforeChat: React.FC<Props> = ({
  chat,
  deleteId,
  modifyId,
  selectDeleteIdHandler,
  selectModifyIdHandler,
  setModifyTxt,
  modifyTxt,
}) => {
  const dispatch = useAppDispatch();

  const teamsTheme = useSelector(selectTeamsTheme);
  const userId = useSelector(selectUserId);
  const selectedId = useSelector(selectSelectedChatId);
  const firstSelectedId = useSelector(selectFirstSelectedChatId);
  const IsShowDeleteCheckbox = useSelector(selectIsShowDeleteCheckbox);
  const checkedQaIds = useSelector(selectCheckedQaIds);

  const modifyInputRef = useRef<any>(null);

  useEffect(() => {
    if (modifyId === chat._id) {
      setModifyTxt(chat.title);
      modifyInputRef.current?.focus();
    }
  }, [modifyId]);

  const modifyChatNameHandler = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();

    dispatch(
      modifyChatAsync.request({
        listId: id,
        title: modifyTxt,
        pinned: chat.pinned,
      })
    );

    selectModifyIdHandler("");
  };

  const deleteChatHandler = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();

    dispatch(deleteChatAsync.request({ listIds: [id] }));

    selectDeleteIdHandler("");
  };

  const pinCheckHandler = (e: React.MouseEvent) => {
    e.stopPropagation();

    dispatch(
      modifyChatPinAsync.request({
        listId: chat._id,
        title: chat.title,
        pinned: !chat.pinned,
      })
    );
  };

  const changeCheckedQaIds = (e: any) => {
    e.stopPropagation();
    dispatch(setCheckedQaIds({ type: "Single", checkedIds: chat._id }));
  };

  return (
    <>
      <div className="Chat_before_title">
        {(selectedId === chat._id || firstSelectedId === chat._id) &&
        modifyId === chat._id ? (
          <input
            ref={modifyInputRef}
            className={`Chat_before_modify_txt Chat_before_modify_txt_${teamsTheme}`}
            type="text"
            value={modifyTxt}
            onChange={(e) => setModifyTxt(e.target.value)}
          />
        ) : (
          <>
            {IsShowDeleteCheckbox ? (
              <div
                className="qa_delete-checkbox_wrap"
                onClick={changeCheckedQaIds}
              >
                <input
                  type="checkbox"
                  className="qa_delete-checkbox"
                  id={chat._id}
                  checked={checkedQaIds.includes(chat._id)}
                  readOnly
                />
              </div>
            ) : (
              <>
                <button className="pin_btn">
                  {chat.pinned ? (
                    <AiFillPushpin
                      className={`pin_icon pin_icon_${teamsTheme}`}
                      onClick={pinCheckHandler}
                    />
                  ) : (
                    <AiOutlinePushpin
                      className={`pin_icon pin_icon_${teamsTheme}`}
                      onClick={pinCheckHandler}
                    />
                  )}
                </button>
              </>
            )}

            <span>
              {chat.fileInfo.length ? <FileMark files={chat.fileInfo} /> : null}
              {chat.title}
            </span>
          </>
        )}
      </div>
      <div className={`Chat_before_date Chat_before_date_${teamsTheme}`}>
        {transformDate(chat.updatedDate)}
      </div>

      {(selectedId === chat._id || firstSelectedId === chat._id) && (
        <div
          className={clsx(
            `delete_modify_chat delete_modify_chat_${teamsTheme}`,
            {
              [`${teamsTheme}_dalle_selected_qa_item`]:
                chat.qaModelEngine === "dalle",
              [`${teamsTheme}_myGPTs_selected_qa_item`]:
                chat.qaModelEngine === "myGPTs",
            }
          )}
        >
          {modifyId === chat._id ? (
            <>
              <button
                className={`qa_btn qa_btn_${teamsTheme}`}
                onClick={(e) => modifyChatNameHandler(e, chat._id)}
              >
                <RiCheckFill />
              </button>
              <button className={`qa_btn qa_btn_${teamsTheme}`}>
                <RiDeleteBack2Fill
                  onClick={(e) => {
                    e.stopPropagation();
                    selectModifyIdHandler("");
                  }}
                />
              </button>
            </>
          ) : (
            deleteId !== chat._id && (
              <button
                className={`qa_btn qa_btn_${teamsTheme}`}
                onClick={(e) => {
                  e.stopPropagation();
                  selectModifyIdHandler(chat._id);
                }}
              >
                <PiPencilSimpleLineBold />
              </button>
            )
          )}

          {deleteId === chat._id ? (
            <>
              <button
                className={`qa_btn qa_btn_${teamsTheme}`}
                onClick={(e) => deleteChatHandler(e, chat._id)}
              >
                <RiCheckFill />
              </button>
              <button className={`qa_btn qa_btn_${teamsTheme}`}>
                <RiDeleteBack2Fill
                  onClick={(e) => {
                    e.stopPropagation();
                    selectDeleteIdHandler("");
                  }}
                />
              </button>
            </>
          ) : (
            modifyId !== chat._id && (
              <button
                className={`qa_btn qa_btn_${teamsTheme}`}
                onClick={(e) => {
                  e.stopPropagation();
                  selectDeleteIdHandler(chat._id);
                }}
              >
                <RiDeleteBinLine />
              </button>
            )
          )}
        </div>
      )}
    </>
  );
};

export default memo(BeforeChat);
