import "./LoginSpinner.scss";

const LoginSpinner = () => {
  return (
    <div className="LoginSpinner_container">
      <div className="loader"></div>
    </div>
  );
};

export default LoginSpinner;
