import { RootState } from "../index";

// ------
export const selectUserInfo = (state: RootState) => state.auth.userInfo;

export const selectIsLogin = (state: RootState) =>
  state.auth.userInfo.access_token;
export const selectUserName = (state: RootState) =>
  state.auth.userInfo.userName;
export const selectUserId = (state: RootState) => state.auth.userInfo.userId;
export const selectIsContractAgreed = (state: RootState) =>
  state.auth.userInfo.isAgreed;
export const selectIsAdmin = (state: RootState) => state.auth.userInfo.isAdmin;
export const selectSettings = (state: RootState) =>
  state.auth.userInfo.baseUserSettings;

export const selectFontSize = (state: RootState) =>
  state.auth.userInfo?.baseUserSettings.fontSize;
export const selectFont = (state: RootState) =>
  state.auth.userInfo?.baseUserSettings.font;
export const selectSettingChatType = (state: RootState) =>
  state.auth.userInfo?.baseUserSettings.chatType;
export const selectTemperature = (state: RootState) =>
  state.auth.userInfo?.baseUserSettings.temperature;
export const selectTeamsTheme = (state: RootState) => state.auth.teamsTheme;

// ❓ gpt 모델 어디에??
// export const selectVersion = (state: RootState) => state.auth.userInfo.gptModels;

export const selectIsShowSidebar = (state: RootState) =>
  state.auth.isShowSidebar;
