import { RootState } from "../index";

export const selectLogData = (state: RootState) => state.log.logData;

export const selectLogTotalCnt = (state: RootState) => state.log.logTotalCount;

export const selectEventLogData = (state: RootState) => state.log.eventLogData;

export const selectEventLogTotalCnt = (state: RootState) =>
  state.log.eventLogTotalCount;
