import { put, call } from "typed-redux-saga";
import { AsyncActionCreatorBuilder, PayloadAction } from "typesafe-actions";
import {
  errorAction,
  startAction,
  stopAction,
  successMessageAction,
} from "../features/ui_loading";
import { logout } from "../features/auth";

type PromiseCreatorFunction<P, T> =
  | ((payload: P) => Promise<T>)
  | (() => Promise<T>);

function isPayloadAction<P>(action: any): action is PayloadAction<string, P> {
  return action.payload !== null;
}

export default function deletePaginationCreateAsyncSaga<P1, P2, P3, T1, T2, T3>(
  deleteActionCreator: AsyncActionCreatorBuilder<
    [string, [{ deleteData: P1; getData: T1 }, undefined]],
    [string, [P2, undefined]],
    [string, [P3, undefined]]
  >,
  deletePromiseCreator: PromiseCreatorFunction<P1, P2>,
  getActionCreator: AsyncActionCreatorBuilder<
    [string, [T1, undefined]],
    [string, [T2, undefined]],
    [string, [T3, undefined]]
  >,
  getPromiseCreator: PromiseCreatorFunction<T1, T2>
) {
  return function* saga(
    action: ReturnType<typeof deleteActionCreator.request>
  ) {
    try {
      yield* put(startAction({ name: action.type }));
      const deleteResult = isPayloadAction<P1>(action)
        ? yield* call(deletePromiseCreator, action.payload.deleteData)
        : yield* call(deletePromiseCreator as any);

      if (deleteResult) {
        const getPayloadCheck = isPayloadAction<T1>(action);

        let getResult;

        if (getPayloadCheck) {
          if ((action.payload.getData as any).pageIndex) {
            getResult = yield* call(getPromiseCreator, {
              ...(action.payload.getData as any),
              pageIndex:
                (action.payload.getData as any).dataListLength - 1 === 0
                  ? (action.payload.getData as any).pageIndex - 1 === 0
                    ? 1
                    : (action.payload.getData as any).pageIndex - 1
                  : (action.payload.getData as any).pageIndex,
            });
          } else if ((action.payload.getData as any).page_index) {
            getResult = yield* call(getPromiseCreator, {
              ...(action.payload.getData as any),
              page_index:
                (action.payload.getData as any).dataListLength - 1 === 0
                  ? (action.payload.getData as any).page_index - 1 === 0
                    ? 1
                    : (action.payload.getData as any).page_index - 1
                  : (action.payload.getData as any).page_index,
            });
          } else {
            getResult = yield* call(getPromiseCreator, {
              ...(action.payload.getData as any),
              skip:
                (action.payload.getData as any).dataListLength - 1 === 0
                  ? (action.payload.getData as any).skip - 1 === 0
                    ? 1
                    : (action.payload.getData as any).skip - 1
                  : (action.payload.getData as any).skip,
            });
          }
        } else {
          getResult = yield* call(getPromiseCreator as any);
        }

        if (getResult) {
          yield put(getActionCreator.success(getResult as any));
        }
      }

      yield* put(
        successMessageAction({ type: deleteActionCreator.success.toString() })
      );
    } catch (error: any) {
      console.log("에러 발생", error);
      if (error.response) {
        console.log(
          "요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다."
        );
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log(deleteActionCreator.failure.toString());
        if (error.response.status === 401) {
          yield* put(logout());
        }
        console.log(error);
        if (error.response.data && error.response.data.error_message) {
          yield* put(
            errorAction({
              type: deleteActionCreator.failure.toString(),
              errorCode: error.response.status,
              msg: error.response.data.error_message,
            })
          );
        } else {
          yield* put(
            errorAction({
              type: deleteActionCreator.failure.toString(),
              errorCode: 0,
              msg: "",
            })
          );
        }
      } else if (error.request) {
        console.log("요청이 이루어 졌으나 응답을 받지 못했습니다.");
        // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
        // Node.js의 http.ClientRequest 인스턴스입니다.
        console.log(error.request);
        yield* put(
          errorAction({ msg: "네트워크 에러", type: "", errorCode: 0 })
        );
      } else {
        console.log(
          "오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다."
        );
        console.log(error, action);
        console.log("Error", error.message);
        yield* put(errorAction({ msg: error.message, type: "", errorCode: 0 }));
      }
      console.log("에러 config", error.config);
      // yield put(asyncActionCreator.failure(e));
    } finally {
      yield* put(stopAction({ name: action.type }));
    }
  };
}
