import "./Loading.scss";
import LOGO from "../../../assets/img/logo_fav.png";
import LOGO_WHITE from "../../../assets/img/logo_fav_white.png";
import { selectTeamsTheme } from "../../../features/auth";
import { useSelector } from "react-redux";
import { AnswerProfileImage } from "./AnswerProfileImage";

const LoadingAnswerComment = () => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <div className="LoadingAnswerComment_container">
      <div className="LoadingAnswerComment">
        <div
          className={`loading_search_type_wrap loading_search_type_wrap_${teamsTheme}`}
        >
          {teamsTheme === "default" ? (
            <img className="loading_search_type" src={LOGO} alt="doosan" />
          ) : (
            <img
              className="loading_search_type"
              src={LOGO_WHITE}
              alt="doosan"
            />
          )}
          {/* <AnswerProfileImage /> */}
        </div>
        <div
          className={`LoadingAnswerComment_wrap LoadingAnswerComment_wrap_${teamsTheme}`}
        >
          Generating answer
          <span className="loading">.</span>
          <span className="loading">.</span>
          <span className="loading">.</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnswerComment;
