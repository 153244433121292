// 허용가능한 확장자 목록!
export const ALLOW_FILE_EXTENSION =
  ".c, .cpp, .css, .csv, .docx, .gif, .html, .java, .jpeg, .jpg, .js, .json, .md, .pdf, .php, .png, .pptx, .py, .rb, .tar, .tex, .ts, .txt, .xlsx, .xml, .zip";
export const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 5MB

export const isValidFileExtension = ({ name }: { name: string }): boolean => {
  const extension = removeFileName(name);

  if (!(ALLOW_FILE_EXTENSION.indexOf(extension) > -1) || extension === "") {
    // 해당 if문이 수행되는 조건은
    // 1. 허용하지 않은 확장자일경우
    // 2. 확장자가 없는경우이다.

    return false;
  }
  return true;
};

/**
 * 해당 함수의 기능은 .을 제거한 순수 파일 확장자를 return해준다.
 * @param originalFileName 업로드할 파일명
 * @returns .을 제거한 순수 파일 확장자(png, jpg 등)
 */
export const removeFileName = (originalFileName: string): string => {
  const lastIndex = originalFileName.lastIndexOf(".");

  // 파일 이름에서 .이 존재하지 않는 경우
  if (lastIndex < 0) {
    return "";
  }

  // substring을 함수를 이용해 확장자만 잘라준다
  // lastIndex의 값은 마지막 .의 위치이기 때문에 해당 위치 다음부터 끝까지 문자열을 잘라준다.
  // 문자열을 자른 후 소문자로 변경시켜 확장자 값을 반환 해준다.
  return originalFileName.substring(lastIndex + 1).toLowerCase();
};

export const getNameAndExtension = (
  originalFileName: string
): [string, string] => {
  const lastIndex = originalFileName.lastIndexOf(".");

  // 파일 이름에서 .이 존재하지 않는 경우
  if (lastIndex < 0) {
    return [originalFileName.substring(0, lastIndex).toLowerCase(), ""];
  }

  // substring을 함수를 이용해 확장자만 잘라준다
  // lastIndex의 값은 마지막 .의 위치이기 때문에 해당 위치 다음부터 끝까지 문자열을 잘라준다.
  // 문자열을 자른 후 소문자로 변경시켜 확장자 값을 반환 해준다.

  return [
    originalFileName.substring(0, lastIndex).toLowerCase(),
    originalFileName.substring(lastIndex + 1).toLowerCase(),
  ];
};

export const Spreadsheet = "ods, xlsx, xls, Spreadsheet";
export const Document = "doc, docx, odt, Document ";
export const PDF = "pdf, PDF";
export const Presentation = "pptx, ppt, odp, Presentation";
export const Image = "png, webp, jpg, jpeg, gif, Image";

export const isImageFile = (extension: string) => {
  return Image.includes(extension) ? true : false;
};

export const getFileType = (extension: string) => {
  if (Spreadsheet.includes(extension)) return "Spreadsheet";
  if (Document.includes(extension)) return "Document";
  if (PDF.includes(extension)) return "PDF";
  if (Presentation.includes(extension)) return "Presentation";
  if (Image.includes(extension)) return "Image";
  else {
    return "File";
  }
};
