import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import "./inputs.scss";
import { memo } from "react";

type Props = {
  value: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  min?: string;
  max?: string;
};

const InputDateType: React.FC<Props> = ({
  value,
  onChangeHandler,
  max,
  min,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <input
      className={`input_date input_theme_${teamsTheme}`}
      type="date"
      value={value}
      max={max}
      min={min}
      onChange={onChangeHandler}
    />
  );
};

export default memo(InputDateType);
