import "./filter.scss";
import { memo } from "react";
import InputTextType from "../inputs/InputTextType";
import { useEffect, useRef, useState } from "react";
import { TiDelete } from "react-icons/ti";
import { selectTeamsTheme } from "../../../../features/auth";
import { useSelector } from "react-redux";
import InputCheckBoxLabel from "../inputs/InputCheckBoxLabel";
import { useParams } from "react-router-dom";

type Props = {
  title: string;
  checkedData: string[];
  defaultData: { name: string; value: string }[];
  onChangeHandler: (data: { name: string; value: string }) => void;
  allCheckHandler: () => void;
};

const FilterInputTextInCheckLableModal: React.FC<Props> = ({
  title,
  checkedData,
  defaultData,
  onChangeHandler,
  allCheckHandler,
}) => {
  const modalRef = useRef(null);
  const params = useParams();
  const teamsTheme = useSelector(selectTeamsTheme);

  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setSearch("");
  }, [params]);

  //! 모달 끄기
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        modalRef.current &&
        !(modalRef.current as any).contains(event.target)
      ) {
        setOpenModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className="filter_tool filter_tool_input_text">
      <h5>
        {title} ({checkedData.length})
      </h5>

      <div className="input_wrap">
        <InputTextType
          value={search}
          onChangeHandler={(e) => setOpenModal(true)}
          onClickHandler={() => setOpenModal(true)}
        />

        {openModal && (
          <div
            ref={modalRef}
            className={`input_in_modal_wrap input_in_modal_wrap_${teamsTheme}`}
          >
            <div className="input_in_modal_input_wrap">
              <input
                className={`input_in_modal_input input_in_modal_input_${teamsTheme}`}
                placeholder="Search"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search.length > 0 && (
                <button
                  onClick={() => setSearch("")}
                  className="searchText_in_modal_delete_btn"
                >
                  <TiDelete />
                </button>
              )}
            </div>

            <div className="input_in_modal_checkboxs scroll_none">
              <div className="input_in_modal_checkbox">
                <InputCheckBoxLabel
                  checked={defaultData.length === checkedData.length}
                  onChangeHandler={allCheckHandler}
                  checkboxId="all_check"
                  labelName="전체선택"
                />
              </div>
              {defaultData
                .filter((item) =>
                  item.name.toLowerCase().match(search.toLowerCase())
                )
                .map((item, idx) => (
                  <div key={idx} className="input_in_modal_checkbox">
                    <InputCheckBoxLabel
                      checked={checkedData.includes(item.value)}
                      onChangeHandler={(e) => onChangeHandler(item)}
                      checkboxId={item.value}
                      labelName={item.name}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(FilterInputTextInCheckLableModal);
