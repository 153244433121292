import axios from "axios";

export interface IAADParams {
  tenantId: string;
  hash?: string;
}

export interface IUserInfo {
  userId: string;
  userName: string;
}
export interface IAADUserInfo extends IUserInfo {
  isAgreed: boolean;
  isAdmin: boolean;
  baseUserSettings: ISetting;

  // 추가됨
  access_token: string;
  refresh_token: string;
  expires_delta: string;

  // 부서별 파일 업로드 권한 부여 위한 추가 정보
  deptCode: string;
  compCode: string;
  canAnalyzeFile: boolean;
}

export interface ILogin {
  accessToken: string;
  loginType: number;
}

export interface IAgreeParams {
  isAgreed: boolean;
  userId: string;
}

export async function aadLogin(params: ILogin): Promise<IAADUserInfo> {
  const res = await axios.post(`/auth/login`, params);
  return res.data;
}

export async function agreeTermsAndConditions(params: IAgreeParams) {
  const res = await axios.post(`/auth/isAgreed`, params);
  return res.data;
}

export async function getSetting() {
  const res = await axios.get(`/base/getUserSettings`);

  return res.data;
}

export type ChatUIType = "chatting" | "comment";
export interface ISetting {
  _id?: string;
  userId?: string;
  fontSize: string;
  font: string;
  chatType: ChatUIType;
  temperature: number;
  gptModels?: string[];
}

export async function updateSetting(params: ISetting) {
  const res = await axios.post(`/base/upsertUserSettings`, params);

  return res.data;
}
