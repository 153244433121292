import { useEffect, useState } from "react";
import "./Modal.scss";
import { useAppDispatch } from "../../../features";
import { useSelector } from "react-redux";
import chattingImg from "../../../assets/img/chatType.png";
import commentImg from "../../../assets/img/commentType.png";
import {
  ISetting,
  getSettingAsync,
  selectSettings,
  selectTeamsTheme,
  updateSettingAsync,
} from "../../../features/auth";
import InputRadioLabel from "../userInterface/inputs/InputRadioLabel";
import SelectType from "../userInterface/inputs/SelectType";
import ModalCancelBtn from "../userInterface/buttons/ModalCancelBtn";
import ModalRegisterBtn from "../userInterface/buttons/ModalRegisterBtn";

const fontSizeData = ["small", "medium", "large"];
const fontOptions = [
  { name: "noto sans kr", value: "noto sans kr" },
  { name: "malgun gothic", value: "malgun gothic" },
  { name: "gulim", value: "gulim" },
];

type Props = {
  onClose: () => void;
};

const SettingsModal: React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const settings = useSelector(selectSettings);
  const teamsTheme = useSelector(selectTeamsTheme);

  const [input, setInput] = useState<ISetting>(settings);

  useEffect(() => {
    dispatch(getSettingAsync.request(1));
  }, []);

  useEffect(() => {
    setInput(settings);
  }, [settings]);

  const updateSettings = () => {
    if (input._id) {
      dispatch(updateSettingAsync.request(input));
    } else {
      dispatch(
        updateSettingAsync.request({
          ...input,
          userId: input.userId,
          fontSize: input.fontSize,
          font: input.font,
          chatType: input.chatType,
          temperature: input.temperature,
        })
      );
    }

    onClose();
  };

  return (
    <div className={`modal_black_bg modal_black_bg_${teamsTheme}`}>
      <div className={`modal_white_bg modal_white_bg_${teamsTheme}`}>
        <hgroup>
          <h3 className="modal_title">Settings</h3>
        </hgroup>

        <table className={`modal_table_wrap modal_table_wrap_${teamsTheme}`}>
          <colgroup>
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr className="range_tr">
              <th className={`modal_table_th_${teamsTheme}`}>Temperature</th>
              <td>
                <div className={`range_wrap`}>
                  <p
                    className={`range_min_max range_min_max_${teamsTheme} range_min`}
                  >
                    0
                  </p>
                  <div className="range">
                    <div
                      className={`range_value_wrap range_value_wrap_${teamsTheme}`}
                    >
                      <span className="range_value">0.0</span>
                      <span className="range_value">0.1</span>
                      <span className="range_value">0.2</span>
                      <span className="range_value">0.3</span>
                      <span className="range_value">0.4</span>
                      <span className="range_value">0.5</span>
                      <span className="range_value">0.6</span>
                      <span className="range_value">0.7</span>
                      <span className="range_value">0.8</span>
                      <span className="range_value">0.9</span>
                      <span className="range_value">1.0</span>
                    </div>
                    <input
                      className={`input_range input_range_${teamsTheme}`}
                      min={0}
                      max={1}
                      step={0.1}
                      type="range"
                      value={input.temperature}
                      onChange={(e) =>
                        setInput({
                          ...input,
                          temperature: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                  <p
                    className={`range_min_max range_min_max_${teamsTheme} range_max`}
                  >
                    1
                  </p>
                </div>
              </td>
            </tr>

            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>Font Size</th>
              <td>
                {fontSizeData.map((size, idx) => (
                  <InputRadioLabel
                    key={`${size}_${idx}`}
                    checked={input.fontSize === size}
                    onChangeHandler={() =>
                      setInput({ ...input, fontSize: size })
                    }
                    radioId={size}
                    labelName={size}
                  />
                ))}
              </td>
            </tr>

            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>Font</th>
              <td>
                <SelectType
                  options={fontOptions}
                  onChangeHandler={(e) =>
                    setInput({ ...input, font: e.target.value })
                  }
                  value={input.font}
                />
              </td>
            </tr>

            <tr>
              <th className={`modal_table_th_${teamsTheme}`}>Chat Type</th>
              <td>
                <div className="img_radio_wrap">
                  <InputRadioLabel
                    checked={input.chatType === "chatting"}
                    onChangeHandler={() =>
                      setInput({ ...input, chatType: "chatting" })
                    }
                    radioId="chatting_type"
                    labelName="chatting"
                  />

                  <img src={chattingImg} alt="chatting type" />
                </div>

                <div className="img_radio_wrap">
                  <InputRadioLabel
                    checked={input.chatType === "comment"}
                    onChangeHandler={() =>
                      setInput({ ...input, chatType: "comment" })
                    }
                    radioId="comment_type"
                    labelName="comment"
                  />

                  <img src={commentImg} alt="comment type" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className={`modal_btn_wrap modal_btn_wrap_${teamsTheme}`}>
          <ModalCancelBtn onClickHandler={onClose} btnName="Close" />
          <ModalRegisterBtn onClickHandler={updateSettings} btnName="Save" />
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
